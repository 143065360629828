var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) =>
    function __require() {
        return (
            mod ||
                (0, cb[__getOwnPropNames(cb)[0]])(
                    (mod = { exports: {} }).exports,
                    mod
                ),
            mod.exports
        );
    };
var __copyProps = (to, from, except, desc) => {
    if ((from && typeof from === 'object') || typeof from === 'function') {
        for (let key of __getOwnPropNames(from))
            if (!__hasOwnProp.call(to, key) && key !== except)
                __defProp(to, key, {
                    get: () => from[key],
                    enumerable:
                        !(desc = __getOwnPropDesc(from, key)) ||
                        desc.enumerable,
                });
    }
    return to;
};
var __toESM = (mod, isNodeMode, target) => (
    (target = mod != null ? __create(__getProtoOf(mod)) : {}),
    __copyProps(
        // If the importer is in node compatibility mode or this is not an ESM
        // file that has been converted to a CommonJS file using a Babel-
        // compatible transform (i.e. "__esModule" has not been set), then set
        // "default" to the CommonJS "module.exports" for node compatibility.
        isNodeMode || !mod || !mod.__esModule
            ? __defProp(target, 'default', { value: mod, enumerable: true })
            : target,
        mod
    )
);

// node_modules/gsap/dist/ScrollTrigger.js
var require_ScrollTrigger = __commonJS({
    'node_modules/gsap/dist/ScrollTrigger.js'(exports, module) {
        (function (global, factory) {
            typeof exports === 'object' && typeof module !== 'undefined'
                ? factory(exports)
                : typeof define === 'function' && define.amd
                ? define(['exports'], factory)
                : ((global = global || self),
                  factory((global.window = global.window || {})));
        })(exports, function (exports2) {
            'use strict';
            function _defineProperties2(target, props) {
                for (var i = 0; i < props.length; i++) {
                    var descriptor = props[i];
                    descriptor.enumerable = descriptor.enumerable || false;
                    descriptor.configurable = true;
                    if ('value' in descriptor) descriptor.writable = true;
                    Object.defineProperty(target, descriptor.key, descriptor);
                }
            }
            function _createClass2(Constructor, protoProps, staticProps) {
                if (protoProps)
                    _defineProperties2(Constructor.prototype, protoProps);
                if (staticProps) _defineProperties2(Constructor, staticProps);
                return Constructor;
            }
            var gsap2,
                _coreInitted2,
                _clamp3,
                _win3,
                _doc3,
                _docEl,
                _body,
                _isTouch,
                _pointerType,
                ScrollTrigger2,
                _root,
                _normalizer,
                _eventTypes,
                _context2,
                _getGSAP = function _getGSAP2() {
                    return (
                        gsap2 ||
                        (typeof window !== 'undefined' &&
                            (gsap2 = window.gsap) &&
                            gsap2.registerPlugin &&
                            gsap2)
                    );
                },
                _startup = 1,
                _observers = [],
                _scrollers = [],
                _proxies = [],
                _getTime = Date.now,
                _bridge = function _bridge2(name, value) {
                    return value;
                },
                _integrate = function _integrate2() {
                    var core = ScrollTrigger2.core,
                        data = core.bridge || {},
                        scrollers = core._scrollers,
                        proxies = core._proxies;
                    scrollers.push.apply(scrollers, _scrollers);
                    proxies.push.apply(proxies, _proxies);
                    _scrollers = scrollers;
                    _proxies = proxies;
                    _bridge = function _bridge2(name, value) {
                        return data[name](value);
                    };
                },
                _getProxyProp = function _getProxyProp2(element, property) {
                    return (
                        ~_proxies.indexOf(element) &&
                        _proxies[_proxies.indexOf(element) + 1][property]
                    );
                },
                _isViewport = function _isViewport2(el) {
                    return !!~_root.indexOf(el);
                },
                _addListener = function _addListener2(
                    element,
                    type,
                    func,
                    passive,
                    capture
                ) {
                    return element.addEventListener(type, func, {
                        passive: passive !== false,
                        capture: !!capture,
                    });
                },
                _removeListener = function _removeListener2(
                    element,
                    type,
                    func,
                    capture
                ) {
                    return element.removeEventListener(type, func, !!capture);
                },
                _scrollLeft = 'scrollLeft',
                _scrollTop = 'scrollTop',
                _onScroll = function _onScroll2() {
                    return (
                        (_normalizer && _normalizer.isPressed) ||
                        _scrollers.cache++
                    );
                },
                _scrollCacheFunc = function _scrollCacheFunc2(f, doNotCache) {
                    var cachingFunc = function cachingFunc2(value) {
                        if (value || value === 0) {
                            _startup &&
                                (_win3.history.scrollRestoration = 'manual');
                            var isNormalizing =
                                _normalizer && _normalizer.isPressed;
                            value = cachingFunc2.v =
                                Math.round(value) ||
                                (_normalizer && _normalizer.iOS ? 1 : 0);
                            f(value);
                            cachingFunc2.cacheID = _scrollers.cache;
                            isNormalizing && _bridge('ss', value);
                        } else if (
                            doNotCache ||
                            _scrollers.cache !== cachingFunc2.cacheID ||
                            _bridge('ref')
                        ) {
                            cachingFunc2.cacheID = _scrollers.cache;
                            cachingFunc2.v = f();
                        }
                        return cachingFunc2.v + cachingFunc2.offset;
                    };
                    cachingFunc.offset = 0;
                    return f && cachingFunc;
                },
                _horizontal = {
                    s: _scrollLeft,
                    p: 'left',
                    p2: 'Left',
                    os: 'right',
                    os2: 'Right',
                    d: 'width',
                    d2: 'Width',
                    a: 'x',
                    sc: _scrollCacheFunc(function (value) {
                        return arguments.length
                            ? _win3.scrollTo(value, _vertical.sc())
                            : _win3.pageXOffset ||
                                  _doc3[_scrollLeft] ||
                                  _docEl[_scrollLeft] ||
                                  _body[_scrollLeft] ||
                                  0;
                    }),
                },
                _vertical = {
                    s: _scrollTop,
                    p: 'top',
                    p2: 'Top',
                    os: 'bottom',
                    os2: 'Bottom',
                    d: 'height',
                    d2: 'Height',
                    a: 'y',
                    op: _horizontal,
                    sc: _scrollCacheFunc(function (value) {
                        return arguments.length
                            ? _win3.scrollTo(_horizontal.sc(), value)
                            : _win3.pageYOffset ||
                                  _doc3[_scrollTop] ||
                                  _docEl[_scrollTop] ||
                                  _body[_scrollTop] ||
                                  0;
                    }),
                },
                _getTarget = function _getTarget2(t2, self2) {
                    return (
                        (
                            (self2 && self2._ctx && self2._ctx.selector) ||
                            gsap2.utils.toArray
                        )(t2)[0] ||
                        (typeof t2 === 'string' &&
                        gsap2.config().nullTargetWarn !== false
                            ? console.warn('Element not found:', t2)
                            : null)
                    );
                },
                _getScrollFunc = function _getScrollFunc2(element, _ref) {
                    var s = _ref.s,
                        sc = _ref.sc;
                    _isViewport(element) &&
                        (element = _doc3.scrollingElement || _docEl);
                    var i = _scrollers.indexOf(element),
                        offset = sc === _vertical.sc ? 1 : 2;
                    !~i && (i = _scrollers.push(element) - 1);
                    _scrollers[i + offset] ||
                        _addListener(element, 'scroll', _onScroll);
                    var prev = _scrollers[i + offset],
                        func =
                            prev ||
                            (_scrollers[i + offset] =
                                _scrollCacheFunc(
                                    _getProxyProp(element, s),
                                    true
                                ) ||
                                (_isViewport(element)
                                    ? sc
                                    : _scrollCacheFunc(function (value) {
                                          return arguments.length
                                              ? (element[s] = value)
                                              : element[s];
                                      })));
                    func.target = element;
                    prev ||
                        (func.smooth =
                            gsap2.getProperty(element, 'scrollBehavior') ===
                            'smooth');
                    return func;
                },
                _getVelocityProp = function _getVelocityProp2(
                    value,
                    minTimeRefresh,
                    useDelta
                ) {
                    var v1 = value,
                        v2 = value,
                        t1 = _getTime(),
                        t2 = t1,
                        min = minTimeRefresh || 50,
                        dropToZeroTime = Math.max(500, min * 3),
                        update2 = function update3(value2, force) {
                            var t3 = _getTime();
                            if (force || t3 - t1 > min) {
                                v2 = v1;
                                v1 = value2;
                                t2 = t1;
                                t1 = t3;
                            } else if (useDelta) {
                                v1 += value2;
                            } else {
                                v1 =
                                    v2 +
                                    ((value2 - v2) / (t3 - t2)) * (t1 - t2);
                            }
                        },
                        reset = function reset2() {
                            v2 = v1 = useDelta ? 0 : v1;
                            t2 = t1 = 0;
                        },
                        getVelocity = function getVelocity2(latestValue) {
                            var tOld = t2,
                                vOld = v2,
                                t3 = _getTime();
                            (latestValue || latestValue === 0) &&
                                latestValue !== v1 &&
                                update2(latestValue);
                            return t1 === t2 || t3 - t2 > dropToZeroTime
                                ? 0
                                : ((v1 + (useDelta ? vOld : -vOld)) /
                                      ((useDelta ? t3 : t1) - tOld)) *
                                      1e3;
                        };
                    return {
                        update: update2,
                        reset,
                        getVelocity,
                    };
                },
                _getEvent = function _getEvent2(e2, preventDefault) {
                    preventDefault && !e2._gsapAllow && e2.preventDefault();
                    return e2.changedTouches ? e2.changedTouches[0] : e2;
                },
                _getAbsoluteMax = function _getAbsoluteMax2(a) {
                    var max = Math.max.apply(Math, a),
                        min = Math.min.apply(Math, a);
                    return Math.abs(max) >= Math.abs(min) ? max : min;
                },
                _setScrollTrigger = function _setScrollTrigger2() {
                    ScrollTrigger2 = gsap2.core.globals().ScrollTrigger;
                    ScrollTrigger2 && ScrollTrigger2.core && _integrate();
                },
                _initCore3 = function _initCore4(core) {
                    gsap2 = core || _getGSAP();
                    if (
                        !_coreInitted2 &&
                        gsap2 &&
                        typeof document !== 'undefined' &&
                        document.body
                    ) {
                        _win3 = window;
                        _doc3 = document;
                        _docEl = _doc3.documentElement;
                        _body = _doc3.body;
                        _root = [_win3, _doc3, _docEl, _body];
                        _clamp3 = gsap2.utils.clamp;
                        _context2 = gsap2.core.context || function () {};
                        _pointerType =
                            'onpointerenter' in _body ? 'pointer' : 'mouse';
                        _isTouch = Observer2.isTouch =
                            _win3.matchMedia &&
                            _win3.matchMedia('(hover: none), (pointer: coarse)')
                                .matches
                                ? 1
                                : 'ontouchstart' in _win3 ||
                                  navigator.maxTouchPoints > 0 ||
                                  navigator.msMaxTouchPoints > 0
                                ? 2
                                : 0;
                        _eventTypes = Observer2.eventTypes = (
                            'ontouchstart' in _docEl
                                ? 'touchstart,touchmove,touchcancel,touchend'
                                : !('onpointerdown' in _docEl)
                                ? 'mousedown,mousemove,mouseup,mouseup'
                                : 'pointerdown,pointermove,pointercancel,pointerup'
                        ).split(',');
                        setTimeout(function () {
                            return (_startup = 0);
                        }, 500);
                        _setScrollTrigger();
                        _coreInitted2 = 1;
                    }
                    return _coreInitted2;
                };
            _horizontal.op = _vertical;
            _scrollers.cache = 0;
            var Observer2 = (function () {
                function Observer3(vars) {
                    this.init(vars);
                }
                var _proto = Observer3.prototype;
                _proto.init = function init4(vars) {
                    _coreInitted2 ||
                        _initCore3(gsap2) ||
                        console.warn('Please gsap.registerPlugin(Observer)');
                    ScrollTrigger2 || _setScrollTrigger();
                    var tolerance = vars.tolerance,
                        dragMinimum = vars.dragMinimum,
                        type = vars.type,
                        target = vars.target,
                        lineHeight = vars.lineHeight,
                        debounce = vars.debounce,
                        preventDefault = vars.preventDefault,
                        onStop = vars.onStop,
                        onStopDelay = vars.onStopDelay,
                        ignore = vars.ignore,
                        wheelSpeed = vars.wheelSpeed,
                        event2 = vars.event,
                        onDragStart = vars.onDragStart,
                        onDragEnd = vars.onDragEnd,
                        onDrag = vars.onDrag,
                        onPress = vars.onPress,
                        onRelease = vars.onRelease,
                        onRight = vars.onRight,
                        onLeft = vars.onLeft,
                        onUp = vars.onUp,
                        onDown = vars.onDown,
                        onChangeX = vars.onChangeX,
                        onChangeY = vars.onChangeY,
                        onChange = vars.onChange,
                        onToggleX = vars.onToggleX,
                        onToggleY = vars.onToggleY,
                        onHover = vars.onHover,
                        onHoverEnd = vars.onHoverEnd,
                        onMove = vars.onMove,
                        ignoreCheck = vars.ignoreCheck,
                        isNormalizer = vars.isNormalizer,
                        onGestureStart = vars.onGestureStart,
                        onGestureEnd = vars.onGestureEnd,
                        onWheel = vars.onWheel,
                        onEnable = vars.onEnable,
                        onDisable = vars.onDisable,
                        onClick2 = vars.onClick,
                        scrollSpeed = vars.scrollSpeed,
                        capture = vars.capture,
                        allowClicks = vars.allowClicks,
                        lockAxis = vars.lockAxis,
                        onLockAxis = vars.onLockAxis;
                    this.target = target = _getTarget(target) || _docEl;
                    this.vars = vars;
                    ignore && (ignore = gsap2.utils.toArray(ignore));
                    tolerance = tolerance || 1e-9;
                    dragMinimum = dragMinimum || 0;
                    wheelSpeed = wheelSpeed || 1;
                    scrollSpeed = scrollSpeed || 1;
                    type = type || 'wheel,touch,pointer';
                    debounce = debounce !== false;
                    lineHeight ||
                        (lineHeight =
                            parseFloat(
                                _win3.getComputedStyle(_body).lineHeight
                            ) || 22);
                    var id,
                        onStopDelayedCall,
                        dragged,
                        moved,
                        wheeled,
                        locked,
                        axis,
                        self2 = this,
                        prevDeltaX = 0,
                        prevDeltaY = 0,
                        passive = vars.passive || !preventDefault,
                        scrollFuncX = _getScrollFunc(target, _horizontal),
                        scrollFuncY = _getScrollFunc(target, _vertical),
                        scrollX = scrollFuncX(),
                        scrollY = scrollFuncY(),
                        limitToTouch =
                            ~type.indexOf('touch') &&
                            !~type.indexOf('pointer') &&
                            _eventTypes[0] === 'pointerdown',
                        isViewport = _isViewport(target),
                        ownerDoc = target.ownerDocument || _doc3,
                        deltaX = [0, 0, 0],
                        deltaY = [0, 0, 0],
                        onClickTime = 0,
                        clickCapture = function clickCapture2() {
                            return (onClickTime = _getTime());
                        },
                        _ignoreCheck = function _ignoreCheck2(
                            e2,
                            isPointerOrTouch
                        ) {
                            return (
                                ((self2.event = e2) &&
                                    ignore &&
                                    ~ignore.indexOf(e2.target)) ||
                                (isPointerOrTouch &&
                                    limitToTouch &&
                                    e2.pointerType !== 'touch') ||
                                (ignoreCheck &&
                                    ignoreCheck(e2, isPointerOrTouch))
                            );
                        },
                        onStopFunc = function onStopFunc2() {
                            self2._vx.reset();
                            self2._vy.reset();
                            onStopDelayedCall.pause();
                            onStop && onStop(self2);
                        },
                        update2 = function update3() {
                            var dx = (self2.deltaX = _getAbsoluteMax(deltaX)),
                                dy = (self2.deltaY = _getAbsoluteMax(deltaY)),
                                changedX = Math.abs(dx) >= tolerance,
                                changedY = Math.abs(dy) >= tolerance;
                            onChange &&
                                (changedX || changedY) &&
                                onChange(self2, dx, dy, deltaX, deltaY);
                            if (changedX) {
                                onRight && self2.deltaX > 0 && onRight(self2);
                                onLeft && self2.deltaX < 0 && onLeft(self2);
                                onChangeX && onChangeX(self2);
                                onToggleX &&
                                    self2.deltaX < 0 !== prevDeltaX < 0 &&
                                    onToggleX(self2);
                                prevDeltaX = self2.deltaX;
                                deltaX[0] = deltaX[1] = deltaX[2] = 0;
                            }
                            if (changedY) {
                                onDown && self2.deltaY > 0 && onDown(self2);
                                onUp && self2.deltaY < 0 && onUp(self2);
                                onChangeY && onChangeY(self2);
                                onToggleY &&
                                    self2.deltaY < 0 !== prevDeltaY < 0 &&
                                    onToggleY(self2);
                                prevDeltaY = self2.deltaY;
                                deltaY[0] = deltaY[1] = deltaY[2] = 0;
                            }
                            if (moved || dragged) {
                                onMove && onMove(self2);
                                if (dragged) {
                                    onDrag(self2);
                                    dragged = false;
                                }
                                moved = false;
                            }
                            locked &&
                                !(locked = false) &&
                                onLockAxis &&
                                onLockAxis(self2);
                            if (wheeled) {
                                onWheel(self2);
                                wheeled = false;
                            }
                            id = 0;
                        },
                        onDelta = function onDelta2(x, y, index) {
                            deltaX[index] += x;
                            deltaY[index] += y;
                            self2._vx.update(x);
                            self2._vy.update(y);
                            debounce
                                ? id || (id = requestAnimationFrame(update2))
                                : update2();
                        },
                        onTouchOrPointerDelta = function onTouchOrPointerDelta2(
                            x,
                            y
                        ) {
                            if (lockAxis && !axis) {
                                self2.axis = axis =
                                    Math.abs(x) > Math.abs(y) ? 'x' : 'y';
                                locked = true;
                            }
                            if (axis !== 'y') {
                                deltaX[2] += x;
                                self2._vx.update(x, true);
                            }
                            if (axis !== 'x') {
                                deltaY[2] += y;
                                self2._vy.update(y, true);
                            }
                            debounce
                                ? id || (id = requestAnimationFrame(update2))
                                : update2();
                        },
                        _onDrag = function _onDrag2(e2) {
                            if (_ignoreCheck(e2, 1)) {
                                return;
                            }
                            e2 = _getEvent(e2, preventDefault);
                            var x = e2.clientX,
                                y = e2.clientY,
                                dx = x - self2.x,
                                dy = y - self2.y,
                                isDragging = self2.isDragging;
                            self2.x = x;
                            self2.y = y;
                            if (
                                isDragging ||
                                Math.abs(self2.startX - x) >= dragMinimum ||
                                Math.abs(self2.startY - y) >= dragMinimum
                            ) {
                                onDrag && (dragged = true);
                                isDragging || (self2.isDragging = true);
                                onTouchOrPointerDelta(dx, dy);
                                isDragging ||
                                    (onDragStart && onDragStart(self2));
                            }
                        },
                        _onPress = (self2.onPress = function (e2) {
                            if (_ignoreCheck(e2, 1) || (e2 && e2.button)) {
                                return;
                            }
                            self2.axis = axis = null;
                            onStopDelayedCall.pause();
                            self2.isPressed = true;
                            e2 = _getEvent(e2);
                            prevDeltaX = prevDeltaY = 0;
                            self2.startX = self2.x = e2.clientX;
                            self2.startY = self2.y = e2.clientY;
                            self2._vx.reset();
                            self2._vy.reset();
                            _addListener(
                                isNormalizer ? target : ownerDoc,
                                _eventTypes[1],
                                _onDrag,
                                passive,
                                true
                            );
                            self2.deltaX = self2.deltaY = 0;
                            onPress && onPress(self2);
                        }),
                        _onRelease = (self2.onRelease = function (e2) {
                            if (_ignoreCheck(e2, 1)) {
                                return;
                            }
                            _removeListener(
                                isNormalizer ? target : ownerDoc,
                                _eventTypes[1],
                                _onDrag,
                                true
                            );
                            var isTrackingDrag = !isNaN(self2.y - self2.startY),
                                wasDragging = self2.isDragging,
                                isDragNotClick =
                                    wasDragging &&
                                    (Math.abs(self2.x - self2.startX) > 3 ||
                                        Math.abs(self2.y - self2.startY) > 3),
                                eventData = _getEvent(e2);
                            if (!isDragNotClick && isTrackingDrag) {
                                self2._vx.reset();
                                self2._vy.reset();
                                if (preventDefault && allowClicks) {
                                    gsap2.delayedCall(0.08, function () {
                                        if (
                                            _getTime() - onClickTime > 300 &&
                                            !e2.defaultPrevented
                                        ) {
                                            if (e2.target.click) {
                                                e2.target.click();
                                            } else if (ownerDoc.createEvent) {
                                                var syntheticEvent =
                                                    ownerDoc.createEvent(
                                                        'MouseEvents'
                                                    );
                                                syntheticEvent.initMouseEvent(
                                                    'click',
                                                    true,
                                                    true,
                                                    _win3,
                                                    1,
                                                    eventData.screenX,
                                                    eventData.screenY,
                                                    eventData.clientX,
                                                    eventData.clientY,
                                                    false,
                                                    false,
                                                    false,
                                                    false,
                                                    0,
                                                    null
                                                );
                                                e2.target.dispatchEvent(
                                                    syntheticEvent
                                                );
                                            }
                                        }
                                    });
                                }
                            }
                            self2.isDragging =
                                self2.isGesturing =
                                self2.isPressed =
                                    false;
                            onStop &&
                                wasDragging &&
                                !isNormalizer &&
                                onStopDelayedCall.restart(true);
                            onDragEnd && wasDragging && onDragEnd(self2);
                            onRelease && onRelease(self2, isDragNotClick);
                        }),
                        _onGestureStart = function _onGestureStart2(e2) {
                            return (
                                e2.touches &&
                                e2.touches.length > 1 &&
                                (self2.isGesturing = true) &&
                                onGestureStart(e2, self2.isDragging)
                            );
                        },
                        _onGestureEnd = function _onGestureEnd2() {
                            return (
                                (self2.isGesturing = false) ||
                                onGestureEnd(self2)
                            );
                        },
                        onScroll2 = function onScroll3(e2) {
                            if (_ignoreCheck(e2)) {
                                return;
                            }
                            var x = scrollFuncX(),
                                y = scrollFuncY();
                            onDelta(
                                (x - scrollX) * scrollSpeed,
                                (y - scrollY) * scrollSpeed,
                                1
                            );
                            scrollX = x;
                            scrollY = y;
                            onStop && onStopDelayedCall.restart(true);
                        },
                        _onWheel = function _onWheel2(e2) {
                            if (_ignoreCheck(e2)) {
                                return;
                            }
                            e2 = _getEvent(e2, preventDefault);
                            onWheel && (wheeled = true);
                            var multiplier =
                                (e2.deltaMode === 1
                                    ? lineHeight
                                    : e2.deltaMode === 2
                                    ? _win3.innerHeight
                                    : 1) * wheelSpeed;
                            onDelta(
                                e2.deltaX * multiplier,
                                e2.deltaY * multiplier,
                                0
                            );
                            onStop &&
                                !isNormalizer &&
                                onStopDelayedCall.restart(true);
                        },
                        _onMove = function _onMove2(e2) {
                            if (_ignoreCheck(e2)) {
                                return;
                            }
                            var x = e2.clientX,
                                y = e2.clientY,
                                dx = x - self2.x,
                                dy = y - self2.y;
                            self2.x = x;
                            self2.y = y;
                            moved = true;
                            onStop && onStopDelayedCall.restart(true);
                            (dx || dy) && onTouchOrPointerDelta(dx, dy);
                        },
                        _onHover = function _onHover2(e2) {
                            self2.event = e2;
                            onHover(self2);
                        },
                        _onHoverEnd = function _onHoverEnd2(e2) {
                            self2.event = e2;
                            onHoverEnd(self2);
                        },
                        _onClick = function _onClick2(e2) {
                            return (
                                _ignoreCheck(e2) ||
                                (_getEvent(e2, preventDefault) &&
                                    onClick2(self2))
                            );
                        };
                    onStopDelayedCall = self2._dc = gsap2
                        .delayedCall(onStopDelay || 0.25, onStopFunc)
                        .pause();
                    self2.deltaX = self2.deltaY = 0;
                    self2._vx = _getVelocityProp(0, 50, true);
                    self2._vy = _getVelocityProp(0, 50, true);
                    self2.scrollX = scrollFuncX;
                    self2.scrollY = scrollFuncY;
                    self2.isDragging =
                        self2.isGesturing =
                        self2.isPressed =
                            false;
                    _context2(this);
                    self2.enable = function (e2) {
                        if (!self2.isEnabled) {
                            _addListener(
                                isViewport ? ownerDoc : target,
                                'scroll',
                                _onScroll
                            );
                            type.indexOf('scroll') >= 0 &&
                                _addListener(
                                    isViewport ? ownerDoc : target,
                                    'scroll',
                                    onScroll2,
                                    passive,
                                    capture
                                );
                            type.indexOf('wheel') >= 0 &&
                                _addListener(
                                    target,
                                    'wheel',
                                    _onWheel,
                                    passive,
                                    capture
                                );
                            if (
                                (type.indexOf('touch') >= 0 && _isTouch) ||
                                type.indexOf('pointer') >= 0
                            ) {
                                _addListener(
                                    target,
                                    _eventTypes[0],
                                    _onPress,
                                    passive,
                                    capture
                                );
                                _addListener(
                                    ownerDoc,
                                    _eventTypes[2],
                                    _onRelease
                                );
                                _addListener(
                                    ownerDoc,
                                    _eventTypes[3],
                                    _onRelease
                                );
                                allowClicks &&
                                    _addListener(
                                        target,
                                        'click',
                                        clickCapture,
                                        true,
                                        true
                                    );
                                onClick2 &&
                                    _addListener(target, 'click', _onClick);
                                onGestureStart &&
                                    _addListener(
                                        ownerDoc,
                                        'gesturestart',
                                        _onGestureStart
                                    );
                                onGestureEnd &&
                                    _addListener(
                                        ownerDoc,
                                        'gestureend',
                                        _onGestureEnd
                                    );
                                onHover &&
                                    _addListener(
                                        target,
                                        _pointerType + 'enter',
                                        _onHover
                                    );
                                onHoverEnd &&
                                    _addListener(
                                        target,
                                        _pointerType + 'leave',
                                        _onHoverEnd
                                    );
                                onMove &&
                                    _addListener(
                                        target,
                                        _pointerType + 'move',
                                        _onMove
                                    );
                            }
                            self2.isEnabled = true;
                            e2 && e2.type && _onPress(e2);
                            onEnable && onEnable(self2);
                        }
                        return self2;
                    };
                    self2.disable = function () {
                        if (self2.isEnabled) {
                            _observers.filter(function (o) {
                                return o !== self2 && _isViewport(o.target);
                            }).length ||
                                _removeListener(
                                    isViewport ? ownerDoc : target,
                                    'scroll',
                                    _onScroll
                                );
                            if (self2.isPressed) {
                                self2._vx.reset();
                                self2._vy.reset();
                                _removeListener(
                                    isNormalizer ? target : ownerDoc,
                                    _eventTypes[1],
                                    _onDrag,
                                    true
                                );
                            }
                            _removeListener(
                                isViewport ? ownerDoc : target,
                                'scroll',
                                onScroll2,
                                capture
                            );
                            _removeListener(target, 'wheel', _onWheel, capture);
                            _removeListener(
                                target,
                                _eventTypes[0],
                                _onPress,
                                capture
                            );
                            _removeListener(
                                ownerDoc,
                                _eventTypes[2],
                                _onRelease
                            );
                            _removeListener(
                                ownerDoc,
                                _eventTypes[3],
                                _onRelease
                            );
                            _removeListener(
                                target,
                                'click',
                                clickCapture,
                                true
                            );
                            _removeListener(target, 'click', _onClick);
                            _removeListener(
                                ownerDoc,
                                'gesturestart',
                                _onGestureStart
                            );
                            _removeListener(
                                ownerDoc,
                                'gestureend',
                                _onGestureEnd
                            );
                            _removeListener(
                                target,
                                _pointerType + 'enter',
                                _onHover
                            );
                            _removeListener(
                                target,
                                _pointerType + 'leave',
                                _onHoverEnd
                            );
                            _removeListener(
                                target,
                                _pointerType + 'move',
                                _onMove
                            );
                            self2.isEnabled =
                                self2.isPressed =
                                self2.isDragging =
                                    false;
                            onDisable && onDisable(self2);
                        }
                    };
                    self2.kill = self2.revert = function () {
                        self2.disable();
                        var i = _observers.indexOf(self2);
                        i >= 0 && _observers.splice(i, 1);
                        _normalizer === self2 && (_normalizer = 0);
                    };
                    _observers.push(self2);
                    isNormalizer &&
                        _isViewport(target) &&
                        (_normalizer = self2);
                    self2.enable(event2);
                };
                _createClass2(Observer3, [
                    {
                        key: 'velocityX',
                        get: function get2() {
                            return this._vx.getVelocity();
                        },
                    },
                    {
                        key: 'velocityY',
                        get: function get2() {
                            return this._vy.getVelocity();
                        },
                    },
                ]);
                return Observer3;
            })();
            Observer2.version = '3.12.5';
            Observer2.create = function (vars) {
                return new Observer2(vars);
            };
            Observer2.register = _initCore3;
            Observer2.getAll = function () {
                return _observers.slice();
            };
            Observer2.getById = function (id) {
                return _observers.filter(function (o) {
                    return o.vars.id === id;
                })[0];
            };
            _getGSAP() && gsap2.registerPlugin(Observer2);
            var gsap$1,
                _coreInitted$1,
                _win$1,
                _doc$1,
                _docEl$1,
                _body$1,
                _root$1,
                _resizeDelay,
                _toArray,
                _clamp$1,
                _time2,
                _syncInterval,
                _refreshing,
                _pointerIsDown,
                _transformProp2,
                _i,
                _prevWidth,
                _prevHeight,
                _autoRefresh,
                _sort,
                _suppressOverwrites2,
                _ignoreResize,
                _normalizer$1,
                _ignoreMobileResize,
                _baseScreenHeight,
                _baseScreenWidth,
                _fixIOSBug,
                _context$1,
                _scrollRestoration,
                _div100vh,
                _100vh,
                _isReverted,
                _clampingMax,
                _limitCallbacks,
                _startup$1 = 1,
                _getTime$1 = Date.now,
                _time1 = _getTime$1(),
                _lastScrollTime = 0,
                _enabled = 0,
                _parseClamp = function _parseClamp2(value, type, self2) {
                    var clamp3 =
                        _isString3(value) &&
                        (value.substr(0, 6) === 'clamp(' ||
                            value.indexOf('max') > -1);
                    self2['_' + type + 'Clamp'] = clamp3;
                    return clamp3 ? value.substr(6, value.length - 7) : value;
                },
                _keepClamp = function _keepClamp2(value, clamp3) {
                    return clamp3 &&
                        (!_isString3(value) || value.substr(0, 6) !== 'clamp(')
                        ? 'clamp(' + value + ')'
                        : value;
                },
                _rafBugFix = function _rafBugFix2() {
                    return _enabled && requestAnimationFrame(_rafBugFix2);
                },
                _pointerDownHandler = function _pointerDownHandler2() {
                    return (_pointerIsDown = 1);
                },
                _pointerUpHandler = function _pointerUpHandler2() {
                    return (_pointerIsDown = 0);
                },
                _passThrough3 = function _passThrough4(v) {
                    return v;
                },
                _round3 = function _round4(value) {
                    return Math.round(value * 1e5) / 1e5 || 0;
                },
                _windowExists5 = function _windowExists6() {
                    return typeof window !== 'undefined';
                },
                _getGSAP$1 = function _getGSAP2() {
                    return (
                        gsap$1 ||
                        (_windowExists5() &&
                            (gsap$1 = window.gsap) &&
                            gsap$1.registerPlugin &&
                            gsap$1)
                    );
                },
                _isViewport$1 = function _isViewport2(e2) {
                    return !!~_root$1.indexOf(e2);
                },
                _getViewportDimension = function _getViewportDimension2(
                    dimensionProperty
                ) {
                    return (
                        (dimensionProperty === 'Height'
                            ? _100vh
                            : _win$1['inner' + dimensionProperty]) ||
                        _docEl$1['client' + dimensionProperty] ||
                        _body$1['client' + dimensionProperty]
                    );
                },
                _getBoundsFunc = function _getBoundsFunc2(element) {
                    return (
                        _getProxyProp(element, 'getBoundingClientRect') ||
                        (_isViewport$1(element)
                            ? function () {
                                  _winOffsets.width = _win$1.innerWidth;
                                  _winOffsets.height = _100vh;
                                  return _winOffsets;
                              }
                            : function () {
                                  return _getBounds(element);
                              })
                    );
                },
                _getSizeFunc = function _getSizeFunc2(
                    scroller,
                    isViewport,
                    _ref
                ) {
                    var d = _ref.d,
                        d2 = _ref.d2,
                        a = _ref.a;
                    return (a = _getProxyProp(
                        scroller,
                        'getBoundingClientRect'
                    ))
                        ? function () {
                              return a()[d];
                          }
                        : function () {
                              return (
                                  (isViewport
                                      ? _getViewportDimension(d2)
                                      : scroller['client' + d2]) || 0
                              );
                          };
                },
                _getOffsetsFunc = function _getOffsetsFunc2(
                    element,
                    isViewport
                ) {
                    return !isViewport || ~_proxies.indexOf(element)
                        ? _getBoundsFunc(element)
                        : function () {
                              return _winOffsets;
                          };
                },
                _maxScroll = function _maxScroll2(element, _ref2) {
                    var s = _ref2.s,
                        d2 = _ref2.d2,
                        d = _ref2.d,
                        a = _ref2.a;
                    return Math.max(
                        0,
                        (s = 'scroll' + d2) && (a = _getProxyProp(element, s))
                            ? a() - _getBoundsFunc(element)()[d]
                            : _isViewport$1(element)
                            ? (_docEl$1[s] || _body$1[s]) -
                              _getViewportDimension(d2)
                            : element[s] - element['offset' + d2]
                    );
                },
                _iterateAutoRefresh = function _iterateAutoRefresh2(
                    func,
                    events2
                ) {
                    for (var i = 0; i < _autoRefresh.length; i += 3) {
                        (!events2 || ~events2.indexOf(_autoRefresh[i + 1])) &&
                            func(
                                _autoRefresh[i],
                                _autoRefresh[i + 1],
                                _autoRefresh[i + 2]
                            );
                    }
                },
                _isString3 = function _isString4(value) {
                    return typeof value === 'string';
                },
                _isFunction3 = function _isFunction4(value) {
                    return typeof value === 'function';
                },
                _isNumber3 = function _isNumber4(value) {
                    return typeof value === 'number';
                },
                _isObject3 = function _isObject4(value) {
                    return typeof value === 'object';
                },
                _endAnimation = function _endAnimation2(
                    animation,
                    reversed,
                    pause
                ) {
                    return (
                        animation &&
                        animation.progress(reversed ? 0 : 1) &&
                        pause &&
                        animation.pause()
                    );
                },
                _callback3 = function _callback4(self2, func) {
                    if (self2.enabled) {
                        var result = self2._ctx
                            ? self2._ctx.add(function () {
                                  return func(self2);
                              })
                            : func(self2);
                        result &&
                            result.totalTime &&
                            (self2.callbackAnimation = result);
                    }
                },
                _abs = Math.abs,
                _left = 'left',
                _top = 'top',
                _right = 'right',
                _bottom = 'bottom',
                _width = 'width',
                _height = 'height',
                _Right = 'Right',
                _Left = 'Left',
                _Top = 'Top',
                _Bottom = 'Bottom',
                _padding = 'padding',
                _margin = 'margin',
                _Width = 'Width',
                _Height = 'Height',
                _px = 'px',
                _getComputedStyle = function _getComputedStyle2(element) {
                    return _win$1.getComputedStyle(element);
                },
                _makePositionable = function _makePositionable2(element) {
                    var position = _getComputedStyle(element).position;
                    element.style.position =
                        position === 'absolute' || position === 'fixed'
                            ? position
                            : 'relative';
                },
                _setDefaults3 = function _setDefaults4(obj, defaults4) {
                    for (var p in defaults4) {
                        p in obj || (obj[p] = defaults4[p]);
                    }
                    return obj;
                },
                _getBounds = function _getBounds2(element, withoutTransforms) {
                    var tween =
                            withoutTransforms &&
                            _getComputedStyle(element)[_transformProp2] !==
                                'matrix(1, 0, 0, 1, 0, 0)' &&
                            gsap$1
                                .to(element, {
                                    x: 0,
                                    y: 0,
                                    xPercent: 0,
                                    yPercent: 0,
                                    rotation: 0,
                                    rotationX: 0,
                                    rotationY: 0,
                                    scale: 1,
                                    skewX: 0,
                                    skewY: 0,
                                })
                                .progress(1),
                        bounds = element.getBoundingClientRect();
                    tween && tween.progress(0).kill();
                    return bounds;
                },
                _getSize = function _getSize2(element, _ref3) {
                    var d2 = _ref3.d2;
                    return (
                        element['offset' + d2] || element['client' + d2] || 0
                    );
                },
                _getLabelRatioArray = function _getLabelRatioArray2(timeline2) {
                    var a = [],
                        labels = timeline2.labels,
                        duration = timeline2.duration(),
                        p;
                    for (p in labels) {
                        a.push(labels[p] / duration);
                    }
                    return a;
                },
                _getClosestLabel = function _getClosestLabel2(animation) {
                    return function (value) {
                        return gsap$1.utils.snap(
                            _getLabelRatioArray(animation),
                            value
                        );
                    };
                },
                _snapDirectional = function _snapDirectional2(
                    snapIncrementOrArray
                ) {
                    var snap3 = gsap$1.utils.snap(snapIncrementOrArray),
                        a =
                            Array.isArray(snapIncrementOrArray) &&
                            snapIncrementOrArray
                                .slice(0)
                                .sort(function (a2, b) {
                                    return a2 - b;
                                });
                    return a
                        ? function (value, direction, threshold) {
                              if (threshold === void 0) {
                                  threshold = 1e-3;
                              }
                              var i;
                              if (!direction) {
                                  return snap3(value);
                              }
                              if (direction > 0) {
                                  value -= threshold;
                                  for (i = 0; i < a.length; i++) {
                                      if (a[i] >= value) {
                                          return a[i];
                                      }
                                  }
                                  return a[i - 1];
                              } else {
                                  i = a.length;
                                  value += threshold;
                                  while (i--) {
                                      if (a[i] <= value) {
                                          return a[i];
                                      }
                                  }
                              }
                              return a[0];
                          }
                        : function (value, direction, threshold) {
                              if (threshold === void 0) {
                                  threshold = 1e-3;
                              }
                              var snapped = snap3(value);
                              return !direction ||
                                  Math.abs(snapped - value) < threshold ||
                                  snapped - value < 0 === direction < 0
                                  ? snapped
                                  : snap3(
                                        direction < 0
                                            ? value - snapIncrementOrArray
                                            : value + snapIncrementOrArray
                                    );
                          };
                },
                _getLabelAtDirection = function _getLabelAtDirection2(
                    timeline2
                ) {
                    return function (value, st) {
                        return _snapDirectional(_getLabelRatioArray(timeline2))(
                            value,
                            st.direction
                        );
                    };
                },
                _multiListener = function _multiListener2(
                    func,
                    element,
                    types,
                    callback
                ) {
                    return types.split(',').forEach(function (type) {
                        return func(element, type, callback);
                    });
                },
                _addListener$1 = function _addListener2(
                    element,
                    type,
                    func,
                    nonPassive,
                    capture
                ) {
                    return element.addEventListener(type, func, {
                        passive: !nonPassive,
                        capture: !!capture,
                    });
                },
                _removeListener$1 = function _removeListener2(
                    element,
                    type,
                    func,
                    capture
                ) {
                    return element.removeEventListener(type, func, !!capture);
                },
                _wheelListener = function _wheelListener2(
                    func,
                    el,
                    scrollFunc
                ) {
                    scrollFunc = scrollFunc && scrollFunc.wheelHandler;
                    if (scrollFunc) {
                        func(el, 'wheel', scrollFunc);
                        func(el, 'touchmove', scrollFunc);
                    }
                },
                _markerDefaults = {
                    startColor: 'green',
                    endColor: 'red',
                    indent: 0,
                    fontSize: '16px',
                    fontWeight: 'normal',
                },
                _defaults3 = {
                    toggleActions: 'play',
                    anticipatePin: 0,
                },
                _keywords = {
                    top: 0,
                    left: 0,
                    center: 0.5,
                    bottom: 1,
                    right: 1,
                },
                _offsetToPx = function _offsetToPx2(value, size) {
                    if (_isString3(value)) {
                        var eqIndex = value.indexOf('='),
                            relative = ~eqIndex
                                ? +(value.charAt(eqIndex - 1) + 1) *
                                  parseFloat(value.substr(eqIndex + 1))
                                : 0;
                        if (~eqIndex) {
                            value.indexOf('%') > eqIndex &&
                                (relative *= size / 100);
                            value = value.substr(0, eqIndex - 1);
                        }
                        value =
                            relative +
                            (value in _keywords
                                ? _keywords[value] * size
                                : ~value.indexOf('%')
                                ? (parseFloat(value) * size) / 100
                                : parseFloat(value) || 0);
                    }
                    return value;
                },
                _createMarker = function _createMarker2(
                    type,
                    name,
                    container,
                    direction,
                    _ref4,
                    offset,
                    matchWidthEl,
                    containerAnimation
                ) {
                    var startColor = _ref4.startColor,
                        endColor = _ref4.endColor,
                        fontSize = _ref4.fontSize,
                        indent = _ref4.indent,
                        fontWeight = _ref4.fontWeight;
                    var e2 = _doc$1.createElement('div'),
                        useFixedPosition =
                            _isViewport$1(container) ||
                            _getProxyProp(container, 'pinType') === 'fixed',
                        isScroller = type.indexOf('scroller') !== -1,
                        parent = useFixedPosition ? _body$1 : container,
                        isStart = type.indexOf('start') !== -1,
                        color = isStart ? startColor : endColor,
                        css =
                            'border-color:' +
                            color +
                            ';font-size:' +
                            fontSize +
                            ';color:' +
                            color +
                            ';font-weight:' +
                            fontWeight +
                            ';pointer-events:none;white-space:nowrap;font-family:sans-serif,Arial;z-index:1000;padding:4px 8px;border-width:0;border-style:solid;';
                    css +=
                        'position:' +
                        ((isScroller || containerAnimation) && useFixedPosition
                            ? 'fixed;'
                            : 'absolute;');
                    (isScroller || containerAnimation || !useFixedPosition) &&
                        (css +=
                            (direction === _vertical ? _right : _bottom) +
                            ':' +
                            (offset + parseFloat(indent)) +
                            'px;');
                    matchWidthEl &&
                        (css +=
                            'box-sizing:border-box;text-align:left;width:' +
                            matchWidthEl.offsetWidth +
                            'px;');
                    e2._isStart = isStart;
                    e2.setAttribute(
                        'class',
                        'gsap-marker-' + type + (name ? ' marker-' + name : '')
                    );
                    e2.style.cssText = css;
                    e2.innerText =
                        name || name === 0 ? type + '-' + name : type;
                    parent.children[0]
                        ? parent.insertBefore(e2, parent.children[0])
                        : parent.appendChild(e2);
                    e2._offset = e2['offset' + direction.op.d2];
                    _positionMarker(e2, 0, direction, isStart);
                    return e2;
                },
                _positionMarker = function _positionMarker2(
                    marker,
                    start,
                    direction,
                    flipped
                ) {
                    var vars = {
                            display: 'block',
                        },
                        side = direction[flipped ? 'os2' : 'p2'],
                        oppositeSide = direction[flipped ? 'p2' : 'os2'];
                    marker._isFlipped = flipped;
                    vars[direction.a + 'Percent'] = flipped ? -100 : 0;
                    vars[direction.a] = flipped ? '1px' : 0;
                    vars['border' + side + _Width] = 1;
                    vars['border' + oppositeSide + _Width] = 0;
                    vars[direction.p] = start + 'px';
                    gsap$1.set(marker, vars);
                },
                _triggers = [],
                _ids = {},
                _rafID,
                _sync = function _sync2() {
                    return (
                        _getTime$1() - _lastScrollTime > 34 &&
                        (_rafID || (_rafID = requestAnimationFrame(_updateAll)))
                    );
                },
                _onScroll$1 = function _onScroll2() {
                    if (
                        !_normalizer$1 ||
                        !_normalizer$1.isPressed ||
                        _normalizer$1.startX > _body$1.clientWidth
                    ) {
                        _scrollers.cache++;
                        if (_normalizer$1) {
                            _rafID ||
                                (_rafID = requestAnimationFrame(_updateAll));
                        } else {
                            _updateAll();
                        }
                        _lastScrollTime || _dispatch3('scrollStart');
                        _lastScrollTime = _getTime$1();
                    }
                },
                _setBaseDimensions = function _setBaseDimensions2() {
                    _baseScreenWidth = _win$1.innerWidth;
                    _baseScreenHeight = _win$1.innerHeight;
                },
                _onResize = function _onResize2() {
                    _scrollers.cache++;
                    !_refreshing &&
                        !_ignoreResize &&
                        !_doc$1.fullscreenElement &&
                        !_doc$1.webkitFullscreenElement &&
                        (!_ignoreMobileResize ||
                            _baseScreenWidth !== _win$1.innerWidth ||
                            Math.abs(_win$1.innerHeight - _baseScreenHeight) >
                                _win$1.innerHeight * 0.25) &&
                        _resizeDelay.restart(true);
                },
                _listeners2 = {},
                _emptyArray2 = [],
                _softRefresh = function _softRefresh2() {
                    return (
                        _removeListener$1(
                            ScrollTrigger$1,
                            'scrollEnd',
                            _softRefresh2
                        ) || _refreshAll(true)
                    );
                },
                _dispatch3 = function _dispatch4(type) {
                    return (
                        (_listeners2[type] &&
                            _listeners2[type].map(function (f) {
                                return f();
                            })) ||
                        _emptyArray2
                    );
                },
                _savedStyles = [],
                _revertRecorded = function _revertRecorded2(media) {
                    for (var i = 0; i < _savedStyles.length; i += 5) {
                        if (
                            !media ||
                            (_savedStyles[i + 4] &&
                                _savedStyles[i + 4].query === media)
                        ) {
                            _savedStyles[i].style.cssText = _savedStyles[i + 1];
                            _savedStyles[i].getBBox &&
                                _savedStyles[i].setAttribute(
                                    'transform',
                                    _savedStyles[i + 2] || ''
                                );
                            _savedStyles[i + 3].uncache = 1;
                        }
                    }
                },
                _revertAll = function _revertAll2(kill, media) {
                    var trigger;
                    for (_i = 0; _i < _triggers.length; _i++) {
                        trigger = _triggers[_i];
                        if (trigger && (!media || trigger._ctx === media)) {
                            if (kill) {
                                trigger.kill(1);
                            } else {
                                trigger.revert(true, true);
                            }
                        }
                    }
                    _isReverted = true;
                    media && _revertRecorded(media);
                    media || _dispatch3('revert');
                },
                _clearScrollMemory = function _clearScrollMemory2(
                    scrollRestoration,
                    force
                ) {
                    _scrollers.cache++;
                    (force || !_refreshingAll) &&
                        _scrollers.forEach(function (obj) {
                            return (
                                _isFunction3(obj) &&
                                obj.cacheID++ &&
                                (obj.rec = 0)
                            );
                        });
                    _isString3(scrollRestoration) &&
                        (_win$1.history.scrollRestoration = _scrollRestoration =
                            scrollRestoration);
                },
                _refreshingAll,
                _refreshID = 0,
                _queueRefreshID,
                _queueRefreshAll = function _queueRefreshAll2() {
                    if (_queueRefreshID !== _refreshID) {
                        var id = (_queueRefreshID = _refreshID);
                        requestAnimationFrame(function () {
                            return id === _refreshID && _refreshAll(true);
                        });
                    }
                },
                _refresh100vh = function _refresh100vh2() {
                    _body$1.appendChild(_div100vh);
                    _100vh =
                        (!_normalizer$1 && _div100vh.offsetHeight) ||
                        _win$1.innerHeight;
                    _body$1.removeChild(_div100vh);
                },
                _hideAllMarkers = function _hideAllMarkers2(hide) {
                    return _toArray(
                        '.gsap-marker-start, .gsap-marker-end, .gsap-marker-scroller-start, .gsap-marker-scroller-end'
                    ).forEach(function (el) {
                        return (el.style.display = hide ? 'none' : 'block');
                    });
                },
                _refreshAll = function _refreshAll2(force, skipRevert) {
                    if (_lastScrollTime && !force && !_isReverted) {
                        _addListener$1(
                            ScrollTrigger$1,
                            'scrollEnd',
                            _softRefresh
                        );
                        return;
                    }
                    _refresh100vh();
                    _refreshingAll = ScrollTrigger$1.isRefreshing = true;
                    _scrollers.forEach(function (obj) {
                        return (
                            _isFunction3(obj) &&
                            ++obj.cacheID &&
                            (obj.rec = obj())
                        );
                    });
                    var refreshInits = _dispatch3('refreshInit');
                    _sort && ScrollTrigger$1.sort();
                    skipRevert || _revertAll();
                    _scrollers.forEach(function (obj) {
                        if (_isFunction3(obj)) {
                            obj.smooth &&
                                (obj.target.style.scrollBehavior = 'auto');
                            obj(0);
                        }
                    });
                    _triggers.slice(0).forEach(function (t2) {
                        return t2.refresh();
                    });
                    _isReverted = false;
                    _triggers.forEach(function (t2) {
                        if (t2._subPinOffset && t2.pin) {
                            var prop = t2.vars.horizontal
                                    ? 'offsetWidth'
                                    : 'offsetHeight',
                                original = t2.pin[prop];
                            t2.revert(true, 1);
                            t2.adjustPinSpacing(t2.pin[prop] - original);
                            t2.refresh();
                        }
                    });
                    _clampingMax = 1;
                    _hideAllMarkers(true);
                    _triggers.forEach(function (t2) {
                        var max = _maxScroll(t2.scroller, t2._dir),
                            endClamp =
                                t2.vars.end === 'max' ||
                                (t2._endClamp && t2.end > max),
                            startClamp = t2._startClamp && t2.start >= max;
                        (endClamp || startClamp) &&
                            t2.setPositions(
                                startClamp ? max - 1 : t2.start,
                                endClamp
                                    ? Math.max(
                                          startClamp ? max : t2.start + 1,
                                          max
                                      )
                                    : t2.end,
                                true
                            );
                    });
                    _hideAllMarkers(false);
                    _clampingMax = 0;
                    refreshInits.forEach(function (result) {
                        return result && result.render && result.render(-1);
                    });
                    _scrollers.forEach(function (obj) {
                        if (_isFunction3(obj)) {
                            obj.smooth &&
                                requestAnimationFrame(function () {
                                    return (obj.target.style.scrollBehavior =
                                        'smooth');
                                });
                            obj.rec && obj(obj.rec);
                        }
                    });
                    _clearScrollMemory(_scrollRestoration, 1);
                    _resizeDelay.pause();
                    _refreshID++;
                    _refreshingAll = 2;
                    _updateAll(2);
                    _triggers.forEach(function (t2) {
                        return (
                            _isFunction3(t2.vars.onRefresh) &&
                            t2.vars.onRefresh(t2)
                        );
                    });
                    _refreshingAll = ScrollTrigger$1.isRefreshing = false;
                    _dispatch3('refresh');
                },
                _lastScroll = 0,
                _direction = 1,
                _primary,
                _updateAll = function _updateAll2(force) {
                    if (force === 2 || (!_refreshingAll && !_isReverted)) {
                        ScrollTrigger$1.isUpdating = true;
                        _primary && _primary.update(0);
                        var l = _triggers.length,
                            time = _getTime$1(),
                            recordVelocity = time - _time1 >= 50,
                            scroll = l && _triggers[0].scroll();
                        _direction = _lastScroll > scroll ? -1 : 1;
                        _refreshingAll || (_lastScroll = scroll);
                        if (recordVelocity) {
                            if (
                                _lastScrollTime &&
                                !_pointerIsDown &&
                                time - _lastScrollTime > 200
                            ) {
                                _lastScrollTime = 0;
                                _dispatch3('scrollEnd');
                            }
                            _time2 = _time1;
                            _time1 = time;
                        }
                        if (_direction < 0) {
                            _i = l;
                            while (_i-- > 0) {
                                _triggers[_i] &&
                                    _triggers[_i].update(0, recordVelocity);
                            }
                            _direction = 1;
                        } else {
                            for (_i = 0; _i < l; _i++) {
                                _triggers[_i] &&
                                    _triggers[_i].update(0, recordVelocity);
                            }
                        }
                        ScrollTrigger$1.isUpdating = false;
                    }
                    _rafID = 0;
                },
                _propNamesToCopy = [
                    _left,
                    _top,
                    _bottom,
                    _right,
                    _margin + _Bottom,
                    _margin + _Right,
                    _margin + _Top,
                    _margin + _Left,
                    'display',
                    'flexShrink',
                    'float',
                    'zIndex',
                    'gridColumnStart',
                    'gridColumnEnd',
                    'gridRowStart',
                    'gridRowEnd',
                    'gridArea',
                    'justifySelf',
                    'alignSelf',
                    'placeSelf',
                    'order',
                ],
                _stateProps = _propNamesToCopy.concat([
                    _width,
                    _height,
                    'boxSizing',
                    'max' + _Width,
                    'max' + _Height,
                    'position',
                    _margin,
                    _padding,
                    _padding + _Top,
                    _padding + _Right,
                    _padding + _Bottom,
                    _padding + _Left,
                ]),
                _swapPinOut = function _swapPinOut2(pin, spacer, state) {
                    _setState(state);
                    var cache2 = pin._gsap;
                    if (cache2.spacerIsNative) {
                        _setState(cache2.spacerState);
                    } else if (pin._gsap.swappedIn) {
                        var parent = spacer.parentNode;
                        if (parent) {
                            parent.insertBefore(pin, spacer);
                            parent.removeChild(spacer);
                        }
                    }
                    pin._gsap.swappedIn = false;
                },
                _swapPinIn = function _swapPinIn2(
                    pin,
                    spacer,
                    cs,
                    spacerState
                ) {
                    if (!pin._gsap.swappedIn) {
                        var i = _propNamesToCopy.length,
                            spacerStyle = spacer.style,
                            pinStyle = pin.style,
                            p;
                        while (i--) {
                            p = _propNamesToCopy[i];
                            spacerStyle[p] = cs[p];
                        }
                        spacerStyle.position =
                            cs.position === 'absolute'
                                ? 'absolute'
                                : 'relative';
                        cs.display === 'inline' &&
                            (spacerStyle.display = 'inline-block');
                        pinStyle[_bottom] = pinStyle[_right] = 'auto';
                        spacerStyle.flexBasis = cs.flexBasis || 'auto';
                        spacerStyle.overflow = 'visible';
                        spacerStyle.boxSizing = 'border-box';
                        spacerStyle[_width] = _getSize(pin, _horizontal) + _px;
                        spacerStyle[_height] = _getSize(pin, _vertical) + _px;
                        spacerStyle[_padding] =
                            pinStyle[_margin] =
                            pinStyle[_top] =
                            pinStyle[_left] =
                                '0';
                        _setState(spacerState);
                        pinStyle[_width] = pinStyle['max' + _Width] =
                            cs[_width];
                        pinStyle[_height] = pinStyle['max' + _Height] =
                            cs[_height];
                        pinStyle[_padding] = cs[_padding];
                        if (pin.parentNode !== spacer) {
                            pin.parentNode.insertBefore(spacer, pin);
                            spacer.appendChild(pin);
                        }
                        pin._gsap.swappedIn = true;
                    }
                },
                _capsExp2 = /([A-Z])/g,
                _setState = function _setState2(state) {
                    if (state) {
                        var style = state.t.style,
                            l = state.length,
                            i = 0,
                            p,
                            value;
                        (
                            state.t._gsap || gsap$1.core.getCache(state.t)
                        ).uncache = 1;
                        for (; i < l; i += 2) {
                            value = state[i + 1];
                            p = state[i];
                            if (value) {
                                style[p] = value;
                            } else if (style[p]) {
                                style.removeProperty(
                                    p.replace(_capsExp2, '-$1').toLowerCase()
                                );
                            }
                        }
                    }
                },
                _getState = function _getState2(element) {
                    var l = _stateProps.length,
                        style = element.style,
                        state = [],
                        i = 0;
                    for (; i < l; i++) {
                        state.push(_stateProps[i], style[_stateProps[i]]);
                    }
                    state.t = element;
                    return state;
                },
                _copyState = function _copyState2(
                    state,
                    override,
                    omitOffsets
                ) {
                    var result = [],
                        l = state.length,
                        i = omitOffsets ? 8 : 0,
                        p;
                    for (; i < l; i += 2) {
                        p = state[i];
                        result.push(
                            p,
                            p in override ? override[p] : state[i + 1]
                        );
                    }
                    result.t = state.t;
                    return result;
                },
                _winOffsets = {
                    left: 0,
                    top: 0,
                },
                _parsePosition3 = function _parsePosition4(
                    value,
                    trigger,
                    scrollerSize,
                    direction,
                    scroll,
                    marker,
                    markerScroller,
                    self2,
                    scrollerBounds,
                    borderWidth,
                    useFixedPosition,
                    scrollerMax,
                    containerAnimation,
                    clampZeroProp
                ) {
                    _isFunction3(value) && (value = value(self2));
                    if (_isString3(value) && value.substr(0, 3) === 'max') {
                        value =
                            scrollerMax +
                            (value.charAt(4) === '='
                                ? _offsetToPx(
                                      '0' + value.substr(3),
                                      scrollerSize
                                  )
                                : 0);
                    }
                    var time = containerAnimation
                            ? containerAnimation.time()
                            : 0,
                        p1,
                        p2,
                        element;
                    containerAnimation && containerAnimation.seek(0);
                    isNaN(value) || (value = +value);
                    if (!_isNumber3(value)) {
                        _isFunction3(trigger) && (trigger = trigger(self2));
                        var offsets = (value || '0').split(' '),
                            bounds,
                            localOffset,
                            globalOffset,
                            display;
                        element = _getTarget(trigger, self2) || _body$1;
                        bounds = _getBounds(element) || {};
                        if (
                            (!bounds || (!bounds.left && !bounds.top)) &&
                            _getComputedStyle(element).display === 'none'
                        ) {
                            display = element.style.display;
                            element.style.display = 'block';
                            bounds = _getBounds(element);
                            display
                                ? (element.style.display = display)
                                : element.style.removeProperty('display');
                        }
                        localOffset = _offsetToPx(
                            offsets[0],
                            bounds[direction.d]
                        );
                        globalOffset = _offsetToPx(
                            offsets[1] || '0',
                            scrollerSize
                        );
                        value =
                            bounds[direction.p] -
                            scrollerBounds[direction.p] -
                            borderWidth +
                            localOffset +
                            scroll -
                            globalOffset;
                        markerScroller &&
                            _positionMarker(
                                markerScroller,
                                globalOffset,
                                direction,
                                scrollerSize - globalOffset < 20 ||
                                    (markerScroller._isStart &&
                                        globalOffset > 20)
                            );
                        scrollerSize -= scrollerSize - globalOffset;
                    } else {
                        containerAnimation &&
                            (value = gsap$1.utils.mapRange(
                                containerAnimation.scrollTrigger.start,
                                containerAnimation.scrollTrigger.end,
                                0,
                                scrollerMax,
                                value
                            ));
                        markerScroller &&
                            _positionMarker(
                                markerScroller,
                                scrollerSize,
                                direction,
                                true
                            );
                    }
                    if (clampZeroProp) {
                        self2[clampZeroProp] = value || -1e-3;
                        value < 0 && (value = 0);
                    }
                    if (marker) {
                        var position = value + scrollerSize,
                            isStart = marker._isStart;
                        p1 = 'scroll' + direction.d2;
                        _positionMarker(
                            marker,
                            position,
                            direction,
                            (isStart && position > 20) ||
                                (!isStart &&
                                    (useFixedPosition
                                        ? Math.max(_body$1[p1], _docEl$1[p1])
                                        : marker.parentNode[p1]) <=
                                        position + 1)
                        );
                        if (useFixedPosition) {
                            scrollerBounds = _getBounds(markerScroller);
                            useFixedPosition &&
                                (marker.style[direction.op.p] =
                                    scrollerBounds[direction.op.p] -
                                    direction.op.m -
                                    marker._offset +
                                    _px);
                        }
                    }
                    if (containerAnimation && element) {
                        p1 = _getBounds(element);
                        containerAnimation.seek(scrollerMax);
                        p2 = _getBounds(element);
                        containerAnimation._caScrollDist =
                            p1[direction.p] - p2[direction.p];
                        value =
                            (value / containerAnimation._caScrollDist) *
                            scrollerMax;
                    }
                    containerAnimation && containerAnimation.seek(time);
                    return containerAnimation ? value : Math.round(value);
                },
                _prefixExp = /(webkit|moz|length|cssText|inset)/i,
                _reparent = function _reparent2(element, parent, top, left) {
                    if (element.parentNode !== parent) {
                        var style = element.style,
                            p,
                            cs;
                        if (parent === _body$1) {
                            element._stOrig = style.cssText;
                            cs = _getComputedStyle(element);
                            for (p in cs) {
                                if (
                                    !+p &&
                                    !_prefixExp.test(p) &&
                                    cs[p] &&
                                    typeof style[p] === 'string' &&
                                    p !== '0'
                                ) {
                                    style[p] = cs[p];
                                }
                            }
                            style.top = top;
                            style.left = left;
                        } else {
                            style.cssText = element._stOrig;
                        }
                        gsap$1.core.getCache(element).uncache = 1;
                        parent.appendChild(element);
                    }
                },
                _interruptionTracker = function _interruptionTracker2(
                    getValueFunc,
                    initialValue,
                    onInterrupt
                ) {
                    var last1 = initialValue,
                        last2 = last1;
                    return function (value) {
                        var current = Math.round(getValueFunc());
                        if (
                            current !== last1 &&
                            current !== last2 &&
                            Math.abs(current - last1) > 3 &&
                            Math.abs(current - last2) > 3
                        ) {
                            value = current;
                            onInterrupt && onInterrupt();
                        }
                        last2 = last1;
                        last1 = value;
                        return value;
                    };
                },
                _shiftMarker = function _shiftMarker2(
                    marker,
                    direction,
                    value
                ) {
                    var vars = {};
                    vars[direction.p] = '+=' + value;
                    gsap$1.set(marker, vars);
                },
                _getTweenCreator = function _getTweenCreator2(
                    scroller,
                    direction
                ) {
                    var getScroll = _getScrollFunc(scroller, direction),
                        prop = '_scroll' + direction.p2,
                        getTween = function getTween2(
                            scrollTo,
                            vars,
                            initialValue,
                            change1,
                            change2
                        ) {
                            var tween = getTween2.tween,
                                onComplete = vars.onComplete,
                                modifiers = {};
                            initialValue = initialValue || getScroll();
                            var checkForInterruption = _interruptionTracker(
                                getScroll,
                                initialValue,
                                function () {
                                    tween.kill();
                                    getTween2.tween = 0;
                                }
                            );
                            change2 = (change1 && change2) || 0;
                            change1 = change1 || scrollTo - initialValue;
                            tween && tween.kill();
                            vars[prop] = scrollTo;
                            vars.inherit = false;
                            vars.modifiers = modifiers;
                            modifiers[prop] = function () {
                                return checkForInterruption(
                                    initialValue +
                                        change1 * tween.ratio +
                                        change2 * tween.ratio * tween.ratio
                                );
                            };
                            vars.onUpdate = function () {
                                _scrollers.cache++;
                                getTween2.tween && _updateAll();
                            };
                            vars.onComplete = function () {
                                getTween2.tween = 0;
                                onComplete && onComplete.call(tween);
                            };
                            tween = getTween2.tween = gsap$1.to(scroller, vars);
                            return tween;
                        };
                    scroller[prop] = getScroll;
                    getScroll.wheelHandler = function () {
                        return (
                            getTween.tween &&
                            getTween.tween.kill() &&
                            (getTween.tween = 0)
                        );
                    };
                    _addListener$1(scroller, 'wheel', getScroll.wheelHandler);
                    ScrollTrigger$1.isTouch &&
                        _addListener$1(
                            scroller,
                            'touchmove',
                            getScroll.wheelHandler
                        );
                    return getTween;
                };
            var ScrollTrigger$1 = (function () {
                function ScrollTrigger3(vars, animation) {
                    _coreInitted$1 ||
                        ScrollTrigger3.register(gsap$1) ||
                        console.warn(
                            'Please gsap.registerPlugin(ScrollTrigger)'
                        );
                    _context$1(this);
                    this.init(vars, animation);
                }
                var _proto = ScrollTrigger3.prototype;
                _proto.init = function init4(vars, animation) {
                    this.progress = this.start = 0;
                    this.vars && this.kill(true, true);
                    if (!_enabled) {
                        this.update = this.refresh = this.kill = _passThrough3;
                        return;
                    }
                    vars = _setDefaults3(
                        _isString3(vars) || _isNumber3(vars) || vars.nodeType
                            ? {
                                  trigger: vars,
                              }
                            : vars,
                        _defaults3
                    );
                    var _vars = vars,
                        onUpdate = _vars.onUpdate,
                        toggleClass = _vars.toggleClass,
                        id = _vars.id,
                        onToggle = _vars.onToggle,
                        onRefresh = _vars.onRefresh,
                        scrub = _vars.scrub,
                        trigger = _vars.trigger,
                        pin = _vars.pin,
                        pinSpacing = _vars.pinSpacing,
                        invalidateOnRefresh = _vars.invalidateOnRefresh,
                        anticipatePin = _vars.anticipatePin,
                        onScrubComplete = _vars.onScrubComplete,
                        onSnapComplete = _vars.onSnapComplete,
                        once = _vars.once,
                        snap3 = _vars.snap,
                        pinReparent = _vars.pinReparent,
                        pinSpacer = _vars.pinSpacer,
                        containerAnimation = _vars.containerAnimation,
                        fastScrollEnd = _vars.fastScrollEnd,
                        preventOverlaps = _vars.preventOverlaps,
                        direction =
                            vars.horizontal ||
                            (vars.containerAnimation &&
                                vars.horizontal !== false)
                                ? _horizontal
                                : _vertical,
                        isToggle = !scrub && scrub !== 0,
                        scroller = _getTarget(vars.scroller || _win$1),
                        scrollerCache = gsap$1.core.getCache(scroller),
                        isViewport = _isViewport$1(scroller),
                        useFixedPosition =
                            ('pinType' in vars
                                ? vars.pinType
                                : _getProxyProp(scroller, 'pinType') ||
                                  (isViewport && 'fixed')) === 'fixed',
                        callbacks = [
                            vars.onEnter,
                            vars.onLeave,
                            vars.onEnterBack,
                            vars.onLeaveBack,
                        ],
                        toggleActions =
                            isToggle && vars.toggleActions.split(' '),
                        markers =
                            'markers' in vars
                                ? vars.markers
                                : _defaults3.markers,
                        borderWidth = isViewport
                            ? 0
                            : parseFloat(
                                  _getComputedStyle(scroller)[
                                      'border' + direction.p2 + _Width
                                  ]
                              ) || 0,
                        self2 = this,
                        onRefreshInit =
                            vars.onRefreshInit &&
                            function () {
                                return vars.onRefreshInit(self2);
                            },
                        getScrollerSize = _getSizeFunc(
                            scroller,
                            isViewport,
                            direction
                        ),
                        getScrollerOffsets = _getOffsetsFunc(
                            scroller,
                            isViewport
                        ),
                        lastSnap = 0,
                        lastRefresh = 0,
                        prevProgress = 0,
                        scrollFunc = _getScrollFunc(scroller, direction),
                        tweenTo,
                        pinCache,
                        snapFunc,
                        scroll1,
                        scroll2,
                        start,
                        end,
                        markerStart,
                        markerEnd,
                        markerStartTrigger,
                        markerEndTrigger,
                        markerVars,
                        executingOnRefresh,
                        change,
                        pinOriginalState,
                        pinActiveState,
                        pinState,
                        spacer,
                        offset,
                        pinGetter,
                        pinSetter,
                        pinStart,
                        pinChange,
                        spacingStart,
                        spacerState,
                        markerStartSetter,
                        pinMoves,
                        markerEndSetter,
                        cs,
                        snap1,
                        snap22,
                        scrubTween,
                        scrubSmooth,
                        snapDurClamp,
                        snapDelayedCall,
                        prevScroll,
                        prevAnimProgress,
                        caMarkerSetter,
                        customRevertReturn;
                    self2._startClamp = self2._endClamp = false;
                    self2._dir = direction;
                    anticipatePin *= 45;
                    self2.scroller = scroller;
                    self2.scroll = containerAnimation
                        ? containerAnimation.time.bind(containerAnimation)
                        : scrollFunc;
                    scroll1 = scrollFunc();
                    self2.vars = vars;
                    animation = animation || vars.animation;
                    if ('refreshPriority' in vars) {
                        _sort = 1;
                        vars.refreshPriority === -9999 && (_primary = self2);
                    }
                    scrollerCache.tweenScroll = scrollerCache.tweenScroll || {
                        top: _getTweenCreator(scroller, _vertical),
                        left: _getTweenCreator(scroller, _horizontal),
                    };
                    self2.tweenTo = tweenTo =
                        scrollerCache.tweenScroll[direction.p];
                    self2.scrubDuration = function (value) {
                        scrubSmooth = _isNumber3(value) && value;
                        if (!scrubSmooth) {
                            scrubTween && scrubTween.progress(1).kill();
                            scrubTween = 0;
                        } else {
                            scrubTween
                                ? scrubTween.duration(value)
                                : (scrubTween = gsap$1.to(animation, {
                                      ease: 'expo',
                                      totalProgress: '+=0',
                                      inherit: false,
                                      duration: scrubSmooth,
                                      paused: true,
                                      onComplete: function onComplete() {
                                          return (
                                              onScrubComplete &&
                                              onScrubComplete(self2)
                                          );
                                      },
                                  }));
                        }
                    };
                    if (animation) {
                        animation.vars.lazy = false;
                        (animation._initted && !self2.isReverted) ||
                            (animation.vars.immediateRender !== false &&
                                vars.immediateRender !== false &&
                                animation.duration() &&
                                animation.render(0, true, true));
                        self2.animation = animation.pause();
                        animation.scrollTrigger = self2;
                        self2.scrubDuration(scrub);
                        snap1 = 0;
                        id || (id = animation.vars.id);
                    }
                    if (snap3) {
                        if (!_isObject3(snap3) || snap3.push) {
                            snap3 = {
                                snapTo: snap3,
                            };
                        }
                        'scrollBehavior' in _body$1.style &&
                            gsap$1.set(
                                isViewport ? [_body$1, _docEl$1] : scroller,
                                {
                                    scrollBehavior: 'auto',
                                }
                            );
                        _scrollers.forEach(function (o) {
                            return (
                                _isFunction3(o) &&
                                o.target ===
                                    (isViewport
                                        ? _doc$1.scrollingElement || _docEl$1
                                        : scroller) &&
                                (o.smooth = false)
                            );
                        });
                        snapFunc = _isFunction3(snap3.snapTo)
                            ? snap3.snapTo
                            : snap3.snapTo === 'labels'
                            ? _getClosestLabel(animation)
                            : snap3.snapTo === 'labelsDirectional'
                            ? _getLabelAtDirection(animation)
                            : snap3.directional !== false
                            ? function (value, st) {
                                  return _snapDirectional(snap3.snapTo)(
                                      value,
                                      _getTime$1() - lastRefresh < 500
                                          ? 0
                                          : st.direction
                                  );
                              }
                            : gsap$1.utils.snap(snap3.snapTo);
                        snapDurClamp = snap3.duration || {
                            min: 0.1,
                            max: 2,
                        };
                        snapDurClamp = _isObject3(snapDurClamp)
                            ? _clamp$1(snapDurClamp.min, snapDurClamp.max)
                            : _clamp$1(snapDurClamp, snapDurClamp);
                        snapDelayedCall = gsap$1
                            .delayedCall(
                                snap3.delay || scrubSmooth / 2 || 0.1,
                                function () {
                                    var scroll = scrollFunc(),
                                        refreshedRecently =
                                            _getTime$1() - lastRefresh < 500,
                                        tween = tweenTo.tween;
                                    if (
                                        (refreshedRecently ||
                                            Math.abs(self2.getVelocity()) <
                                                10) &&
                                        !tween &&
                                        !_pointerIsDown &&
                                        lastSnap !== scroll
                                    ) {
                                        var progress =
                                                (scroll - start) / change,
                                            totalProgress =
                                                animation && !isToggle
                                                    ? animation.totalProgress()
                                                    : progress,
                                            velocity = refreshedRecently
                                                ? 0
                                                : ((totalProgress - snap22) /
                                                      (_getTime$1() - _time2)) *
                                                      1e3 || 0,
                                            change1 = gsap$1.utils.clamp(
                                                -progress,
                                                1 - progress,
                                                (_abs(velocity / 2) *
                                                    velocity) /
                                                    0.185
                                            ),
                                            naturalEnd =
                                                progress +
                                                (snap3.inertia === false
                                                    ? 0
                                                    : change1),
                                            endValue,
                                            endScroll,
                                            _snap = snap3,
                                            onStart = _snap.onStart,
                                            _onInterrupt = _snap.onInterrupt,
                                            _onComplete = _snap.onComplete;
                                        endValue = snapFunc(naturalEnd, self2);
                                        _isNumber3(endValue) ||
                                            (endValue = naturalEnd);
                                        endScroll = Math.round(
                                            start + endValue * change
                                        );
                                        if (
                                            scroll <= end &&
                                            scroll >= start &&
                                            endScroll !== scroll
                                        ) {
                                            if (
                                                tween &&
                                                !tween._initted &&
                                                tween.data <=
                                                    _abs(endScroll - scroll)
                                            ) {
                                                return;
                                            }
                                            if (snap3.inertia === false) {
                                                change1 = endValue - progress;
                                            }
                                            tweenTo(
                                                endScroll,
                                                {
                                                    duration: snapDurClamp(
                                                        _abs(
                                                            (Math.max(
                                                                _abs(
                                                                    naturalEnd -
                                                                        totalProgress
                                                                ),
                                                                _abs(
                                                                    endValue -
                                                                        totalProgress
                                                                )
                                                            ) *
                                                                0.185) /
                                                                velocity /
                                                                0.05 || 0
                                                        )
                                                    ),
                                                    ease:
                                                        snap3.ease || 'power3',
                                                    data: _abs(
                                                        endScroll - scroll
                                                    ),
                                                    onInterrupt:
                                                        function onInterrupt() {
                                                            return (
                                                                snapDelayedCall.restart(
                                                                    true
                                                                ) &&
                                                                _onInterrupt &&
                                                                _onInterrupt(
                                                                    self2
                                                                )
                                                            );
                                                        },
                                                    onComplete:
                                                        function onComplete() {
                                                            self2.update();
                                                            lastSnap =
                                                                scrollFunc();
                                                            if (animation) {
                                                                scrubTween
                                                                    ? scrubTween.resetTo(
                                                                          'totalProgress',
                                                                          endValue,
                                                                          animation._tTime /
                                                                              animation._tDur
                                                                      )
                                                                    : animation.progress(
                                                                          endValue
                                                                      );
                                                            }
                                                            snap1 = snap22 =
                                                                animation &&
                                                                !isToggle
                                                                    ? animation.totalProgress()
                                                                    : self2.progress;
                                                            onSnapComplete &&
                                                                onSnapComplete(
                                                                    self2
                                                                );
                                                            _onComplete &&
                                                                _onComplete(
                                                                    self2
                                                                );
                                                        },
                                                },
                                                scroll,
                                                change1 * change,
                                                endScroll -
                                                    scroll -
                                                    change1 * change
                                            );
                                            onStart &&
                                                onStart(self2, tweenTo.tween);
                                        }
                                    } else if (
                                        self2.isActive &&
                                        lastSnap !== scroll
                                    ) {
                                        snapDelayedCall.restart(true);
                                    }
                                }
                            )
                            .pause();
                    }
                    id && (_ids[id] = self2);
                    trigger = self2.trigger = _getTarget(
                        trigger || (pin !== true && pin)
                    );
                    customRevertReturn =
                        trigger && trigger._gsap && trigger._gsap.stRevert;
                    customRevertReturn &&
                        (customRevertReturn = customRevertReturn(self2));
                    pin = pin === true ? trigger : _getTarget(pin);
                    _isString3(toggleClass) &&
                        (toggleClass = {
                            targets: trigger,
                            className: toggleClass,
                        });
                    if (pin) {
                        pinSpacing === false ||
                            pinSpacing === _margin ||
                            (pinSpacing =
                                !pinSpacing &&
                                pin.parentNode &&
                                pin.parentNode.style &&
                                _getComputedStyle(pin.parentNode).display ===
                                    'flex'
                                    ? false
                                    : _padding);
                        self2.pin = pin;
                        pinCache = gsap$1.core.getCache(pin);
                        if (!pinCache.spacer) {
                            if (pinSpacer) {
                                pinSpacer = _getTarget(pinSpacer);
                                pinSpacer &&
                                    !pinSpacer.nodeType &&
                                    (pinSpacer =
                                        pinSpacer.current ||
                                        pinSpacer.nativeElement);
                                pinCache.spacerIsNative = !!pinSpacer;
                                pinSpacer &&
                                    (pinCache.spacerState =
                                        _getState(pinSpacer));
                            }
                            pinCache.spacer = spacer =
                                pinSpacer || _doc$1.createElement('div');
                            spacer.classList.add('pin-spacer');
                            id && spacer.classList.add('pin-spacer-' + id);
                            pinCache.pinState = pinOriginalState =
                                _getState(pin);
                        } else {
                            pinOriginalState = pinCache.pinState;
                        }
                        vars.force3D !== false &&
                            gsap$1.set(pin, {
                                force3D: true,
                            });
                        self2.spacer = spacer = pinCache.spacer;
                        cs = _getComputedStyle(pin);
                        spacingStart = cs[pinSpacing + direction.os2];
                        pinGetter = gsap$1.getProperty(pin);
                        pinSetter = gsap$1.quickSetter(pin, direction.a, _px);
                        _swapPinIn(pin, spacer, cs);
                        pinState = _getState(pin);
                    }
                    if (markers) {
                        markerVars = _isObject3(markers)
                            ? _setDefaults3(markers, _markerDefaults)
                            : _markerDefaults;
                        markerStartTrigger = _createMarker(
                            'scroller-start',
                            id,
                            scroller,
                            direction,
                            markerVars,
                            0
                        );
                        markerEndTrigger = _createMarker(
                            'scroller-end',
                            id,
                            scroller,
                            direction,
                            markerVars,
                            0,
                            markerStartTrigger
                        );
                        offset = markerStartTrigger['offset' + direction.op.d2];
                        var content = _getTarget(
                            _getProxyProp(scroller, 'content') || scroller
                        );
                        markerStart = this.markerStart = _createMarker(
                            'start',
                            id,
                            content,
                            direction,
                            markerVars,
                            offset,
                            0,
                            containerAnimation
                        );
                        markerEnd = this.markerEnd = _createMarker(
                            'end',
                            id,
                            content,
                            direction,
                            markerVars,
                            offset,
                            0,
                            containerAnimation
                        );
                        containerAnimation &&
                            (caMarkerSetter = gsap$1.quickSetter(
                                [markerStart, markerEnd],
                                direction.a,
                                _px
                            ));
                        if (
                            !useFixedPosition &&
                            !(
                                _proxies.length &&
                                _getProxyProp(scroller, 'fixedMarkers') === true
                            )
                        ) {
                            _makePositionable(isViewport ? _body$1 : scroller);
                            gsap$1.set([markerStartTrigger, markerEndTrigger], {
                                force3D: true,
                            });
                            markerStartSetter = gsap$1.quickSetter(
                                markerStartTrigger,
                                direction.a,
                                _px
                            );
                            markerEndSetter = gsap$1.quickSetter(
                                markerEndTrigger,
                                direction.a,
                                _px
                            );
                        }
                    }
                    if (containerAnimation) {
                        var oldOnUpdate = containerAnimation.vars.onUpdate,
                            oldParams = containerAnimation.vars.onUpdateParams;
                        containerAnimation.eventCallback(
                            'onUpdate',
                            function () {
                                self2.update(0, 0, 1);
                                oldOnUpdate &&
                                    oldOnUpdate.apply(
                                        containerAnimation,
                                        oldParams || []
                                    );
                            }
                        );
                    }
                    self2.previous = function () {
                        return _triggers[_triggers.indexOf(self2) - 1];
                    };
                    self2.next = function () {
                        return _triggers[_triggers.indexOf(self2) + 1];
                    };
                    self2.revert = function (revert, temp) {
                        if (!temp) {
                            return self2.kill(true);
                        }
                        var r = revert !== false || !self2.enabled,
                            prevRefreshing = _refreshing;
                        if (r !== self2.isReverted) {
                            if (r) {
                                prevScroll = Math.max(
                                    scrollFunc(),
                                    self2.scroll.rec || 0
                                );
                                prevProgress = self2.progress;
                                prevAnimProgress =
                                    animation && animation.progress();
                            }
                            markerStart &&
                                [
                                    markerStart,
                                    markerEnd,
                                    markerStartTrigger,
                                    markerEndTrigger,
                                ].forEach(function (m) {
                                    return (m.style.display = r
                                        ? 'none'
                                        : 'block');
                                });
                            if (r) {
                                _refreshing = self2;
                                self2.update(r);
                            }
                            if (pin && (!pinReparent || !self2.isActive)) {
                                if (r) {
                                    _swapPinOut(pin, spacer, pinOriginalState);
                                } else {
                                    _swapPinIn(
                                        pin,
                                        spacer,
                                        _getComputedStyle(pin),
                                        spacerState
                                    );
                                }
                            }
                            r || self2.update(r);
                            _refreshing = prevRefreshing;
                            self2.isReverted = r;
                        }
                    };
                    self2.refresh = function (
                        soft,
                        force,
                        position,
                        pinOffset
                    ) {
                        if ((_refreshing || !self2.enabled) && !force) {
                            return;
                        }
                        if (pin && soft && _lastScrollTime) {
                            _addListener$1(
                                ScrollTrigger3,
                                'scrollEnd',
                                _softRefresh
                            );
                            return;
                        }
                        !_refreshingAll &&
                            onRefreshInit &&
                            onRefreshInit(self2);
                        _refreshing = self2;
                        if (tweenTo.tween && !position) {
                            tweenTo.tween.kill();
                            tweenTo.tween = 0;
                        }
                        scrubTween && scrubTween.pause();
                        invalidateOnRefresh &&
                            animation &&
                            animation
                                .revert({
                                    kill: false,
                                })
                                .invalidate();
                        self2.isReverted || self2.revert(true, true);
                        self2._subPinOffset = false;
                        var size = getScrollerSize(),
                            scrollerBounds = getScrollerOffsets(),
                            max = containerAnimation
                                ? containerAnimation.duration()
                                : _maxScroll(scroller, direction),
                            isFirstRefresh = change <= 0.01,
                            offset2 = 0,
                            otherPinOffset = pinOffset || 0,
                            parsedEnd = _isObject3(position)
                                ? position.end
                                : vars.end,
                            parsedEndTrigger = vars.endTrigger || trigger,
                            parsedStart = _isObject3(position)
                                ? position.start
                                : vars.start ||
                                  (vars.start === 0 || !trigger
                                      ? 0
                                      : pin
                                      ? '0 0'
                                      : '0 100%'),
                            pinnedContainer = (self2.pinnedContainer =
                                vars.pinnedContainer &&
                                _getTarget(vars.pinnedContainer, self2)),
                            triggerIndex =
                                (trigger &&
                                    Math.max(0, _triggers.indexOf(self2))) ||
                                0,
                            i = triggerIndex,
                            cs2,
                            bounds,
                            scroll,
                            isVertical,
                            override,
                            curTrigger,
                            curPin,
                            oppositeScroll,
                            initted,
                            revertedPins,
                            forcedOverflow,
                            markerStartOffset,
                            markerEndOffset;
                        if (markers && _isObject3(position)) {
                            markerStartOffset = gsap$1.getProperty(
                                markerStartTrigger,
                                direction.p
                            );
                            markerEndOffset = gsap$1.getProperty(
                                markerEndTrigger,
                                direction.p
                            );
                        }
                        while (i--) {
                            curTrigger = _triggers[i];
                            curTrigger.end ||
                                curTrigger.refresh(0, 1) ||
                                (_refreshing = self2);
                            curPin = curTrigger.pin;
                            if (
                                curPin &&
                                (curPin === trigger ||
                                    curPin === pin ||
                                    curPin === pinnedContainer) &&
                                !curTrigger.isReverted
                            ) {
                                revertedPins || (revertedPins = []);
                                revertedPins.unshift(curTrigger);
                                curTrigger.revert(true, true);
                            }
                            if (curTrigger !== _triggers[i]) {
                                triggerIndex--;
                                i--;
                            }
                        }
                        _isFunction3(parsedStart) &&
                            (parsedStart = parsedStart(self2));
                        parsedStart = _parseClamp(parsedStart, 'start', self2);
                        start =
                            _parsePosition3(
                                parsedStart,
                                trigger,
                                size,
                                direction,
                                scrollFunc(),
                                markerStart,
                                markerStartTrigger,
                                self2,
                                scrollerBounds,
                                borderWidth,
                                useFixedPosition,
                                max,
                                containerAnimation,
                                self2._startClamp && '_startClamp'
                            ) || (pin ? -1e-3 : 0);
                        _isFunction3(parsedEnd) &&
                            (parsedEnd = parsedEnd(self2));
                        if (_isString3(parsedEnd) && !parsedEnd.indexOf('+=')) {
                            if (~parsedEnd.indexOf(' ')) {
                                parsedEnd =
                                    (_isString3(parsedStart)
                                        ? parsedStart.split(' ')[0]
                                        : '') + parsedEnd;
                            } else {
                                offset2 = _offsetToPx(
                                    parsedEnd.substr(2),
                                    size
                                );
                                parsedEnd = _isString3(parsedStart)
                                    ? parsedStart
                                    : (containerAnimation
                                          ? gsap$1.utils.mapRange(
                                                0,
                                                containerAnimation.duration(),
                                                containerAnimation.scrollTrigger
                                                    .start,
                                                containerAnimation.scrollTrigger
                                                    .end,
                                                start
                                            )
                                          : start) + offset2;
                                parsedEndTrigger = trigger;
                            }
                        }
                        parsedEnd = _parseClamp(parsedEnd, 'end', self2);
                        end =
                            Math.max(
                                start,
                                _parsePosition3(
                                    parsedEnd ||
                                        (parsedEndTrigger ? '100% 0' : max),
                                    parsedEndTrigger,
                                    size,
                                    direction,
                                    scrollFunc() + offset2,
                                    markerEnd,
                                    markerEndTrigger,
                                    self2,
                                    scrollerBounds,
                                    borderWidth,
                                    useFixedPosition,
                                    max,
                                    containerAnimation,
                                    self2._endClamp && '_endClamp'
                                )
                            ) || -1e-3;
                        offset2 = 0;
                        i = triggerIndex;
                        while (i--) {
                            curTrigger = _triggers[i];
                            curPin = curTrigger.pin;
                            if (
                                curPin &&
                                curTrigger.start - curTrigger._pinPush <=
                                    start &&
                                !containerAnimation &&
                                curTrigger.end > 0
                            ) {
                                cs2 =
                                    curTrigger.end -
                                    (self2._startClamp
                                        ? Math.max(0, curTrigger.start)
                                        : curTrigger.start);
                                if (
                                    ((curPin === trigger &&
                                        curTrigger.start - curTrigger._pinPush <
                                            start) ||
                                        curPin === pinnedContainer) &&
                                    isNaN(parsedStart)
                                ) {
                                    offset2 += cs2 * (1 - curTrigger.progress);
                                }
                                curPin === pin && (otherPinOffset += cs2);
                            }
                        }
                        start += offset2;
                        end += offset2;
                        self2._startClamp && (self2._startClamp += offset2);
                        if (self2._endClamp && !_refreshingAll) {
                            self2._endClamp = end || -1e-3;
                            end = Math.min(
                                end,
                                _maxScroll(scroller, direction)
                            );
                        }
                        change = end - start || ((start -= 0.01) && 1e-3);
                        if (isFirstRefresh) {
                            prevProgress = gsap$1.utils.clamp(
                                0,
                                1,
                                gsap$1.utils.normalize(start, end, prevScroll)
                            );
                        }
                        self2._pinPush = otherPinOffset;
                        if (markerStart && offset2) {
                            cs2 = {};
                            cs2[direction.a] = '+=' + offset2;
                            pinnedContainer &&
                                (cs2[direction.p] = '-=' + scrollFunc());
                            gsap$1.set([markerStart, markerEnd], cs2);
                        }
                        if (
                            pin &&
                            !(
                                _clampingMax &&
                                self2.end >= _maxScroll(scroller, direction)
                            )
                        ) {
                            cs2 = _getComputedStyle(pin);
                            isVertical = direction === _vertical;
                            scroll = scrollFunc();
                            pinStart =
                                parseFloat(pinGetter(direction.a)) +
                                otherPinOffset;
                            if (!max && end > 1) {
                                forcedOverflow = (
                                    isViewport
                                        ? _doc$1.scrollingElement || _docEl$1
                                        : scroller
                                ).style;
                                forcedOverflow = {
                                    style: forcedOverflow,
                                    value: forcedOverflow[
                                        'overflow' + direction.a.toUpperCase()
                                    ],
                                };
                                if (
                                    isViewport &&
                                    _getComputedStyle(_body$1)[
                                        'overflow' + direction.a.toUpperCase()
                                    ] !== 'scroll'
                                ) {
                                    forcedOverflow.style[
                                        'overflow' + direction.a.toUpperCase()
                                    ] = 'scroll';
                                }
                            }
                            _swapPinIn(pin, spacer, cs2);
                            pinState = _getState(pin);
                            bounds = _getBounds(pin, true);
                            oppositeScroll =
                                useFixedPosition &&
                                _getScrollFunc(
                                    scroller,
                                    isVertical ? _horizontal : _vertical
                                )();
                            if (pinSpacing) {
                                spacerState = [
                                    pinSpacing + direction.os2,
                                    change + otherPinOffset + _px,
                                ];
                                spacerState.t = spacer;
                                i =
                                    pinSpacing === _padding
                                        ? _getSize(pin, direction) +
                                          change +
                                          otherPinOffset
                                        : 0;
                                if (i) {
                                    spacerState.push(direction.d, i + _px);
                                    spacer.style.flexBasis !== 'auto' &&
                                        (spacer.style.flexBasis = i + _px);
                                }
                                _setState(spacerState);
                                if (pinnedContainer) {
                                    _triggers.forEach(function (t2) {
                                        if (
                                            t2.pin === pinnedContainer &&
                                            t2.vars.pinSpacing !== false
                                        ) {
                                            t2._subPinOffset = true;
                                        }
                                    });
                                }
                                useFixedPosition && scrollFunc(prevScroll);
                            } else {
                                i = _getSize(pin, direction);
                                i &&
                                    spacer.style.flexBasis !== 'auto' &&
                                    (spacer.style.flexBasis = i + _px);
                            }
                            if (useFixedPosition) {
                                override = {
                                    top:
                                        bounds.top +
                                        (isVertical
                                            ? scroll - start
                                            : oppositeScroll) +
                                        _px,
                                    left:
                                        bounds.left +
                                        (isVertical
                                            ? oppositeScroll
                                            : scroll - start) +
                                        _px,
                                    boxSizing: 'border-box',
                                    position: 'fixed',
                                };
                                override[_width] = override['max' + _Width] =
                                    Math.ceil(bounds.width) + _px;
                                override[_height] = override['max' + _Height] =
                                    Math.ceil(bounds.height) + _px;
                                override[_margin] =
                                    override[_margin + _Top] =
                                    override[_margin + _Right] =
                                    override[_margin + _Bottom] =
                                    override[_margin + _Left] =
                                        '0';
                                override[_padding] = cs2[_padding];
                                override[_padding + _Top] =
                                    cs2[_padding + _Top];
                                override[_padding + _Right] =
                                    cs2[_padding + _Right];
                                override[_padding + _Bottom] =
                                    cs2[_padding + _Bottom];
                                override[_padding + _Left] =
                                    cs2[_padding + _Left];
                                pinActiveState = _copyState(
                                    pinOriginalState,
                                    override,
                                    pinReparent
                                );
                                _refreshingAll && scrollFunc(0);
                            }
                            if (animation) {
                                initted = animation._initted;
                                _suppressOverwrites2(1);
                                animation.render(
                                    animation.duration(),
                                    true,
                                    true
                                );
                                pinChange =
                                    pinGetter(direction.a) -
                                    pinStart +
                                    change +
                                    otherPinOffset;
                                pinMoves = Math.abs(change - pinChange) > 1;
                                useFixedPosition &&
                                    pinMoves &&
                                    pinActiveState.splice(
                                        pinActiveState.length - 2,
                                        2
                                    );
                                animation.render(0, true, true);
                                initted || animation.invalidate(true);
                                animation.parent ||
                                    animation.totalTime(animation.totalTime());
                                _suppressOverwrites2(0);
                            } else {
                                pinChange = change;
                            }
                            forcedOverflow &&
                                (forcedOverflow.value
                                    ? (forcedOverflow.style[
                                          'overflow' + direction.a.toUpperCase()
                                      ] = forcedOverflow.value)
                                    : forcedOverflow.style.removeProperty(
                                          'overflow-' + direction.a
                                      ));
                        } else if (
                            trigger &&
                            scrollFunc() &&
                            !containerAnimation
                        ) {
                            bounds = trigger.parentNode;
                            while (bounds && bounds !== _body$1) {
                                if (bounds._pinOffset) {
                                    start -= bounds._pinOffset;
                                    end -= bounds._pinOffset;
                                }
                                bounds = bounds.parentNode;
                            }
                        }
                        revertedPins &&
                            revertedPins.forEach(function (t2) {
                                return t2.revert(false, true);
                            });
                        self2.start = start;
                        self2.end = end;
                        scroll1 = scroll2 = _refreshingAll
                            ? prevScroll
                            : scrollFunc();
                        if (!containerAnimation && !_refreshingAll) {
                            scroll1 < prevScroll && scrollFunc(prevScroll);
                            self2.scroll.rec = 0;
                        }
                        self2.revert(false, true);
                        lastRefresh = _getTime$1();
                        if (snapDelayedCall) {
                            lastSnap = -1;
                            snapDelayedCall.restart(true);
                        }
                        _refreshing = 0;
                        animation &&
                            isToggle &&
                            (animation._initted || prevAnimProgress) &&
                            animation.progress() !== prevAnimProgress &&
                            animation
                                .progress(prevAnimProgress || 0, true)
                                .render(animation.time(), true, true);
                        if (
                            isFirstRefresh ||
                            prevProgress !== self2.progress ||
                            containerAnimation ||
                            invalidateOnRefresh
                        ) {
                            animation &&
                                !isToggle &&
                                animation.totalProgress(
                                    containerAnimation &&
                                        start < -1e-3 &&
                                        !prevProgress
                                        ? gsap$1.utils.normalize(start, end, 0)
                                        : prevProgress,
                                    true
                                );
                            self2.progress =
                                isFirstRefresh ||
                                (scroll1 - start) / change === prevProgress
                                    ? 0
                                    : prevProgress;
                        }
                        pin &&
                            pinSpacing &&
                            (spacer._pinOffset = Math.round(
                                self2.progress * pinChange
                            ));
                        scrubTween && scrubTween.invalidate();
                        if (!isNaN(markerStartOffset)) {
                            markerStartOffset -= gsap$1.getProperty(
                                markerStartTrigger,
                                direction.p
                            );
                            markerEndOffset -= gsap$1.getProperty(
                                markerEndTrigger,
                                direction.p
                            );
                            _shiftMarker(
                                markerStartTrigger,
                                direction,
                                markerStartOffset
                            );
                            _shiftMarker(
                                markerStart,
                                direction,
                                markerStartOffset - (pinOffset || 0)
                            );
                            _shiftMarker(
                                markerEndTrigger,
                                direction,
                                markerEndOffset
                            );
                            _shiftMarker(
                                markerEnd,
                                direction,
                                markerEndOffset - (pinOffset || 0)
                            );
                        }
                        isFirstRefresh && !_refreshingAll && self2.update();
                        if (
                            onRefresh &&
                            !_refreshingAll &&
                            !executingOnRefresh
                        ) {
                            executingOnRefresh = true;
                            onRefresh(self2);
                            executingOnRefresh = false;
                        }
                    };
                    self2.getVelocity = function () {
                        return (
                            ((scrollFunc() - scroll2) /
                                (_getTime$1() - _time2)) *
                                1e3 || 0
                        );
                    };
                    self2.endAnimation = function () {
                        _endAnimation(self2.callbackAnimation);
                        if (animation) {
                            scrubTween
                                ? scrubTween.progress(1)
                                : !animation.paused()
                                ? _endAnimation(animation, animation.reversed())
                                : isToggle ||
                                  _endAnimation(
                                      animation,
                                      self2.direction < 0,
                                      1
                                  );
                        }
                    };
                    self2.labelToScroll = function (label) {
                        return (
                            (animation &&
                                animation.labels &&
                                (start || self2.refresh() || start) +
                                    (animation.labels[label] /
                                        animation.duration()) *
                                        change) ||
                            0
                        );
                    };
                    self2.getTrailing = function (name) {
                        var i = _triggers.indexOf(self2),
                            a =
                                self2.direction > 0
                                    ? _triggers.slice(0, i).reverse()
                                    : _triggers.slice(i + 1);
                        return (
                            _isString3(name)
                                ? a.filter(function (t2) {
                                      return t2.vars.preventOverlaps === name;
                                  })
                                : a
                        ).filter(function (t2) {
                            return self2.direction > 0
                                ? t2.end <= start
                                : t2.start >= end;
                        });
                    };
                    self2.update = function (reset, recordVelocity, forceFake) {
                        if (containerAnimation && !forceFake && !reset) {
                            return;
                        }
                        var scroll =
                                _refreshingAll === true
                                    ? prevScroll
                                    : self2.scroll(),
                            p = reset ? 0 : (scroll - start) / change,
                            clipped = p < 0 ? 0 : p > 1 ? 1 : p || 0,
                            prevProgress2 = self2.progress,
                            isActive,
                            wasActive,
                            toggleState,
                            action,
                            stateChanged,
                            toggled,
                            isAtMax,
                            isTakingAction;
                        if (recordVelocity) {
                            scroll2 = scroll1;
                            scroll1 = containerAnimation
                                ? scrollFunc()
                                : scroll;
                            if (snap3) {
                                snap22 = snap1;
                                snap1 =
                                    animation && !isToggle
                                        ? animation.totalProgress()
                                        : clipped;
                            }
                        }
                        if (
                            anticipatePin &&
                            pin &&
                            !_refreshing &&
                            !_startup$1 &&
                            _lastScrollTime
                        ) {
                            if (
                                !clipped &&
                                start <
                                    scroll +
                                        ((scroll - scroll2) /
                                            (_getTime$1() - _time2)) *
                                            anticipatePin
                            ) {
                                clipped = 1e-4;
                            } else if (
                                clipped === 1 &&
                                end >
                                    scroll +
                                        ((scroll - scroll2) /
                                            (_getTime$1() - _time2)) *
                                            anticipatePin
                            ) {
                                clipped = 0.9999;
                            }
                        }
                        if (clipped !== prevProgress2 && self2.enabled) {
                            isActive = self2.isActive =
                                !!clipped && clipped < 1;
                            wasActive = !!prevProgress2 && prevProgress2 < 1;
                            toggled = isActive !== wasActive;
                            stateChanged =
                                toggled || !!clipped !== !!prevProgress2;
                            self2.direction = clipped > prevProgress2 ? 1 : -1;
                            self2.progress = clipped;
                            if (stateChanged && !_refreshing) {
                                toggleState =
                                    clipped && !prevProgress2
                                        ? 0
                                        : clipped === 1
                                        ? 1
                                        : prevProgress2 === 1
                                        ? 2
                                        : 3;
                                if (isToggle) {
                                    action =
                                        (!toggled &&
                                            toggleActions[toggleState + 1] !==
                                                'none' &&
                                            toggleActions[toggleState + 1]) ||
                                        toggleActions[toggleState];
                                    isTakingAction =
                                        animation &&
                                        (action === 'complete' ||
                                            action === 'reset' ||
                                            action in animation);
                                }
                            }
                            preventOverlaps &&
                                (toggled || isTakingAction) &&
                                (isTakingAction || scrub || !animation) &&
                                (_isFunction3(preventOverlaps)
                                    ? preventOverlaps(self2)
                                    : self2
                                          .getTrailing(preventOverlaps)
                                          .forEach(function (t2) {
                                              return t2.endAnimation();
                                          }));
                            if (!isToggle) {
                                if (scrubTween && !_refreshing && !_startup$1) {
                                    scrubTween._dp._time - scrubTween._start !==
                                        scrubTween._time &&
                                        scrubTween.render(
                                            scrubTween._dp._time -
                                                scrubTween._start
                                        );
                                    if (scrubTween.resetTo) {
                                        scrubTween.resetTo(
                                            'totalProgress',
                                            clipped,
                                            animation._tTime / animation._tDur
                                        );
                                    } else {
                                        scrubTween.vars.totalProgress = clipped;
                                        scrubTween.invalidate().restart();
                                    }
                                } else if (animation) {
                                    animation.totalProgress(
                                        clipped,
                                        !!(
                                            _refreshing &&
                                            (lastRefresh || reset)
                                        )
                                    );
                                }
                            }
                            if (pin) {
                                reset &&
                                    pinSpacing &&
                                    (spacer.style[pinSpacing + direction.os2] =
                                        spacingStart);
                                if (!useFixedPosition) {
                                    pinSetter(
                                        _round3(pinStart + pinChange * clipped)
                                    );
                                } else if (stateChanged) {
                                    isAtMax =
                                        !reset &&
                                        clipped > prevProgress2 &&
                                        end + 1 > scroll &&
                                        scroll + 1 >=
                                            _maxScroll(scroller, direction);
                                    if (pinReparent) {
                                        if (!reset && (isActive || isAtMax)) {
                                            var bounds = _getBounds(pin, true),
                                                _offset = scroll - start;
                                            _reparent(
                                                pin,
                                                _body$1,
                                                bounds.top +
                                                    (direction === _vertical
                                                        ? _offset
                                                        : 0) +
                                                    _px,
                                                bounds.left +
                                                    (direction === _vertical
                                                        ? 0
                                                        : _offset) +
                                                    _px
                                            );
                                        } else {
                                            _reparent(pin, spacer);
                                        }
                                    }
                                    _setState(
                                        isActive || isAtMax
                                            ? pinActiveState
                                            : pinState
                                    );
                                    (pinMoves && clipped < 1 && isActive) ||
                                        pinSetter(
                                            pinStart +
                                                (clipped === 1 && !isAtMax
                                                    ? pinChange
                                                    : 0)
                                        );
                                }
                            }
                            snap3 &&
                                !tweenTo.tween &&
                                !_refreshing &&
                                !_startup$1 &&
                                snapDelayedCall.restart(true);
                            toggleClass &&
                                (toggled ||
                                    (once &&
                                        clipped &&
                                        (clipped < 1 || !_limitCallbacks))) &&
                                _toArray(toggleClass.targets).forEach(function (
                                    el
                                ) {
                                    return el.classList[
                                        isActive || once ? 'add' : 'remove'
                                    ](toggleClass.className);
                                });
                            onUpdate && !isToggle && !reset && onUpdate(self2);
                            if (stateChanged && !_refreshing) {
                                if (isToggle) {
                                    if (isTakingAction) {
                                        if (action === 'complete') {
                                            animation.pause().totalProgress(1);
                                        } else if (action === 'reset') {
                                            animation.restart(true).pause();
                                        } else if (action === 'restart') {
                                            animation.restart(true);
                                        } else {
                                            animation[action]();
                                        }
                                    }
                                    onUpdate && onUpdate(self2);
                                }
                                if (toggled || !_limitCallbacks) {
                                    onToggle &&
                                        toggled &&
                                        _callback3(self2, onToggle);
                                    callbacks[toggleState] &&
                                        _callback3(
                                            self2,
                                            callbacks[toggleState]
                                        );
                                    once &&
                                        (clipped === 1
                                            ? self2.kill(false, 1)
                                            : (callbacks[toggleState] = 0));
                                    if (!toggled) {
                                        toggleState = clipped === 1 ? 1 : 3;
                                        callbacks[toggleState] &&
                                            _callback3(
                                                self2,
                                                callbacks[toggleState]
                                            );
                                    }
                                }
                                if (
                                    fastScrollEnd &&
                                    !isActive &&
                                    Math.abs(self2.getVelocity()) >
                                        (_isNumber3(fastScrollEnd)
                                            ? fastScrollEnd
                                            : 2500)
                                ) {
                                    _endAnimation(self2.callbackAnimation);
                                    scrubTween
                                        ? scrubTween.progress(1)
                                        : _endAnimation(
                                              animation,
                                              action === 'reverse'
                                                  ? 1
                                                  : !clipped,
                                              1
                                          );
                                }
                            } else if (isToggle && onUpdate && !_refreshing) {
                                onUpdate(self2);
                            }
                        }
                        if (markerEndSetter) {
                            var n = containerAnimation
                                ? (scroll / containerAnimation.duration()) *
                                  (containerAnimation._caScrollDist || 0)
                                : scroll;
                            markerStartSetter(
                                n + (markerStartTrigger._isFlipped ? 1 : 0)
                            );
                            markerEndSetter(n);
                        }
                        caMarkerSetter &&
                            caMarkerSetter(
                                (-scroll / containerAnimation.duration()) *
                                    (containerAnimation._caScrollDist || 0)
                            );
                    };
                    self2.enable = function (reset, refresh) {
                        if (!self2.enabled) {
                            self2.enabled = true;
                            _addListener$1(scroller, 'resize', _onResize);
                            isViewport ||
                                _addListener$1(scroller, 'scroll', _onScroll$1);
                            onRefreshInit &&
                                _addListener$1(
                                    ScrollTrigger3,
                                    'refreshInit',
                                    onRefreshInit
                                );
                            if (reset !== false) {
                                self2.progress = prevProgress = 0;
                                scroll1 = scroll2 = lastSnap = scrollFunc();
                            }
                            refresh !== false && self2.refresh();
                        }
                    };
                    self2.getTween = function (snap4) {
                        return snap4 && tweenTo ? tweenTo.tween : scrubTween;
                    };
                    self2.setPositions = function (
                        newStart,
                        newEnd,
                        keepClamp,
                        pinOffset
                    ) {
                        if (containerAnimation) {
                            var st = containerAnimation.scrollTrigger,
                                duration = containerAnimation.duration(),
                                _change = st.end - st.start;
                            newStart =
                                st.start + (_change * newStart) / duration;
                            newEnd = st.start + (_change * newEnd) / duration;
                        }
                        self2.refresh(
                            false,
                            false,
                            {
                                start: _keepClamp(
                                    newStart,
                                    keepClamp && !!self2._startClamp
                                ),
                                end: _keepClamp(
                                    newEnd,
                                    keepClamp && !!self2._endClamp
                                ),
                            },
                            pinOffset
                        );
                        self2.update();
                    };
                    self2.adjustPinSpacing = function (amount) {
                        if (spacerState && amount) {
                            var i = spacerState.indexOf(direction.d) + 1;
                            spacerState[i] =
                                parseFloat(spacerState[i]) + amount + _px;
                            spacerState[1] =
                                parseFloat(spacerState[1]) + amount + _px;
                            _setState(spacerState);
                        }
                    };
                    self2.disable = function (reset, allowAnimation) {
                        if (self2.enabled) {
                            reset !== false && self2.revert(true, true);
                            self2.enabled = self2.isActive = false;
                            allowAnimation ||
                                (scrubTween && scrubTween.pause());
                            prevScroll = 0;
                            pinCache && (pinCache.uncache = 1);
                            onRefreshInit &&
                                _removeListener$1(
                                    ScrollTrigger3,
                                    'refreshInit',
                                    onRefreshInit
                                );
                            if (snapDelayedCall) {
                                snapDelayedCall.pause();
                                tweenTo.tween &&
                                    tweenTo.tween.kill() &&
                                    (tweenTo.tween = 0);
                            }
                            if (!isViewport) {
                                var i = _triggers.length;
                                while (i--) {
                                    if (
                                        _triggers[i].scroller === scroller &&
                                        _triggers[i] !== self2
                                    ) {
                                        return;
                                    }
                                }
                                _removeListener$1(
                                    scroller,
                                    'resize',
                                    _onResize
                                );
                                isViewport ||
                                    _removeListener$1(
                                        scroller,
                                        'scroll',
                                        _onScroll$1
                                    );
                            }
                        }
                    };
                    self2.kill = function (revert, allowAnimation) {
                        self2.disable(revert, allowAnimation);
                        scrubTween && !allowAnimation && scrubTween.kill();
                        id && delete _ids[id];
                        var i = _triggers.indexOf(self2);
                        i >= 0 && _triggers.splice(i, 1);
                        i === _i && _direction > 0 && _i--;
                        i = 0;
                        _triggers.forEach(function (t2) {
                            return t2.scroller === self2.scroller && (i = 1);
                        });
                        i || _refreshingAll || (self2.scroll.rec = 0);
                        if (animation) {
                            animation.scrollTrigger = null;
                            revert &&
                                animation.revert({
                                    kill: false,
                                });
                            allowAnimation || animation.kill();
                        }
                        markerStart &&
                            [
                                markerStart,
                                markerEnd,
                                markerStartTrigger,
                                markerEndTrigger,
                            ].forEach(function (m) {
                                return (
                                    m.parentNode && m.parentNode.removeChild(m)
                                );
                            });
                        _primary === self2 && (_primary = 0);
                        if (pin) {
                            pinCache && (pinCache.uncache = 1);
                            i = 0;
                            _triggers.forEach(function (t2) {
                                return t2.pin === pin && i++;
                            });
                            i || (pinCache.spacer = 0);
                        }
                        vars.onKill && vars.onKill(self2);
                    };
                    _triggers.push(self2);
                    self2.enable(false, false);
                    customRevertReturn && customRevertReturn(self2);
                    if (animation && animation.add && !change) {
                        var updateFunc = self2.update;
                        self2.update = function () {
                            self2.update = updateFunc;
                            start || end || self2.refresh();
                        };
                        gsap$1.delayedCall(0.01, self2.update);
                        change = 0.01;
                        start = end = 0;
                    } else {
                        self2.refresh();
                    }
                    pin && _queueRefreshAll();
                };
                ScrollTrigger3.register = function register(core) {
                    if (!_coreInitted$1) {
                        gsap$1 = core || _getGSAP$1();
                        _windowExists5() &&
                            window.document &&
                            ScrollTrigger3.enable();
                        _coreInitted$1 = _enabled;
                    }
                    return _coreInitted$1;
                };
                ScrollTrigger3.defaults = function defaults4(config3) {
                    if (config3) {
                        for (var p in config3) {
                            _defaults3[p] = config3[p];
                        }
                    }
                    return _defaults3;
                };
                ScrollTrigger3.disable = function disable(reset, kill) {
                    _enabled = 0;
                    _triggers.forEach(function (trigger) {
                        return trigger[kill ? 'kill' : 'disable'](reset);
                    });
                    _removeListener$1(_win$1, 'wheel', _onScroll$1);
                    _removeListener$1(_doc$1, 'scroll', _onScroll$1);
                    clearInterval(_syncInterval);
                    _removeListener$1(_doc$1, 'touchcancel', _passThrough3);
                    _removeListener$1(_body$1, 'touchstart', _passThrough3);
                    _multiListener(
                        _removeListener$1,
                        _doc$1,
                        'pointerdown,touchstart,mousedown',
                        _pointerDownHandler
                    );
                    _multiListener(
                        _removeListener$1,
                        _doc$1,
                        'pointerup,touchend,mouseup',
                        _pointerUpHandler
                    );
                    _resizeDelay.kill();
                    _iterateAutoRefresh(_removeListener$1);
                    for (var i = 0; i < _scrollers.length; i += 3) {
                        _wheelListener(
                            _removeListener$1,
                            _scrollers[i],
                            _scrollers[i + 1]
                        );
                        _wheelListener(
                            _removeListener$1,
                            _scrollers[i],
                            _scrollers[i + 2]
                        );
                    }
                };
                ScrollTrigger3.enable = function enable() {
                    _win$1 = window;
                    _doc$1 = document;
                    _docEl$1 = _doc$1.documentElement;
                    _body$1 = _doc$1.body;
                    if (gsap$1) {
                        _toArray = gsap$1.utils.toArray;
                        _clamp$1 = gsap$1.utils.clamp;
                        _context$1 = gsap$1.core.context || _passThrough3;
                        _suppressOverwrites2 =
                            gsap$1.core.suppressOverwrites || _passThrough3;
                        _scrollRestoration =
                            _win$1.history.scrollRestoration || 'auto';
                        _lastScroll = _win$1.pageYOffset;
                        gsap$1.core.globals('ScrollTrigger', ScrollTrigger3);
                        if (_body$1) {
                            _enabled = 1;
                            _div100vh = document.createElement('div');
                            _div100vh.style.height = '100vh';
                            _div100vh.style.position = 'absolute';
                            _refresh100vh();
                            _rafBugFix();
                            Observer2.register(gsap$1);
                            ScrollTrigger3.isTouch = Observer2.isTouch;
                            _fixIOSBug =
                                Observer2.isTouch &&
                                /(iPad|iPhone|iPod|Mac)/g.test(
                                    navigator.userAgent
                                );
                            _ignoreMobileResize = Observer2.isTouch === 1;
                            _addListener$1(_win$1, 'wheel', _onScroll$1);
                            _root$1 = [_win$1, _doc$1, _docEl$1, _body$1];
                            if (gsap$1.matchMedia) {
                                ScrollTrigger3.matchMedia = function (vars) {
                                    var mm = gsap$1.matchMedia(),
                                        p;
                                    for (p in vars) {
                                        mm.add(p, vars[p]);
                                    }
                                    return mm;
                                };
                                gsap$1.addEventListener(
                                    'matchMediaInit',
                                    function () {
                                        return _revertAll();
                                    }
                                );
                                gsap$1.addEventListener(
                                    'matchMediaRevert',
                                    function () {
                                        return _revertRecorded();
                                    }
                                );
                                gsap$1.addEventListener(
                                    'matchMedia',
                                    function () {
                                        _refreshAll(0, 1);
                                        _dispatch3('matchMedia');
                                    }
                                );
                                gsap$1.matchMedia(
                                    '(orientation: portrait)',
                                    function () {
                                        _setBaseDimensions();
                                        return _setBaseDimensions;
                                    }
                                );
                            } else {
                                console.warn('Requires GSAP 3.11.0 or later');
                            }
                            _setBaseDimensions();
                            _addListener$1(_doc$1, 'scroll', _onScroll$1);
                            var bodyStyle = _body$1.style,
                                border = bodyStyle.borderTopStyle,
                                AnimationProto =
                                    gsap$1.core.Animation.prototype,
                                bounds,
                                i;
                            AnimationProto.revert ||
                                Object.defineProperty(
                                    AnimationProto,
                                    'revert',
                                    {
                                        value: function value() {
                                            return this.time(-0.01, true);
                                        },
                                    }
                                );
                            bodyStyle.borderTopStyle = 'solid';
                            bounds = _getBounds(_body$1);
                            _vertical.m =
                                Math.round(bounds.top + _vertical.sc()) || 0;
                            _horizontal.m =
                                Math.round(bounds.left + _horizontal.sc()) || 0;
                            border
                                ? (bodyStyle.borderTopStyle = border)
                                : bodyStyle.removeProperty('border-top-style');
                            _syncInterval = setInterval(_sync, 250);
                            gsap$1.delayedCall(0.5, function () {
                                return (_startup$1 = 0);
                            });
                            _addListener$1(
                                _doc$1,
                                'touchcancel',
                                _passThrough3
                            );
                            _addListener$1(
                                _body$1,
                                'touchstart',
                                _passThrough3
                            );
                            _multiListener(
                                _addListener$1,
                                _doc$1,
                                'pointerdown,touchstart,mousedown',
                                _pointerDownHandler
                            );
                            _multiListener(
                                _addListener$1,
                                _doc$1,
                                'pointerup,touchend,mouseup',
                                _pointerUpHandler
                            );
                            _transformProp2 =
                                gsap$1.utils.checkPrefix('transform');
                            _stateProps.push(_transformProp2);
                            _coreInitted$1 = _getTime$1();
                            _resizeDelay = gsap$1
                                .delayedCall(0.2, _refreshAll)
                                .pause();
                            _autoRefresh = [
                                _doc$1,
                                'visibilitychange',
                                function () {
                                    var w = _win$1.innerWidth,
                                        h = _win$1.innerHeight;
                                    if (_doc$1.hidden) {
                                        _prevWidth = w;
                                        _prevHeight = h;
                                    } else if (
                                        _prevWidth !== w ||
                                        _prevHeight !== h
                                    ) {
                                        _onResize();
                                    }
                                },
                                _doc$1,
                                'DOMContentLoaded',
                                _refreshAll,
                                _win$1,
                                'load',
                                _refreshAll,
                                _win$1,
                                'resize',
                                _onResize,
                            ];
                            _iterateAutoRefresh(_addListener$1);
                            _triggers.forEach(function (trigger) {
                                return trigger.enable(0, 1);
                            });
                            for (i = 0; i < _scrollers.length; i += 3) {
                                _wheelListener(
                                    _removeListener$1,
                                    _scrollers[i],
                                    _scrollers[i + 1]
                                );
                                _wheelListener(
                                    _removeListener$1,
                                    _scrollers[i],
                                    _scrollers[i + 2]
                                );
                            }
                        }
                    }
                };
                ScrollTrigger3.config = function config3(vars) {
                    'limitCallbacks' in vars &&
                        (_limitCallbacks = !!vars.limitCallbacks);
                    var ms = vars.syncInterval;
                    (ms && clearInterval(_syncInterval)) ||
                        ((_syncInterval = ms) && setInterval(_sync, ms));
                    'ignoreMobileResize' in vars &&
                        (_ignoreMobileResize =
                            ScrollTrigger3.isTouch === 1 &&
                            vars.ignoreMobileResize);
                    if ('autoRefreshEvents' in vars) {
                        _iterateAutoRefresh(_removeListener$1) ||
                            _iterateAutoRefresh(
                                _addListener$1,
                                vars.autoRefreshEvents || 'none'
                            );
                        _ignoreResize =
                            (vars.autoRefreshEvents + '').indexOf('resize') ===
                            -1;
                    }
                };
                ScrollTrigger3.scrollerProxy = function scrollerProxy(
                    target,
                    vars
                ) {
                    var t2 = _getTarget(target),
                        i = _scrollers.indexOf(t2),
                        isViewport = _isViewport$1(t2);
                    if (~i) {
                        _scrollers.splice(i, isViewport ? 6 : 2);
                    }
                    if (vars) {
                        isViewport
                            ? _proxies.unshift(
                                  _win$1,
                                  vars,
                                  _body$1,
                                  vars,
                                  _docEl$1,
                                  vars
                              )
                            : _proxies.unshift(t2, vars);
                    }
                };
                ScrollTrigger3.clearMatchMedia = function clearMatchMedia(
                    query
                ) {
                    _triggers.forEach(function (t2) {
                        return (
                            t2._ctx &&
                            t2._ctx.query === query &&
                            t2._ctx.kill(true, true)
                        );
                    });
                };
                ScrollTrigger3.isInViewport = function isInViewport(
                    element,
                    ratio,
                    horizontal
                ) {
                    var bounds = (
                            _isString3(element) ? _getTarget(element) : element
                        ).getBoundingClientRect(),
                        offset =
                            bounds[horizontal ? _width : _height] * ratio || 0;
                    return horizontal
                        ? bounds.right - offset > 0 &&
                              bounds.left + offset < _win$1.innerWidth
                        : bounds.bottom - offset > 0 &&
                              bounds.top + offset < _win$1.innerHeight;
                };
                ScrollTrigger3.positionInViewport = function positionInViewport(
                    element,
                    referencePoint,
                    horizontal
                ) {
                    _isString3(element) && (element = _getTarget(element));
                    var bounds = element.getBoundingClientRect(),
                        size = bounds[horizontal ? _width : _height],
                        offset =
                            referencePoint == null
                                ? size / 2
                                : referencePoint in _keywords
                                ? _keywords[referencePoint] * size
                                : ~referencePoint.indexOf('%')
                                ? (parseFloat(referencePoint) * size) / 100
                                : parseFloat(referencePoint) || 0;
                    return horizontal
                        ? (bounds.left + offset) / _win$1.innerWidth
                        : (bounds.top + offset) / _win$1.innerHeight;
                };
                ScrollTrigger3.killAll = function killAll(allowListeners) {
                    _triggers.slice(0).forEach(function (t2) {
                        return t2.vars.id !== 'ScrollSmoother' && t2.kill();
                    });
                    if (allowListeners !== true) {
                        var listeners = _listeners2.killAll || [];
                        _listeners2 = {};
                        listeners.forEach(function (f) {
                            return f();
                        });
                    }
                };
                return ScrollTrigger3;
            })();
            ScrollTrigger$1.version = '3.12.5';
            ScrollTrigger$1.saveStyles = function (targets) {
                return targets
                    ? _toArray(targets).forEach(function (target) {
                          if (target && target.style) {
                              var i = _savedStyles.indexOf(target);
                              i >= 0 && _savedStyles.splice(i, 5);
                              _savedStyles.push(
                                  target,
                                  target.style.cssText,
                                  target.getBBox &&
                                      target.getAttribute('transform'),
                                  gsap$1.core.getCache(target),
                                  _context$1()
                              );
                          }
                      })
                    : _savedStyles;
            };
            ScrollTrigger$1.revert = function (soft, media) {
                return _revertAll(!soft, media);
            };
            ScrollTrigger$1.create = function (vars, animation) {
                return new ScrollTrigger$1(vars, animation);
            };
            ScrollTrigger$1.refresh = function (safe) {
                return safe
                    ? _onResize()
                    : (_coreInitted$1 || ScrollTrigger$1.register()) &&
                          _refreshAll(true);
            };
            ScrollTrigger$1.update = function (force) {
                return ++_scrollers.cache && _updateAll(force === true ? 2 : 0);
            };
            ScrollTrigger$1.clearScrollMemory = _clearScrollMemory;
            ScrollTrigger$1.maxScroll = function (element, horizontal) {
                return _maxScroll(
                    element,
                    horizontal ? _horizontal : _vertical
                );
            };
            ScrollTrigger$1.getScrollFunc = function (element, horizontal) {
                return _getScrollFunc(
                    _getTarget(element),
                    horizontal ? _horizontal : _vertical
                );
            };
            ScrollTrigger$1.getById = function (id) {
                return _ids[id];
            };
            ScrollTrigger$1.getAll = function () {
                return _triggers.filter(function (t2) {
                    return t2.vars.id !== 'ScrollSmoother';
                });
            };
            ScrollTrigger$1.isScrolling = function () {
                return !!_lastScrollTime;
            };
            ScrollTrigger$1.snapDirectional = _snapDirectional;
            ScrollTrigger$1.addEventListener = function (type, callback) {
                var a = _listeners2[type] || (_listeners2[type] = []);
                ~a.indexOf(callback) || a.push(callback);
            };
            ScrollTrigger$1.removeEventListener = function (type, callback) {
                var a = _listeners2[type],
                    i = a && a.indexOf(callback);
                i >= 0 && a.splice(i, 1);
            };
            ScrollTrigger$1.batch = function (targets, vars) {
                var result = [],
                    varsCopy = {},
                    interval = vars.interval || 0.016,
                    batchMax = vars.batchMax || 1e9,
                    proxyCallback = function proxyCallback2(type, callback) {
                        var elements = [],
                            triggers = [],
                            delay = gsap$1
                                .delayedCall(interval, function () {
                                    callback(elements, triggers);
                                    elements = [];
                                    triggers = [];
                                })
                                .pause();
                        return function (self2) {
                            elements.length || delay.restart(true);
                            elements.push(self2.trigger);
                            triggers.push(self2);
                            batchMax <= elements.length && delay.progress(1);
                        };
                    },
                    p;
                for (p in vars) {
                    varsCopy[p] =
                        p.substr(0, 2) === 'on' &&
                        _isFunction3(vars[p]) &&
                        p !== 'onRefreshInit'
                            ? proxyCallback(p, vars[p])
                            : vars[p];
                }
                if (_isFunction3(batchMax)) {
                    batchMax = batchMax();
                    _addListener$1(ScrollTrigger$1, 'refresh', function () {
                        return (batchMax = vars.batchMax());
                    });
                }
                _toArray(targets).forEach(function (target) {
                    var config3 = {};
                    for (p in varsCopy) {
                        config3[p] = varsCopy[p];
                    }
                    config3.trigger = target;
                    result.push(ScrollTrigger$1.create(config3));
                });
                return result;
            };
            var _clampScrollAndGetDurationMultiplier =
                    function _clampScrollAndGetDurationMultiplier2(
                        scrollFunc,
                        current,
                        end,
                        max
                    ) {
                        current > max
                            ? scrollFunc(max)
                            : current < 0 && scrollFunc(0);
                        return end > max
                            ? (max - current) / (end - current)
                            : end < 0
                            ? current / (current - end)
                            : 1;
                    },
                _allowNativePanning = function _allowNativePanning2(
                    target,
                    direction
                ) {
                    if (direction === true) {
                        target.style.removeProperty('touch-action');
                    } else {
                        target.style.touchAction =
                            direction === true
                                ? 'auto'
                                : direction
                                ? 'pan-' +
                                  direction +
                                  (Observer2.isTouch ? ' pinch-zoom' : '')
                                : 'none';
                    }
                    target === _docEl$1 &&
                        _allowNativePanning2(_body$1, direction);
                },
                _overflow = {
                    auto: 1,
                    scroll: 1,
                },
                _nestedScroll = function _nestedScroll2(_ref5) {
                    var event2 = _ref5.event,
                        target = _ref5.target,
                        axis = _ref5.axis;
                    var node = (
                            event2.changedTouches
                                ? event2.changedTouches[0]
                                : event2
                        ).target,
                        cache2 = node._gsap || gsap$1.core.getCache(node),
                        time = _getTime$1(),
                        cs;
                    if (!cache2._isScrollT || time - cache2._isScrollT > 2e3) {
                        while (
                            node &&
                            node !== _body$1 &&
                            ((node.scrollHeight <= node.clientHeight &&
                                node.scrollWidth <= node.clientWidth) ||
                                !(
                                    _overflow[
                                        (cs = _getComputedStyle(node)).overflowY
                                    ] || _overflow[cs.overflowX]
                                ))
                        ) {
                            node = node.parentNode;
                        }
                        cache2._isScroll =
                            node &&
                            node !== target &&
                            !_isViewport$1(node) &&
                            (_overflow[
                                (cs = _getComputedStyle(node)).overflowY
                            ] ||
                                _overflow[cs.overflowX]);
                        cache2._isScrollT = time;
                    }
                    if (cache2._isScroll || axis === 'x') {
                        event2.stopPropagation();
                        event2._gsapAllow = true;
                    }
                },
                _inputObserver = function _inputObserver2(
                    target,
                    type,
                    inputs,
                    nested
                ) {
                    return Observer2.create({
                        target,
                        capture: true,
                        debounce: false,
                        lockAxis: true,
                        type,
                        onWheel: (nested = nested && _nestedScroll),
                        onPress: nested,
                        onDrag: nested,
                        onScroll: nested,
                        onEnable: function onEnable() {
                            return (
                                inputs &&
                                _addListener$1(
                                    _doc$1,
                                    Observer2.eventTypes[0],
                                    _captureInputs,
                                    false,
                                    true
                                )
                            );
                        },
                        onDisable: function onDisable() {
                            return _removeListener$1(
                                _doc$1,
                                Observer2.eventTypes[0],
                                _captureInputs,
                                true
                            );
                        },
                    });
                },
                _inputExp = /(input|label|select|textarea)/i,
                _inputIsFocused,
                _captureInputs = function _captureInputs2(e2) {
                    var isInput = _inputExp.test(e2.target.tagName);
                    if (isInput || _inputIsFocused) {
                        e2._gsapAllow = true;
                        _inputIsFocused = isInput;
                    }
                },
                _getScrollNormalizer = function _getScrollNormalizer2(vars) {
                    _isObject3(vars) || (vars = {});
                    vars.preventDefault =
                        vars.isNormalizer =
                        vars.allowClicks =
                            true;
                    vars.type || (vars.type = 'wheel,touch');
                    vars.debounce = !!vars.debounce;
                    vars.id = vars.id || 'normalizer';
                    var _vars2 = vars,
                        normalizeScrollX = _vars2.normalizeScrollX,
                        momentum = _vars2.momentum,
                        allowNestedScroll = _vars2.allowNestedScroll,
                        onRelease = _vars2.onRelease,
                        self2,
                        maxY,
                        target = _getTarget(vars.target) || _docEl$1,
                        smoother = gsap$1.core.globals().ScrollSmoother,
                        smootherInstance = smoother && smoother.get(),
                        content =
                            _fixIOSBug &&
                            ((vars.content && _getTarget(vars.content)) ||
                                (smootherInstance &&
                                    vars.content !== false &&
                                    !smootherInstance.smooth() &&
                                    smootherInstance.content())),
                        scrollFuncY = _getScrollFunc(target, _vertical),
                        scrollFuncX = _getScrollFunc(target, _horizontal),
                        scale = 1,
                        initialScale =
                            (Observer2.isTouch && _win$1.visualViewport
                                ? _win$1.visualViewport.scale *
                                  _win$1.visualViewport.width
                                : _win$1.outerWidth) / _win$1.innerWidth,
                        wheelRefresh = 0,
                        resolveMomentumDuration = _isFunction3(momentum)
                            ? function () {
                                  return momentum(self2);
                              }
                            : function () {
                                  return momentum || 2.8;
                              },
                        lastRefreshID,
                        skipTouchMove,
                        inputObserver = _inputObserver(
                            target,
                            vars.type,
                            true,
                            allowNestedScroll
                        ),
                        resumeTouchMove = function resumeTouchMove2() {
                            return (skipTouchMove = false);
                        },
                        scrollClampX = _passThrough3,
                        scrollClampY = _passThrough3,
                        updateClamps = function updateClamps2() {
                            maxY = _maxScroll(target, _vertical);
                            scrollClampY = _clamp$1(_fixIOSBug ? 1 : 0, maxY);
                            normalizeScrollX &&
                                (scrollClampX = _clamp$1(
                                    0,
                                    _maxScroll(target, _horizontal)
                                ));
                            lastRefreshID = _refreshID;
                        },
                        removeContentOffset = function removeContentOffset2() {
                            content._gsap.y =
                                _round3(
                                    parseFloat(content._gsap.y) +
                                        scrollFuncY.offset
                                ) + 'px';
                            content.style.transform =
                                'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ' +
                                parseFloat(content._gsap.y) +
                                ', 0, 1)';
                            scrollFuncY.offset = scrollFuncY.cacheID = 0;
                        },
                        ignoreDrag = function ignoreDrag2() {
                            if (skipTouchMove) {
                                requestAnimationFrame(resumeTouchMove);
                                var offset = _round3(self2.deltaY / 2),
                                    scroll = scrollClampY(
                                        scrollFuncY.v - offset
                                    );
                                if (
                                    content &&
                                    scroll !==
                                        scrollFuncY.v + scrollFuncY.offset
                                ) {
                                    scrollFuncY.offset = scroll - scrollFuncY.v;
                                    var y = _round3(
                                        (parseFloat(
                                            content && content._gsap.y
                                        ) || 0) - scrollFuncY.offset
                                    );
                                    content.style.transform =
                                        'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ' +
                                        y +
                                        ', 0, 1)';
                                    content._gsap.y = y + 'px';
                                    scrollFuncY.cacheID = _scrollers.cache;
                                    _updateAll();
                                }
                                return true;
                            }
                            scrollFuncY.offset && removeContentOffset();
                            skipTouchMove = true;
                        },
                        tween,
                        startScrollX,
                        startScrollY,
                        onStopDelayedCall,
                        onResize2 = function onResize3() {
                            updateClamps();
                            if (tween.isActive() && tween.vars.scrollY > maxY) {
                                scrollFuncY() > maxY
                                    ? tween.progress(1) && scrollFuncY(maxY)
                                    : tween.resetTo('scrollY', maxY);
                            }
                        };
                    content &&
                        gsap$1.set(content, {
                            y: '+=0',
                        });
                    vars.ignoreCheck = function (e2) {
                        return (
                            (_fixIOSBug &&
                                e2.type === 'touchmove' &&
                                ignoreDrag()) ||
                            (scale > 1.05 && e2.type !== 'touchstart') ||
                            self2.isGesturing ||
                            (e2.touches && e2.touches.length > 1)
                        );
                    };
                    vars.onPress = function () {
                        skipTouchMove = false;
                        var prevScale = scale;
                        scale = _round3(
                            ((_win$1.visualViewport &&
                                _win$1.visualViewport.scale) ||
                                1) / initialScale
                        );
                        tween.pause();
                        prevScale !== scale &&
                            _allowNativePanning(
                                target,
                                scale > 1.01
                                    ? true
                                    : normalizeScrollX
                                    ? false
                                    : 'x'
                            );
                        startScrollX = scrollFuncX();
                        startScrollY = scrollFuncY();
                        updateClamps();
                        lastRefreshID = _refreshID;
                    };
                    vars.onRelease = vars.onGestureStart = function (
                        self3,
                        wasDragging
                    ) {
                        scrollFuncY.offset && removeContentOffset();
                        if (!wasDragging) {
                            onStopDelayedCall.restart(true);
                        } else {
                            _scrollers.cache++;
                            var dur = resolveMomentumDuration(),
                                currentScroll,
                                endScroll;
                            if (normalizeScrollX) {
                                currentScroll = scrollFuncX();
                                endScroll =
                                    currentScroll +
                                    (dur * 0.05 * -self3.velocityX) / 0.227;
                                dur *= _clampScrollAndGetDurationMultiplier(
                                    scrollFuncX,
                                    currentScroll,
                                    endScroll,
                                    _maxScroll(target, _horizontal)
                                );
                                tween.vars.scrollX = scrollClampX(endScroll);
                            }
                            currentScroll = scrollFuncY();
                            endScroll =
                                currentScroll +
                                (dur * 0.05 * -self3.velocityY) / 0.227;
                            dur *= _clampScrollAndGetDurationMultiplier(
                                scrollFuncY,
                                currentScroll,
                                endScroll,
                                _maxScroll(target, _vertical)
                            );
                            tween.vars.scrollY = scrollClampY(endScroll);
                            tween.invalidate().duration(dur).play(0.01);
                            if (
                                (_fixIOSBug && tween.vars.scrollY >= maxY) ||
                                currentScroll >= maxY - 1
                            ) {
                                gsap$1.to(
                                    {},
                                    {
                                        onUpdate: onResize2,
                                        duration: dur,
                                    }
                                );
                            }
                        }
                        onRelease && onRelease(self3);
                    };
                    vars.onWheel = function () {
                        tween._ts && tween.pause();
                        if (_getTime$1() - wheelRefresh > 1e3) {
                            lastRefreshID = 0;
                            wheelRefresh = _getTime$1();
                        }
                    };
                    vars.onChange = function (self3, dx, dy, xArray, yArray) {
                        _refreshID !== lastRefreshID && updateClamps();
                        dx &&
                            normalizeScrollX &&
                            scrollFuncX(
                                scrollClampX(
                                    xArray[2] === dx
                                        ? startScrollX +
                                              (self3.startX - self3.x)
                                        : scrollFuncX() + dx - xArray[1]
                                )
                            );
                        if (dy) {
                            scrollFuncY.offset && removeContentOffset();
                            var isTouch = yArray[2] === dy,
                                y = isTouch
                                    ? startScrollY + self3.startY - self3.y
                                    : scrollFuncY() + dy - yArray[1],
                                yClamped = scrollClampY(y);
                            isTouch &&
                                y !== yClamped &&
                                (startScrollY += yClamped - y);
                            scrollFuncY(yClamped);
                        }
                        (dy || dx) && _updateAll();
                    };
                    vars.onEnable = function () {
                        _allowNativePanning(
                            target,
                            normalizeScrollX ? false : 'x'
                        );
                        ScrollTrigger$1.addEventListener('refresh', onResize2);
                        _addListener$1(_win$1, 'resize', onResize2);
                        if (scrollFuncY.smooth) {
                            scrollFuncY.target.style.scrollBehavior = 'auto';
                            scrollFuncY.smooth = scrollFuncX.smooth = false;
                        }
                        inputObserver.enable();
                    };
                    vars.onDisable = function () {
                        _allowNativePanning(target, true);
                        _removeListener$1(_win$1, 'resize', onResize2);
                        ScrollTrigger$1.removeEventListener(
                            'refresh',
                            onResize2
                        );
                        inputObserver.kill();
                    };
                    vars.lockAxis = vars.lockAxis !== false;
                    self2 = new Observer2(vars);
                    self2.iOS = _fixIOSBug;
                    _fixIOSBug && !scrollFuncY() && scrollFuncY(1);
                    _fixIOSBug && gsap$1.ticker.add(_passThrough3);
                    onStopDelayedCall = self2._dc;
                    tween = gsap$1.to(self2, {
                        ease: 'power4',
                        paused: true,
                        inherit: false,
                        scrollX: normalizeScrollX ? '+=0.1' : '+=0',
                        scrollY: '+=0.1',
                        modifiers: {
                            scrollY: _interruptionTracker(
                                scrollFuncY,
                                scrollFuncY(),
                                function () {
                                    return tween.pause();
                                }
                            ),
                        },
                        onUpdate: _updateAll,
                        onComplete: onStopDelayedCall.vars.onComplete,
                    });
                    return self2;
                };
            ScrollTrigger$1.sort = function (func) {
                return _triggers.sort(
                    func ||
                        function (a, b) {
                            return (
                                (a.vars.refreshPriority || 0) * -1e6 +
                                a.start -
                                (b.start + (b.vars.refreshPriority || 0) * -1e6)
                            );
                        }
                );
            };
            ScrollTrigger$1.observe = function (vars) {
                return new Observer2(vars);
            };
            ScrollTrigger$1.normalizeScroll = function (vars) {
                if (typeof vars === 'undefined') {
                    return _normalizer$1;
                }
                if (vars === true && _normalizer$1) {
                    return _normalizer$1.enable();
                }
                if (vars === false) {
                    _normalizer$1 && _normalizer$1.kill();
                    _normalizer$1 = vars;
                    return;
                }
                var normalizer =
                    vars instanceof Observer2
                        ? vars
                        : _getScrollNormalizer(vars);
                _normalizer$1 &&
                    _normalizer$1.target === normalizer.target &&
                    _normalizer$1.kill();
                _isViewport$1(normalizer.target) &&
                    (_normalizer$1 = normalizer);
                return normalizer;
            };
            ScrollTrigger$1.core = {
                _getVelocityProp,
                _inputObserver,
                _scrollers,
                _proxies,
                bridge: {
                    ss: function ss() {
                        _lastScrollTime || _dispatch3('scrollStart');
                        _lastScrollTime = _getTime$1();
                    },
                    ref: function ref() {
                        return _refreshing;
                    },
                },
            };
            _getGSAP$1() && gsap$1.registerPlugin(ScrollTrigger$1);
            exports2.ScrollTrigger = ScrollTrigger$1;
            exports2.default = ScrollTrigger$1;
            if (typeof window === 'undefined' || window !== exports2) {
                Object.defineProperty(exports2, '__esModule', { value: true });
            } else {
                delete window.default;
            }
        });
    },
});

// node_modules/gsap/gsap-core.js
function _assertThisInitialized(self2) {
    if (self2 === void 0) {
        throw new ReferenceError(
            "this hasn't been initialised - super() hasn't been called"
        );
    }
    return self2;
}
function _inheritsLoose(subClass, superClass) {
    subClass.prototype = Object.create(superClass.prototype);
    subClass.prototype.constructor = subClass;
    subClass.__proto__ = superClass;
}
var _config = {
    autoSleep: 120,
    force3D: 'auto',
    nullTargetWarn: 1,
    units: {
        lineHeight: '',
    },
};
var _defaults = {
    duration: 0.5,
    overwrite: false,
    delay: 0,
};
var _suppressOverwrites;
var _reverting;
var _context;
var _bigNum = 1e8;
var _tinyNum = 1 / _bigNum;
var _2PI = Math.PI * 2;
var _HALF_PI = _2PI / 4;
var _gsID = 0;
var _sqrt = Math.sqrt;
var _cos = Math.cos;
var _sin = Math.sin;
var _isString = function _isString2(value) {
    return typeof value === 'string';
};
var _isFunction = function _isFunction2(value) {
    return typeof value === 'function';
};
var _isNumber = function _isNumber2(value) {
    return typeof value === 'number';
};
var _isUndefined = function _isUndefined2(value) {
    return typeof value === 'undefined';
};
var _isObject = function _isObject2(value) {
    return typeof value === 'object';
};
var _isNotFalse = function _isNotFalse2(value) {
    return value !== false;
};
var _windowExists = function _windowExists2() {
    return typeof window !== 'undefined';
};
var _isFuncOrString = function _isFuncOrString2(value) {
    return _isFunction(value) || _isString(value);
};
var _isTypedArray =
    (typeof ArrayBuffer === 'function' && ArrayBuffer.isView) || function () {};
var _isArray = Array.isArray;
var _strictNumExp = /(?:-?\.?\d|\.)+/gi;
var _numExp = /[-+=.]*\d+[.e\-+]*\d*[e\-+]*\d*/g;
var _numWithUnitExp = /[-+=.]*\d+[.e-]*\d*[a-z%]*/g;
var _complexStringNumExp = /[-+=.]*\d+\.?\d*(?:e-|e\+)?\d*/gi;
var _relExp = /[+-]=-?[.\d]+/;
var _delimitedValueExp = /[^,'"\[\]\s]+/gi;
var _unitExp = /^[+\-=e\s\d]*\d+[.\d]*([a-z]*|%)\s*$/i;
var _globalTimeline;
var _win;
var _coreInitted;
var _doc;
var _globals = {};
var _installScope = {};
var _coreReady;
var _install = function _install2(scope) {
    return (_installScope = _merge(scope, _globals)) && gsap;
};
var _missingPlugin = function _missingPlugin2(property, value) {
    return console.warn(
        'Invalid property',
        property,
        'set to',
        value,
        'Missing plugin? gsap.registerPlugin()'
    );
};
var _warn = function _warn2(message, suppress) {
    return !suppress && console.warn(message);
};
var _addGlobal = function _addGlobal2(name, obj) {
    return (
        (name &&
            (_globals[name] = obj) &&
            _installScope &&
            (_installScope[name] = obj)) ||
        _globals
    );
};
var _emptyFunc = function _emptyFunc2() {
    return 0;
};
var _startAtRevertConfig = {
    suppressEvents: true,
    isStart: true,
    kill: false,
};
var _revertConfigNoKill = {
    suppressEvents: true,
    kill: false,
};
var _revertConfig = {
    suppressEvents: true,
};
var _reservedProps = {};
var _lazyTweens = [];
var _lazyLookup = {};
var _lastRenderedFrame;
var _plugins = {};
var _effects = {};
var _nextGCFrame = 30;
var _harnessPlugins = [];
var _callbackNames = '';
var _harness = function _harness2(targets) {
    var target = targets[0],
        harnessPlugin,
        i;
    _isObject(target) || _isFunction(target) || (targets = [targets]);
    if (!(harnessPlugin = (target._gsap || {}).harness)) {
        i = _harnessPlugins.length;
        while (i-- && !_harnessPlugins[i].targetTest(target)) {}
        harnessPlugin = _harnessPlugins[i];
    }
    i = targets.length;
    while (i--) {
        (targets[i] &&
            (targets[i]._gsap ||
                (targets[i]._gsap = new GSCache(targets[i], harnessPlugin)))) ||
            targets.splice(i, 1);
    }
    return targets;
};
var _getCache = function _getCache2(target) {
    return target._gsap || _harness(toArray(target))[0]._gsap;
};
var _getProperty = function _getProperty2(target, property, v) {
    return (v = target[property]) && _isFunction(v)
        ? target[property]()
        : (_isUndefined(v) &&
              target.getAttribute &&
              target.getAttribute(property)) ||
              v;
};
var _forEachName = function _forEachName2(names, func) {
    return (names = names.split(',')).forEach(func) || names;
};
var _round = function _round2(value) {
    return Math.round(value * 1e5) / 1e5 || 0;
};
var _roundPrecise = function _roundPrecise2(value) {
    return Math.round(value * 1e7) / 1e7 || 0;
};
var _parseRelative = function _parseRelative2(start, value) {
    var operator = value.charAt(0),
        end = parseFloat(value.substr(2));
    start = parseFloat(start);
    return operator === '+'
        ? start + end
        : operator === '-'
        ? start - end
        : operator === '*'
        ? start * end
        : start / end;
};
var _arrayContainsAny = function _arrayContainsAny2(toSearch, toFind) {
    var l = toFind.length,
        i = 0;
    for (; toSearch.indexOf(toFind[i]) < 0 && ++i < l; ) {}
    return i < l;
};
var _lazyRender = function _lazyRender2() {
    var l = _lazyTweens.length,
        a = _lazyTweens.slice(0),
        i,
        tween;
    _lazyLookup = {};
    _lazyTweens.length = 0;
    for (i = 0; i < l; i++) {
        tween = a[i];
        tween &&
            tween._lazy &&
            (tween.render(tween._lazy[0], tween._lazy[1], true)._lazy = 0);
    }
};
var _lazySafeRender = function _lazySafeRender2(
    animation,
    time,
    suppressEvents,
    force
) {
    _lazyTweens.length && !_reverting && _lazyRender();
    animation.render(
        time,
        suppressEvents,
        force ||
            (_reverting &&
                time < 0 &&
                (animation._initted || animation._startAt))
    );
    _lazyTweens.length && !_reverting && _lazyRender();
};
var _numericIfPossible = function _numericIfPossible2(value) {
    var n = parseFloat(value);
    return (n || n === 0) && (value + '').match(_delimitedValueExp).length < 2
        ? n
        : _isString(value)
        ? value.trim()
        : value;
};
var _passThrough = function _passThrough2(p) {
    return p;
};
var _setDefaults = function _setDefaults2(obj, defaults4) {
    for (var p in defaults4) {
        p in obj || (obj[p] = defaults4[p]);
    }
    return obj;
};
var _setKeyframeDefaults = function _setKeyframeDefaults2(excludeDuration) {
    return function (obj, defaults4) {
        for (var p in defaults4) {
            p in obj ||
                (p === 'duration' && excludeDuration) ||
                p === 'ease' ||
                (obj[p] = defaults4[p]);
        }
    };
};
var _merge = function _merge2(base, toMerge) {
    for (var p in toMerge) {
        base[p] = toMerge[p];
    }
    return base;
};
var _mergeDeep = function _mergeDeep2(base, toMerge) {
    for (var p in toMerge) {
        p !== '__proto__' &&
            p !== 'constructor' &&
            p !== 'prototype' &&
            (base[p] = _isObject(toMerge[p])
                ? _mergeDeep2(base[p] || (base[p] = {}), toMerge[p])
                : toMerge[p]);
    }
    return base;
};
var _copyExcluding = function _copyExcluding2(obj, excluding) {
    var copy = {},
        p;
    for (p in obj) {
        p in excluding || (copy[p] = obj[p]);
    }
    return copy;
};
var _inheritDefaults = function _inheritDefaults2(vars) {
    var parent = vars.parent || _globalTimeline,
        func = vars.keyframes
            ? _setKeyframeDefaults(_isArray(vars.keyframes))
            : _setDefaults;
    if (_isNotFalse(vars.inherit)) {
        while (parent) {
            func(vars, parent.vars.defaults);
            parent = parent.parent || parent._dp;
        }
    }
    return vars;
};
var _arraysMatch = function _arraysMatch2(a1, a2) {
    var i = a1.length,
        match = i === a2.length;
    while (match && i-- && a1[i] === a2[i]) {}
    return i < 0;
};
var _addLinkedListItem = function _addLinkedListItem2(
    parent,
    child,
    firstProp,
    lastProp,
    sortBy
) {
    if (firstProp === void 0) {
        firstProp = '_first';
    }
    if (lastProp === void 0) {
        lastProp = '_last';
    }
    var prev = parent[lastProp],
        t2;
    if (sortBy) {
        t2 = child[sortBy];
        while (prev && prev[sortBy] > t2) {
            prev = prev._prev;
        }
    }
    if (prev) {
        child._next = prev._next;
        prev._next = child;
    } else {
        child._next = parent[firstProp];
        parent[firstProp] = child;
    }
    if (child._next) {
        child._next._prev = child;
    } else {
        parent[lastProp] = child;
    }
    child._prev = prev;
    child.parent = child._dp = parent;
    return child;
};
var _removeLinkedListItem = function _removeLinkedListItem2(
    parent,
    child,
    firstProp,
    lastProp
) {
    if (firstProp === void 0) {
        firstProp = '_first';
    }
    if (lastProp === void 0) {
        lastProp = '_last';
    }
    var prev = child._prev,
        next = child._next;
    if (prev) {
        prev._next = next;
    } else if (parent[firstProp] === child) {
        parent[firstProp] = next;
    }
    if (next) {
        next._prev = prev;
    } else if (parent[lastProp] === child) {
        parent[lastProp] = prev;
    }
    child._next = child._prev = child.parent = null;
};
var _removeFromParent = function _removeFromParent2(
    child,
    onlyIfParentHasAutoRemove
) {
    child.parent &&
        (!onlyIfParentHasAutoRemove || child.parent.autoRemoveChildren) &&
        child.parent.remove &&
        child.parent.remove(child);
    child._act = 0;
};
var _uncache = function _uncache2(animation, child) {
    if (
        animation &&
        (!child || child._end > animation._dur || child._start < 0)
    ) {
        var a = animation;
        while (a) {
            a._dirty = 1;
            a = a.parent;
        }
    }
    return animation;
};
var _recacheAncestors = function _recacheAncestors2(animation) {
    var parent = animation.parent;
    while (parent && parent.parent) {
        parent._dirty = 1;
        parent.totalDuration();
        parent = parent.parent;
    }
    return animation;
};
var _rewindStartAt = function _rewindStartAt2(
    tween,
    totalTime,
    suppressEvents,
    force
) {
    return (
        tween._startAt &&
        (_reverting
            ? tween._startAt.revert(_revertConfigNoKill)
            : (tween.vars.immediateRender && !tween.vars.autoRevert) ||
              tween._startAt.render(totalTime, true, force))
    );
};
var _hasNoPausedAncestors = function _hasNoPausedAncestors2(animation) {
    return (
        !animation ||
        (animation._ts && _hasNoPausedAncestors2(animation.parent))
    );
};
var _elapsedCycleDuration = function _elapsedCycleDuration2(animation) {
    return animation._repeat
        ? _animationCycle(
              animation._tTime,
              (animation = animation.duration() + animation._rDelay)
          ) * animation
        : 0;
};
var _animationCycle = function _animationCycle2(tTime, cycleDuration) {
    var whole = Math.floor((tTime /= cycleDuration));
    return tTime && whole === tTime ? whole - 1 : whole;
};
var _parentToChildTotalTime = function _parentToChildTotalTime2(
    parentTime,
    child
) {
    return (
        (parentTime - child._start) * child._ts +
        (child._ts >= 0
            ? 0
            : child._dirty
            ? child.totalDuration()
            : child._tDur)
    );
};
var _setEnd = function _setEnd2(animation) {
    return (animation._end = _roundPrecise(
        animation._start +
            (animation._tDur /
                Math.abs(animation._ts || animation._rts || _tinyNum) || 0)
    ));
};
var _alignPlayhead = function _alignPlayhead2(animation, totalTime) {
    var parent = animation._dp;
    if (parent && parent.smoothChildTiming && animation._ts) {
        animation._start = _roundPrecise(
            parent._time -
                (animation._ts > 0
                    ? totalTime / animation._ts
                    : ((animation._dirty
                          ? animation.totalDuration()
                          : animation._tDur) -
                          totalTime) /
                      -animation._ts)
        );
        _setEnd(animation);
        parent._dirty || _uncache(parent, animation);
    }
    return animation;
};
var _postAddChecks = function _postAddChecks2(timeline2, child) {
    var t2;
    if (
        child._time ||
        (!child._dur && child._initted) ||
        (child._start < timeline2._time && (child._dur || !child.add))
    ) {
        t2 = _parentToChildTotalTime(timeline2.rawTime(), child);
        if (
            !child._dur ||
            _clamp(0, child.totalDuration(), t2) - child._tTime > _tinyNum
        ) {
            child.render(t2, true);
        }
    }
    if (
        _uncache(timeline2, child)._dp &&
        timeline2._initted &&
        timeline2._time >= timeline2._dur &&
        timeline2._ts
    ) {
        if (timeline2._dur < timeline2.duration()) {
            t2 = timeline2;
            while (t2._dp) {
                t2.rawTime() >= 0 && t2.totalTime(t2._tTime);
                t2 = t2._dp;
            }
        }
        timeline2._zTime = -_tinyNum;
    }
};
var _addToTimeline = function _addToTimeline2(
    timeline2,
    child,
    position,
    skipChecks
) {
    child.parent && _removeFromParent(child);
    child._start = _roundPrecise(
        (_isNumber(position)
            ? position
            : position || timeline2 !== _globalTimeline
            ? _parsePosition(timeline2, position, child)
            : timeline2._time) + child._delay
    );
    child._end = _roundPrecise(
        child._start +
            (child.totalDuration() / Math.abs(child.timeScale()) || 0)
    );
    _addLinkedListItem(
        timeline2,
        child,
        '_first',
        '_last',
        timeline2._sort ? '_start' : 0
    );
    _isFromOrFromStart(child) || (timeline2._recent = child);
    skipChecks || _postAddChecks(timeline2, child);
    timeline2._ts < 0 && _alignPlayhead(timeline2, timeline2._tTime);
    return timeline2;
};
var _scrollTrigger = function _scrollTrigger2(animation, trigger) {
    return (
        (_globals.ScrollTrigger || _missingPlugin('scrollTrigger', trigger)) &&
        _globals.ScrollTrigger.create(trigger, animation)
    );
};
var _attemptInitTween = function _attemptInitTween2(
    tween,
    time,
    force,
    suppressEvents,
    tTime
) {
    _initTween(tween, time, tTime);
    if (!tween._initted) {
        return 1;
    }
    if (
        !force &&
        tween._pt &&
        !_reverting &&
        ((tween._dur && tween.vars.lazy !== false) ||
            (!tween._dur && tween.vars.lazy)) &&
        _lastRenderedFrame !== _ticker.frame
    ) {
        _lazyTweens.push(tween);
        tween._lazy = [tTime, suppressEvents];
        return 1;
    }
};
var _parentPlayheadIsBeforeStart = function _parentPlayheadIsBeforeStart2(
    _ref
) {
    var parent = _ref.parent;
    return (
        parent &&
        parent._ts &&
        parent._initted &&
        !parent._lock &&
        (parent.rawTime() < 0 || _parentPlayheadIsBeforeStart2(parent))
    );
};
var _isFromOrFromStart = function _isFromOrFromStart2(_ref2) {
    var data = _ref2.data;
    return data === 'isFromStart' || data === 'isStart';
};
var _renderZeroDurationTween = function _renderZeroDurationTween2(
    tween,
    totalTime,
    suppressEvents,
    force
) {
    var prevRatio = tween.ratio,
        ratio =
            totalTime < 0 ||
            (!totalTime &&
                ((!tween._start &&
                    _parentPlayheadIsBeforeStart(tween) &&
                    !(!tween._initted && _isFromOrFromStart(tween))) ||
                    ((tween._ts < 0 || tween._dp._ts < 0) &&
                        !_isFromOrFromStart(tween))))
                ? 0
                : 1,
        repeatDelay = tween._rDelay,
        tTime = 0,
        pt,
        iteration,
        prevIteration;
    if (repeatDelay && tween._repeat) {
        tTime = _clamp(0, tween._tDur, totalTime);
        iteration = _animationCycle(tTime, repeatDelay);
        tween._yoyo && iteration & 1 && (ratio = 1 - ratio);
        if (iteration !== _animationCycle(tween._tTime, repeatDelay)) {
            prevRatio = 1 - ratio;
            tween.vars.repeatRefresh && tween._initted && tween.invalidate();
        }
    }
    if (
        ratio !== prevRatio ||
        _reverting ||
        force ||
        tween._zTime === _tinyNum ||
        (!totalTime && tween._zTime)
    ) {
        if (
            !tween._initted &&
            _attemptInitTween(tween, totalTime, force, suppressEvents, tTime)
        ) {
            return;
        }
        prevIteration = tween._zTime;
        tween._zTime = totalTime || (suppressEvents ? _tinyNum : 0);
        suppressEvents || (suppressEvents = totalTime && !prevIteration);
        tween.ratio = ratio;
        tween._from && (ratio = 1 - ratio);
        tween._time = 0;
        tween._tTime = tTime;
        pt = tween._pt;
        while (pt) {
            pt.r(ratio, pt.d);
            pt = pt._next;
        }
        totalTime < 0 && _rewindStartAt(tween, totalTime, suppressEvents, true);
        tween._onUpdate && !suppressEvents && _callback(tween, 'onUpdate');
        tTime &&
            tween._repeat &&
            !suppressEvents &&
            tween.parent &&
            _callback(tween, 'onRepeat');
        if (
            (totalTime >= tween._tDur || totalTime < 0) &&
            tween.ratio === ratio
        ) {
            ratio && _removeFromParent(tween, 1);
            if (!suppressEvents && !_reverting) {
                _callback(
                    tween,
                    ratio ? 'onComplete' : 'onReverseComplete',
                    true
                );
                tween._prom && tween._prom();
            }
        }
    } else if (!tween._zTime) {
        tween._zTime = totalTime;
    }
};
var _findNextPauseTween = function _findNextPauseTween2(
    animation,
    prevTime,
    time
) {
    var child;
    if (time > prevTime) {
        child = animation._first;
        while (child && child._start <= time) {
            if (child.data === 'isPause' && child._start > prevTime) {
                return child;
            }
            child = child._next;
        }
    } else {
        child = animation._last;
        while (child && child._start >= time) {
            if (child.data === 'isPause' && child._start < prevTime) {
                return child;
            }
            child = child._prev;
        }
    }
};
var _setDuration = function _setDuration2(
    animation,
    duration,
    skipUncache,
    leavePlayhead
) {
    var repeat = animation._repeat,
        dur = _roundPrecise(duration) || 0,
        totalProgress = animation._tTime / animation._tDur;
    totalProgress &&
        !leavePlayhead &&
        (animation._time *= dur / animation._dur);
    animation._dur = dur;
    animation._tDur = !repeat
        ? dur
        : repeat < 0
        ? 1e10
        : _roundPrecise(dur * (repeat + 1) + animation._rDelay * repeat);
    totalProgress > 0 &&
        !leavePlayhead &&
        _alignPlayhead(
            animation,
            (animation._tTime = animation._tDur * totalProgress)
        );
    animation.parent && _setEnd(animation);
    skipUncache || _uncache(animation.parent, animation);
    return animation;
};
var _onUpdateTotalDuration = function _onUpdateTotalDuration2(animation) {
    return animation instanceof Timeline
        ? _uncache(animation)
        : _setDuration(animation, animation._dur);
};
var _zeroPosition = {
    _start: 0,
    endTime: _emptyFunc,
    totalDuration: _emptyFunc,
};
var _parsePosition = function _parsePosition2(
    animation,
    position,
    percentAnimation
) {
    var labels = animation.labels,
        recent = animation._recent || _zeroPosition,
        clippedDuration =
            animation.duration() >= _bigNum
                ? recent.endTime(false)
                : animation._dur,
        i,
        offset,
        isPercent;
    if (_isString(position) && (isNaN(position) || position in labels)) {
        offset = position.charAt(0);
        isPercent = position.substr(-1) === '%';
        i = position.indexOf('=');
        if (offset === '<' || offset === '>') {
            i >= 0 && (position = position.replace(/=/, ''));
            return (
                (offset === '<'
                    ? recent._start
                    : recent.endTime(recent._repeat >= 0)) +
                (parseFloat(position.substr(1)) || 0) *
                    (isPercent
                        ? (i < 0 ? recent : percentAnimation).totalDuration() /
                          100
                        : 1)
            );
        }
        if (i < 0) {
            position in labels || (labels[position] = clippedDuration);
            return labels[position];
        }
        offset = parseFloat(position.charAt(i - 1) + position.substr(i + 1));
        if (isPercent && percentAnimation) {
            offset =
                (offset / 100) *
                (_isArray(percentAnimation)
                    ? percentAnimation[0]
                    : percentAnimation
                ).totalDuration();
        }
        return i > 1
            ? _parsePosition2(
                  animation,
                  position.substr(0, i - 1),
                  percentAnimation
              ) + offset
            : clippedDuration + offset;
    }
    return position == null ? clippedDuration : +position;
};
var _createTweenType = function _createTweenType2(type, params, timeline2) {
    var isLegacy = _isNumber(params[1]),
        varsIndex = (isLegacy ? 2 : 1) + (type < 2 ? 0 : 1),
        vars = params[varsIndex],
        irVars,
        parent;
    isLegacy && (vars.duration = params[1]);
    vars.parent = timeline2;
    if (type) {
        irVars = vars;
        parent = timeline2;
        while (parent && !('immediateRender' in irVars)) {
            irVars = parent.vars.defaults || {};
            parent = _isNotFalse(parent.vars.inherit) && parent.parent;
        }
        vars.immediateRender = _isNotFalse(irVars.immediateRender);
        type < 2
            ? (vars.runBackwards = 1)
            : (vars.startAt = params[varsIndex - 1]);
    }
    return new Tween(params[0], vars, params[varsIndex + 1]);
};
var _conditionalReturn = function _conditionalReturn2(value, func) {
    return value || value === 0 ? func(value) : func;
};
var _clamp = function _clamp2(min, max, value) {
    return value < min ? min : value > max ? max : value;
};
var getUnit = function getUnit2(value, v) {
    return !_isString(value) || !(v = _unitExp.exec(value)) ? '' : v[1];
};
var clamp = function clamp2(min, max, value) {
    return _conditionalReturn(value, function (v) {
        return _clamp(min, max, v);
    });
};
var _slice = [].slice;
var _isArrayLike = function _isArrayLike2(value, nonEmpty) {
    return (
        value &&
        _isObject(value) &&
        'length' in value &&
        ((!nonEmpty && !value.length) ||
            (value.length - 1 in value && _isObject(value[0]))) &&
        !value.nodeType &&
        value !== _win
    );
};
var _flatten = function _flatten2(ar, leaveStrings, accumulator) {
    if (accumulator === void 0) {
        accumulator = [];
    }
    return (
        ar.forEach(function (value) {
            var _accumulator;
            return (_isString(value) && !leaveStrings) || _isArrayLike(value, 1)
                ? (_accumulator = accumulator).push.apply(
                      _accumulator,
                      toArray(value)
                  )
                : accumulator.push(value);
        }) || accumulator
    );
};
var toArray = function toArray2(value, scope, leaveStrings) {
    return _context && !scope && _context.selector
        ? _context.selector(value)
        : _isString(value) && !leaveStrings && (_coreInitted || !_wake())
        ? _slice.call((scope || _doc).querySelectorAll(value), 0)
        : _isArray(value)
        ? _flatten(value, leaveStrings)
        : _isArrayLike(value)
        ? _slice.call(value, 0)
        : value
        ? [value]
        : [];
};
var selector = function selector2(value) {
    value = toArray(value)[0] || _warn('Invalid scope') || {};
    return function (v) {
        var el = value.current || value.nativeElement || value;
        return toArray(
            v,
            el.querySelectorAll
                ? el
                : el === value
                ? _warn('Invalid scope') || _doc.createElement('div')
                : value
        );
    };
};
var shuffle = function shuffle2(a) {
    return a.sort(function () {
        return 0.5 - Math.random();
    });
};
var distribute = function distribute2(v) {
    if (_isFunction(v)) {
        return v;
    }
    var vars = _isObject(v)
            ? v
            : {
                  each: v,
              },
        ease = _parseEase(vars.ease),
        from = vars.from || 0,
        base = parseFloat(vars.base) || 0,
        cache2 = {},
        isDecimal = from > 0 && from < 1,
        ratios = isNaN(from) || isDecimal,
        axis = vars.axis,
        ratioX = from,
        ratioY = from;
    if (_isString(from)) {
        ratioX = ratioY =
            {
                center: 0.5,
                edges: 0.5,
                end: 1,
            }[from] || 0;
    } else if (!isDecimal && ratios) {
        ratioX = from[0];
        ratioY = from[1];
    }
    return function (i, target, a) {
        var l = (a || vars).length,
            distances = cache2[l],
            originX,
            originY,
            x,
            y,
            d,
            j,
            max,
            min,
            wrapAt;
        if (!distances) {
            wrapAt = vars.grid === 'auto' ? 0 : (vars.grid || [1, _bigNum])[1];
            if (!wrapAt) {
                max = -_bigNum;
                while (
                    max < (max = a[wrapAt++].getBoundingClientRect().left) &&
                    wrapAt < l
                ) {}
                wrapAt < l && wrapAt--;
            }
            distances = cache2[l] = [];
            originX = ratios
                ? Math.min(wrapAt, l) * ratioX - 0.5
                : from % wrapAt;
            originY =
                wrapAt === _bigNum
                    ? 0
                    : ratios
                    ? (l * ratioY) / wrapAt - 0.5
                    : (from / wrapAt) | 0;
            max = 0;
            min = _bigNum;
            for (j = 0; j < l; j++) {
                x = (j % wrapAt) - originX;
                y = originY - ((j / wrapAt) | 0);
                distances[j] = d = !axis
                    ? _sqrt(x * x + y * y)
                    : Math.abs(axis === 'y' ? y : x);
                d > max && (max = d);
                d < min && (min = d);
            }
            from === 'random' && shuffle(distances);
            distances.max = max - min;
            distances.min = min;
            distances.v = l =
                (parseFloat(vars.amount) ||
                    parseFloat(vars.each) *
                        (wrapAt > l
                            ? l - 1
                            : !axis
                            ? Math.max(wrapAt, l / wrapAt)
                            : axis === 'y'
                            ? l / wrapAt
                            : wrapAt) ||
                    0) * (from === 'edges' ? -1 : 1);
            distances.b = l < 0 ? base - l : base;
            distances.u = getUnit(vars.amount || vars.each) || 0;
            ease = ease && l < 0 ? _invertEase(ease) : ease;
        }
        l = (distances[i] - distances.min) / distances.max || 0;
        return (
            _roundPrecise(distances.b + (ease ? ease(l) : l) * distances.v) +
            distances.u
        );
    };
};
var _roundModifier = function _roundModifier2(v) {
    var p = Math.pow(10, ((v + '').split('.')[1] || '').length);
    return function (raw) {
        var n = _roundPrecise(Math.round(parseFloat(raw) / v) * v * p);
        return (n - (n % 1)) / p + (_isNumber(raw) ? 0 : getUnit(raw));
    };
};
var snap = function snap2(snapTo, value) {
    var isArray2 = _isArray(snapTo),
        radius,
        is2D;
    if (!isArray2 && _isObject(snapTo)) {
        radius = isArray2 = snapTo.radius || _bigNum;
        if (snapTo.values) {
            snapTo = toArray(snapTo.values);
            if ((is2D = !_isNumber(snapTo[0]))) {
                radius *= radius;
            }
        } else {
            snapTo = _roundModifier(snapTo.increment);
        }
    }
    return _conditionalReturn(
        value,
        !isArray2
            ? _roundModifier(snapTo)
            : _isFunction(snapTo)
            ? function (raw) {
                  is2D = snapTo(raw);
                  return Math.abs(is2D - raw) <= radius ? is2D : raw;
              }
            : function (raw) {
                  var x = parseFloat(is2D ? raw.x : raw),
                      y = parseFloat(is2D ? raw.y : 0),
                      min = _bigNum,
                      closest = 0,
                      i = snapTo.length,
                      dx,
                      dy;
                  while (i--) {
                      if (is2D) {
                          dx = snapTo[i].x - x;
                          dy = snapTo[i].y - y;
                          dx = dx * dx + dy * dy;
                      } else {
                          dx = Math.abs(snapTo[i] - x);
                      }
                      if (dx < min) {
                          min = dx;
                          closest = i;
                      }
                  }
                  closest = !radius || min <= radius ? snapTo[closest] : raw;
                  return is2D || closest === raw || _isNumber(raw)
                      ? closest
                      : closest + getUnit(raw);
              }
    );
};
var random = function random2(min, max, roundingIncrement, returnFunction) {
    return _conditionalReturn(
        _isArray(min)
            ? !max
            : roundingIncrement === true
            ? !!(roundingIncrement = 0)
            : !returnFunction,
        function () {
            return _isArray(min)
                ? min[~~(Math.random() * min.length)]
                : (roundingIncrement = roundingIncrement || 1e-5) &&
                      (returnFunction =
                          roundingIncrement < 1
                              ? Math.pow(
                                    10,
                                    (roundingIncrement + '').length - 2
                                )
                              : 1) &&
                      Math.floor(
                          Math.round(
                              (min -
                                  roundingIncrement / 2 +
                                  Math.random() *
                                      (max - min + roundingIncrement * 0.99)) /
                                  roundingIncrement
                          ) *
                              roundingIncrement *
                              returnFunction
                      ) / returnFunction;
        }
    );
};
var pipe = function pipe2() {
    for (
        var _len = arguments.length, functions = new Array(_len), _key = 0;
        _key < _len;
        _key++
    ) {
        functions[_key] = arguments[_key];
    }
    return function (value) {
        return functions.reduce(function (v, f) {
            return f(v);
        }, value);
    };
};
var unitize = function unitize2(func, unit) {
    return function (value) {
        return func(parseFloat(value)) + (unit || getUnit(value));
    };
};
var normalize = function normalize2(min, max, value) {
    return mapRange(min, max, 0, 1, value);
};
var _wrapArray = function _wrapArray2(a, wrapper, value) {
    return _conditionalReturn(value, function (index) {
        return a[~~wrapper(index)];
    });
};
var wrap = function wrap2(min, max, value) {
    var range = max - min;
    return _isArray(min)
        ? _wrapArray(min, wrap2(0, min.length), max)
        : _conditionalReturn(value, function (value2) {
              return ((range + ((value2 - min) % range)) % range) + min;
          });
};
var wrapYoyo = function wrapYoyo2(min, max, value) {
    var range = max - min,
        total = range * 2;
    return _isArray(min)
        ? _wrapArray(min, wrapYoyo2(0, min.length - 1), max)
        : _conditionalReturn(value, function (value2) {
              value2 = (total + ((value2 - min) % total)) % total || 0;
              return min + (value2 > range ? total - value2 : value2);
          });
};
var _replaceRandom = function _replaceRandom2(value) {
    var prev = 0,
        s = '',
        i,
        nums,
        end,
        isArray2;
    while (~(i = value.indexOf('random(', prev))) {
        end = value.indexOf(')', i);
        isArray2 = value.charAt(i + 7) === '[';
        nums = value
            .substr(i + 7, end - i - 7)
            .match(isArray2 ? _delimitedValueExp : _strictNumExp);
        s +=
            value.substr(prev, i - prev) +
            random(
                isArray2 ? nums : +nums[0],
                isArray2 ? 0 : +nums[1],
                +nums[2] || 1e-5
            );
        prev = end + 1;
    }
    return s + value.substr(prev, value.length - prev);
};
var mapRange = function mapRange2(inMin, inMax, outMin, outMax, value) {
    var inRange = inMax - inMin,
        outRange = outMax - outMin;
    return _conditionalReturn(value, function (value2) {
        return outMin + (((value2 - inMin) / inRange) * outRange || 0);
    });
};
var interpolate = function interpolate2(start, end, progress, mutate) {
    var func = isNaN(start + end)
        ? 0
        : function (p2) {
              return (1 - p2) * start + p2 * end;
          };
    if (!func) {
        var isString2 = _isString(start),
            master = {},
            p,
            i,
            interpolators,
            l,
            il;
        progress === true && (mutate = 1) && (progress = null);
        if (isString2) {
            start = {
                p: start,
            };
            end = {
                p: end,
            };
        } else if (_isArray(start) && !_isArray(end)) {
            interpolators = [];
            l = start.length;
            il = l - 2;
            for (i = 1; i < l; i++) {
                interpolators.push(interpolate2(start[i - 1], start[i]));
            }
            l--;
            func = function func2(p2) {
                p2 *= l;
                var i2 = Math.min(il, ~~p2);
                return interpolators[i2](p2 - i2);
            };
            progress = end;
        } else if (!mutate) {
            start = _merge(_isArray(start) ? [] : {}, start);
        }
        if (!interpolators) {
            for (p in end) {
                _addPropTween.call(master, start, p, 'get', end[p]);
            }
            func = function func2(p2) {
                return (
                    _renderPropTweens(p2, master) ||
                    (isString2 ? start.p : start)
                );
            };
        }
    }
    return _conditionalReturn(progress, func);
};
var _getLabelInDirection = function _getLabelInDirection2(
    timeline2,
    fromTime,
    backward
) {
    var labels = timeline2.labels,
        min = _bigNum,
        p,
        distance,
        label;
    for (p in labels) {
        distance = labels[p] - fromTime;
        if (
            distance < 0 === !!backward &&
            distance &&
            min > (distance = Math.abs(distance))
        ) {
            label = p;
            min = distance;
        }
    }
    return label;
};
var _callback = function _callback2(animation, type, executeLazyFirst) {
    var v = animation.vars,
        callback = v[type],
        prevContext = _context,
        context3 = animation._ctx,
        params,
        scope,
        result;
    if (!callback) {
        return;
    }
    params = v[type + 'Params'];
    scope = v.callbackScope || animation;
    executeLazyFirst && _lazyTweens.length && _lazyRender();
    context3 && (_context = context3);
    result = params ? callback.apply(scope, params) : callback.call(scope);
    _context = prevContext;
    return result;
};
var _interrupt = function _interrupt2(animation) {
    _removeFromParent(animation);
    animation.scrollTrigger && animation.scrollTrigger.kill(!!_reverting);
    animation.progress() < 1 && _callback(animation, 'onInterrupt');
    return animation;
};
var _quickTween;
var _registerPluginQueue = [];
var _createPlugin = function _createPlugin2(config3) {
    if (!config3) return;
    config3 = (!config3.name && config3['default']) || config3;
    if (_windowExists() || config3.headless) {
        var name = config3.name,
            isFunc = _isFunction(config3),
            Plugin =
                name && !isFunc && config3.init
                    ? function () {
                          this._props = [];
                      }
                    : config3,
            instanceDefaults = {
                init: _emptyFunc,
                render: _renderPropTweens,
                add: _addPropTween,
                kill: _killPropTweensOf,
                modifier: _addPluginModifier,
                rawVars: 0,
            },
            statics = {
                targetTest: 0,
                get: 0,
                getSetter: _getSetter,
                aliases: {},
                register: 0,
            };
        _wake();
        if (config3 !== Plugin) {
            if (_plugins[name]) {
                return;
            }
            _setDefaults(
                Plugin,
                _setDefaults(_copyExcluding(config3, instanceDefaults), statics)
            );
            _merge(
                Plugin.prototype,
                _merge(instanceDefaults, _copyExcluding(config3, statics))
            );
            _plugins[(Plugin.prop = name)] = Plugin;
            if (config3.targetTest) {
                _harnessPlugins.push(Plugin);
                _reservedProps[name] = 1;
            }
            name =
                (name === 'css'
                    ? 'CSS'
                    : name.charAt(0).toUpperCase() + name.substr(1)) + 'Plugin';
        }
        _addGlobal(name, Plugin);
        config3.register && config3.register(gsap, Plugin, PropTween);
    } else {
        _registerPluginQueue.push(config3);
    }
};
var _255 = 255;
var _colorLookup = {
    aqua: [0, _255, _255],
    lime: [0, _255, 0],
    silver: [192, 192, 192],
    black: [0, 0, 0],
    maroon: [128, 0, 0],
    teal: [0, 128, 128],
    blue: [0, 0, _255],
    navy: [0, 0, 128],
    white: [_255, _255, _255],
    olive: [128, 128, 0],
    yellow: [_255, _255, 0],
    orange: [_255, 165, 0],
    gray: [128, 128, 128],
    purple: [128, 0, 128],
    green: [0, 128, 0],
    red: [_255, 0, 0],
    pink: [_255, 192, 203],
    cyan: [0, _255, _255],
    transparent: [_255, _255, _255, 0],
};
var _hue = function _hue2(h, m1, m2) {
    h += h < 0 ? 1 : h > 1 ? -1 : 0;
    return (
        ((h * 6 < 1
            ? m1 + (m2 - m1) * h * 6
            : h < 0.5
            ? m2
            : h * 3 < 2
            ? m1 + (m2 - m1) * (2 / 3 - h) * 6
            : m1) *
            _255 +
            0.5) |
        0
    );
};
var splitColor = function splitColor2(v, toHSL, forceAlpha) {
    var a = !v
            ? _colorLookup.black
            : _isNumber(v)
            ? [v >> 16, (v >> 8) & _255, v & _255]
            : 0,
        r,
        g,
        b,
        h,
        s,
        l,
        max,
        min,
        d,
        wasHSL;
    if (!a) {
        if (v.substr(-1) === ',') {
            v = v.substr(0, v.length - 1);
        }
        if (_colorLookup[v]) {
            a = _colorLookup[v];
        } else if (v.charAt(0) === '#') {
            if (v.length < 6) {
                r = v.charAt(1);
                g = v.charAt(2);
                b = v.charAt(3);
                v =
                    '#' +
                    r +
                    r +
                    g +
                    g +
                    b +
                    b +
                    (v.length === 5 ? v.charAt(4) + v.charAt(4) : '');
            }
            if (v.length === 9) {
                a = parseInt(v.substr(1, 6), 16);
                return [
                    a >> 16,
                    (a >> 8) & _255,
                    a & _255,
                    parseInt(v.substr(7), 16) / 255,
                ];
            }
            v = parseInt(v.substr(1), 16);
            a = [v >> 16, (v >> 8) & _255, v & _255];
        } else if (v.substr(0, 3) === 'hsl') {
            a = wasHSL = v.match(_strictNumExp);
            if (!toHSL) {
                h = (+a[0] % 360) / 360;
                s = +a[1] / 100;
                l = +a[2] / 100;
                g = l <= 0.5 ? l * (s + 1) : l + s - l * s;
                r = l * 2 - g;
                a.length > 3 && (a[3] *= 1);
                a[0] = _hue(h + 1 / 3, r, g);
                a[1] = _hue(h, r, g);
                a[2] = _hue(h - 1 / 3, r, g);
            } else if (~v.indexOf('=')) {
                a = v.match(_numExp);
                forceAlpha && a.length < 4 && (a[3] = 1);
                return a;
            }
        } else {
            a = v.match(_strictNumExp) || _colorLookup.transparent;
        }
        a = a.map(Number);
    }
    if (toHSL && !wasHSL) {
        r = a[0] / _255;
        g = a[1] / _255;
        b = a[2] / _255;
        max = Math.max(r, g, b);
        min = Math.min(r, g, b);
        l = (max + min) / 2;
        if (max === min) {
            h = s = 0;
        } else {
            d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            h =
                max === r
                    ? (g - b) / d + (g < b ? 6 : 0)
                    : max === g
                    ? (b - r) / d + 2
                    : (r - g) / d + 4;
            h *= 60;
        }
        a[0] = ~~(h + 0.5);
        a[1] = ~~(s * 100 + 0.5);
        a[2] = ~~(l * 100 + 0.5);
    }
    forceAlpha && a.length < 4 && (a[3] = 1);
    return a;
};
var _colorOrderData = function _colorOrderData2(v) {
    var values = [],
        c = [],
        i = -1;
    v.split(_colorExp).forEach(function (v2) {
        var a = v2.match(_numWithUnitExp) || [];
        values.push.apply(values, a);
        c.push((i += a.length + 1));
    });
    values.c = c;
    return values;
};
var _formatColors = function _formatColors2(s, toHSL, orderMatchData) {
    var result = '',
        colors = (s + result).match(_colorExp),
        type = toHSL ? 'hsla(' : 'rgba(',
        i = 0,
        c,
        shell,
        d,
        l;
    if (!colors) {
        return s;
    }
    colors = colors.map(function (color) {
        return (
            (color = splitColor(color, toHSL, 1)) &&
            type +
                (toHSL
                    ? color[0] +
                      ',' +
                      color[1] +
                      '%,' +
                      color[2] +
                      '%,' +
                      color[3]
                    : color.join(',')) +
                ')'
        );
    });
    if (orderMatchData) {
        d = _colorOrderData(s);
        c = orderMatchData.c;
        if (c.join(result) !== d.c.join(result)) {
            shell = s.replace(_colorExp, '1').split(_numWithUnitExp);
            l = shell.length - 1;
            for (; i < l; i++) {
                result +=
                    shell[i] +
                    (~c.indexOf(i)
                        ? colors.shift() || type + '0,0,0,0)'
                        : (d.length
                              ? d
                              : colors.length
                              ? colors
                              : orderMatchData
                          ).shift());
            }
        }
    }
    if (!shell) {
        shell = s.split(_colorExp);
        l = shell.length - 1;
        for (; i < l; i++) {
            result += shell[i] + colors[i];
        }
    }
    return result + shell[l];
};
var _colorExp = (function () {
    var s =
            '(?:\\b(?:(?:rgb|rgba|hsl|hsla)\\(.+?\\))|\\B#(?:[0-9a-f]{3,4}){1,2}\\b',
        p;
    for (p in _colorLookup) {
        s += '|' + p + '\\b';
    }
    return new RegExp(s + ')', 'gi');
})();
var _hslExp = /hsl[a]?\(/;
var _colorStringFilter = function _colorStringFilter2(a) {
    var combined = a.join(' '),
        toHSL;
    _colorExp.lastIndex = 0;
    if (_colorExp.test(combined)) {
        toHSL = _hslExp.test(combined);
        a[1] = _formatColors(a[1], toHSL);
        a[0] = _formatColors(a[0], toHSL, _colorOrderData(a[1]));
        return true;
    }
};
var _tickerActive;
var _ticker = (function () {
    var _getTime = Date.now,
        _lagThreshold = 500,
        _adjustedLag = 33,
        _startTime = _getTime(),
        _lastUpdate = _startTime,
        _gap = 1e3 / 240,
        _nextTime = _gap,
        _listeners2 = [],
        _id,
        _req,
        _raf,
        _self,
        _delta,
        _i,
        _tick = function _tick2(v) {
            var elapsed = _getTime() - _lastUpdate,
                manual = v === true,
                overlap,
                dispatch,
                time,
                frame;
            (elapsed > _lagThreshold || elapsed < 0) &&
                (_startTime += elapsed - _adjustedLag);
            _lastUpdate += elapsed;
            time = _lastUpdate - _startTime;
            overlap = time - _nextTime;
            if (overlap > 0 || manual) {
                frame = ++_self.frame;
                _delta = time - _self.time * 1e3;
                _self.time = time = time / 1e3;
                _nextTime += overlap + (overlap >= _gap ? 4 : _gap - overlap);
                dispatch = 1;
            }
            manual || (_id = _req(_tick2));
            if (dispatch) {
                for (_i = 0; _i < _listeners2.length; _i++) {
                    _listeners2[_i](time, _delta, frame, v);
                }
            }
        };
    _self = {
        time: 0,
        frame: 0,
        tick: function tick() {
            _tick(true);
        },
        deltaRatio: function deltaRatio(fps) {
            return _delta / (1e3 / (fps || 60));
        },
        wake: function wake() {
            if (_coreReady) {
                if (!_coreInitted && _windowExists()) {
                    _win = _coreInitted = window;
                    _doc = _win.document || {};
                    _globals.gsap = gsap;
                    (_win.gsapVersions || (_win.gsapVersions = [])).push(
                        gsap.version
                    );
                    _install(
                        _installScope ||
                            _win.GreenSockGlobals ||
                            (!_win.gsap && _win) ||
                            {}
                    );
                    _registerPluginQueue.forEach(_createPlugin);
                }
                _raf =
                    typeof requestAnimationFrame !== 'undefined' &&
                    requestAnimationFrame;
                _id && _self.sleep();
                _req =
                    _raf ||
                    function (f) {
                        return setTimeout(
                            f,
                            (_nextTime - _self.time * 1e3 + 1) | 0
                        );
                    };
                _tickerActive = 1;
                _tick(2);
            }
        },
        sleep: function sleep() {
            (_raf ? cancelAnimationFrame : clearTimeout)(_id);
            _tickerActive = 0;
            _req = _emptyFunc;
        },
        lagSmoothing: function lagSmoothing(threshold, adjustedLag) {
            _lagThreshold = threshold || Infinity;
            _adjustedLag = Math.min(adjustedLag || 33, _lagThreshold);
        },
        fps: function fps(_fps) {
            _gap = 1e3 / (_fps || 240);
            _nextTime = _self.time * 1e3 + _gap;
        },
        add: function add(callback, once, prioritize) {
            var func = once
                ? function (t2, d, f, v) {
                      callback(t2, d, f, v);
                      _self.remove(func);
                  }
                : callback;
            _self.remove(callback);
            _listeners2[prioritize ? 'unshift' : 'push'](func);
            _wake();
            return func;
        },
        remove: function remove2(callback, i) {
            ~(i = _listeners2.indexOf(callback)) &&
                _listeners2.splice(i, 1) &&
                _i >= i &&
                _i--;
        },
        _listeners: _listeners2,
    };
    return _self;
})();
var _wake = function _wake2() {
    return !_tickerActive && _ticker.wake();
};
var _easeMap = {};
var _customEaseExp = /^[\d.\-M][\d.\-,\s]/;
var _quotesExp = /["']/g;
var _parseObjectInString = function _parseObjectInString2(value) {
    var obj = {},
        split2 = value.substr(1, value.length - 3).split(':'),
        key = split2[0],
        i = 1,
        l = split2.length,
        index,
        val,
        parsedVal;
    for (; i < l; i++) {
        val = split2[i];
        index = i !== l - 1 ? val.lastIndexOf(',') : val.length;
        parsedVal = val.substr(0, index);
        obj[key] = isNaN(parsedVal)
            ? parsedVal.replace(_quotesExp, '').trim()
            : +parsedVal;
        key = val.substr(index + 1).trim();
    }
    return obj;
};
var _valueInParentheses = function _valueInParentheses2(value) {
    var open = value.indexOf('(') + 1,
        close = value.indexOf(')'),
        nested = value.indexOf('(', open);
    return value.substring(
        open,
        ~nested && nested < close ? value.indexOf(')', close + 1) : close
    );
};
var _configEaseFromString = function _configEaseFromString2(name) {
    var split2 = (name + '').split('('),
        ease = _easeMap[split2[0]];
    return ease && split2.length > 1 && ease.config
        ? ease.config.apply(
              null,
              ~name.indexOf('{')
                  ? [_parseObjectInString(split2[1])]
                  : _valueInParentheses(name).split(',').map(_numericIfPossible)
          )
        : _easeMap._CE && _customEaseExp.test(name)
        ? _easeMap._CE('', name)
        : ease;
};
var _invertEase = function _invertEase2(ease) {
    return function (p) {
        return 1 - ease(1 - p);
    };
};
var _propagateYoyoEase = function _propagateYoyoEase2(timeline2, isYoyo) {
    var child = timeline2._first,
        ease;
    while (child) {
        if (child instanceof Timeline) {
            _propagateYoyoEase2(child, isYoyo);
        } else if (
            child.vars.yoyoEase &&
            (!child._yoyo || !child._repeat) &&
            child._yoyo !== isYoyo
        ) {
            if (child.timeline) {
                _propagateYoyoEase2(child.timeline, isYoyo);
            } else {
                ease = child._ease;
                child._ease = child._yEase;
                child._yEase = ease;
                child._yoyo = isYoyo;
            }
        }
        child = child._next;
    }
};
var _parseEase = function _parseEase2(ease, defaultEase) {
    return !ease
        ? defaultEase
        : (_isFunction(ease)
              ? ease
              : _easeMap[ease] || _configEaseFromString(ease)) || defaultEase;
};
var _insertEase = function _insertEase2(names, easeIn, easeOut, easeInOut) {
    if (easeOut === void 0) {
        easeOut = function easeOut2(p) {
            return 1 - easeIn(1 - p);
        };
    }
    if (easeInOut === void 0) {
        easeInOut = function easeInOut2(p) {
            return p < 0.5 ? easeIn(p * 2) / 2 : 1 - easeIn((1 - p) * 2) / 2;
        };
    }
    var ease = {
            easeIn,
            easeOut,
            easeInOut,
        },
        lowercaseName;
    _forEachName(names, function (name) {
        _easeMap[name] = _globals[name] = ease;
        _easeMap[(lowercaseName = name.toLowerCase())] = easeOut;
        for (var p in ease) {
            _easeMap[
                lowercaseName +
                    (p === 'easeIn'
                        ? '.in'
                        : p === 'easeOut'
                        ? '.out'
                        : '.inOut')
            ] = _easeMap[name + '.' + p] = ease[p];
        }
    });
    return ease;
};
var _easeInOutFromOut = function _easeInOutFromOut2(easeOut) {
    return function (p) {
        return p < 0.5
            ? (1 - easeOut(1 - p * 2)) / 2
            : 0.5 + easeOut((p - 0.5) * 2) / 2;
    };
};
var _configElastic = function _configElastic2(type, amplitude, period) {
    var p1 = amplitude >= 1 ? amplitude : 1,
        p2 = (period || (type ? 0.3 : 0.45)) / (amplitude < 1 ? amplitude : 1),
        p3 = (p2 / _2PI) * (Math.asin(1 / p1) || 0),
        easeOut = function easeOut2(p) {
            return p === 1
                ? 1
                : p1 * Math.pow(2, -10 * p) * _sin((p - p3) * p2) + 1;
        },
        ease =
            type === 'out'
                ? easeOut
                : type === 'in'
                ? function (p) {
                      return 1 - easeOut(1 - p);
                  }
                : _easeInOutFromOut(easeOut);
    p2 = _2PI / p2;
    ease.config = function (amplitude2, period2) {
        return _configElastic2(type, amplitude2, period2);
    };
    return ease;
};
var _configBack = function _configBack2(type, overshoot) {
    if (overshoot === void 0) {
        overshoot = 1.70158;
    }
    var easeOut = function easeOut2(p) {
            return p ? --p * p * ((overshoot + 1) * p + overshoot) + 1 : 0;
        },
        ease =
            type === 'out'
                ? easeOut
                : type === 'in'
                ? function (p) {
                      return 1 - easeOut(1 - p);
                  }
                : _easeInOutFromOut(easeOut);
    ease.config = function (overshoot2) {
        return _configBack2(type, overshoot2);
    };
    return ease;
};
_forEachName('Linear,Quad,Cubic,Quart,Quint,Strong', function (name, i) {
    var power = i < 5 ? i + 1 : i;
    _insertEase(
        name + ',Power' + (power - 1),
        i
            ? function (p) {
                  return Math.pow(p, power);
              }
            : function (p) {
                  return p;
              },
        function (p) {
            return 1 - Math.pow(1 - p, power);
        },
        function (p) {
            return p < 0.5
                ? Math.pow(p * 2, power) / 2
                : 1 - Math.pow((1 - p) * 2, power) / 2;
        }
    );
});
_easeMap.Linear.easeNone = _easeMap.none = _easeMap.Linear.easeIn;
_insertEase(
    'Elastic',
    _configElastic('in'),
    _configElastic('out'),
    _configElastic()
);
(function (n, c) {
    var n1 = 1 / c,
        n2 = 2 * n1,
        n3 = 2.5 * n1,
        easeOut = function easeOut2(p) {
            return p < n1
                ? n * p * p
                : p < n2
                ? n * Math.pow(p - 1.5 / c, 2) + 0.75
                : p < n3
                ? n * (p -= 2.25 / c) * p + 0.9375
                : n * Math.pow(p - 2.625 / c, 2) + 0.984375;
        };
    _insertEase(
        'Bounce',
        function (p) {
            return 1 - easeOut(1 - p);
        },
        easeOut
    );
})(7.5625, 2.75);
_insertEase('Expo', function (p) {
    return p ? Math.pow(2, 10 * (p - 1)) : 0;
});
_insertEase('Circ', function (p) {
    return -(_sqrt(1 - p * p) - 1);
});
_insertEase('Sine', function (p) {
    return p === 1 ? 1 : -_cos(p * _HALF_PI) + 1;
});
_insertEase('Back', _configBack('in'), _configBack('out'), _configBack());
_easeMap.SteppedEase =
    _easeMap.steps =
    _globals.SteppedEase =
        {
            config: function config(steps, immediateStart) {
                if (steps === void 0) {
                    steps = 1;
                }
                var p1 = 1 / steps,
                    p2 = steps + (immediateStart ? 0 : 1),
                    p3 = immediateStart ? 1 : 0,
                    max = 1 - _tinyNum;
                return function (p) {
                    return (((p2 * _clamp(0, max, p)) | 0) + p3) * p1;
                };
            },
        };
_defaults.ease = _easeMap['quad.out'];
_forEachName(
    'onComplete,onUpdate,onStart,onRepeat,onReverseComplete,onInterrupt',
    function (name) {
        return (_callbackNames += name + ',' + name + 'Params,');
    }
);
var GSCache = function GSCache2(target, harness) {
    this.id = _gsID++;
    target._gsap = this;
    this.target = target;
    this.harness = harness;
    this.get = harness ? harness.get : _getProperty;
    this.set = harness ? harness.getSetter : _getSetter;
};
var Animation = /* @__PURE__ */ (function () {
    function Animation2(vars) {
        this.vars = vars;
        this._delay = +vars.delay || 0;
        if ((this._repeat = vars.repeat === Infinity ? -2 : vars.repeat || 0)) {
            this._rDelay = vars.repeatDelay || 0;
            this._yoyo = !!vars.yoyo || !!vars.yoyoEase;
        }
        this._ts = 1;
        _setDuration(this, +vars.duration, 1, 1);
        this.data = vars.data;
        if (_context) {
            this._ctx = _context;
            _context.data.push(this);
        }
        _tickerActive || _ticker.wake();
    }
    var _proto = Animation2.prototype;
    _proto.delay = function delay(value) {
        if (value || value === 0) {
            this.parent &&
                this.parent.smoothChildTiming &&
                this.startTime(this._start + value - this._delay);
            this._delay = value;
            return this;
        }
        return this._delay;
    };
    _proto.duration = function duration(value) {
        return arguments.length
            ? this.totalDuration(
                  this._repeat > 0
                      ? value + (value + this._rDelay) * this._repeat
                      : value
              )
            : this.totalDuration() && this._dur;
    };
    _proto.totalDuration = function totalDuration(value) {
        if (!arguments.length) {
            return this._tDur;
        }
        this._dirty = 0;
        return _setDuration(
            this,
            this._repeat < 0
                ? value
                : (value - this._repeat * this._rDelay) / (this._repeat + 1)
        );
    };
    _proto.totalTime = function totalTime(_totalTime, suppressEvents) {
        _wake();
        if (!arguments.length) {
            return this._tTime;
        }
        var parent = this._dp;
        if (parent && parent.smoothChildTiming && this._ts) {
            _alignPlayhead(this, _totalTime);
            !parent._dp || parent.parent || _postAddChecks(parent, this);
            while (parent && parent.parent) {
                if (
                    parent.parent._time !==
                    parent._start +
                        (parent._ts >= 0
                            ? parent._tTime / parent._ts
                            : (parent.totalDuration() - parent._tTime) /
                              -parent._ts)
                ) {
                    parent.totalTime(parent._tTime, true);
                }
                parent = parent.parent;
            }
            if (
                !this.parent &&
                this._dp.autoRemoveChildren &&
                ((this._ts > 0 && _totalTime < this._tDur) ||
                    (this._ts < 0 && _totalTime > 0) ||
                    (!this._tDur && !_totalTime))
            ) {
                _addToTimeline(this._dp, this, this._start - this._delay);
            }
        }
        if (
            this._tTime !== _totalTime ||
            (!this._dur && !suppressEvents) ||
            (this._initted && Math.abs(this._zTime) === _tinyNum) ||
            (!_totalTime && !this._initted && (this.add || this._ptLookup))
        ) {
            this._ts || (this._pTime = _totalTime);
            _lazySafeRender(this, _totalTime, suppressEvents);
        }
        return this;
    };
    _proto.time = function time(value, suppressEvents) {
        return arguments.length
            ? this.totalTime(
                  Math.min(
                      this.totalDuration(),
                      value + _elapsedCycleDuration(this)
                  ) %
                      (this._dur + this._rDelay) || (value ? this._dur : 0),
                  suppressEvents
              )
            : this._time;
    };
    _proto.totalProgress = function totalProgress(value, suppressEvents) {
        return arguments.length
            ? this.totalTime(this.totalDuration() * value, suppressEvents)
            : this.totalDuration()
            ? Math.min(1, this._tTime / this._tDur)
            : this.rawTime() > 0
            ? 1
            : 0;
    };
    _proto.progress = function progress(value, suppressEvents) {
        return arguments.length
            ? this.totalTime(
                  this.duration() *
                      (this._yoyo && !(this.iteration() & 1)
                          ? 1 - value
                          : value) +
                      _elapsedCycleDuration(this),
                  suppressEvents
              )
            : this.duration()
            ? Math.min(1, this._time / this._dur)
            : this.rawTime() > 0
            ? 1
            : 0;
    };
    _proto.iteration = function iteration(value, suppressEvents) {
        var cycleDuration = this.duration() + this._rDelay;
        return arguments.length
            ? this.totalTime(
                  this._time + (value - 1) * cycleDuration,
                  suppressEvents
              )
            : this._repeat
            ? _animationCycle(this._tTime, cycleDuration) + 1
            : 1;
    };
    _proto.timeScale = function timeScale(value, suppressEvents) {
        if (!arguments.length) {
            return this._rts === -_tinyNum ? 0 : this._rts;
        }
        if (this._rts === value) {
            return this;
        }
        var tTime =
            this.parent && this._ts
                ? _parentToChildTotalTime(this.parent._time, this)
                : this._tTime;
        this._rts = +value || 0;
        this._ts = this._ps || value === -_tinyNum ? 0 : this._rts;
        this.totalTime(
            _clamp(-Math.abs(this._delay), this._tDur, tTime),
            suppressEvents !== false
        );
        _setEnd(this);
        return _recacheAncestors(this);
    };
    _proto.paused = function paused(value) {
        if (!arguments.length) {
            return this._ps;
        }
        if (this._ps !== value) {
            this._ps = value;
            if (value) {
                this._pTime =
                    this._tTime || Math.max(-this._delay, this.rawTime());
                this._ts = this._act = 0;
            } else {
                _wake();
                this._ts = this._rts;
                this.totalTime(
                    this.parent && !this.parent.smoothChildTiming
                        ? this.rawTime()
                        : this._tTime || this._pTime,
                    this.progress() === 1 &&
                        Math.abs(this._zTime) !== _tinyNum &&
                        (this._tTime -= _tinyNum)
                );
            }
        }
        return this;
    };
    _proto.startTime = function startTime(value) {
        if (arguments.length) {
            this._start = value;
            var parent = this.parent || this._dp;
            parent &&
                (parent._sort || !this.parent) &&
                _addToTimeline(parent, this, value - this._delay);
            return this;
        }
        return this._start;
    };
    _proto.endTime = function endTime(includeRepeats) {
        return (
            this._start +
            (_isNotFalse(includeRepeats)
                ? this.totalDuration()
                : this.duration()) /
                Math.abs(this._ts || 1)
        );
    };
    _proto.rawTime = function rawTime(wrapRepeats) {
        var parent = this.parent || this._dp;
        return !parent
            ? this._tTime
            : wrapRepeats &&
              (!this._ts ||
                  (this._repeat && this._time && this.totalProgress() < 1))
            ? this._tTime % (this._dur + this._rDelay)
            : !this._ts
            ? this._tTime
            : _parentToChildTotalTime(parent.rawTime(wrapRepeats), this);
    };
    _proto.revert = function revert(config3) {
        if (config3 === void 0) {
            config3 = _revertConfig;
        }
        var prevIsReverting = _reverting;
        _reverting = config3;
        if (this._initted || this._startAt) {
            this.timeline && this.timeline.revert(config3);
            this.totalTime(-0.01, config3.suppressEvents);
        }
        this.data !== 'nested' && config3.kill !== false && this.kill();
        _reverting = prevIsReverting;
        return this;
    };
    _proto.globalTime = function globalTime(rawTime) {
        var animation = this,
            time = arguments.length ? rawTime : animation.rawTime();
        while (animation) {
            time = animation._start + time / (Math.abs(animation._ts) || 1);
            animation = animation._dp;
        }
        return !this.parent && this._sat ? this._sat.globalTime(rawTime) : time;
    };
    _proto.repeat = function repeat(value) {
        if (arguments.length) {
            this._repeat = value === Infinity ? -2 : value;
            return _onUpdateTotalDuration(this);
        }
        return this._repeat === -2 ? Infinity : this._repeat;
    };
    _proto.repeatDelay = function repeatDelay(value) {
        if (arguments.length) {
            var time = this._time;
            this._rDelay = value;
            _onUpdateTotalDuration(this);
            return time ? this.time(time) : this;
        }
        return this._rDelay;
    };
    _proto.yoyo = function yoyo(value) {
        if (arguments.length) {
            this._yoyo = value;
            return this;
        }
        return this._yoyo;
    };
    _proto.seek = function seek(position, suppressEvents) {
        return this.totalTime(
            _parsePosition(this, position),
            _isNotFalse(suppressEvents)
        );
    };
    _proto.restart = function restart(includeDelay, suppressEvents) {
        return this.play().totalTime(
            includeDelay ? -this._delay : 0,
            _isNotFalse(suppressEvents)
        );
    };
    _proto.play = function play(from, suppressEvents) {
        from != null && this.seek(from, suppressEvents);
        return this.reversed(false).paused(false);
    };
    _proto.reverse = function reverse(from, suppressEvents) {
        from != null && this.seek(from || this.totalDuration(), suppressEvents);
        return this.reversed(true).paused(false);
    };
    _proto.pause = function pause(atTime, suppressEvents) {
        atTime != null && this.seek(atTime, suppressEvents);
        return this.paused(true);
    };
    _proto.resume = function resume() {
        return this.paused(false);
    };
    _proto.reversed = function reversed(value) {
        if (arguments.length) {
            !!value !== this.reversed() &&
                this.timeScale(-this._rts || (value ? -_tinyNum : 0));
            return this;
        }
        return this._rts < 0;
    };
    _proto.invalidate = function invalidate() {
        this._initted = this._act = 0;
        this._zTime = -_tinyNum;
        return this;
    };
    _proto.isActive = function isActive() {
        var parent = this.parent || this._dp,
            start = this._start,
            rawTime;
        return !!(
            !parent ||
            (this._ts &&
                this._initted &&
                parent.isActive() &&
                (rawTime = parent.rawTime(true)) >= start &&
                rawTime < this.endTime(true) - _tinyNum)
        );
    };
    _proto.eventCallback = function eventCallback(type, callback, params) {
        var vars = this.vars;
        if (arguments.length > 1) {
            if (!callback) {
                delete vars[type];
            } else {
                vars[type] = callback;
                params && (vars[type + 'Params'] = params);
                type === 'onUpdate' && (this._onUpdate = callback);
            }
            return this;
        }
        return vars[type];
    };
    _proto.then = function then(onFulfilled) {
        var self2 = this;
        return new Promise(function (resolve) {
            var f = _isFunction(onFulfilled) ? onFulfilled : _passThrough,
                _resolve = function _resolve2() {
                    var _then = self2.then;
                    self2.then = null;
                    _isFunction(f) &&
                        (f = f(self2)) &&
                        (f.then || f === self2) &&
                        (self2.then = _then);
                    resolve(f);
                    self2.then = _then;
                };
            if (
                (self2._initted &&
                    self2.totalProgress() === 1 &&
                    self2._ts >= 0) ||
                (!self2._tTime && self2._ts < 0)
            ) {
                _resolve();
            } else {
                self2._prom = _resolve;
            }
        });
    };
    _proto.kill = function kill() {
        _interrupt(this);
    };
    return Animation2;
})();
_setDefaults(Animation.prototype, {
    _time: 0,
    _start: 0,
    _end: 0,
    _tTime: 0,
    _tDur: 0,
    _dirty: 0,
    _repeat: 0,
    _yoyo: false,
    parent: null,
    _initted: false,
    _rDelay: 0,
    _ts: 1,
    _dp: 0,
    ratio: 0,
    _zTime: -_tinyNum,
    _prom: 0,
    _ps: false,
    _rts: 1,
});
var Timeline = /* @__PURE__ */ (function (_Animation) {
    _inheritsLoose(Timeline2, _Animation);
    function Timeline2(vars, position) {
        var _this;
        if (vars === void 0) {
            vars = {};
        }
        _this = _Animation.call(this, vars) || this;
        _this.labels = {};
        _this.smoothChildTiming = !!vars.smoothChildTiming;
        _this.autoRemoveChildren = !!vars.autoRemoveChildren;
        _this._sort = _isNotFalse(vars.sortChildren);
        _globalTimeline &&
            _addToTimeline(
                vars.parent || _globalTimeline,
                _assertThisInitialized(_this),
                position
            );
        vars.reversed && _this.reverse();
        vars.paused && _this.paused(true);
        vars.scrollTrigger &&
            _scrollTrigger(_assertThisInitialized(_this), vars.scrollTrigger);
        return _this;
    }
    var _proto2 = Timeline2.prototype;
    _proto2.to = function to(targets, vars, position) {
        _createTweenType(0, arguments, this);
        return this;
    };
    _proto2.from = function from(targets, vars, position) {
        _createTweenType(1, arguments, this);
        return this;
    };
    _proto2.fromTo = function fromTo(targets, fromVars, toVars, position) {
        _createTweenType(2, arguments, this);
        return this;
    };
    _proto2.set = function set2(targets, vars, position) {
        vars.duration = 0;
        vars.parent = this;
        _inheritDefaults(vars).repeatDelay || (vars.repeat = 0);
        vars.immediateRender = !!vars.immediateRender;
        new Tween(targets, vars, _parsePosition(this, position), 1);
        return this;
    };
    _proto2.call = function call(callback, params, position) {
        return _addToTimeline(
            this,
            Tween.delayedCall(0, callback, params),
            position
        );
    };
    _proto2.staggerTo = function staggerTo(
        targets,
        duration,
        vars,
        stagger,
        position,
        onCompleteAll,
        onCompleteAllParams
    ) {
        vars.duration = duration;
        vars.stagger = vars.stagger || stagger;
        vars.onComplete = onCompleteAll;
        vars.onCompleteParams = onCompleteAllParams;
        vars.parent = this;
        new Tween(targets, vars, _parsePosition(this, position));
        return this;
    };
    _proto2.staggerFrom = function staggerFrom(
        targets,
        duration,
        vars,
        stagger,
        position,
        onCompleteAll,
        onCompleteAllParams
    ) {
        vars.runBackwards = 1;
        _inheritDefaults(vars).immediateRender = _isNotFalse(
            vars.immediateRender
        );
        return this.staggerTo(
            targets,
            duration,
            vars,
            stagger,
            position,
            onCompleteAll,
            onCompleteAllParams
        );
    };
    _proto2.staggerFromTo = function staggerFromTo(
        targets,
        duration,
        fromVars,
        toVars,
        stagger,
        position,
        onCompleteAll,
        onCompleteAllParams
    ) {
        toVars.startAt = fromVars;
        _inheritDefaults(toVars).immediateRender = _isNotFalse(
            toVars.immediateRender
        );
        return this.staggerTo(
            targets,
            duration,
            toVars,
            stagger,
            position,
            onCompleteAll,
            onCompleteAllParams
        );
    };
    _proto2.render = function render3(totalTime, suppressEvents, force) {
        var prevTime = this._time,
            tDur = this._dirty ? this.totalDuration() : this._tDur,
            dur = this._dur,
            tTime = totalTime <= 0 ? 0 : _roundPrecise(totalTime),
            crossingStart =
                this._zTime < 0 !== totalTime < 0 && (this._initted || !dur),
            time,
            child,
            next,
            iteration,
            cycleDuration,
            prevPaused,
            pauseTween,
            timeScale,
            prevStart,
            prevIteration,
            yoyo,
            isYoyo;
        this !== _globalTimeline &&
            tTime > tDur &&
            totalTime >= 0 &&
            (tTime = tDur);
        if (tTime !== this._tTime || force || crossingStart) {
            if (prevTime !== this._time && dur) {
                tTime += this._time - prevTime;
                totalTime += this._time - prevTime;
            }
            time = tTime;
            prevStart = this._start;
            timeScale = this._ts;
            prevPaused = !timeScale;
            if (crossingStart) {
                dur || (prevTime = this._zTime);
                (totalTime || !suppressEvents) && (this._zTime = totalTime);
            }
            if (this._repeat) {
                yoyo = this._yoyo;
                cycleDuration = dur + this._rDelay;
                if (this._repeat < -1 && totalTime < 0) {
                    return this.totalTime(
                        cycleDuration * 100 + totalTime,
                        suppressEvents,
                        force
                    );
                }
                time = _roundPrecise(tTime % cycleDuration);
                if (tTime === tDur) {
                    iteration = this._repeat;
                    time = dur;
                } else {
                    iteration = ~~(tTime / cycleDuration);
                    if (iteration && iteration === tTime / cycleDuration) {
                        time = dur;
                        iteration--;
                    }
                    time > dur && (time = dur);
                }
                prevIteration = _animationCycle(this._tTime, cycleDuration);
                !prevTime &&
                    this._tTime &&
                    prevIteration !== iteration &&
                    this._tTime - prevIteration * cycleDuration - this._dur <=
                        0 &&
                    (prevIteration = iteration);
                if (yoyo && iteration & 1) {
                    time = dur - time;
                    isYoyo = 1;
                }
                if (iteration !== prevIteration && !this._lock) {
                    var rewinding = yoyo && prevIteration & 1,
                        doesWrap = rewinding === (yoyo && iteration & 1);
                    iteration < prevIteration && (rewinding = !rewinding);
                    prevTime = rewinding ? 0 : tTime % dur ? dur : tTime;
                    this._lock = 1;
                    this.render(
                        prevTime ||
                            (isYoyo
                                ? 0
                                : _roundPrecise(iteration * cycleDuration)),
                        suppressEvents,
                        !dur
                    )._lock = 0;
                    this._tTime = tTime;
                    !suppressEvents &&
                        this.parent &&
                        _callback(this, 'onRepeat');
                    this.vars.repeatRefresh &&
                        !isYoyo &&
                        (this.invalidate()._lock = 1);
                    if (
                        (prevTime && prevTime !== this._time) ||
                        prevPaused !== !this._ts ||
                        (this.vars.onRepeat && !this.parent && !this._act)
                    ) {
                        return this;
                    }
                    dur = this._dur;
                    tDur = this._tDur;
                    if (doesWrap) {
                        this._lock = 2;
                        prevTime = rewinding ? dur : -1e-4;
                        this.render(prevTime, true);
                        this.vars.repeatRefresh && !isYoyo && this.invalidate();
                    }
                    this._lock = 0;
                    if (!this._ts && !prevPaused) {
                        return this;
                    }
                    _propagateYoyoEase(this, isYoyo);
                }
            }
            if (this._hasPause && !this._forcing && this._lock < 2) {
                pauseTween = _findNextPauseTween(
                    this,
                    _roundPrecise(prevTime),
                    _roundPrecise(time)
                );
                if (pauseTween) {
                    tTime -= time - (time = pauseTween._start);
                }
            }
            this._tTime = tTime;
            this._time = time;
            this._act = !timeScale;
            if (!this._initted) {
                this._onUpdate = this.vars.onUpdate;
                this._initted = 1;
                this._zTime = totalTime;
                prevTime = 0;
            }
            if (!prevTime && time && !suppressEvents && !iteration) {
                _callback(this, 'onStart');
                if (this._tTime !== tTime) {
                    return this;
                }
            }
            if (time >= prevTime && totalTime >= 0) {
                child = this._first;
                while (child) {
                    next = child._next;
                    if (
                        (child._act || time >= child._start) &&
                        child._ts &&
                        pauseTween !== child
                    ) {
                        if (child.parent !== this) {
                            return this.render(
                                totalTime,
                                suppressEvents,
                                force
                            );
                        }
                        child.render(
                            child._ts > 0
                                ? (time - child._start) * child._ts
                                : (child._dirty
                                      ? child.totalDuration()
                                      : child._tDur) +
                                      (time - child._start) * child._ts,
                            suppressEvents,
                            force
                        );
                        if (time !== this._time || (!this._ts && !prevPaused)) {
                            pauseTween = 0;
                            next && (tTime += this._zTime = -_tinyNum);
                            break;
                        }
                    }
                    child = next;
                }
            } else {
                child = this._last;
                var adjustedTime = totalTime < 0 ? totalTime : time;
                while (child) {
                    next = child._prev;
                    if (
                        (child._act || adjustedTime <= child._end) &&
                        child._ts &&
                        pauseTween !== child
                    ) {
                        if (child.parent !== this) {
                            return this.render(
                                totalTime,
                                suppressEvents,
                                force
                            );
                        }
                        child.render(
                            child._ts > 0
                                ? (adjustedTime - child._start) * child._ts
                                : (child._dirty
                                      ? child.totalDuration()
                                      : child._tDur) +
                                      (adjustedTime - child._start) * child._ts,
                            suppressEvents,
                            force ||
                                (_reverting &&
                                    (child._initted || child._startAt))
                        );
                        if (time !== this._time || (!this._ts && !prevPaused)) {
                            pauseTween = 0;
                            next &&
                                (tTime += this._zTime =
                                    adjustedTime ? -_tinyNum : _tinyNum);
                            break;
                        }
                    }
                    child = next;
                }
            }
            if (pauseTween && !suppressEvents) {
                this.pause();
                pauseTween.render(time >= prevTime ? 0 : -_tinyNum)._zTime =
                    time >= prevTime ? 1 : -1;
                if (this._ts) {
                    this._start = prevStart;
                    _setEnd(this);
                    return this.render(totalTime, suppressEvents, force);
                }
            }
            this._onUpdate &&
                !suppressEvents &&
                _callback(this, 'onUpdate', true);
            if (
                (tTime === tDur && this._tTime >= this.totalDuration()) ||
                (!tTime && prevTime)
            ) {
                if (
                    prevStart === this._start ||
                    Math.abs(timeScale) !== Math.abs(this._ts)
                ) {
                    if (!this._lock) {
                        (totalTime || !dur) &&
                            ((tTime === tDur && this._ts > 0) ||
                                (!tTime && this._ts < 0)) &&
                            _removeFromParent(this, 1);
                        if (
                            !suppressEvents &&
                            !(totalTime < 0 && !prevTime) &&
                            (tTime || prevTime || !tDur)
                        ) {
                            _callback(
                                this,
                                tTime === tDur && totalTime >= 0
                                    ? 'onComplete'
                                    : 'onReverseComplete',
                                true
                            );
                            this._prom &&
                                !(tTime < tDur && this.timeScale() > 0) &&
                                this._prom();
                        }
                    }
                }
            }
        }
        return this;
    };
    _proto2.add = function add(child, position) {
        var _this2 = this;
        _isNumber(position) ||
            (position = _parsePosition(this, position, child));
        if (!(child instanceof Animation)) {
            if (_isArray(child)) {
                child.forEach(function (obj) {
                    return _this2.add(obj, position);
                });
                return this;
            }
            if (_isString(child)) {
                return this.addLabel(child, position);
            }
            if (_isFunction(child)) {
                child = Tween.delayedCall(0, child);
            } else {
                return this;
            }
        }
        return this !== child ? _addToTimeline(this, child, position) : this;
    };
    _proto2.getChildren = function getChildren(
        nested,
        tweens,
        timelines,
        ignoreBeforeTime
    ) {
        if (nested === void 0) {
            nested = true;
        }
        if (tweens === void 0) {
            tweens = true;
        }
        if (timelines === void 0) {
            timelines = true;
        }
        if (ignoreBeforeTime === void 0) {
            ignoreBeforeTime = -_bigNum;
        }
        var a = [],
            child = this._first;
        while (child) {
            if (child._start >= ignoreBeforeTime) {
                if (child instanceof Tween) {
                    tweens && a.push(child);
                } else {
                    timelines && a.push(child);
                    nested &&
                        a.push.apply(
                            a,
                            child.getChildren(true, tweens, timelines)
                        );
                }
            }
            child = child._next;
        }
        return a;
    };
    _proto2.getById = function getById2(id) {
        var animations = this.getChildren(1, 1, 1),
            i = animations.length;
        while (i--) {
            if (animations[i].vars.id === id) {
                return animations[i];
            }
        }
    };
    _proto2.remove = function remove2(child) {
        if (_isString(child)) {
            return this.removeLabel(child);
        }
        if (_isFunction(child)) {
            return this.killTweensOf(child);
        }
        _removeLinkedListItem(this, child);
        if (child === this._recent) {
            this._recent = this._last;
        }
        return _uncache(this);
    };
    _proto2.totalTime = function totalTime(_totalTime2, suppressEvents) {
        if (!arguments.length) {
            return this._tTime;
        }
        this._forcing = 1;
        if (!this._dp && this._ts) {
            this._start = _roundPrecise(
                _ticker.time -
                    (this._ts > 0
                        ? _totalTime2 / this._ts
                        : (this.totalDuration() - _totalTime2) / -this._ts)
            );
        }
        _Animation.prototype.totalTime.call(this, _totalTime2, suppressEvents);
        this._forcing = 0;
        return this;
    };
    _proto2.addLabel = function addLabel(label, position) {
        this.labels[label] = _parsePosition(this, position);
        return this;
    };
    _proto2.removeLabel = function removeLabel(label) {
        delete this.labels[label];
        return this;
    };
    _proto2.addPause = function addPause(position, callback, params) {
        var t2 = Tween.delayedCall(0, callback || _emptyFunc, params);
        t2.data = 'isPause';
        this._hasPause = 1;
        return _addToTimeline(this, t2, _parsePosition(this, position));
    };
    _proto2.removePause = function removePause(position) {
        var child = this._first;
        position = _parsePosition(this, position);
        while (child) {
            if (child._start === position && child.data === 'isPause') {
                _removeFromParent(child);
            }
            child = child._next;
        }
    };
    _proto2.killTweensOf = function killTweensOf(targets, props, onlyActive) {
        var tweens = this.getTweensOf(targets, onlyActive),
            i = tweens.length;
        while (i--) {
            _overwritingTween !== tweens[i] && tweens[i].kill(targets, props);
        }
        return this;
    };
    _proto2.getTweensOf = function getTweensOf2(targets, onlyActive) {
        var a = [],
            parsedTargets = toArray(targets),
            child = this._first,
            isGlobalTime = _isNumber(onlyActive),
            children;
        while (child) {
            if (child instanceof Tween) {
                if (
                    _arrayContainsAny(child._targets, parsedTargets) &&
                    (isGlobalTime
                        ? (!_overwritingTween ||
                              (child._initted && child._ts)) &&
                          child.globalTime(0) <= onlyActive &&
                          child.globalTime(child.totalDuration()) > onlyActive
                        : !onlyActive || child.isActive())
                ) {
                    a.push(child);
                }
            } else if (
                (children = child.getTweensOf(parsedTargets, onlyActive)).length
            ) {
                a.push.apply(a, children);
            }
            child = child._next;
        }
        return a;
    };
    _proto2.tweenTo = function tweenTo(position, vars) {
        vars = vars || {};
        var tl = this,
            endTime = _parsePosition(tl, position),
            _vars = vars,
            startAt = _vars.startAt,
            _onStart = _vars.onStart,
            onStartParams = _vars.onStartParams,
            immediateRender = _vars.immediateRender,
            initted,
            tween = Tween.to(
                tl,
                _setDefaults(
                    {
                        ease: vars.ease || 'none',
                        lazy: false,
                        immediateRender: false,
                        time: endTime,
                        overwrite: 'auto',
                        duration:
                            vars.duration ||
                            Math.abs(
                                (endTime -
                                    (startAt && 'time' in startAt
                                        ? startAt.time
                                        : tl._time)) /
                                    tl.timeScale()
                            ) ||
                            _tinyNum,
                        onStart: function onStart() {
                            tl.pause();
                            if (!initted) {
                                var duration =
                                    vars.duration ||
                                    Math.abs(
                                        (endTime -
                                            (startAt && 'time' in startAt
                                                ? startAt.time
                                                : tl._time)) /
                                            tl.timeScale()
                                    );
                                tween._dur !== duration &&
                                    _setDuration(tween, duration, 0, 1).render(
                                        tween._time,
                                        true,
                                        true
                                    );
                                initted = 1;
                            }
                            _onStart &&
                                _onStart.apply(tween, onStartParams || []);
                        },
                    },
                    vars
                )
            );
        return immediateRender ? tween.render(0) : tween;
    };
    _proto2.tweenFromTo = function tweenFromTo(fromPosition, toPosition, vars) {
        return this.tweenTo(
            toPosition,
            _setDefaults(
                {
                    startAt: {
                        time: _parsePosition(this, fromPosition),
                    },
                },
                vars
            )
        );
    };
    _proto2.recent = function recent() {
        return this._recent;
    };
    _proto2.nextLabel = function nextLabel(afterTime) {
        if (afterTime === void 0) {
            afterTime = this._time;
        }
        return _getLabelInDirection(this, _parsePosition(this, afterTime));
    };
    _proto2.previousLabel = function previousLabel(beforeTime) {
        if (beforeTime === void 0) {
            beforeTime = this._time;
        }
        return _getLabelInDirection(this, _parsePosition(this, beforeTime), 1);
    };
    _proto2.currentLabel = function currentLabel(value) {
        return arguments.length
            ? this.seek(value, true)
            : this.previousLabel(this._time + _tinyNum);
    };
    _proto2.shiftChildren = function shiftChildren(
        amount,
        adjustLabels,
        ignoreBeforeTime
    ) {
        if (ignoreBeforeTime === void 0) {
            ignoreBeforeTime = 0;
        }
        var child = this._first,
            labels = this.labels,
            p;
        while (child) {
            if (child._start >= ignoreBeforeTime) {
                child._start += amount;
                child._end += amount;
            }
            child = child._next;
        }
        if (adjustLabels) {
            for (p in labels) {
                if (labels[p] >= ignoreBeforeTime) {
                    labels[p] += amount;
                }
            }
        }
        return _uncache(this);
    };
    _proto2.invalidate = function invalidate(soft) {
        var child = this._first;
        this._lock = 0;
        while (child) {
            child.invalidate(soft);
            child = child._next;
        }
        return _Animation.prototype.invalidate.call(this, soft);
    };
    _proto2.clear = function clear2(includeLabels) {
        if (includeLabels === void 0) {
            includeLabels = true;
        }
        var child = this._first,
            next;
        while (child) {
            next = child._next;
            this.remove(child);
            child = next;
        }
        this._dp && (this._time = this._tTime = this._pTime = 0);
        includeLabels && (this.labels = {});
        return _uncache(this);
    };
    _proto2.totalDuration = function totalDuration(value) {
        var max = 0,
            self2 = this,
            child = self2._last,
            prevStart = _bigNum,
            prev,
            start,
            parent;
        if (arguments.length) {
            return self2.timeScale(
                (self2._repeat < 0 ? self2.duration() : self2.totalDuration()) /
                    (self2.reversed() ? -value : value)
            );
        }
        if (self2._dirty) {
            parent = self2.parent;
            while (child) {
                prev = child._prev;
                child._dirty && child.totalDuration();
                start = child._start;
                if (
                    start > prevStart &&
                    self2._sort &&
                    child._ts &&
                    !self2._lock
                ) {
                    self2._lock = 1;
                    _addToTimeline(
                        self2,
                        child,
                        start - child._delay,
                        1
                    )._lock = 0;
                } else {
                    prevStart = start;
                }
                if (start < 0 && child._ts) {
                    max -= start;
                    if (
                        (!parent && !self2._dp) ||
                        (parent && parent.smoothChildTiming)
                    ) {
                        self2._start += start / self2._ts;
                        self2._time -= start;
                        self2._tTime -= start;
                    }
                    self2.shiftChildren(-start, false, -Infinity);
                    prevStart = 0;
                }
                child._end > max && child._ts && (max = child._end);
                child = prev;
            }
            _setDuration(
                self2,
                self2 === _globalTimeline && self2._time > max
                    ? self2._time
                    : max,
                1,
                1
            );
            self2._dirty = 0;
        }
        return self2._tDur;
    };
    Timeline2.updateRoot = function updateRoot(time) {
        if (_globalTimeline._ts) {
            _lazySafeRender(
                _globalTimeline,
                _parentToChildTotalTime(time, _globalTimeline)
            );
            _lastRenderedFrame = _ticker.frame;
        }
        if (_ticker.frame >= _nextGCFrame) {
            _nextGCFrame += _config.autoSleep || 120;
            var child = _globalTimeline._first;
            if (!child || !child._ts) {
                if (_config.autoSleep && _ticker._listeners.length < 2) {
                    while (child && !child._ts) {
                        child = child._next;
                    }
                    child || _ticker.sleep();
                }
            }
        }
    };
    return Timeline2;
})(Animation);
_setDefaults(Timeline.prototype, {
    _lock: 0,
    _hasPause: 0,
    _forcing: 0,
});
var _addComplexStringPropTween = function _addComplexStringPropTween2(
    target,
    prop,
    start,
    end,
    setter,
    stringFilter,
    funcParam
) {
    var pt = new PropTween(
            this._pt,
            target,
            prop,
            0,
            1,
            _renderComplexString,
            null,
            setter
        ),
        index = 0,
        matchIndex = 0,
        result,
        startNums,
        color,
        endNum,
        chunk,
        startNum,
        hasRandom,
        a;
    pt.b = start;
    pt.e = end;
    start += '';
    end += '';
    if ((hasRandom = ~end.indexOf('random('))) {
        end = _replaceRandom(end);
    }
    if (stringFilter) {
        a = [start, end];
        stringFilter(a, target, prop);
        start = a[0];
        end = a[1];
    }
    startNums = start.match(_complexStringNumExp) || [];
    while ((result = _complexStringNumExp.exec(end))) {
        endNum = result[0];
        chunk = end.substring(index, result.index);
        if (color) {
            color = (color + 1) % 5;
        } else if (chunk.substr(-5) === 'rgba(') {
            color = 1;
        }
        if (endNum !== startNums[matchIndex++]) {
            startNum = parseFloat(startNums[matchIndex - 1]) || 0;
            pt._pt = {
                _next: pt._pt,
                p: chunk || matchIndex === 1 ? chunk : ',',
                //note: SVG spec allows omission of comma/space when a negative sign is wedged between two numbers, like 2.5-5.3 instead of 2.5,-5.3 but when tweening, the negative value may switch to positive, so we insert the comma just in case.
                s: startNum,
                c:
                    endNum.charAt(1) === '='
                        ? _parseRelative(startNum, endNum) - startNum
                        : parseFloat(endNum) - startNum,
                m: color && color < 4 ? Math.round : 0,
            };
            index = _complexStringNumExp.lastIndex;
        }
    }
    pt.c = index < end.length ? end.substring(index, end.length) : '';
    pt.fp = funcParam;
    if (_relExp.test(end) || hasRandom) {
        pt.e = 0;
    }
    this._pt = pt;
    return pt;
};
var _addPropTween = function _addPropTween2(
    target,
    prop,
    start,
    end,
    index,
    targets,
    modifier,
    stringFilter,
    funcParam,
    optional
) {
    _isFunction(end) && (end = end(index || 0, target, targets));
    var currentValue = target[prop],
        parsedStart =
            start !== 'get'
                ? start
                : !_isFunction(currentValue)
                ? currentValue
                : funcParam
                ? target[
                      prop.indexOf('set') ||
                      !_isFunction(target['get' + prop.substr(3)])
                          ? prop
                          : 'get' + prop.substr(3)
                  ](funcParam)
                : target[prop](),
        setter = !_isFunction(currentValue)
            ? _setterPlain
            : funcParam
            ? _setterFuncWithParam
            : _setterFunc,
        pt;
    if (_isString(end)) {
        if (~end.indexOf('random(')) {
            end = _replaceRandom(end);
        }
        if (end.charAt(1) === '=') {
            pt = _parseRelative(parsedStart, end) + (getUnit(parsedStart) || 0);
            if (pt || pt === 0) {
                end = pt;
            }
        }
    }
    if (!optional || parsedStart !== end || _forceAllPropTweens) {
        if (!isNaN(parsedStart * end) && end !== '') {
            pt = new PropTween(
                this._pt,
                target,
                prop,
                +parsedStart || 0,
                end - (parsedStart || 0),
                typeof currentValue === 'boolean'
                    ? _renderBoolean
                    : _renderPlain,
                0,
                setter
            );
            funcParam && (pt.fp = funcParam);
            modifier && pt.modifier(modifier, this, target);
            return (this._pt = pt);
        }
        !currentValue && !(prop in target) && _missingPlugin(prop, end);
        return _addComplexStringPropTween.call(
            this,
            target,
            prop,
            parsedStart,
            end,
            setter,
            stringFilter || _config.stringFilter,
            funcParam
        );
    }
};
var _processVars = function _processVars2(vars, index, target, targets, tween) {
    _isFunction(vars) &&
        (vars = _parseFuncOrString(vars, tween, index, target, targets));
    if (
        !_isObject(vars) ||
        (vars.style && vars.nodeType) ||
        _isArray(vars) ||
        _isTypedArray(vars)
    ) {
        return _isString(vars)
            ? _parseFuncOrString(vars, tween, index, target, targets)
            : vars;
    }
    var copy = {},
        p;
    for (p in vars) {
        copy[p] = _parseFuncOrString(vars[p], tween, index, target, targets);
    }
    return copy;
};
var _checkPlugin = function _checkPlugin2(
    property,
    vars,
    tween,
    index,
    target,
    targets
) {
    var plugin, pt, ptLookup, i;
    if (
        _plugins[property] &&
        (plugin = new _plugins[property]()).init(
            target,
            plugin.rawVars
                ? vars[property]
                : _processVars(vars[property], index, target, targets, tween),
            tween,
            index,
            targets
        ) !== false
    ) {
        tween._pt = pt = new PropTween(
            tween._pt,
            target,
            property,
            0,
            1,
            plugin.render,
            plugin,
            0,
            plugin.priority
        );
        if (tween !== _quickTween) {
            ptLookup = tween._ptLookup[tween._targets.indexOf(target)];
            i = plugin._props.length;
            while (i--) {
                ptLookup[plugin._props[i]] = pt;
            }
        }
    }
    return plugin;
};
var _overwritingTween;
var _forceAllPropTweens;
var _initTween = function _initTween2(tween, time, tTime) {
    var vars = tween.vars,
        ease = vars.ease,
        startAt = vars.startAt,
        immediateRender = vars.immediateRender,
        lazy = vars.lazy,
        onUpdate = vars.onUpdate,
        runBackwards = vars.runBackwards,
        yoyoEase = vars.yoyoEase,
        keyframes = vars.keyframes,
        autoRevert = vars.autoRevert,
        dur = tween._dur,
        prevStartAt = tween._startAt,
        targets = tween._targets,
        parent = tween.parent,
        fullTargets =
            parent && parent.data === 'nested' ? parent.vars.targets : targets,
        autoOverwrite = tween._overwrite === 'auto' && !_suppressOverwrites,
        tl = tween.timeline,
        cleanVars,
        i,
        p,
        pt,
        target,
        hasPriority,
        gsData,
        harness,
        plugin,
        ptLookup,
        index,
        harnessVars,
        overwritten;
    tl && (!keyframes || !ease) && (ease = 'none');
    tween._ease = _parseEase(ease, _defaults.ease);
    tween._yEase = yoyoEase
        ? _invertEase(
              _parseEase(yoyoEase === true ? ease : yoyoEase, _defaults.ease)
          )
        : 0;
    if (yoyoEase && tween._yoyo && !tween._repeat) {
        yoyoEase = tween._yEase;
        tween._yEase = tween._ease;
        tween._ease = yoyoEase;
    }
    tween._from = !tl && !!vars.runBackwards;
    if (!tl || (keyframes && !vars.stagger)) {
        harness = targets[0] ? _getCache(targets[0]).harness : 0;
        harnessVars = harness && vars[harness.prop];
        cleanVars = _copyExcluding(vars, _reservedProps);
        if (prevStartAt) {
            prevStartAt._zTime < 0 && prevStartAt.progress(1);
            time < 0 && runBackwards && immediateRender && !autoRevert
                ? prevStartAt.render(-1, true)
                : prevStartAt.revert(
                      runBackwards && dur
                          ? _revertConfigNoKill
                          : _startAtRevertConfig
                  );
            prevStartAt._lazy = 0;
        }
        if (startAt) {
            _removeFromParent(
                (tween._startAt = Tween.set(
                    targets,
                    _setDefaults(
                        {
                            data: 'isStart',
                            overwrite: false,
                            parent,
                            immediateRender: true,
                            lazy: !prevStartAt && _isNotFalse(lazy),
                            startAt: null,
                            delay: 0,
                            onUpdate:
                                onUpdate &&
                                function () {
                                    return _callback(tween, 'onUpdate');
                                },
                            stagger: 0,
                        },
                        startAt
                    )
                ))
            );
            tween._startAt._dp = 0;
            tween._startAt._sat = tween;
            time < 0 &&
                (_reverting || (!immediateRender && !autoRevert)) &&
                tween._startAt.revert(_revertConfigNoKill);
            if (immediateRender) {
                if (dur && time <= 0 && tTime <= 0) {
                    time && (tween._zTime = time);
                    return;
                }
            }
        } else if (runBackwards && dur) {
            if (!prevStartAt) {
                time && (immediateRender = false);
                p = _setDefaults(
                    {
                        overwrite: false,
                        data: 'isFromStart',
                        //we tag the tween with as "isFromStart" so that if [inside a plugin] we need to only do something at the very END of a tween, we have a way of identifying this tween as merely the one that's setting the beginning values for a "from()" tween. For example, clearProps in CSSPlugin should only get applied at the very END of a tween and without this tag, from(...{height:100, clearProps:"height", delay:1}) would wipe the height at the beginning of the tween and after 1 second, it'd kick back in.
                        lazy:
                            immediateRender &&
                            !prevStartAt &&
                            _isNotFalse(lazy),
                        immediateRender,
                        //zero-duration tweens render immediately by default, but if we're not specifically instructed to render this tween immediately, we should skip this and merely _init() to record the starting values (rendering them immediately would push them to completion which is wasteful in that case - we'd have to render(-1) immediately after)
                        stagger: 0,
                        parent,
                        //ensures that nested tweens that had a stagger are handled properly, like gsap.from(".class", {y: gsap.utils.wrap([-100,100]), stagger: 0.5})
                    },
                    cleanVars
                );
                harnessVars && (p[harness.prop] = harnessVars);
                _removeFromParent((tween._startAt = Tween.set(targets, p)));
                tween._startAt._dp = 0;
                tween._startAt._sat = tween;
                time < 0 &&
                    (_reverting
                        ? tween._startAt.revert(_revertConfigNoKill)
                        : tween._startAt.render(-1, true));
                tween._zTime = time;
                if (!immediateRender) {
                    _initTween2(tween._startAt, _tinyNum, _tinyNum);
                } else if (!time) {
                    return;
                }
            }
        }
        tween._pt = tween._ptCache = 0;
        lazy = (dur && _isNotFalse(lazy)) || (lazy && !dur);
        for (i = 0; i < targets.length; i++) {
            target = targets[i];
            gsData = target._gsap || _harness(targets)[i]._gsap;
            tween._ptLookup[i] = ptLookup = {};
            _lazyLookup[gsData.id] && _lazyTweens.length && _lazyRender();
            index = fullTargets === targets ? i : fullTargets.indexOf(target);
            if (
                harness &&
                (plugin = new harness()).init(
                    target,
                    harnessVars || cleanVars,
                    tween,
                    index,
                    fullTargets
                ) !== false
            ) {
                tween._pt = pt = new PropTween(
                    tween._pt,
                    target,
                    plugin.name,
                    0,
                    1,
                    plugin.render,
                    plugin,
                    0,
                    plugin.priority
                );
                plugin._props.forEach(function (name) {
                    ptLookup[name] = pt;
                });
                plugin.priority && (hasPriority = 1);
            }
            if (!harness || harnessVars) {
                for (p in cleanVars) {
                    if (
                        _plugins[p] &&
                        (plugin = _checkPlugin(
                            p,
                            cleanVars,
                            tween,
                            index,
                            target,
                            fullTargets
                        ))
                    ) {
                        plugin.priority && (hasPriority = 1);
                    } else {
                        ptLookup[p] = pt = _addPropTween.call(
                            tween,
                            target,
                            p,
                            'get',
                            cleanVars[p],
                            index,
                            fullTargets,
                            0,
                            vars.stringFilter
                        );
                    }
                }
            }
            tween._op && tween._op[i] && tween.kill(target, tween._op[i]);
            if (autoOverwrite && tween._pt) {
                _overwritingTween = tween;
                _globalTimeline.killTweensOf(
                    target,
                    ptLookup,
                    tween.globalTime(time)
                );
                overwritten = !tween.parent;
                _overwritingTween = 0;
            }
            tween._pt && lazy && (_lazyLookup[gsData.id] = 1);
        }
        hasPriority && _sortPropTweensByPriority(tween);
        tween._onInit && tween._onInit(tween);
    }
    tween._onUpdate = onUpdate;
    tween._initted = (!tween._op || tween._pt) && !overwritten;
    keyframes && time <= 0 && tl.render(_bigNum, true, true);
};
var _updatePropTweens = function _updatePropTweens2(
    tween,
    property,
    value,
    start,
    startIsRelative,
    ratio,
    time,
    skipRecursion
) {
    var ptCache = ((tween._pt && tween._ptCache) || (tween._ptCache = {}))[
            property
        ],
        pt,
        rootPT,
        lookup,
        i;
    if (!ptCache) {
        ptCache = tween._ptCache[property] = [];
        lookup = tween._ptLookup;
        i = tween._targets.length;
        while (i--) {
            pt = lookup[i][property];
            if (pt && pt.d && pt.d._pt) {
                pt = pt.d._pt;
                while (pt && pt.p !== property && pt.fp !== property) {
                    pt = pt._next;
                }
            }
            if (!pt) {
                _forceAllPropTweens = 1;
                tween.vars[property] = '+=0';
                _initTween(tween, time);
                _forceAllPropTweens = 0;
                return skipRecursion
                    ? _warn(property + ' not eligible for reset')
                    : 1;
            }
            ptCache.push(pt);
        }
    }
    i = ptCache.length;
    while (i--) {
        rootPT = ptCache[i];
        pt = rootPT._pt || rootPT;
        pt.s =
            (start || start === 0) && !startIsRelative
                ? start
                : pt.s + (start || 0) + ratio * pt.c;
        pt.c = value - pt.s;
        rootPT.e && (rootPT.e = _round(value) + getUnit(rootPT.e));
        rootPT.b && (rootPT.b = pt.s + getUnit(rootPT.b));
    }
};
var _addAliasesToVars = function _addAliasesToVars2(targets, vars) {
    var harness = targets[0] ? _getCache(targets[0]).harness : 0,
        propertyAliases = harness && harness.aliases,
        copy,
        p,
        i,
        aliases;
    if (!propertyAliases) {
        return vars;
    }
    copy = _merge({}, vars);
    for (p in propertyAliases) {
        if (p in copy) {
            aliases = propertyAliases[p].split(',');
            i = aliases.length;
            while (i--) {
                copy[aliases[i]] = copy[p];
            }
        }
    }
    return copy;
};
var _parseKeyframe = function _parseKeyframe2(prop, obj, allProps, easeEach) {
    var ease = obj.ease || easeEach || 'power1.inOut',
        p,
        a;
    if (_isArray(obj)) {
        a = allProps[prop] || (allProps[prop] = []);
        obj.forEach(function (value, i) {
            return a.push({
                t: (i / (obj.length - 1)) * 100,
                v: value,
                e: ease,
            });
        });
    } else {
        for (p in obj) {
            a = allProps[p] || (allProps[p] = []);
            p === 'ease' ||
                a.push({
                    t: parseFloat(prop),
                    v: obj[p],
                    e: ease,
                });
        }
    }
};
var _parseFuncOrString = function _parseFuncOrString2(
    value,
    tween,
    i,
    target,
    targets
) {
    return _isFunction(value)
        ? value.call(tween, i, target, targets)
        : _isString(value) && ~value.indexOf('random(')
        ? _replaceRandom(value)
        : value;
};
var _staggerTweenProps =
    _callbackNames +
    'repeat,repeatDelay,yoyo,repeatRefresh,yoyoEase,autoRevert';
var _staggerPropsToSkip = {};
_forEachName(
    _staggerTweenProps + ',id,stagger,delay,duration,paused,scrollTrigger',
    function (name) {
        return (_staggerPropsToSkip[name] = 1);
    }
);
var Tween = /* @__PURE__ */ (function (_Animation2) {
    _inheritsLoose(Tween2, _Animation2);
    function Tween2(targets, vars, position, skipInherit) {
        var _this3;
        if (typeof vars === 'number') {
            position.duration = vars;
            vars = position;
            position = null;
        }
        _this3 =
            _Animation2.call(
                this,
                skipInherit ? vars : _inheritDefaults(vars)
            ) || this;
        var _this3$vars = _this3.vars,
            duration = _this3$vars.duration,
            delay = _this3$vars.delay,
            immediateRender = _this3$vars.immediateRender,
            stagger = _this3$vars.stagger,
            overwrite = _this3$vars.overwrite,
            keyframes = _this3$vars.keyframes,
            defaults4 = _this3$vars.defaults,
            scrollTrigger = _this3$vars.scrollTrigger,
            yoyoEase = _this3$vars.yoyoEase,
            parent = vars.parent || _globalTimeline,
            parsedTargets = (
                _isArray(targets) || _isTypedArray(targets)
                    ? _isNumber(targets[0])
                    : 'length' in vars
            )
                ? [targets]
                : toArray(targets),
            tl,
            i,
            copy,
            l,
            p,
            curTarget,
            staggerFunc,
            staggerVarsToMerge;
        _this3._targets = parsedTargets.length
            ? _harness(parsedTargets)
            : _warn(
                  'GSAP target ' + targets + ' not found. https://gsap.com',
                  !_config.nullTargetWarn
              ) || [];
        _this3._ptLookup = [];
        _this3._overwrite = overwrite;
        if (
            keyframes ||
            stagger ||
            _isFuncOrString(duration) ||
            _isFuncOrString(delay)
        ) {
            vars = _this3.vars;
            tl = _this3.timeline = new Timeline({
                data: 'nested',
                defaults: defaults4 || {},
                targets:
                    parent && parent.data === 'nested'
                        ? parent.vars.targets
                        : parsedTargets,
            });
            tl.kill();
            tl.parent = tl._dp = _assertThisInitialized(_this3);
            tl._start = 0;
            if (
                stagger ||
                _isFuncOrString(duration) ||
                _isFuncOrString(delay)
            ) {
                l = parsedTargets.length;
                staggerFunc = stagger && distribute(stagger);
                if (_isObject(stagger)) {
                    for (p in stagger) {
                        if (~_staggerTweenProps.indexOf(p)) {
                            staggerVarsToMerge || (staggerVarsToMerge = {});
                            staggerVarsToMerge[p] = stagger[p];
                        }
                    }
                }
                for (i = 0; i < l; i++) {
                    copy = _copyExcluding(vars, _staggerPropsToSkip);
                    copy.stagger = 0;
                    yoyoEase && (copy.yoyoEase = yoyoEase);
                    staggerVarsToMerge && _merge(copy, staggerVarsToMerge);
                    curTarget = parsedTargets[i];
                    copy.duration = +_parseFuncOrString(
                        duration,
                        _assertThisInitialized(_this3),
                        i,
                        curTarget,
                        parsedTargets
                    );
                    copy.delay =
                        (+_parseFuncOrString(
                            delay,
                            _assertThisInitialized(_this3),
                            i,
                            curTarget,
                            parsedTargets
                        ) || 0) - _this3._delay;
                    if (!stagger && l === 1 && copy.delay) {
                        _this3._delay = delay = copy.delay;
                        _this3._start += delay;
                        copy.delay = 0;
                    }
                    tl.to(
                        curTarget,
                        copy,
                        staggerFunc
                            ? staggerFunc(i, curTarget, parsedTargets)
                            : 0
                    );
                    tl._ease = _easeMap.none;
                }
                tl.duration() ? (duration = delay = 0) : (_this3.timeline = 0);
            } else if (keyframes) {
                _inheritDefaults(
                    _setDefaults(tl.vars.defaults, {
                        ease: 'none',
                    })
                );
                tl._ease = _parseEase(keyframes.ease || vars.ease || 'none');
                var time = 0,
                    a,
                    kf,
                    v;
                if (_isArray(keyframes)) {
                    keyframes.forEach(function (frame) {
                        return tl.to(parsedTargets, frame, '>');
                    });
                    tl.duration();
                } else {
                    copy = {};
                    for (p in keyframes) {
                        p === 'ease' ||
                            p === 'easeEach' ||
                            _parseKeyframe(
                                p,
                                keyframes[p],
                                copy,
                                keyframes.easeEach
                            );
                    }
                    for (p in copy) {
                        a = copy[p].sort(function (a2, b) {
                            return a2.t - b.t;
                        });
                        time = 0;
                        for (i = 0; i < a.length; i++) {
                            kf = a[i];
                            v = {
                                ease: kf.e,
                                duration:
                                    ((kf.t - (i ? a[i - 1].t : 0)) / 100) *
                                    duration,
                            };
                            v[p] = kf.v;
                            tl.to(parsedTargets, v, time);
                            time += v.duration;
                        }
                    }
                    tl.duration() < duration &&
                        tl.to(
                            {},
                            {
                                duration: duration - tl.duration(),
                            }
                        );
                }
            }
            duration || _this3.duration((duration = tl.duration()));
        } else {
            _this3.timeline = 0;
        }
        if (overwrite === true && !_suppressOverwrites) {
            _overwritingTween = _assertThisInitialized(_this3);
            _globalTimeline.killTweensOf(parsedTargets);
            _overwritingTween = 0;
        }
        _addToTimeline(parent, _assertThisInitialized(_this3), position);
        vars.reversed && _this3.reverse();
        vars.paused && _this3.paused(true);
        if (
            immediateRender ||
            (!duration &&
                !keyframes &&
                _this3._start === _roundPrecise(parent._time) &&
                _isNotFalse(immediateRender) &&
                _hasNoPausedAncestors(_assertThisInitialized(_this3)) &&
                parent.data !== 'nested')
        ) {
            _this3._tTime = -_tinyNum;
            _this3.render(Math.max(0, -delay) || 0);
        }
        scrollTrigger &&
            _scrollTrigger(_assertThisInitialized(_this3), scrollTrigger);
        return _this3;
    }
    var _proto3 = Tween2.prototype;
    _proto3.render = function render3(totalTime, suppressEvents, force) {
        var prevTime = this._time,
            tDur = this._tDur,
            dur = this._dur,
            isNegative = totalTime < 0,
            tTime =
                totalTime > tDur - _tinyNum && !isNegative
                    ? tDur
                    : totalTime < _tinyNum
                    ? 0
                    : totalTime,
            time,
            pt,
            iteration,
            cycleDuration,
            prevIteration,
            isYoyo,
            ratio,
            timeline2,
            yoyoEase;
        if (!dur) {
            _renderZeroDurationTween(this, totalTime, suppressEvents, force);
        } else if (
            tTime !== this._tTime ||
            !totalTime ||
            force ||
            (!this._initted && this._tTime) ||
            (this._startAt && this._zTime < 0 !== isNegative)
        ) {
            time = tTime;
            timeline2 = this.timeline;
            if (this._repeat) {
                cycleDuration = dur + this._rDelay;
                if (this._repeat < -1 && isNegative) {
                    return this.totalTime(
                        cycleDuration * 100 + totalTime,
                        suppressEvents,
                        force
                    );
                }
                time = _roundPrecise(tTime % cycleDuration);
                if (tTime === tDur) {
                    iteration = this._repeat;
                    time = dur;
                } else {
                    iteration = ~~(tTime / cycleDuration);
                    if (
                        iteration &&
                        iteration === _roundPrecise(tTime / cycleDuration)
                    ) {
                        time = dur;
                        iteration--;
                    }
                    time > dur && (time = dur);
                }
                isYoyo = this._yoyo && iteration & 1;
                if (isYoyo) {
                    yoyoEase = this._yEase;
                    time = dur - time;
                }
                prevIteration = _animationCycle(this._tTime, cycleDuration);
                if (
                    time === prevTime &&
                    !force &&
                    this._initted &&
                    iteration === prevIteration
                ) {
                    this._tTime = tTime;
                    return this;
                }
                if (iteration !== prevIteration) {
                    timeline2 &&
                        this._yEase &&
                        _propagateYoyoEase(timeline2, isYoyo);
                    if (
                        this.vars.repeatRefresh &&
                        !isYoyo &&
                        !this._lock &&
                        this._time !== cycleDuration &&
                        this._initted
                    ) {
                        this._lock = force = 1;
                        this.render(
                            _roundPrecise(cycleDuration * iteration),
                            true
                        ).invalidate()._lock = 0;
                    }
                }
            }
            if (!this._initted) {
                if (
                    _attemptInitTween(
                        this,
                        isNegative ? totalTime : time,
                        force,
                        suppressEvents,
                        tTime
                    )
                ) {
                    this._tTime = 0;
                    return this;
                }
                if (
                    prevTime !== this._time &&
                    !(
                        force &&
                        this.vars.repeatRefresh &&
                        iteration !== prevIteration
                    )
                ) {
                    return this;
                }
                if (dur !== this._dur) {
                    return this.render(totalTime, suppressEvents, force);
                }
            }
            this._tTime = tTime;
            this._time = time;
            if (!this._act && this._ts) {
                this._act = 1;
                this._lazy = 0;
            }
            this.ratio = ratio = (yoyoEase || this._ease)(time / dur);
            if (this._from) {
                this.ratio = ratio = 1 - ratio;
            }
            if (time && !prevTime && !suppressEvents && !iteration) {
                _callback(this, 'onStart');
                if (this._tTime !== tTime) {
                    return this;
                }
            }
            pt = this._pt;
            while (pt) {
                pt.r(ratio, pt.d);
                pt = pt._next;
            }
            (timeline2 &&
                timeline2.render(
                    totalTime < 0
                        ? totalTime
                        : timeline2._dur * timeline2._ease(time / this._dur),
                    suppressEvents,
                    force
                )) ||
                (this._startAt && (this._zTime = totalTime));
            if (this._onUpdate && !suppressEvents) {
                isNegative &&
                    _rewindStartAt(this, totalTime, suppressEvents, force);
                _callback(this, 'onUpdate');
            }
            this._repeat &&
                iteration !== prevIteration &&
                this.vars.onRepeat &&
                !suppressEvents &&
                this.parent &&
                _callback(this, 'onRepeat');
            if ((tTime === this._tDur || !tTime) && this._tTime === tTime) {
                isNegative &&
                    !this._onUpdate &&
                    _rewindStartAt(this, totalTime, true, true);
                (totalTime || !dur) &&
                    ((tTime === this._tDur && this._ts > 0) ||
                        (!tTime && this._ts < 0)) &&
                    _removeFromParent(this, 1);
                if (
                    !suppressEvents &&
                    !(isNegative && !prevTime) &&
                    (tTime || prevTime || isYoyo)
                ) {
                    _callback(
                        this,
                        tTime === tDur ? 'onComplete' : 'onReverseComplete',
                        true
                    );
                    this._prom &&
                        !(tTime < tDur && this.timeScale() > 0) &&
                        this._prom();
                }
            }
        }
        return this;
    };
    _proto3.targets = function targets() {
        return this._targets;
    };
    _proto3.invalidate = function invalidate(soft) {
        (!soft || !this.vars.runBackwards) && (this._startAt = 0);
        this._pt = this._op = this._onUpdate = this._lazy = this.ratio = 0;
        this._ptLookup = [];
        this.timeline && this.timeline.invalidate(soft);
        return _Animation2.prototype.invalidate.call(this, soft);
    };
    _proto3.resetTo = function resetTo(
        property,
        value,
        start,
        startIsRelative,
        skipRecursion
    ) {
        _tickerActive || _ticker.wake();
        this._ts || this.play();
        var time = Math.min(
                this._dur,
                (this._dp._time - this._start) * this._ts
            ),
            ratio;
        this._initted || _initTween(this, time);
        ratio = this._ease(time / this._dur);
        if (
            _updatePropTweens(
                this,
                property,
                value,
                start,
                startIsRelative,
                ratio,
                time,
                skipRecursion
            )
        ) {
            return this.resetTo(property, value, start, startIsRelative, 1);
        }
        _alignPlayhead(this, 0);
        this.parent ||
            _addLinkedListItem(
                this._dp,
                this,
                '_first',
                '_last',
                this._dp._sort ? '_start' : 0
            );
        return this.render(0);
    };
    _proto3.kill = function kill(targets, vars) {
        if (vars === void 0) {
            vars = 'all';
        }
        if (!targets && (!vars || vars === 'all')) {
            this._lazy = this._pt = 0;
            return this.parent ? _interrupt(this) : this;
        }
        if (this.timeline) {
            var tDur = this.timeline.totalDuration();
            this.timeline.killTweensOf(
                targets,
                vars,
                _overwritingTween && _overwritingTween.vars.overwrite !== true
            )._first || _interrupt(this);
            this.parent &&
                tDur !== this.timeline.totalDuration() &&
                _setDuration(
                    this,
                    (this._dur * this.timeline._tDur) / tDur,
                    0,
                    1
                );
            return this;
        }
        var parsedTargets = this._targets,
            killingTargets = targets ? toArray(targets) : parsedTargets,
            propTweenLookup = this._ptLookup,
            firstPT = this._pt,
            overwrittenProps,
            curLookup,
            curOverwriteProps,
            props,
            p,
            pt,
            i;
        if (
            (!vars || vars === 'all') &&
            _arraysMatch(parsedTargets, killingTargets)
        ) {
            vars === 'all' && (this._pt = 0);
            return _interrupt(this);
        }
        overwrittenProps = this._op = this._op || [];
        if (vars !== 'all') {
            if (_isString(vars)) {
                p = {};
                _forEachName(vars, function (name) {
                    return (p[name] = 1);
                });
                vars = p;
            }
            vars = _addAliasesToVars(parsedTargets, vars);
        }
        i = parsedTargets.length;
        while (i--) {
            if (~killingTargets.indexOf(parsedTargets[i])) {
                curLookup = propTweenLookup[i];
                if (vars === 'all') {
                    overwrittenProps[i] = vars;
                    props = curLookup;
                    curOverwriteProps = {};
                } else {
                    curOverwriteProps = overwrittenProps[i] =
                        overwrittenProps[i] || {};
                    props = vars;
                }
                for (p in props) {
                    pt = curLookup && curLookup[p];
                    if (pt) {
                        if (!('kill' in pt.d) || pt.d.kill(p) === true) {
                            _removeLinkedListItem(this, pt, '_pt');
                        }
                        delete curLookup[p];
                    }
                    if (curOverwriteProps !== 'all') {
                        curOverwriteProps[p] = 1;
                    }
                }
            }
        }
        this._initted && !this._pt && firstPT && _interrupt(this);
        return this;
    };
    Tween2.to = function to(targets, vars) {
        return new Tween2(targets, vars, arguments[2]);
    };
    Tween2.from = function from(targets, vars) {
        return _createTweenType(1, arguments);
    };
    Tween2.delayedCall = function delayedCall(delay, callback, params, scope) {
        return new Tween2(callback, 0, {
            immediateRender: false,
            lazy: false,
            overwrite: false,
            delay,
            onComplete: callback,
            onReverseComplete: callback,
            onCompleteParams: params,
            onReverseCompleteParams: params,
            callbackScope: scope,
        });
    };
    Tween2.fromTo = function fromTo(targets, fromVars, toVars) {
        return _createTweenType(2, arguments);
    };
    Tween2.set = function set2(targets, vars) {
        vars.duration = 0;
        vars.repeatDelay || (vars.repeat = 0);
        return new Tween2(targets, vars);
    };
    Tween2.killTweensOf = function killTweensOf(targets, props, onlyActive) {
        return _globalTimeline.killTweensOf(targets, props, onlyActive);
    };
    return Tween2;
})(Animation);
_setDefaults(Tween.prototype, {
    _targets: [],
    _lazy: 0,
    _startAt: 0,
    _op: 0,
    _onInit: 0,
});
_forEachName('staggerTo,staggerFrom,staggerFromTo', function (name) {
    Tween[name] = function () {
        var tl = new Timeline(),
            params = _slice.call(arguments, 0);
        params.splice(name === 'staggerFromTo' ? 5 : 4, 0, 0);
        return tl[name].apply(tl, params);
    };
});
var _setterPlain = function _setterPlain2(target, property, value) {
    return (target[property] = value);
};
var _setterFunc = function _setterFunc2(target, property, value) {
    return target[property](value);
};
var _setterFuncWithParam = function _setterFuncWithParam2(
    target,
    property,
    value,
    data
) {
    return target[property](data.fp, value);
};
var _setterAttribute = function _setterAttribute2(target, property, value) {
    return target.setAttribute(property, value);
};
var _getSetter = function _getSetter2(target, property) {
    return _isFunction(target[property])
        ? _setterFunc
        : _isUndefined(target[property]) && target.setAttribute
        ? _setterAttribute
        : _setterPlain;
};
var _renderPlain = function _renderPlain2(ratio, data) {
    return data.set(
        data.t,
        data.p,
        Math.round((data.s + data.c * ratio) * 1e6) / 1e6,
        data
    );
};
var _renderBoolean = function _renderBoolean2(ratio, data) {
    return data.set(data.t, data.p, !!(data.s + data.c * ratio), data);
};
var _renderComplexString = function _renderComplexString2(ratio, data) {
    var pt = data._pt,
        s = '';
    if (!ratio && data.b) {
        s = data.b;
    } else if (ratio === 1 && data.e) {
        s = data.e;
    } else {
        while (pt) {
            s =
                pt.p +
                (pt.m
                    ? pt.m(pt.s + pt.c * ratio)
                    : Math.round((pt.s + pt.c * ratio) * 1e4) / 1e4) +
                s;
            pt = pt._next;
        }
        s += data.c;
    }
    data.set(data.t, data.p, s, data);
};
var _renderPropTweens = function _renderPropTweens2(ratio, data) {
    var pt = data._pt;
    while (pt) {
        pt.r(ratio, pt.d);
        pt = pt._next;
    }
};
var _addPluginModifier = function _addPluginModifier2(
    modifier,
    tween,
    target,
    property
) {
    var pt = this._pt,
        next;
    while (pt) {
        next = pt._next;
        pt.p === property && pt.modifier(modifier, tween, target);
        pt = next;
    }
};
var _killPropTweensOf = function _killPropTweensOf2(property) {
    var pt = this._pt,
        hasNonDependentRemaining,
        next;
    while (pt) {
        next = pt._next;
        if ((pt.p === property && !pt.op) || pt.op === property) {
            _removeLinkedListItem(this, pt, '_pt');
        } else if (!pt.dep) {
            hasNonDependentRemaining = 1;
        }
        pt = next;
    }
    return !hasNonDependentRemaining;
};
var _setterWithModifier = function _setterWithModifier2(
    target,
    property,
    value,
    data
) {
    data.mSet(target, property, data.m.call(data.tween, value, data.mt), data);
};
var _sortPropTweensByPriority = function _sortPropTweensByPriority2(parent) {
    var pt = parent._pt,
        next,
        pt2,
        first,
        last;
    while (pt) {
        next = pt._next;
        pt2 = first;
        while (pt2 && pt2.pr > pt.pr) {
            pt2 = pt2._next;
        }
        if ((pt._prev = pt2 ? pt2._prev : last)) {
            pt._prev._next = pt;
        } else {
            first = pt;
        }
        if ((pt._next = pt2)) {
            pt2._prev = pt;
        } else {
            last = pt;
        }
        pt = next;
    }
    parent._pt = first;
};
var PropTween = /* @__PURE__ */ (function () {
    function PropTween2(
        next,
        target,
        prop,
        start,
        change,
        renderer,
        data,
        setter,
        priority
    ) {
        this.t = target;
        this.s = start;
        this.c = change;
        this.p = prop;
        this.r = renderer || _renderPlain;
        this.d = data || this;
        this.set = setter || _setterPlain;
        this.pr = priority || 0;
        this._next = next;
        if (next) {
            next._prev = this;
        }
    }
    var _proto4 = PropTween2.prototype;
    _proto4.modifier = function modifier(func, tween, target) {
        this.mSet = this.mSet || this.set;
        this.set = _setterWithModifier;
        this.m = func;
        this.mt = target;
        this.tween = tween;
    };
    return PropTween2;
})();
_forEachName(
    _callbackNames +
        'parent,duration,ease,delay,overwrite,runBackwards,startAt,yoyo,immediateRender,repeat,repeatDelay,data,paused,reversed,lazy,callbackScope,stringFilter,id,yoyoEase,stagger,inherit,repeatRefresh,keyframes,autoRevert,scrollTrigger',
    function (name) {
        return (_reservedProps[name] = 1);
    }
);
_globals.TweenMax = _globals.TweenLite = Tween;
_globals.TimelineLite = _globals.TimelineMax = Timeline;
_globalTimeline = new Timeline({
    sortChildren: false,
    defaults: _defaults,
    autoRemoveChildren: true,
    id: 'root',
    smoothChildTiming: true,
});
_config.stringFilter = _colorStringFilter;
var _media = [];
var _listeners = {};
var _emptyArray = [];
var _lastMediaTime = 0;
var _contextID = 0;
var _dispatch = function _dispatch2(type) {
    return (_listeners[type] || _emptyArray).map(function (f) {
        return f();
    });
};
var _onMediaChange = function _onMediaChange2() {
    var time = Date.now(),
        matches = [];
    if (time - _lastMediaTime > 2) {
        _dispatch('matchMediaInit');
        _media.forEach(function (c) {
            var queries = c.queries,
                conditions = c.conditions,
                match,
                p,
                anyMatch,
                toggled;
            for (p in queries) {
                match = _win.matchMedia(queries[p]).matches;
                match && (anyMatch = 1);
                if (match !== conditions[p]) {
                    conditions[p] = match;
                    toggled = 1;
                }
            }
            if (toggled) {
                c.revert();
                anyMatch && matches.push(c);
            }
        });
        _dispatch('matchMediaRevert');
        matches.forEach(function (c) {
            return c.onMatch(c, function (func) {
                return c.add(null, func);
            });
        });
        _lastMediaTime = time;
        _dispatch('matchMedia');
    }
};
var Context = /* @__PURE__ */ (function () {
    function Context2(func, scope) {
        this.selector = scope && selector(scope);
        this.data = [];
        this._r = [];
        this.isReverted = false;
        this.id = _contextID++;
        func && this.add(func);
    }
    var _proto5 = Context2.prototype;
    _proto5.add = function add(name, func, scope) {
        if (_isFunction(name)) {
            scope = func;
            func = name;
            name = _isFunction;
        }
        var self2 = this,
            f = function f2() {
                var prev = _context,
                    prevSelector = self2.selector,
                    result;
                prev && prev !== self2 && prev.data.push(self2);
                scope && (self2.selector = selector(scope));
                _context = self2;
                result = func.apply(self2, arguments);
                _isFunction(result) && self2._r.push(result);
                _context = prev;
                self2.selector = prevSelector;
                self2.isReverted = false;
                return result;
            };
        self2.last = f;
        return name === _isFunction
            ? f(self2, function (func2) {
                  return self2.add(null, func2);
              })
            : name
            ? (self2[name] = f)
            : f;
    };
    _proto5.ignore = function ignore(func) {
        var prev = _context;
        _context = null;
        func(this);
        _context = prev;
    };
    _proto5.getTweens = function getTweens() {
        var a = [];
        this.data.forEach(function (e2) {
            return e2 instanceof Context2
                ? a.push.apply(a, e2.getTweens())
                : e2 instanceof Tween &&
                      !(e2.parent && e2.parent.data === 'nested') &&
                      a.push(e2);
        });
        return a;
    };
    _proto5.clear = function clear2() {
        this._r.length = this.data.length = 0;
    };
    _proto5.kill = function kill(revert, matchMedia2) {
        var _this4 = this;
        if (revert) {
            (function () {
                var tweens = _this4.getTweens(),
                    i2 = _this4.data.length,
                    t2;
                while (i2--) {
                    t2 = _this4.data[i2];
                    if (t2.data === 'isFlip') {
                        t2.revert();
                        t2.getChildren(true, true, false).forEach(function (
                            tween
                        ) {
                            return tweens.splice(tweens.indexOf(tween), 1);
                        });
                    }
                }
                tweens
                    .map(function (t3) {
                        return {
                            g:
                                t3._dur ||
                                t3._delay ||
                                (t3._sat && !t3._sat.vars.immediateRender)
                                    ? t3.globalTime(0)
                                    : -Infinity,
                            t: t3,
                        };
                    })
                    .sort(function (a, b) {
                        return b.g - a.g || -Infinity;
                    })
                    .forEach(function (o) {
                        return o.t.revert(revert);
                    });
                i2 = _this4.data.length;
                while (i2--) {
                    t2 = _this4.data[i2];
                    if (t2 instanceof Timeline) {
                        if (t2.data !== 'nested') {
                            t2.scrollTrigger && t2.scrollTrigger.revert();
                            t2.kill();
                        }
                    } else {
                        !(t2 instanceof Tween) &&
                            t2.revert &&
                            t2.revert(revert);
                    }
                }
                _this4._r.forEach(function (f) {
                    return f(revert, _this4);
                });
                _this4.isReverted = true;
            })();
        } else {
            this.data.forEach(function (e2) {
                return e2.kill && e2.kill();
            });
        }
        this.clear();
        if (matchMedia2) {
            var i = _media.length;
            while (i--) {
                _media[i].id === this.id && _media.splice(i, 1);
            }
        }
    };
    _proto5.revert = function revert(config3) {
        this.kill(config3 || {});
    };
    return Context2;
})();
var MatchMedia = /* @__PURE__ */ (function () {
    function MatchMedia2(scope) {
        this.contexts = [];
        this.scope = scope;
        _context && _context.data.push(this);
    }
    var _proto6 = MatchMedia2.prototype;
    _proto6.add = function add(conditions, func, scope) {
        _isObject(conditions) ||
            (conditions = {
                matches: conditions,
            });
        var context3 = new Context(0, scope || this.scope),
            cond = (context3.conditions = {}),
            mq,
            p,
            active;
        _context &&
            !context3.selector &&
            (context3.selector = _context.selector);
        this.contexts.push(context3);
        func = context3.add('onMatch', func);
        context3.queries = conditions;
        for (p in conditions) {
            if (p === 'all') {
                active = 1;
            } else {
                mq = _win.matchMedia(conditions[p]);
                if (mq) {
                    _media.indexOf(context3) < 0 && _media.push(context3);
                    (cond[p] = mq.matches) && (active = 1);
                    mq.addListener
                        ? mq.addListener(_onMediaChange)
                        : mq.addEventListener('change', _onMediaChange);
                }
            }
        }
        active &&
            func(context3, function (f) {
                return context3.add(null, f);
            });
        return this;
    };
    _proto6.revert = function revert(config3) {
        this.kill(config3 || {});
    };
    _proto6.kill = function kill(revert) {
        this.contexts.forEach(function (c) {
            return c.kill(revert, true);
        });
    };
    return MatchMedia2;
})();
var _gsap = {
    registerPlugin: function registerPlugin() {
        for (
            var _len2 = arguments.length, args = new Array(_len2), _key2 = 0;
            _key2 < _len2;
            _key2++
        ) {
            args[_key2] = arguments[_key2];
        }
        args.forEach(function (config3) {
            return _createPlugin(config3);
        });
    },
    timeline: function timeline(vars) {
        return new Timeline(vars);
    },
    getTweensOf: function getTweensOf(targets, onlyActive) {
        return _globalTimeline.getTweensOf(targets, onlyActive);
    },
    getProperty: function getProperty(target, property, unit, uncache) {
        _isString(target) && (target = toArray(target)[0]);
        var getter = _getCache(target || {}).get,
            format = unit ? _passThrough : _numericIfPossible;
        unit === 'native' && (unit = '');
        return !target
            ? target
            : !property
            ? function (property2, unit2, uncache2) {
                  return format(
                      (
                          (_plugins[property2] && _plugins[property2].get) ||
                          getter
                      )(target, property2, unit2, uncache2)
                  );
              }
            : format(
                  ((_plugins[property] && _plugins[property].get) || getter)(
                      target,
                      property,
                      unit,
                      uncache
                  )
              );
    },
    quickSetter: function quickSetter(target, property, unit) {
        target = toArray(target);
        if (target.length > 1) {
            var setters = target.map(function (t2) {
                    return gsap.quickSetter(t2, property, unit);
                }),
                l = setters.length;
            return function (value) {
                var i = l;
                while (i--) {
                    setters[i](value);
                }
            };
        }
        target = target[0] || {};
        var Plugin = _plugins[property],
            cache2 = _getCache(target),
            p =
                (cache2.harness && (cache2.harness.aliases || {})[property]) ||
                property,
            setter = Plugin
                ? function (value) {
                      var p2 = new Plugin();
                      _quickTween._pt = 0;
                      p2.init(
                          target,
                          unit ? value + unit : value,
                          _quickTween,
                          0,
                          [target]
                      );
                      p2.render(1, p2);
                      _quickTween._pt && _renderPropTweens(1, _quickTween);
                  }
                : cache2.set(target, p);
        return Plugin
            ? setter
            : function (value) {
                  return setter(
                      target,
                      p,
                      unit ? value + unit : value,
                      cache2,
                      1
                  );
              };
    },
    quickTo: function quickTo(target, property, vars) {
        var _merge22;
        var tween = gsap.to(
                target,
                _merge(
                    ((_merge22 = {}),
                    (_merge22[property] = '+=0.1'),
                    (_merge22.paused = true),
                    _merge22),
                    vars || {}
                )
            ),
            func = function func2(value, start, startIsRelative) {
                return tween.resetTo(property, value, start, startIsRelative);
            };
        func.tween = tween;
        return func;
    },
    isTweening: function isTweening(targets) {
        return _globalTimeline.getTweensOf(targets, true).length > 0;
    },
    defaults: function defaults(value) {
        value &&
            value.ease &&
            (value.ease = _parseEase(value.ease, _defaults.ease));
        return _mergeDeep(_defaults, value || {});
    },
    config: function config2(value) {
        return _mergeDeep(_config, value || {});
    },
    registerEffect: function registerEffect(_ref3) {
        var name = _ref3.name,
            effect = _ref3.effect,
            plugins = _ref3.plugins,
            defaults4 = _ref3.defaults,
            extendTimeline = _ref3.extendTimeline;
        (plugins || '').split(',').forEach(function (pluginName) {
            return (
                pluginName &&
                !_plugins[pluginName] &&
                !_globals[pluginName] &&
                _warn(name + ' effect requires ' + pluginName + ' plugin.')
            );
        });
        _effects[name] = function (targets, vars, tl) {
            return effect(
                toArray(targets),
                _setDefaults(vars || {}, defaults4),
                tl
            );
        };
        if (extendTimeline) {
            Timeline.prototype[name] = function (targets, vars, position) {
                return this.add(
                    _effects[name](
                        targets,
                        _isObject(vars) ? vars : (position = vars) && {},
                        this
                    ),
                    position
                );
            };
        }
    },
    registerEase: function registerEase(name, ease) {
        _easeMap[name] = _parseEase(ease);
    },
    parseEase: function parseEase(ease, defaultEase) {
        return arguments.length ? _parseEase(ease, defaultEase) : _easeMap;
    },
    getById: function getById(id) {
        return _globalTimeline.getById(id);
    },
    exportRoot: function exportRoot(vars, includeDelayedCalls) {
        if (vars === void 0) {
            vars = {};
        }
        var tl = new Timeline(vars),
            child,
            next;
        tl.smoothChildTiming = _isNotFalse(vars.smoothChildTiming);
        _globalTimeline.remove(tl);
        tl._dp = 0;
        tl._time = tl._tTime = _globalTimeline._time;
        child = _globalTimeline._first;
        while (child) {
            next = child._next;
            if (
                includeDelayedCalls ||
                !(
                    !child._dur &&
                    child instanceof Tween &&
                    child.vars.onComplete === child._targets[0]
                )
            ) {
                _addToTimeline(tl, child, child._start - child._delay);
            }
            child = next;
        }
        _addToTimeline(_globalTimeline, tl, 0);
        return tl;
    },
    context: function context(func, scope) {
        return func ? new Context(func, scope) : _context;
    },
    matchMedia: function matchMedia(scope) {
        return new MatchMedia(scope);
    },
    matchMediaRefresh: function matchMediaRefresh() {
        return (
            _media.forEach(function (c) {
                var cond = c.conditions,
                    found,
                    p;
                for (p in cond) {
                    if (cond[p]) {
                        cond[p] = false;
                        found = 1;
                    }
                }
                found && c.revert();
            }) || _onMediaChange()
        );
    },
    addEventListener: function addEventListener(type, callback) {
        var a = _listeners[type] || (_listeners[type] = []);
        ~a.indexOf(callback) || a.push(callback);
    },
    removeEventListener: function removeEventListener(type, callback) {
        var a = _listeners[type],
            i = a && a.indexOf(callback);
        i >= 0 && a.splice(i, 1);
    },
    utils: {
        wrap,
        wrapYoyo,
        distribute,
        random,
        snap,
        normalize,
        getUnit,
        clamp,
        splitColor,
        toArray,
        selector,
        mapRange,
        pipe,
        unitize,
        interpolate,
        shuffle,
    },
    install: _install,
    effects: _effects,
    ticker: _ticker,
    updateRoot: Timeline.updateRoot,
    plugins: _plugins,
    globalTimeline: _globalTimeline,
    core: {
        PropTween,
        globals: _addGlobal,
        Tween,
        Timeline,
        Animation,
        getCache: _getCache,
        _removeLinkedListItem,
        reverting: function reverting() {
            return _reverting;
        },
        context: function context2(toAdd) {
            if (toAdd && _context) {
                _context.data.push(toAdd);
                toAdd._ctx = _context;
            }
            return _context;
        },
        suppressOverwrites: function suppressOverwrites(value) {
            return (_suppressOverwrites = value);
        },
    },
};
_forEachName('to,from,fromTo,delayedCall,set,killTweensOf', function (name) {
    return (_gsap[name] = Tween[name]);
});
_ticker.add(Timeline.updateRoot);
_quickTween = _gsap.to(
    {},
    {
        duration: 0,
    }
);
var _getPluginPropTween = function _getPluginPropTween2(plugin, prop) {
    var pt = plugin._pt;
    while (pt && pt.p !== prop && pt.op !== prop && pt.fp !== prop) {
        pt = pt._next;
    }
    return pt;
};
var _addModifiers = function _addModifiers2(tween, modifiers) {
    var targets = tween._targets,
        p,
        i,
        pt;
    for (p in modifiers) {
        i = targets.length;
        while (i--) {
            pt = tween._ptLookup[i][p];
            if (pt && (pt = pt.d)) {
                if (pt._pt) {
                    pt = _getPluginPropTween(pt, p);
                }
                pt &&
                    pt.modifier &&
                    pt.modifier(modifiers[p], tween, targets[i], p);
            }
        }
    }
};
var _buildModifierPlugin = function _buildModifierPlugin2(name, modifier) {
    return {
        name,
        rawVars: 1,
        //don't pre-process function-based values or "random()" strings.
        init: function init4(target, vars, tween) {
            tween._onInit = function (tween2) {
                var temp, p;
                if (_isString(vars)) {
                    temp = {};
                    _forEachName(vars, function (name2) {
                        return (temp[name2] = 1);
                    });
                    vars = temp;
                }
                if (modifier) {
                    temp = {};
                    for (p in vars) {
                        temp[p] = modifier(vars[p]);
                    }
                    vars = temp;
                }
                _addModifiers(tween2, vars);
            };
        },
    };
};
var gsap =
    _gsap.registerPlugin(
        {
            name: 'attr',
            init: function init(target, vars, tween, index, targets) {
                var p, pt, v;
                this.tween = tween;
                for (p in vars) {
                    v = target.getAttribute(p) || '';
                    pt = this.add(
                        target,
                        'setAttribute',
                        (v || 0) + '',
                        vars[p],
                        index,
                        targets,
                        0,
                        0,
                        p
                    );
                    pt.op = p;
                    pt.b = v;
                    this._props.push(p);
                }
            },
            render: function render(ratio, data) {
                var pt = data._pt;
                while (pt) {
                    _reverting
                        ? pt.set(pt.t, pt.p, pt.b, pt)
                        : pt.r(ratio, pt.d);
                    pt = pt._next;
                }
            },
        },
        {
            name: 'endArray',
            init: function init2(target, value) {
                var i = value.length;
                while (i--) {
                    this.add(
                        target,
                        i,
                        target[i] || 0,
                        value[i],
                        0,
                        0,
                        0,
                        0,
                        0,
                        1
                    );
                }
            },
        },
        _buildModifierPlugin('roundProps', _roundModifier),
        _buildModifierPlugin('modifiers'),
        _buildModifierPlugin('snap', snap)
    ) || _gsap;
Tween.version = Timeline.version = gsap.version = '3.12.5';
_coreReady = 1;
_windowExists() && _wake();
var Power0 = _easeMap.Power0;
var Power1 = _easeMap.Power1;
var Power2 = _easeMap.Power2;
var Power3 = _easeMap.Power3;
var Power4 = _easeMap.Power4;
var Linear = _easeMap.Linear;
var Quad = _easeMap.Quad;
var Cubic = _easeMap.Cubic;
var Quart = _easeMap.Quart;
var Quint = _easeMap.Quint;
var Strong = _easeMap.Strong;
var Elastic = _easeMap.Elastic;
var Back = _easeMap.Back;
var SteppedEase = _easeMap.SteppedEase;
var Bounce = _easeMap.Bounce;
var Sine = _easeMap.Sine;
var Expo = _easeMap.Expo;
var Circ = _easeMap.Circ;

// node_modules/gsap/CSSPlugin.js
var _win2;
var _doc2;
var _docElement;
var _pluginInitted;
var _tempDiv;
var _tempDivStyler;
var _recentSetterPlugin;
var _reverting2;
var _windowExists3 = function _windowExists4() {
    return typeof window !== 'undefined';
};
var _transformProps = {};
var _RAD2DEG = 180 / Math.PI;
var _DEG2RAD = Math.PI / 180;
var _atan2 = Math.atan2;
var _bigNum2 = 1e8;
var _capsExp = /([A-Z])/g;
var _horizontalExp = /(left|right|width|margin|padding|x)/i;
var _complexExp = /[\s,\(]\S/;
var _propertyAliases = {
    autoAlpha: 'opacity,visibility',
    scale: 'scaleX,scaleY',
    alpha: 'opacity',
};
var _renderCSSProp = function _renderCSSProp2(ratio, data) {
    return data.set(
        data.t,
        data.p,
        Math.round((data.s + data.c * ratio) * 1e4) / 1e4 + data.u,
        data
    );
};
var _renderPropWithEnd = function _renderPropWithEnd2(ratio, data) {
    return data.set(
        data.t,
        data.p,
        ratio === 1
            ? data.e
            : Math.round((data.s + data.c * ratio) * 1e4) / 1e4 + data.u,
        data
    );
};
var _renderCSSPropWithBeginning = function _renderCSSPropWithBeginning2(
    ratio,
    data
) {
    return data.set(
        data.t,
        data.p,
        ratio
            ? Math.round((data.s + data.c * ratio) * 1e4) / 1e4 + data.u
            : data.b,
        data
    );
};
var _renderRoundedCSSProp = function _renderRoundedCSSProp2(ratio, data) {
    var value = data.s + data.c * ratio;
    data.set(
        data.t,
        data.p,
        ~~(value + (value < 0 ? -0.5 : 0.5)) + data.u,
        data
    );
};
var _renderNonTweeningValue = function _renderNonTweeningValue2(ratio, data) {
    return data.set(data.t, data.p, ratio ? data.e : data.b, data);
};
var _renderNonTweeningValueOnlyAtEnd =
    function _renderNonTweeningValueOnlyAtEnd2(ratio, data) {
        return data.set(data.t, data.p, ratio !== 1 ? data.b : data.e, data);
    };
var _setterCSSStyle = function _setterCSSStyle2(target, property, value) {
    return (target.style[property] = value);
};
var _setterCSSProp = function _setterCSSProp2(target, property, value) {
    return target.style.setProperty(property, value);
};
var _setterTransform = function _setterTransform2(target, property, value) {
    return (target._gsap[property] = value);
};
var _setterScale = function _setterScale2(target, property, value) {
    return (target._gsap.scaleX = target._gsap.scaleY = value);
};
var _setterScaleWithRender = function _setterScaleWithRender2(
    target,
    property,
    value,
    data,
    ratio
) {
    var cache2 = target._gsap;
    cache2.scaleX = cache2.scaleY = value;
    cache2.renderTransform(ratio, cache2);
};
var _setterTransformWithRender = function _setterTransformWithRender2(
    target,
    property,
    value,
    data,
    ratio
) {
    var cache2 = target._gsap;
    cache2[property] = value;
    cache2.renderTransform(ratio, cache2);
};
var _transformProp = 'transform';
var _transformOriginProp = _transformProp + 'Origin';
var _saveStyle = function _saveStyle2(property, isNotCSS) {
    var _this = this;
    var target = this.target,
        style = target.style,
        cache2 = target._gsap;
    if (property in _transformProps && style) {
        this.tfm = this.tfm || {};
        if (property !== 'transform') {
            property = _propertyAliases[property] || property;
            ~property.indexOf(',')
                ? property.split(',').forEach(function (a) {
                      return (_this.tfm[a] = _get(target, a));
                  })
                : (this.tfm[property] = cache2.x
                      ? cache2[property]
                      : _get(target, property));
            property === _transformOriginProp &&
                (this.tfm.zOrigin = cache2.zOrigin);
        } else {
            return _propertyAliases.transform.split(',').forEach(function (p) {
                return _saveStyle2.call(_this, p, isNotCSS);
            });
        }
        if (this.props.indexOf(_transformProp) >= 0) {
            return;
        }
        if (cache2.svg) {
            this.svgo = target.getAttribute('data-svg-origin');
            this.props.push(_transformOriginProp, isNotCSS, '');
        }
        property = _transformProp;
    }
    (style || isNotCSS) && this.props.push(property, isNotCSS, style[property]);
};
var _removeIndependentTransforms = function _removeIndependentTransforms2(
    style
) {
    if (style.translate) {
        style.removeProperty('translate');
        style.removeProperty('scale');
        style.removeProperty('rotate');
    }
};
var _revertStyle = function _revertStyle2() {
    var props = this.props,
        target = this.target,
        style = target.style,
        cache2 = target._gsap,
        i,
        p;
    for (i = 0; i < props.length; i += 3) {
        props[i + 1]
            ? (target[props[i]] = props[i + 2])
            : props[i + 2]
            ? (style[props[i]] = props[i + 2])
            : style.removeProperty(
                  props[i].substr(0, 2) === '--'
                      ? props[i]
                      : props[i].replace(_capsExp, '-$1').toLowerCase()
              );
    }
    if (this.tfm) {
        for (p in this.tfm) {
            cache2[p] = this.tfm[p];
        }
        if (cache2.svg) {
            cache2.renderTransform();
            target.setAttribute('data-svg-origin', this.svgo || '');
        }
        i = _reverting2();
        if ((!i || !i.isStart) && !style[_transformProp]) {
            _removeIndependentTransforms(style);
            if (cache2.zOrigin && style[_transformOriginProp]) {
                style[_transformOriginProp] += ' ' + cache2.zOrigin + 'px';
                cache2.zOrigin = 0;
                cache2.renderTransform();
            }
            cache2.uncache = 1;
        }
    }
};
var _getStyleSaver = function _getStyleSaver2(target, properties) {
    var saver = {
        target,
        props: [],
        revert: _revertStyle,
        save: _saveStyle,
    };
    target._gsap || gsap.core.getCache(target);
    properties &&
        properties.split(',').forEach(function (p) {
            return saver.save(p);
        });
    return saver;
};
var _supports3D;
var _createElement = function _createElement2(type, ns) {
    var e2 = _doc2.createElementNS
        ? _doc2.createElementNS(
              (ns || 'http://www.w3.org/1999/xhtml').replace(/^https/, 'http'),
              type
          )
        : _doc2.createElement(type);
    return e2 && e2.style ? e2 : _doc2.createElement(type);
};
var _getComputedProperty = function _getComputedProperty2(
    target,
    property,
    skipPrefixFallback
) {
    var cs = getComputedStyle(target);
    return (
        cs[property] ||
        cs.getPropertyValue(property.replace(_capsExp, '-$1').toLowerCase()) ||
        cs.getPropertyValue(property) ||
        (!skipPrefixFallback &&
            _getComputedProperty2(
                target,
                _checkPropPrefix(property) || property,
                1
            )) ||
        ''
    );
};
var _prefixes = 'O,Moz,ms,Ms,Webkit'.split(',');
var _checkPropPrefix = function _checkPropPrefix2(
    property,
    element,
    preferPrefix
) {
    var e2 = element || _tempDiv,
        s = e2.style,
        i = 5;
    if (property in s && !preferPrefix) {
        return property;
    }
    property = property.charAt(0).toUpperCase() + property.substr(1);
    while (i-- && !(_prefixes[i] + property in s)) {}
    return i < 0
        ? null
        : (i === 3 ? 'ms' : i >= 0 ? _prefixes[i] : '') + property;
};
var _initCore = function _initCore2() {
    if (_windowExists3() && window.document) {
        _win2 = window;
        _doc2 = _win2.document;
        _docElement = _doc2.documentElement;
        _tempDiv = _createElement('div') || {
            style: {},
        };
        _tempDivStyler = _createElement('div');
        _transformProp = _checkPropPrefix(_transformProp);
        _transformOriginProp = _transformProp + 'Origin';
        _tempDiv.style.cssText =
            'border-width:0;line-height:0;position:absolute;padding:0';
        _supports3D = !!_checkPropPrefix('perspective');
        _reverting2 = gsap.core.reverting;
        _pluginInitted = 1;
    }
};
var _getBBoxHack = function _getBBoxHack2(swapIfPossible) {
    var svg = _createElement(
            'svg',
            (this.ownerSVGElement &&
                this.ownerSVGElement.getAttribute('xmlns')) ||
                'http://www.w3.org/2000/svg'
        ),
        oldParent = this.parentNode,
        oldSibling = this.nextSibling,
        oldCSS = this.style.cssText,
        bbox;
    _docElement.appendChild(svg);
    svg.appendChild(this);
    this.style.display = 'block';
    if (swapIfPossible) {
        try {
            bbox = this.getBBox();
            this._gsapBBox = this.getBBox;
            this.getBBox = _getBBoxHack2;
        } catch (e2) {}
    } else if (this._gsapBBox) {
        bbox = this._gsapBBox();
    }
    if (oldParent) {
        if (oldSibling) {
            oldParent.insertBefore(this, oldSibling);
        } else {
            oldParent.appendChild(this);
        }
    }
    _docElement.removeChild(svg);
    this.style.cssText = oldCSS;
    return bbox;
};
var _getAttributeFallbacks = function _getAttributeFallbacks2(
    target,
    attributesArray
) {
    var i = attributesArray.length;
    while (i--) {
        if (target.hasAttribute(attributesArray[i])) {
            return target.getAttribute(attributesArray[i]);
        }
    }
};
var _getBBox = function _getBBox2(target) {
    var bounds;
    try {
        bounds = target.getBBox();
    } catch (error) {
        bounds = _getBBoxHack.call(target, true);
    }
    (bounds && (bounds.width || bounds.height)) ||
        target.getBBox === _getBBoxHack ||
        (bounds = _getBBoxHack.call(target, true));
    return bounds && !bounds.width && !bounds.x && !bounds.y
        ? {
              x: +_getAttributeFallbacks(target, ['x', 'cx', 'x1']) || 0,
              y: +_getAttributeFallbacks(target, ['y', 'cy', 'y1']) || 0,
              width: 0,
              height: 0,
          }
        : bounds;
};
var _isSVG = function _isSVG2(e2) {
    return !!(
        e2.getCTM &&
        (!e2.parentNode || e2.ownerSVGElement) &&
        _getBBox(e2)
    );
};
var _removeProperty = function _removeProperty2(target, property) {
    if (property) {
        var style = target.style,
            first2Chars;
        if (property in _transformProps && property !== _transformOriginProp) {
            property = _transformProp;
        }
        if (style.removeProperty) {
            first2Chars = property.substr(0, 2);
            if (first2Chars === 'ms' || property.substr(0, 6) === 'webkit') {
                property = '-' + property;
            }
            style.removeProperty(
                first2Chars === '--'
                    ? property
                    : property.replace(_capsExp, '-$1').toLowerCase()
            );
        } else {
            style.removeAttribute(property);
        }
    }
};
var _addNonTweeningPT = function _addNonTweeningPT2(
    plugin,
    target,
    property,
    beginning,
    end,
    onlySetAtEnd
) {
    var pt = new PropTween(
        plugin._pt,
        target,
        property,
        0,
        1,
        onlySetAtEnd
            ? _renderNonTweeningValueOnlyAtEnd
            : _renderNonTweeningValue
    );
    plugin._pt = pt;
    pt.b = beginning;
    pt.e = end;
    plugin._props.push(property);
    return pt;
};
var _nonConvertibleUnits = {
    deg: 1,
    rad: 1,
    turn: 1,
};
var _nonStandardLayouts = {
    grid: 1,
    flex: 1,
};
var _convertToUnit = function _convertToUnit2(target, property, value, unit) {
    var curValue = parseFloat(value) || 0,
        curUnit = (value + '').trim().substr((curValue + '').length) || 'px',
        style = _tempDiv.style,
        horizontal = _horizontalExp.test(property),
        isRootSVG = target.tagName.toLowerCase() === 'svg',
        measureProperty =
            (isRootSVG ? 'client' : 'offset') +
            (horizontal ? 'Width' : 'Height'),
        amount = 100,
        toPixels = unit === 'px',
        toPercent = unit === '%',
        px,
        parent,
        cache2,
        isSVG;
    if (
        unit === curUnit ||
        !curValue ||
        _nonConvertibleUnits[unit] ||
        _nonConvertibleUnits[curUnit]
    ) {
        return curValue;
    }
    curUnit !== 'px' &&
        !toPixels &&
        (curValue = _convertToUnit2(target, property, value, 'px'));
    isSVG = target.getCTM && _isSVG(target);
    if (
        (toPercent || curUnit === '%') &&
        (_transformProps[property] || ~property.indexOf('adius'))
    ) {
        px = isSVG
            ? target.getBBox()[horizontal ? 'width' : 'height']
            : target[measureProperty];
        return _round(
            toPercent ? (curValue / px) * amount : (curValue / 100) * px
        );
    }
    style[horizontal ? 'width' : 'height'] =
        amount + (toPixels ? curUnit : unit);
    parent =
        ~property.indexOf('adius') ||
        (unit === 'em' && target.appendChild && !isRootSVG)
            ? target
            : target.parentNode;
    if (isSVG) {
        parent = (target.ownerSVGElement || {}).parentNode;
    }
    if (!parent || parent === _doc2 || !parent.appendChild) {
        parent = _doc2.body;
    }
    cache2 = parent._gsap;
    if (
        cache2 &&
        toPercent &&
        cache2.width &&
        horizontal &&
        cache2.time === _ticker.time &&
        !cache2.uncache
    ) {
        return _round((curValue / cache2.width) * amount);
    } else {
        if (toPercent && (property === 'height' || property === 'width')) {
            var v = target.style[property];
            target.style[property] = amount + unit;
            px = target[measureProperty];
            v
                ? (target.style[property] = v)
                : _removeProperty(target, property);
        } else {
            (toPercent || curUnit === '%') &&
                !_nonStandardLayouts[_getComputedProperty(parent, 'display')] &&
                (style.position = _getComputedProperty(target, 'position'));
            parent === target && (style.position = 'static');
            parent.appendChild(_tempDiv);
            px = _tempDiv[measureProperty];
            parent.removeChild(_tempDiv);
            style.position = 'absolute';
        }
        if (horizontal && toPercent) {
            cache2 = _getCache(parent);
            cache2.time = _ticker.time;
            cache2.width = parent[measureProperty];
        }
    }
    return _round(
        toPixels
            ? (px * curValue) / amount
            : px && curValue
            ? (amount / px) * curValue
            : 0
    );
};
var _get = function _get2(target, property, unit, uncache) {
    var value;
    _pluginInitted || _initCore();
    if (property in _propertyAliases && property !== 'transform') {
        property = _propertyAliases[property];
        if (~property.indexOf(',')) {
            property = property.split(',')[0];
        }
    }
    if (_transformProps[property] && property !== 'transform') {
        value = _parseTransform(target, uncache);
        value =
            property !== 'transformOrigin'
                ? value[property]
                : value.svg
                ? value.origin
                : _firstTwoOnly(
                      _getComputedProperty(target, _transformOriginProp)
                  ) +
                  ' ' +
                  value.zOrigin +
                  'px';
    } else {
        value = target.style[property];
        if (
            !value ||
            value === 'auto' ||
            uncache ||
            ~(value + '').indexOf('calc(')
        ) {
            value =
                (_specialProps[property] &&
                    _specialProps[property](target, property, unit)) ||
                _getComputedProperty(target, property) ||
                _getProperty(target, property) ||
                (property === 'opacity' ? 1 : 0);
        }
    }
    return unit && !~(value + '').trim().indexOf(' ')
        ? _convertToUnit(target, property, value, unit) + unit
        : value;
};
var _tweenComplexCSSString = function _tweenComplexCSSString2(
    target,
    prop,
    start,
    end
) {
    if (!start || start === 'none') {
        var p = _checkPropPrefix(prop, target, 1),
            s = p && _getComputedProperty(target, p, 1);
        if (s && s !== start) {
            prop = p;
            start = s;
        } else if (prop === 'borderColor') {
            start = _getComputedProperty(target, 'borderTopColor');
        }
    }
    var pt = new PropTween(
            this._pt,
            target.style,
            prop,
            0,
            1,
            _renderComplexString
        ),
        index = 0,
        matchIndex = 0,
        a,
        result,
        startValues,
        startNum,
        color,
        startValue,
        endValue,
        endNum,
        chunk,
        endUnit,
        startUnit,
        endValues;
    pt.b = start;
    pt.e = end;
    start += '';
    end += '';
    if (end === 'auto') {
        startValue = target.style[prop];
        target.style[prop] = end;
        end = _getComputedProperty(target, prop) || end;
        startValue
            ? (target.style[prop] = startValue)
            : _removeProperty(target, prop);
    }
    a = [start, end];
    _colorStringFilter(a);
    start = a[0];
    end = a[1];
    startValues = start.match(_numWithUnitExp) || [];
    endValues = end.match(_numWithUnitExp) || [];
    if (endValues.length) {
        while ((result = _numWithUnitExp.exec(end))) {
            endValue = result[0];
            chunk = end.substring(index, result.index);
            if (color) {
                color = (color + 1) % 5;
            } else if (
                chunk.substr(-5) === 'rgba(' ||
                chunk.substr(-5) === 'hsla('
            ) {
                color = 1;
            }
            if (endValue !== (startValue = startValues[matchIndex++] || '')) {
                startNum = parseFloat(startValue) || 0;
                startUnit = startValue.substr((startNum + '').length);
                endValue.charAt(1) === '=' &&
                    (endValue = _parseRelative(startNum, endValue) + startUnit);
                endNum = parseFloat(endValue);
                endUnit = endValue.substr((endNum + '').length);
                index = _numWithUnitExp.lastIndex - endUnit.length;
                if (!endUnit) {
                    endUnit = endUnit || _config.units[prop] || startUnit;
                    if (index === end.length) {
                        end += endUnit;
                        pt.e += endUnit;
                    }
                }
                if (startUnit !== endUnit) {
                    startNum =
                        _convertToUnit(target, prop, startValue, endUnit) || 0;
                }
                pt._pt = {
                    _next: pt._pt,
                    p: chunk || matchIndex === 1 ? chunk : ',',
                    //note: SVG spec allows omission of comma/space when a negative sign is wedged between two numbers, like 2.5-5.3 instead of 2.5,-5.3 but when tweening, the negative value may switch to positive, so we insert the comma just in case.
                    s: startNum,
                    c: endNum - startNum,
                    m:
                        (color && color < 4) || prop === 'zIndex'
                            ? Math.round
                            : 0,
                };
            }
        }
        pt.c = index < end.length ? end.substring(index, end.length) : '';
    } else {
        pt.r =
            prop === 'display' && end === 'none'
                ? _renderNonTweeningValueOnlyAtEnd
                : _renderNonTweeningValue;
    }
    _relExp.test(end) && (pt.e = 0);
    this._pt = pt;
    return pt;
};
var _keywordToPercent = {
    top: '0%',
    bottom: '100%',
    left: '0%',
    right: '100%',
    center: '50%',
};
var _convertKeywordsToPercentages = function _convertKeywordsToPercentages2(
    value
) {
    var split2 = value.split(' '),
        x = split2[0],
        y = split2[1] || '50%';
    if (x === 'top' || x === 'bottom' || y === 'left' || y === 'right') {
        value = x;
        x = y;
        y = value;
    }
    split2[0] = _keywordToPercent[x] || x;
    split2[1] = _keywordToPercent[y] || y;
    return split2.join(' ');
};
var _renderClearProps = function _renderClearProps2(ratio, data) {
    if (data.tween && data.tween._time === data.tween._dur) {
        var target = data.t,
            style = target.style,
            props = data.u,
            cache2 = target._gsap,
            prop,
            clearTransforms,
            i;
        if (props === 'all' || props === true) {
            style.cssText = '';
            clearTransforms = 1;
        } else {
            props = props.split(',');
            i = props.length;
            while (--i > -1) {
                prop = props[i];
                if (_transformProps[prop]) {
                    clearTransforms = 1;
                    prop =
                        prop === 'transformOrigin'
                            ? _transformOriginProp
                            : _transformProp;
                }
                _removeProperty(target, prop);
            }
        }
        if (clearTransforms) {
            _removeProperty(target, _transformProp);
            if (cache2) {
                cache2.svg && target.removeAttribute('transform');
                _parseTransform(target, 1);
                cache2.uncache = 1;
                _removeIndependentTransforms(style);
            }
        }
    }
};
var _specialProps = {
    clearProps: function clearProps(plugin, target, property, endValue, tween) {
        if (tween.data !== 'isFromStart') {
            var pt = (plugin._pt = new PropTween(
                plugin._pt,
                target,
                property,
                0,
                0,
                _renderClearProps
            ));
            pt.u = endValue;
            pt.pr = -10;
            pt.tween = tween;
            plugin._props.push(property);
            return 1;
        }
    },
    /* className feature (about 0.4kb gzipped).
    , className(plugin, target, property, endValue, tween) {
    	let _renderClassName = (ratio, data) => {
    			data.css.render(ratio, data.css);
    			if (!ratio || ratio === 1) {
    				let inline = data.rmv,
    					target = data.t,
    					p;
    				target.setAttribute("class", ratio ? data.e : data.b);
    				for (p in inline) {
    					_removeProperty(target, p);
    				}
    			}
    		},
    		_getAllStyles = (target) => {
    			let styles = {},
    				computed = getComputedStyle(target),
    				p;
    			for (p in computed) {
    				if (isNaN(p) && p !== "cssText" && p !== "length") {
    					styles[p] = computed[p];
    				}
    			}
    			_setDefaults(styles, _parseTransform(target, 1));
    			return styles;
    		},
    		startClassList = target.getAttribute("class"),
    		style = target.style,
    		cssText = style.cssText,
    		cache = target._gsap,
    		classPT = cache.classPT,
    		inlineToRemoveAtEnd = {},
    		data = {t:target, plugin:plugin, rmv:inlineToRemoveAtEnd, b:startClassList, e:(endValue.charAt(1) !== "=") ? endValue : startClassList.replace(new RegExp("(?:\\s|^)" + endValue.substr(2) + "(?![\\w-])"), "") + ((endValue.charAt(0) === "+") ? " " + endValue.substr(2) : "")},
    		changingVars = {},
    		startVars = _getAllStyles(target),
    		transformRelated = /(transform|perspective)/i,
    		endVars, p;
    	if (classPT) {
    		classPT.r(1, classPT.d);
    		_removeLinkedListItem(classPT.d.plugin, classPT, "_pt");
    	}
    	target.setAttribute("class", data.e);
    	endVars = _getAllStyles(target, true);
    	target.setAttribute("class", startClassList);
    	for (p in endVars) {
    		if (endVars[p] !== startVars[p] && !transformRelated.test(p)) {
    			changingVars[p] = endVars[p];
    			if (!style[p] && style[p] !== "0") {
    				inlineToRemoveAtEnd[p] = 1;
    			}
    		}
    	}
    	cache.classPT = plugin._pt = new PropTween(plugin._pt, target, "className", 0, 0, _renderClassName, data, 0, -11);
    	if (style.cssText !== cssText) { //only apply if things change. Otherwise, in cases like a background-image that's pulled dynamically, it could cause a refresh. See https://gsap.com/forums/topic/20368-possible-gsap-bug-switching-classnames-in-chrome/.
    		style.cssText = cssText; //we recorded cssText before we swapped classes and ran _getAllStyles() because in cases when a className tween is overwritten, we remove all the related tweening properties from that class change (otherwise class-specific stuff can't override properties we've directly set on the target's style object due to specificity).
    	}
    	_parseTransform(target, true); //to clear the caching of transforms
    	data.css = new gsap.plugins.css();
    	data.css.init(target, changingVars, tween);
    	plugin._props.push(...data.css._props);
    	return 1;
    }
    */
};
var _identity2DMatrix = [1, 0, 0, 1, 0, 0];
var _rotationalProperties = {};
var _isNullTransform = function _isNullTransform2(value) {
    return value === 'matrix(1, 0, 0, 1, 0, 0)' || value === 'none' || !value;
};
var _getComputedTransformMatrixAsArray =
    function _getComputedTransformMatrixAsArray2(target) {
        var matrixString = _getComputedProperty(target, _transformProp);
        return _isNullTransform(matrixString)
            ? _identity2DMatrix
            : matrixString.substr(7).match(_numExp).map(_round);
    };
var _getMatrix = function _getMatrix2(target, force2D) {
    var cache2 = target._gsap || _getCache(target),
        style = target.style,
        matrix = _getComputedTransformMatrixAsArray(target),
        parent,
        nextSibling,
        temp,
        addedToDOM;
    if (cache2.svg && target.getAttribute('transform')) {
        temp = target.transform.baseVal.consolidate().matrix;
        matrix = [temp.a, temp.b, temp.c, temp.d, temp.e, temp.f];
        return matrix.join(',') === '1,0,0,1,0,0' ? _identity2DMatrix : matrix;
    } else if (
        matrix === _identity2DMatrix &&
        !target.offsetParent &&
        target !== _docElement &&
        !cache2.svg
    ) {
        temp = style.display;
        style.display = 'block';
        parent = target.parentNode;
        if (!parent || !target.offsetParent) {
            addedToDOM = 1;
            nextSibling = target.nextElementSibling;
            _docElement.appendChild(target);
        }
        matrix = _getComputedTransformMatrixAsArray(target);
        temp ? (style.display = temp) : _removeProperty(target, 'display');
        if (addedToDOM) {
            nextSibling
                ? parent.insertBefore(target, nextSibling)
                : parent
                ? parent.appendChild(target)
                : _docElement.removeChild(target);
        }
    }
    return force2D && matrix.length > 6
        ? [matrix[0], matrix[1], matrix[4], matrix[5], matrix[12], matrix[13]]
        : matrix;
};
var _applySVGOrigin = function _applySVGOrigin2(
    target,
    origin,
    originIsAbsolute,
    smooth,
    matrixArray,
    pluginToAddPropTweensTo
) {
    var cache2 = target._gsap,
        matrix = matrixArray || _getMatrix(target, true),
        xOriginOld = cache2.xOrigin || 0,
        yOriginOld = cache2.yOrigin || 0,
        xOffsetOld = cache2.xOffset || 0,
        yOffsetOld = cache2.yOffset || 0,
        a = matrix[0],
        b = matrix[1],
        c = matrix[2],
        d = matrix[3],
        tx = matrix[4],
        ty = matrix[5],
        originSplit = origin.split(' '),
        xOrigin = parseFloat(originSplit[0]) || 0,
        yOrigin = parseFloat(originSplit[1]) || 0,
        bounds,
        determinant,
        x,
        y;
    if (!originIsAbsolute) {
        bounds = _getBBox(target);
        xOrigin =
            bounds.x +
            (~originSplit[0].indexOf('%')
                ? (xOrigin / 100) * bounds.width
                : xOrigin);
        yOrigin =
            bounds.y +
            (~(originSplit[1] || originSplit[0]).indexOf('%')
                ? (yOrigin / 100) * bounds.height
                : yOrigin);
    } else if (matrix !== _identity2DMatrix && (determinant = a * d - b * c)) {
        x =
            xOrigin * (d / determinant) +
            yOrigin * (-c / determinant) +
            (c * ty - d * tx) / determinant;
        y =
            xOrigin * (-b / determinant) +
            yOrigin * (a / determinant) -
            (a * ty - b * tx) / determinant;
        xOrigin = x;
        yOrigin = y;
    }
    if (smooth || (smooth !== false && cache2.smooth)) {
        tx = xOrigin - xOriginOld;
        ty = yOrigin - yOriginOld;
        cache2.xOffset = xOffsetOld + (tx * a + ty * c) - tx;
        cache2.yOffset = yOffsetOld + (tx * b + ty * d) - ty;
    } else {
        cache2.xOffset = cache2.yOffset = 0;
    }
    cache2.xOrigin = xOrigin;
    cache2.yOrigin = yOrigin;
    cache2.smooth = !!smooth;
    cache2.origin = origin;
    cache2.originIsAbsolute = !!originIsAbsolute;
    target.style[_transformOriginProp] = '0px 0px';
    if (pluginToAddPropTweensTo) {
        _addNonTweeningPT(
            pluginToAddPropTweensTo,
            cache2,
            'xOrigin',
            xOriginOld,
            xOrigin
        );
        _addNonTweeningPT(
            pluginToAddPropTweensTo,
            cache2,
            'yOrigin',
            yOriginOld,
            yOrigin
        );
        _addNonTweeningPT(
            pluginToAddPropTweensTo,
            cache2,
            'xOffset',
            xOffsetOld,
            cache2.xOffset
        );
        _addNonTweeningPT(
            pluginToAddPropTweensTo,
            cache2,
            'yOffset',
            yOffsetOld,
            cache2.yOffset
        );
    }
    target.setAttribute('data-svg-origin', xOrigin + ' ' + yOrigin);
};
var _parseTransform = function _parseTransform2(target, uncache) {
    var cache2 = target._gsap || new GSCache(target);
    if ('x' in cache2 && !uncache && !cache2.uncache) {
        return cache2;
    }
    var style = target.style,
        invertedScaleX = cache2.scaleX < 0,
        px = 'px',
        deg = 'deg',
        cs = getComputedStyle(target),
        origin = _getComputedProperty(target, _transformOriginProp) || '0',
        x,
        y,
        z,
        scaleX,
        scaleY,
        rotation,
        rotationX,
        rotationY,
        skewX,
        skewY,
        perspective,
        xOrigin,
        yOrigin,
        matrix,
        angle,
        cos,
        sin,
        a,
        b,
        c,
        d,
        a12,
        a22,
        t1,
        t2,
        t3,
        a13,
        a23,
        a33,
        a42,
        a43,
        a32;
    x =
        y =
        z =
        rotation =
        rotationX =
        rotationY =
        skewX =
        skewY =
        perspective =
            0;
    scaleX = scaleY = 1;
    cache2.svg = !!(target.getCTM && _isSVG(target));
    if (cs.translate) {
        if (
            cs.translate !== 'none' ||
            cs.scale !== 'none' ||
            cs.rotate !== 'none'
        ) {
            style[_transformProp] =
                (cs.translate !== 'none'
                    ? 'translate3d(' +
                      (cs.translate + ' 0 0')
                          .split(' ')
                          .slice(0, 3)
                          .join(', ') +
                      ') '
                    : '') +
                (cs.rotate !== 'none' ? 'rotate(' + cs.rotate + ') ' : '') +
                (cs.scale !== 'none'
                    ? 'scale(' + cs.scale.split(' ').join(',') + ') '
                    : '') +
                (cs[_transformProp] !== 'none' ? cs[_transformProp] : '');
        }
        style.scale = style.rotate = style.translate = 'none';
    }
    matrix = _getMatrix(target, cache2.svg);
    if (cache2.svg) {
        if (cache2.uncache) {
            t2 = target.getBBox();
            origin =
                cache2.xOrigin - t2.x + 'px ' + (cache2.yOrigin - t2.y) + 'px';
            t1 = '';
        } else {
            t1 = !uncache && target.getAttribute('data-svg-origin');
        }
        _applySVGOrigin(
            target,
            t1 || origin,
            !!t1 || cache2.originIsAbsolute,
            cache2.smooth !== false,
            matrix
        );
    }
    xOrigin = cache2.xOrigin || 0;
    yOrigin = cache2.yOrigin || 0;
    if (matrix !== _identity2DMatrix) {
        a = matrix[0];
        b = matrix[1];
        c = matrix[2];
        d = matrix[3];
        x = a12 = matrix[4];
        y = a22 = matrix[5];
        if (matrix.length === 6) {
            scaleX = Math.sqrt(a * a + b * b);
            scaleY = Math.sqrt(d * d + c * c);
            rotation = a || b ? _atan2(b, a) * _RAD2DEG : 0;
            skewX = c || d ? _atan2(c, d) * _RAD2DEG + rotation : 0;
            skewX && (scaleY *= Math.abs(Math.cos(skewX * _DEG2RAD)));
            if (cache2.svg) {
                x -= xOrigin - (xOrigin * a + yOrigin * c);
                y -= yOrigin - (xOrigin * b + yOrigin * d);
            }
        } else {
            a32 = matrix[6];
            a42 = matrix[7];
            a13 = matrix[8];
            a23 = matrix[9];
            a33 = matrix[10];
            a43 = matrix[11];
            x = matrix[12];
            y = matrix[13];
            z = matrix[14];
            angle = _atan2(a32, a33);
            rotationX = angle * _RAD2DEG;
            if (angle) {
                cos = Math.cos(-angle);
                sin = Math.sin(-angle);
                t1 = a12 * cos + a13 * sin;
                t2 = a22 * cos + a23 * sin;
                t3 = a32 * cos + a33 * sin;
                a13 = a12 * -sin + a13 * cos;
                a23 = a22 * -sin + a23 * cos;
                a33 = a32 * -sin + a33 * cos;
                a43 = a42 * -sin + a43 * cos;
                a12 = t1;
                a22 = t2;
                a32 = t3;
            }
            angle = _atan2(-c, a33);
            rotationY = angle * _RAD2DEG;
            if (angle) {
                cos = Math.cos(-angle);
                sin = Math.sin(-angle);
                t1 = a * cos - a13 * sin;
                t2 = b * cos - a23 * sin;
                t3 = c * cos - a33 * sin;
                a43 = d * sin + a43 * cos;
                a = t1;
                b = t2;
                c = t3;
            }
            angle = _atan2(b, a);
            rotation = angle * _RAD2DEG;
            if (angle) {
                cos = Math.cos(angle);
                sin = Math.sin(angle);
                t1 = a * cos + b * sin;
                t2 = a12 * cos + a22 * sin;
                b = b * cos - a * sin;
                a22 = a22 * cos - a12 * sin;
                a = t1;
                a12 = t2;
            }
            if (rotationX && Math.abs(rotationX) + Math.abs(rotation) > 359.9) {
                rotationX = rotation = 0;
                rotationY = 180 - rotationY;
            }
            scaleX = _round(Math.sqrt(a * a + b * b + c * c));
            scaleY = _round(Math.sqrt(a22 * a22 + a32 * a32));
            angle = _atan2(a12, a22);
            skewX = Math.abs(angle) > 2e-4 ? angle * _RAD2DEG : 0;
            perspective = a43 ? 1 / (a43 < 0 ? -a43 : a43) : 0;
        }
        if (cache2.svg) {
            t1 = target.getAttribute('transform');
            cache2.forceCSS =
                target.setAttribute('transform', '') ||
                !_isNullTransform(_getComputedProperty(target, _transformProp));
            t1 && target.setAttribute('transform', t1);
        }
    }
    if (Math.abs(skewX) > 90 && Math.abs(skewX) < 270) {
        if (invertedScaleX) {
            scaleX *= -1;
            skewX += rotation <= 0 ? 180 : -180;
            rotation += rotation <= 0 ? 180 : -180;
        } else {
            scaleY *= -1;
            skewX += skewX <= 0 ? 180 : -180;
        }
    }
    uncache = uncache || cache2.uncache;
    cache2.x =
        x -
        ((cache2.xPercent =
            x &&
            ((!uncache && cache2.xPercent) ||
                (Math.round(target.offsetWidth / 2) === Math.round(-x)
                    ? -50
                    : 0)))
            ? (target.offsetWidth * cache2.xPercent) / 100
            : 0) +
        px;
    cache2.y =
        y -
        ((cache2.yPercent =
            y &&
            ((!uncache && cache2.yPercent) ||
                (Math.round(target.offsetHeight / 2) === Math.round(-y)
                    ? -50
                    : 0)))
            ? (target.offsetHeight * cache2.yPercent) / 100
            : 0) +
        px;
    cache2.z = z + px;
    cache2.scaleX = _round(scaleX);
    cache2.scaleY = _round(scaleY);
    cache2.rotation = _round(rotation) + deg;
    cache2.rotationX = _round(rotationX) + deg;
    cache2.rotationY = _round(rotationY) + deg;
    cache2.skewX = skewX + deg;
    cache2.skewY = skewY + deg;
    cache2.transformPerspective = perspective + px;
    if (
        (cache2.zOrigin =
            parseFloat(origin.split(' ')[2]) ||
            (!uncache && cache2.zOrigin) ||
            0)
    ) {
        style[_transformOriginProp] = _firstTwoOnly(origin);
    }
    cache2.xOffset = cache2.yOffset = 0;
    cache2.force3D = _config.force3D;
    cache2.renderTransform = cache2.svg
        ? _renderSVGTransforms
        : _supports3D
        ? _renderCSSTransforms
        : _renderNon3DTransforms;
    cache2.uncache = 0;
    return cache2;
};
var _firstTwoOnly = function _firstTwoOnly2(value) {
    return (value = value.split(' '))[0] + ' ' + value[1];
};
var _addPxTranslate = function _addPxTranslate2(target, start, value) {
    var unit = getUnit(start);
    return (
        _round(
            parseFloat(start) +
                parseFloat(_convertToUnit(target, 'x', value + 'px', unit))
        ) + unit
    );
};
var _renderNon3DTransforms = function _renderNon3DTransforms2(ratio, cache2) {
    cache2.z = '0px';
    cache2.rotationY = cache2.rotationX = '0deg';
    cache2.force3D = 0;
    _renderCSSTransforms(ratio, cache2);
};
var _zeroDeg = '0deg';
var _zeroPx = '0px';
var _endParenthesis = ') ';
var _renderCSSTransforms = function _renderCSSTransforms2(ratio, cache2) {
    var _ref = cache2 || this,
        xPercent = _ref.xPercent,
        yPercent = _ref.yPercent,
        x = _ref.x,
        y = _ref.y,
        z = _ref.z,
        rotation = _ref.rotation,
        rotationY = _ref.rotationY,
        rotationX = _ref.rotationX,
        skewX = _ref.skewX,
        skewY = _ref.skewY,
        scaleX = _ref.scaleX,
        scaleY = _ref.scaleY,
        transformPerspective = _ref.transformPerspective,
        force3D = _ref.force3D,
        target = _ref.target,
        zOrigin = _ref.zOrigin,
        transforms = '',
        use3D =
            (force3D === 'auto' && ratio && ratio !== 1) || force3D === true;
    if (zOrigin && (rotationX !== _zeroDeg || rotationY !== _zeroDeg)) {
        var angle = parseFloat(rotationY) * _DEG2RAD,
            a13 = Math.sin(angle),
            a33 = Math.cos(angle),
            cos;
        angle = parseFloat(rotationX) * _DEG2RAD;
        cos = Math.cos(angle);
        x = _addPxTranslate(target, x, a13 * cos * -zOrigin);
        y = _addPxTranslate(target, y, -Math.sin(angle) * -zOrigin);
        z = _addPxTranslate(target, z, a33 * cos * -zOrigin + zOrigin);
    }
    if (transformPerspective !== _zeroPx) {
        transforms += 'perspective(' + transformPerspective + _endParenthesis;
    }
    if (xPercent || yPercent) {
        transforms += 'translate(' + xPercent + '%, ' + yPercent + '%) ';
    }
    if (use3D || x !== _zeroPx || y !== _zeroPx || z !== _zeroPx) {
        transforms +=
            z !== _zeroPx || use3D
                ? 'translate3d(' + x + ', ' + y + ', ' + z + ') '
                : 'translate(' + x + ', ' + y + _endParenthesis;
    }
    if (rotation !== _zeroDeg) {
        transforms += 'rotate(' + rotation + _endParenthesis;
    }
    if (rotationY !== _zeroDeg) {
        transforms += 'rotateY(' + rotationY + _endParenthesis;
    }
    if (rotationX !== _zeroDeg) {
        transforms += 'rotateX(' + rotationX + _endParenthesis;
    }
    if (skewX !== _zeroDeg || skewY !== _zeroDeg) {
        transforms += 'skew(' + skewX + ', ' + skewY + _endParenthesis;
    }
    if (scaleX !== 1 || scaleY !== 1) {
        transforms += 'scale(' + scaleX + ', ' + scaleY + _endParenthesis;
    }
    target.style[_transformProp] = transforms || 'translate(0, 0)';
};
var _renderSVGTransforms = function _renderSVGTransforms2(ratio, cache2) {
    var _ref2 = cache2 || this,
        xPercent = _ref2.xPercent,
        yPercent = _ref2.yPercent,
        x = _ref2.x,
        y = _ref2.y,
        rotation = _ref2.rotation,
        skewX = _ref2.skewX,
        skewY = _ref2.skewY,
        scaleX = _ref2.scaleX,
        scaleY = _ref2.scaleY,
        target = _ref2.target,
        xOrigin = _ref2.xOrigin,
        yOrigin = _ref2.yOrigin,
        xOffset = _ref2.xOffset,
        yOffset = _ref2.yOffset,
        forceCSS = _ref2.forceCSS,
        tx = parseFloat(x),
        ty = parseFloat(y),
        a11,
        a21,
        a12,
        a22,
        temp;
    rotation = parseFloat(rotation);
    skewX = parseFloat(skewX);
    skewY = parseFloat(skewY);
    if (skewY) {
        skewY = parseFloat(skewY);
        skewX += skewY;
        rotation += skewY;
    }
    if (rotation || skewX) {
        rotation *= _DEG2RAD;
        skewX *= _DEG2RAD;
        a11 = Math.cos(rotation) * scaleX;
        a21 = Math.sin(rotation) * scaleX;
        a12 = Math.sin(rotation - skewX) * -scaleY;
        a22 = Math.cos(rotation - skewX) * scaleY;
        if (skewX) {
            skewY *= _DEG2RAD;
            temp = Math.tan(skewX - skewY);
            temp = Math.sqrt(1 + temp * temp);
            a12 *= temp;
            a22 *= temp;
            if (skewY) {
                temp = Math.tan(skewY);
                temp = Math.sqrt(1 + temp * temp);
                a11 *= temp;
                a21 *= temp;
            }
        }
        a11 = _round(a11);
        a21 = _round(a21);
        a12 = _round(a12);
        a22 = _round(a22);
    } else {
        a11 = scaleX;
        a22 = scaleY;
        a21 = a12 = 0;
    }
    if ((tx && !~(x + '').indexOf('px')) || (ty && !~(y + '').indexOf('px'))) {
        tx = _convertToUnit(target, 'x', x, 'px');
        ty = _convertToUnit(target, 'y', y, 'px');
    }
    if (xOrigin || yOrigin || xOffset || yOffset) {
        tx = _round(tx + xOrigin - (xOrigin * a11 + yOrigin * a12) + xOffset);
        ty = _round(ty + yOrigin - (xOrigin * a21 + yOrigin * a22) + yOffset);
    }
    if (xPercent || yPercent) {
        temp = target.getBBox();
        tx = _round(tx + (xPercent / 100) * temp.width);
        ty = _round(ty + (yPercent / 100) * temp.height);
    }
    temp =
        'matrix(' +
        a11 +
        ',' +
        a21 +
        ',' +
        a12 +
        ',' +
        a22 +
        ',' +
        tx +
        ',' +
        ty +
        ')';
    target.setAttribute('transform', temp);
    forceCSS && (target.style[_transformProp] = temp);
};
var _addRotationalPropTween = function _addRotationalPropTween2(
    plugin,
    target,
    property,
    startNum,
    endValue
) {
    var cap = 360,
        isString2 = _isString(endValue),
        endNum =
            parseFloat(endValue) *
            (isString2 && ~endValue.indexOf('rad') ? _RAD2DEG : 1),
        change = endNum - startNum,
        finalValue = startNum + change + 'deg',
        direction,
        pt;
    if (isString2) {
        direction = endValue.split('_')[1];
        if (direction === 'short') {
            change %= cap;
            if (change !== change % (cap / 2)) {
                change += change < 0 ? cap : -cap;
            }
        }
        if (direction === 'cw' && change < 0) {
            change = ((change + cap * _bigNum2) % cap) - ~~(change / cap) * cap;
        } else if (direction === 'ccw' && change > 0) {
            change = ((change - cap * _bigNum2) % cap) - ~~(change / cap) * cap;
        }
    }
    plugin._pt = pt = new PropTween(
        plugin._pt,
        target,
        property,
        startNum,
        change,
        _renderPropWithEnd
    );
    pt.e = finalValue;
    pt.u = 'deg';
    plugin._props.push(property);
    return pt;
};
var _assign = function _assign2(target, source) {
    for (var p in source) {
        target[p] = source[p];
    }
    return target;
};
var _addRawTransformPTs = function _addRawTransformPTs2(
    plugin,
    transforms,
    target
) {
    var startCache = _assign({}, target._gsap),
        exclude = 'perspective,force3D,transformOrigin,svgOrigin',
        style = target.style,
        endCache,
        p,
        startValue,
        endValue,
        startNum,
        endNum,
        startUnit,
        endUnit;
    if (startCache.svg) {
        startValue = target.getAttribute('transform');
        target.setAttribute('transform', '');
        style[_transformProp] = transforms;
        endCache = _parseTransform(target, 1);
        _removeProperty(target, _transformProp);
        target.setAttribute('transform', startValue);
    } else {
        startValue = getComputedStyle(target)[_transformProp];
        style[_transformProp] = transforms;
        endCache = _parseTransform(target, 1);
        style[_transformProp] = startValue;
    }
    for (p in _transformProps) {
        startValue = startCache[p];
        endValue = endCache[p];
        if (startValue !== endValue && exclude.indexOf(p) < 0) {
            startUnit = getUnit(startValue);
            endUnit = getUnit(endValue);
            startNum =
                startUnit !== endUnit
                    ? _convertToUnit(target, p, startValue, endUnit)
                    : parseFloat(startValue);
            endNum = parseFloat(endValue);
            plugin._pt = new PropTween(
                plugin._pt,
                endCache,
                p,
                startNum,
                endNum - startNum,
                _renderCSSProp
            );
            plugin._pt.u = endUnit || 0;
            plugin._props.push(p);
        }
    }
    _assign(endCache, startCache);
};
_forEachName('padding,margin,Width,Radius', function (name, index) {
    var t2 = 'Top',
        r = 'Right',
        b = 'Bottom',
        l = 'Left',
        props = (
            index < 3 ? [t2, r, b, l] : [t2 + l, t2 + r, b + r, b + l]
        ).map(function (side) {
            return index < 2 ? name + side : 'border' + side + name;
        });
    _specialProps[index > 1 ? 'border' + name : name] = function (
        plugin,
        target,
        property,
        endValue,
        tween
    ) {
        var a, vars;
        if (arguments.length < 4) {
            a = props.map(function (prop) {
                return _get(plugin, prop, property);
            });
            vars = a.join(' ');
            return vars.split(a[0]).length === 5 ? a[0] : vars;
        }
        a = (endValue + '').split(' ');
        vars = {};
        props.forEach(function (prop, i) {
            return (vars[prop] = a[i] = a[i] || a[((i - 1) / 2) | 0]);
        });
        plugin.init(target, vars, tween);
    };
});
var CSSPlugin = {
    name: 'css',
    register: _initCore,
    targetTest: function targetTest(target) {
        return target.style && target.nodeType;
    },
    init: function init3(target, vars, tween, index, targets) {
        var props = this._props,
            style = target.style,
            startAt = tween.vars.startAt,
            startValue,
            endValue,
            endNum,
            startNum,
            type,
            specialProp,
            p,
            startUnit,
            endUnit,
            relative,
            isTransformRelated,
            transformPropTween,
            cache2,
            smooth,
            hasPriority,
            inlineProps;
        _pluginInitted || _initCore();
        this.styles = this.styles || _getStyleSaver(target);
        inlineProps = this.styles.props;
        this.tween = tween;
        for (p in vars) {
            if (p === 'autoRound') {
                continue;
            }
            endValue = vars[p];
            if (
                _plugins[p] &&
                _checkPlugin(p, vars, tween, index, target, targets)
            ) {
                continue;
            }
            type = typeof endValue;
            specialProp = _specialProps[p];
            if (type === 'function') {
                endValue = endValue.call(tween, index, target, targets);
                type = typeof endValue;
            }
            if (type === 'string' && ~endValue.indexOf('random(')) {
                endValue = _replaceRandom(endValue);
            }
            if (specialProp) {
                specialProp(this, target, p, endValue, tween) &&
                    (hasPriority = 1);
            } else if (p.substr(0, 2) === '--') {
                startValue = (
                    getComputedStyle(target).getPropertyValue(p) + ''
                ).trim();
                endValue += '';
                _colorExp.lastIndex = 0;
                if (!_colorExp.test(startValue)) {
                    startUnit = getUnit(startValue);
                    endUnit = getUnit(endValue);
                }
                endUnit
                    ? startUnit !== endUnit &&
                      (startValue =
                          _convertToUnit(target, p, startValue, endUnit) +
                          endUnit)
                    : startUnit && (endValue += startUnit);
                this.add(
                    style,
                    'setProperty',
                    startValue,
                    endValue,
                    index,
                    targets,
                    0,
                    0,
                    p
                );
                props.push(p);
                inlineProps.push(p, 0, style[p]);
            } else if (type !== 'undefined') {
                if (startAt && p in startAt) {
                    startValue =
                        typeof startAt[p] === 'function'
                            ? startAt[p].call(tween, index, target, targets)
                            : startAt[p];
                    _isString(startValue) &&
                        ~startValue.indexOf('random(') &&
                        (startValue = _replaceRandom(startValue));
                    getUnit(startValue + '') ||
                        startValue === 'auto' ||
                        (startValue +=
                            _config.units[p] || getUnit(_get(target, p)) || '');
                    (startValue + '').charAt(1) === '=' &&
                        (startValue = _get(target, p));
                } else {
                    startValue = _get(target, p);
                }
                startNum = parseFloat(startValue);
                relative =
                    type === 'string' &&
                    endValue.charAt(1) === '=' &&
                    endValue.substr(0, 2);
                relative && (endValue = endValue.substr(2));
                endNum = parseFloat(endValue);
                if (p in _propertyAliases) {
                    if (p === 'autoAlpha') {
                        if (
                            startNum === 1 &&
                            _get(target, 'visibility') === 'hidden' &&
                            endNum
                        ) {
                            startNum = 0;
                        }
                        inlineProps.push('visibility', 0, style.visibility);
                        _addNonTweeningPT(
                            this,
                            style,
                            'visibility',
                            startNum ? 'inherit' : 'hidden',
                            endNum ? 'inherit' : 'hidden',
                            !endNum
                        );
                    }
                    if (p !== 'scale' && p !== 'transform') {
                        p = _propertyAliases[p];
                        ~p.indexOf(',') && (p = p.split(',')[0]);
                    }
                }
                isTransformRelated = p in _transformProps;
                if (isTransformRelated) {
                    this.styles.save(p);
                    if (!transformPropTween) {
                        cache2 = target._gsap;
                        (cache2.renderTransform && !vars.parseTransform) ||
                            _parseTransform(target, vars.parseTransform);
                        smooth = vars.smoothOrigin !== false && cache2.smooth;
                        transformPropTween = this._pt = new PropTween(
                            this._pt,
                            style,
                            _transformProp,
                            0,
                            1,
                            cache2.renderTransform,
                            cache2,
                            0,
                            -1
                        );
                        transformPropTween.dep = 1;
                    }
                    if (p === 'scale') {
                        this._pt = new PropTween(
                            this._pt,
                            cache2,
                            'scaleY',
                            cache2.scaleY,
                            (relative
                                ? _parseRelative(
                                      cache2.scaleY,
                                      relative + endNum
                                  )
                                : endNum) - cache2.scaleY || 0,
                            _renderCSSProp
                        );
                        this._pt.u = 0;
                        props.push('scaleY', p);
                        p += 'X';
                    } else if (p === 'transformOrigin') {
                        inlineProps.push(
                            _transformOriginProp,
                            0,
                            style[_transformOriginProp]
                        );
                        endValue = _convertKeywordsToPercentages(endValue);
                        if (cache2.svg) {
                            _applySVGOrigin(
                                target,
                                endValue,
                                0,
                                smooth,
                                0,
                                this
                            );
                        } else {
                            endUnit = parseFloat(endValue.split(' ')[2]) || 0;
                            endUnit !== cache2.zOrigin &&
                                _addNonTweeningPT(
                                    this,
                                    cache2,
                                    'zOrigin',
                                    cache2.zOrigin,
                                    endUnit
                                );
                            _addNonTweeningPT(
                                this,
                                style,
                                p,
                                _firstTwoOnly(startValue),
                                _firstTwoOnly(endValue)
                            );
                        }
                        continue;
                    } else if (p === 'svgOrigin') {
                        _applySVGOrigin(target, endValue, 1, smooth, 0, this);
                        continue;
                    } else if (p in _rotationalProperties) {
                        _addRotationalPropTween(
                            this,
                            cache2,
                            p,
                            startNum,
                            relative
                                ? _parseRelative(startNum, relative + endValue)
                                : endValue
                        );
                        continue;
                    } else if (p === 'smoothOrigin') {
                        _addNonTweeningPT(
                            this,
                            cache2,
                            'smooth',
                            cache2.smooth,
                            endValue
                        );
                        continue;
                    } else if (p === 'force3D') {
                        cache2[p] = endValue;
                        continue;
                    } else if (p === 'transform') {
                        _addRawTransformPTs(this, endValue, target);
                        continue;
                    }
                } else if (!(p in style)) {
                    p = _checkPropPrefix(p) || p;
                }
                if (
                    isTransformRelated ||
                    ((endNum || endNum === 0) &&
                        (startNum || startNum === 0) &&
                        !_complexExp.test(endValue) &&
                        p in style)
                ) {
                    startUnit = (startValue + '').substr(
                        (startNum + '').length
                    );
                    endNum || (endNum = 0);
                    endUnit =
                        getUnit(endValue) ||
                        (p in _config.units ? _config.units[p] : startUnit);
                    startUnit !== endUnit &&
                        (startNum = _convertToUnit(
                            target,
                            p,
                            startValue,
                            endUnit
                        ));
                    this._pt = new PropTween(
                        this._pt,
                        isTransformRelated ? cache2 : style,
                        p,
                        startNum,
                        (relative
                            ? _parseRelative(startNum, relative + endNum)
                            : endNum) - startNum,
                        !isTransformRelated &&
                        (endUnit === 'px' || p === 'zIndex') &&
                        vars.autoRound !== false
                            ? _renderRoundedCSSProp
                            : _renderCSSProp
                    );
                    this._pt.u = endUnit || 0;
                    if (startUnit !== endUnit && endUnit !== '%') {
                        this._pt.b = startValue;
                        this._pt.r = _renderCSSPropWithBeginning;
                    }
                } else if (!(p in style)) {
                    if (p in target) {
                        this.add(
                            target,
                            p,
                            startValue || target[p],
                            relative ? relative + endValue : endValue,
                            index,
                            targets
                        );
                    } else if (p !== 'parseTransform') {
                        _missingPlugin(p, endValue);
                        continue;
                    }
                } else {
                    _tweenComplexCSSString.call(
                        this,
                        target,
                        p,
                        startValue,
                        relative ? relative + endValue : endValue
                    );
                }
                isTransformRelated ||
                    (p in style
                        ? inlineProps.push(p, 0, style[p])
                        : inlineProps.push(p, 1, startValue || target[p]));
                props.push(p);
            }
        }
        hasPriority && _sortPropTweensByPriority(this);
    },
    render: function render2(ratio, data) {
        if (data.tween._time || !_reverting2()) {
            var pt = data._pt;
            while (pt) {
                pt.r(ratio, pt.d);
                pt = pt._next;
            }
        } else {
            data.styles.revert();
        }
    },
    get: _get,
    aliases: _propertyAliases,
    getSetter: function getSetter(target, property, plugin) {
        var p = _propertyAliases[property];
        p && p.indexOf(',') < 0 && (property = p);
        return property in _transformProps &&
            property !== _transformOriginProp &&
            (target._gsap.x || _get(target, 'x'))
            ? plugin && _recentSetterPlugin === plugin
                ? property === 'scale'
                    ? _setterScale
                    : _setterTransform
                : (_recentSetterPlugin = plugin || {}) &&
                  (property === 'scale'
                      ? _setterScaleWithRender
                      : _setterTransformWithRender)
            : target.style && !_isUndefined(target.style[property])
            ? _setterCSSStyle
            : ~property.indexOf('-')
            ? _setterCSSProp
            : _getSetter(target, property);
    },
    core: {
        _removeProperty,
        _getMatrix,
    },
};
gsap.utils.checkPrefix = _checkPropPrefix;
gsap.core.getStyleSaver = _getStyleSaver;
(function (positionAndScale, rotation, others, aliases) {
    var all = _forEachName(
        positionAndScale + ',' + rotation + ',' + others,
        function (name) {
            _transformProps[name] = 1;
        }
    );
    _forEachName(rotation, function (name) {
        _config.units[name] = 'deg';
        _rotationalProperties[name] = 1;
    });
    _propertyAliases[all[13]] = positionAndScale + ',' + rotation;
    _forEachName(aliases, function (name) {
        var split2 = name.split(':');
        _propertyAliases[split2[1]] = all[split2[0]];
    });
})(
    'x,y,z,scale,scaleX,scaleY,xPercent,yPercent',
    'rotation,rotationX,rotationY,skewX,skewY',
    'transform,transformOrigin,svgOrigin,force3D,smoothOrigin,transformPerspective',
    '0:translateX,1:translateY,2:translateZ,8:rotate,8:rotationZ,8:rotateZ,9:rotateX,10:rotateY'
);
_forEachName(
    'x,y,z,top,right,bottom,left,width,height,fontSize,padding,margin,perspective',
    function (name) {
        _config.units[name] = 'px';
    }
);
gsap.registerPlugin(CSSPlugin);

// node_modules/gsap/index.js
var gsapWithCSS = gsap.registerPlugin(CSSPlugin) || gsap;
var TweenMaxWithCSS = gsapWithCSS.core.Tween;

// global.js
var import_ScrollTrigger = __toESM(require_ScrollTrigger());

// node_modules/@studio-freight/lenis/dist/lenis.mjs
function t(t2, e2, i) {
    return Math.max(t2, Math.min(e2, i));
}
var Animate = class {
    advance(e2) {
        if (!this.isRunning) return;
        let i = false;
        if (this.lerp)
            (this.value =
                ((s = this.value),
                (o = this.to),
                (n = 60 * this.lerp),
                (r = e2),
                (function (t2, e3, i2) {
                    return (1 - i2) * t2 + i2 * e3;
                })(s, o, 1 - Math.exp(-n * r)))),
                Math.round(this.value) === this.to &&
                    ((this.value = this.to), (i = true));
        else {
            this.currentTime += e2;
            const s2 = t(0, this.currentTime / this.duration, 1);
            i = s2 >= 1;
            const o2 = i ? 1 : this.easing(s2);
            this.value = this.from + (this.to - this.from) * o2;
        }
        var s, o, n, r;
        this.onUpdate?.(this.value, i), i && this.stop();
    }
    stop() {
        this.isRunning = false;
    }
    fromTo(
        t2,
        e2,
        {
            lerp: i = 0.1,
            duration: s = 1,
            easing: o = (t3) => t3,
            onStart: n,
            onUpdate: r,
        }
    ) {
        (this.from = this.value = t2),
            (this.to = e2),
            (this.lerp = i),
            (this.duration = s),
            (this.easing = o),
            (this.currentTime = 0),
            (this.isRunning = true),
            n?.(),
            (this.onUpdate = r);
    }
};
var Dimensions = class {
    constructor({
        wrapper: t2,
        content: e2,
        autoResize: i = true,
        debounce: s = 250,
    } = {}) {
        (this.wrapper = t2),
            (this.content = e2),
            i &&
                ((this.debouncedResize = /* @__PURE__ */ (function (t3, e3) {
                    let i2;
                    return function () {
                        let s2 = arguments,
                            o = this;
                        clearTimeout(i2),
                            (i2 = setTimeout(function () {
                                t3.apply(o, s2);
                            }, e3));
                    };
                })(this.resize, s)),
                this.wrapper === window
                    ? window.addEventListener(
                          'resize',
                          this.debouncedResize,
                          false
                      )
                    : ((this.wrapperResizeObserver = new ResizeObserver(
                          this.debouncedResize
                      )),
                      this.wrapperResizeObserver.observe(this.wrapper)),
                (this.contentResizeObserver = new ResizeObserver(
                    this.debouncedResize
                )),
                this.contentResizeObserver.observe(this.content)),
            this.resize();
    }
    destroy() {
        this.wrapperResizeObserver?.disconnect(),
            this.contentResizeObserver?.disconnect(),
            window.removeEventListener('resize', this.debouncedResize, false);
    }
    resize = () => {
        this.onWrapperResize(), this.onContentResize();
    };
    onWrapperResize = () => {
        this.wrapper === window
            ? ((this.width = window.innerWidth),
              (this.height = window.innerHeight))
            : ((this.width = this.wrapper.clientWidth),
              (this.height = this.wrapper.clientHeight));
    };
    onContentResize = () => {
        this.wrapper === window
            ? ((this.scrollHeight = this.content.scrollHeight),
              (this.scrollWidth = this.content.scrollWidth))
            : ((this.scrollHeight = this.wrapper.scrollHeight),
              (this.scrollWidth = this.wrapper.scrollWidth));
    };
    get limit() {
        return {
            x: this.scrollWidth - this.width,
            y: this.scrollHeight - this.height,
        };
    }
};
var Emitter = class {
    constructor() {
        this.events = {};
    }
    emit(t2, ...e2) {
        let i = this.events[t2] || [];
        for (let t3 = 0, s = i.length; t3 < s; t3++) i[t3](...e2);
    }
    on(t2, e2) {
        return (
            this.events[t2]?.push(e2) || (this.events[t2] = [e2]),
            () => {
                this.events[t2] = this.events[t2]?.filter((t3) => e2 !== t3);
            }
        );
    }
    off(t2, e2) {
        this.events[t2] = this.events[t2]?.filter((t3) => e2 !== t3);
    }
    destroy() {
        this.events = {};
    }
};
var e = 100 / 6;
var VirtualScroll = class {
    constructor(t2, { wheelMultiplier: e2 = 1, touchMultiplier: i = 1 }) {
        (this.element = t2),
            (this.wheelMultiplier = e2),
            (this.touchMultiplier = i),
            (this.touchStart = { x: null, y: null }),
            (this.emitter = new Emitter()),
            window.addEventListener('resize', this.onWindowResize, false),
            this.onWindowResize(),
            this.element.addEventListener('wheel', this.onWheel, {
                passive: false,
            }),
            this.element.addEventListener('touchstart', this.onTouchStart, {
                passive: false,
            }),
            this.element.addEventListener('touchmove', this.onTouchMove, {
                passive: false,
            }),
            this.element.addEventListener('touchend', this.onTouchEnd, {
                passive: false,
            });
    }
    on(t2, e2) {
        return this.emitter.on(t2, e2);
    }
    destroy() {
        this.emitter.destroy(),
            window.removeEventListener('resize', this.onWindowResize, false),
            this.element.removeEventListener('wheel', this.onWheel, {
                passive: false,
            }),
            this.element.removeEventListener('touchstart', this.onTouchStart, {
                passive: false,
            }),
            this.element.removeEventListener('touchmove', this.onTouchMove, {
                passive: false,
            }),
            this.element.removeEventListener('touchend', this.onTouchEnd, {
                passive: false,
            });
    }
    onTouchStart = (t2) => {
        const { clientX: e2, clientY: i } = t2.targetTouches
            ? t2.targetTouches[0]
            : t2;
        (this.touchStart.x = e2),
            (this.touchStart.y = i),
            (this.lastDelta = { x: 0, y: 0 }),
            this.emitter.emit('scroll', { deltaX: 0, deltaY: 0, event: t2 });
    };
    onTouchMove = (t2) => {
        const { clientX: e2, clientY: i } = t2.targetTouches
                ? t2.targetTouches[0]
                : t2,
            s = -(e2 - this.touchStart.x) * this.touchMultiplier,
            o = -(i - this.touchStart.y) * this.touchMultiplier;
        (this.touchStart.x = e2),
            (this.touchStart.y = i),
            (this.lastDelta = { x: s, y: o }),
            this.emitter.emit('scroll', { deltaX: s, deltaY: o, event: t2 });
    };
    onTouchEnd = (t2) => {
        this.emitter.emit('scroll', {
            deltaX: this.lastDelta.x,
            deltaY: this.lastDelta.y,
            event: t2,
        });
    };
    onWheel = (t2) => {
        let { deltaX: i, deltaY: s, deltaMode: o } = t2;
        (i *= 1 === o ? e : 2 === o ? this.windowWidth : 1),
            (s *= 1 === o ? e : 2 === o ? this.windowHeight : 1),
            (i *= this.wheelMultiplier),
            (s *= this.wheelMultiplier),
            this.emitter.emit('scroll', { deltaX: i, deltaY: s, event: t2 });
    };
    onWindowResize = () => {
        (this.windowWidth = window.innerWidth),
            (this.windowHeight = window.innerHeight);
    };
};
var Lenis = class {
    constructor({
        wrapper: t2 = window,
        content: e2 = document.documentElement,
        wheelEventsTarget: i = t2,
        eventsTarget: s = i,
        smoothWheel: o = true,
        syncTouch: n = false,
        syncTouchLerp: r = 0.075,
        touchInertiaMultiplier: l = 35,
        duration: h,
        easing: a = (t3) => Math.min(1, 1.001 - Math.pow(2, -10 * t3)),
        lerp: c = !h && 0.1,
        infinite: d = false,
        orientation: p = 'vertical',
        gestureOrientation: u = 'vertical',
        touchMultiplier: m = 1,
        wheelMultiplier: v = 1,
        autoResize: g = true,
        __experimental__naiveDimensions: S = false,
    } = {}) {
        (this.__isSmooth = false),
            (this.__isScrolling = false),
            (this.__isStopped = false),
            (this.__isLocked = false),
            (this.onVirtualScroll = ({ deltaX: t3, deltaY: e3, event: i2 }) => {
                if (i2.ctrlKey) return;
                const s2 = i2.type.includes('touch'),
                    o2 = i2.type.includes('wheel');
                if (
                    this.options.syncTouch &&
                    s2 &&
                    'touchstart' === i2.type &&
                    !this.isStopped &&
                    !this.isLocked
                )
                    return void this.reset();
                const n2 = 0 === t3 && 0 === e3,
                    r2 =
                        ('vertical' === this.options.gestureOrientation &&
                            0 === e3) ||
                        ('horizontal' === this.options.gestureOrientation &&
                            0 === t3);
                if (n2 || r2) return;
                let l2 = i2.composedPath();
                if (
                    ((l2 = l2.slice(0, l2.indexOf(this.rootElement))),
                    l2.find((t4) => {
                        var e4, i3, n3, r3, l3;
                        return (
                            (null === (e4 = t4.hasAttribute) || void 0 === e4
                                ? void 0
                                : e4.call(t4, 'data-lenis-prevent')) ||
                            (s2 &&
                                (null === (i3 = t4.hasAttribute) ||
                                void 0 === i3
                                    ? void 0
                                    : i3.call(
                                          t4,
                                          'data-lenis-prevent-touch'
                                      ))) ||
                            (o2 &&
                                (null === (n3 = t4.hasAttribute) ||
                                void 0 === n3
                                    ? void 0
                                    : n3.call(
                                          t4,
                                          'data-lenis-prevent-wheel'
                                      ))) ||
                            ((null === (r3 = t4.classList) || void 0 === r3
                                ? void 0
                                : r3.contains('lenis')) &&
                                !(null === (l3 = t4.classList) || void 0 === l3
                                    ? void 0
                                    : l3.contains('lenis-stopped')))
                        );
                    }))
                )
                    return;
                if (this.isStopped || this.isLocked)
                    return void i2.preventDefault();
                if (
                    ((this.isSmooth =
                        (this.options.syncTouch && s2) ||
                        (this.options.smoothWheel && o2)),
                    !this.isSmooth)
                )
                    return (this.isScrolling = false), void this.animate.stop();
                i2.preventDefault();
                let h2 = e3;
                'both' === this.options.gestureOrientation
                    ? (h2 = Math.abs(e3) > Math.abs(t3) ? e3 : t3)
                    : 'horizontal' === this.options.gestureOrientation &&
                      (h2 = t3);
                const a2 = s2 && this.options.syncTouch,
                    c2 = s2 && 'touchend' === i2.type && Math.abs(h2) > 5;
                c2 &&
                    (h2 = this.velocity * this.options.touchInertiaMultiplier),
                    this.scrollTo(
                        this.targetScroll + h2,
                        Object.assign(
                            { programmatic: false },
                            a2
                                ? { lerp: c2 ? this.options.syncTouchLerp : 1 }
                                : {
                                      lerp: this.options.lerp,
                                      duration: this.options.duration,
                                      easing: this.options.easing,
                                  }
                        )
                    );
            }),
            (this.onNativeScroll = () => {
                if (!this.__preventNextScrollEvent && !this.isScrolling) {
                    const t3 = this.animatedScroll;
                    (this.animatedScroll = this.targetScroll =
                        this.actualScroll),
                        (this.velocity = 0),
                        (this.direction = Math.sign(this.animatedScroll - t3)),
                        this.emit();
                }
            }),
            (window.lenisVersion = '1.0.42'),
            (t2 !== document.documentElement && t2 !== document.body) ||
                (t2 = window),
            (this.options = {
                wrapper: t2,
                content: e2,
                wheelEventsTarget: i,
                eventsTarget: s,
                smoothWheel: o,
                syncTouch: n,
                syncTouchLerp: r,
                touchInertiaMultiplier: l,
                duration: h,
                easing: a,
                lerp: c,
                infinite: d,
                gestureOrientation: u,
                orientation: p,
                touchMultiplier: m,
                wheelMultiplier: v,
                autoResize: g,
                __experimental__naiveDimensions: S,
            }),
            (this.animate = new Animate()),
            (this.emitter = new Emitter()),
            (this.dimensions = new Dimensions({
                wrapper: t2,
                content: e2,
                autoResize: g,
            })),
            this.toggleClassName('lenis', true),
            (this.velocity = 0),
            (this.isLocked = false),
            (this.isStopped = false),
            (this.isSmooth = n || o),
            (this.isScrolling = false),
            (this.targetScroll = this.animatedScroll = this.actualScroll),
            this.options.wrapper.addEventListener(
                'scroll',
                this.onNativeScroll,
                false
            ),
            (this.virtualScroll = new VirtualScroll(s, {
                touchMultiplier: m,
                wheelMultiplier: v,
            })),
            this.virtualScroll.on('scroll', this.onVirtualScroll);
    }
    destroy() {
        this.emitter.destroy(),
            this.options.wrapper.removeEventListener(
                'scroll',
                this.onNativeScroll,
                false
            ),
            this.virtualScroll.destroy(),
            this.dimensions.destroy(),
            this.toggleClassName('lenis', false),
            this.toggleClassName('lenis-smooth', false),
            this.toggleClassName('lenis-scrolling', false),
            this.toggleClassName('lenis-stopped', false),
            this.toggleClassName('lenis-locked', false);
    }
    on(t2, e2) {
        return this.emitter.on(t2, e2);
    }
    off(t2, e2) {
        return this.emitter.off(t2, e2);
    }
    setScroll(t2) {
        this.isHorizontal
            ? (this.rootElement.scrollLeft = t2)
            : (this.rootElement.scrollTop = t2);
    }
    resize() {
        this.dimensions.resize();
    }
    emit() {
        this.emitter.emit('scroll', this);
    }
    reset() {
        (this.isLocked = false),
            (this.isScrolling = false),
            (this.animatedScroll = this.targetScroll = this.actualScroll),
            (this.velocity = 0),
            this.animate.stop();
    }
    start() {
        this.isStopped && ((this.isStopped = false), this.reset());
    }
    stop() {
        this.isStopped ||
            ((this.isStopped = true), this.animate.stop(), this.reset());
    }
    raf(t2) {
        const e2 = t2 - (this.time || t2);
        (this.time = t2), this.animate.advance(1e-3 * e2);
    }
    scrollTo(
        e2,
        {
            offset: i = 0,
            immediate: s = false,
            lock: o = false,
            duration: n = this.options.duration,
            easing: r = this.options.easing,
            lerp: l = !n && this.options.lerp,
            onComplete: h,
            force: a = false,
            programmatic: c = true,
        } = {}
    ) {
        if ((!this.isStopped && !this.isLocked) || a) {
            if (['top', 'left', 'start'].includes(e2)) e2 = 0;
            else if (['bottom', 'right', 'end'].includes(e2)) e2 = this.limit;
            else {
                let t2;
                if (
                    ('string' == typeof e2
                        ? (t2 = document.querySelector(e2))
                        : (null == e2 ? void 0 : e2.nodeType) && (t2 = e2),
                    t2)
                ) {
                    if (this.options.wrapper !== window) {
                        const t3 = this.options.wrapper.getBoundingClientRect();
                        i -= this.isHorizontal ? t3.left : t3.top;
                    }
                    const s2 = t2.getBoundingClientRect();
                    e2 =
                        (this.isHorizontal ? s2.left : s2.top) +
                        this.animatedScroll;
                }
            }
            if ('number' == typeof e2) {
                if (
                    ((e2 += i),
                    (e2 = Math.round(e2)),
                    this.options.infinite
                        ? c &&
                          (this.targetScroll = this.animatedScroll =
                              this.scroll)
                        : (e2 = t(0, e2, this.limit)),
                    s)
                )
                    return (
                        (this.animatedScroll = this.targetScroll = e2),
                        this.setScroll(this.scroll),
                        this.reset(),
                        void (null == h || h(this))
                    );
                if (!c) {
                    if (e2 === this.targetScroll) return;
                    this.targetScroll = e2;
                }
                this.animate.fromTo(this.animatedScroll, e2, {
                    duration: n,
                    easing: r,
                    lerp: l,
                    onStart: () => {
                        o && (this.isLocked = true), (this.isScrolling = true);
                    },
                    onUpdate: (t2, e3) => {
                        (this.isScrolling = true),
                            (this.velocity = t2 - this.animatedScroll),
                            (this.direction = Math.sign(this.velocity)),
                            (this.animatedScroll = t2),
                            this.setScroll(this.scroll),
                            c && (this.targetScroll = t2),
                            e3 || this.emit(),
                            e3 &&
                                (this.reset(),
                                this.emit(),
                                null == h || h(this),
                                (this.__preventNextScrollEvent = true),
                                requestAnimationFrame(() => {
                                    delete this.__preventNextScrollEvent;
                                }));
                    },
                });
            }
        }
    }
    get rootElement() {
        return this.options.wrapper === window
            ? document.documentElement
            : this.options.wrapper;
    }
    get limit() {
        return this.options.__experimental__naiveDimensions
            ? this.isHorizontal
                ? this.rootElement.scrollWidth - this.rootElement.clientWidth
                : this.rootElement.scrollHeight - this.rootElement.clientHeight
            : this.dimensions.limit[this.isHorizontal ? 'x' : 'y'];
    }
    get isHorizontal() {
        return 'horizontal' === this.options.orientation;
    }
    get actualScroll() {
        return this.isHorizontal
            ? this.rootElement.scrollLeft
            : this.rootElement.scrollTop;
    }
    get scroll() {
        return this.options.infinite
            ? ((t2 = this.animatedScroll),
              (e2 = this.limit),
              ((t2 % e2) + e2) % e2)
            : this.animatedScroll;
        var t2, e2;
    }
    get progress() {
        return 0 === this.limit ? 1 : this.scroll / this.limit;
    }
    get isSmooth() {
        return this.__isSmooth;
    }
    set isSmooth(t2) {
        this.__isSmooth !== t2 &&
            ((this.__isSmooth = t2), this.toggleClassName('lenis-smooth', t2));
    }
    get isScrolling() {
        return this.__isScrolling;
    }
    set isScrolling(t2) {
        this.__isScrolling !== t2 &&
            ((this.__isScrolling = t2),
            this.toggleClassName('lenis-scrolling', t2));
    }
    get isStopped() {
        return this.__isStopped;
    }
    set isStopped(t2) {
        this.__isStopped !== t2 &&
            ((this.__isStopped = t2),
            this.toggleClassName('lenis-stopped', t2));
    }
    get isLocked() {
        return this.__isLocked;
    }
    set isLocked(t2) {
        this.__isLocked !== t2 &&
            ((this.__isLocked = t2), this.toggleClassName('lenis-locked', t2));
    }
    get className() {
        let t2 = 'lenis';
        return (
            this.isStopped && (t2 += ' lenis-stopped'),
            this.isLocked && (t2 += ' lenis-locked'),
            this.isScrolling && (t2 += ' lenis-scrolling'),
            this.isSmooth && (t2 += ' lenis-smooth'),
            t2
        );
    }
    toggleClassName(t2, e2) {
        this.rootElement.classList.toggle(t2, e2),
            this.emitter.emit('className change', this);
    }
};

// node_modules/split-type/dist/index.js
(function () {
    function append() {
        var length = arguments.length;
        for (var i = 0; i < length; i++) {
            var node = i < 0 || arguments.length <= i ? void 0 : arguments[i];
            if (node.nodeType === 1 || node.nodeType === 11)
                this.appendChild(node);
            else this.appendChild(document.createTextNode(String(node)));
        }
    }
    function replaceChildren() {
        while (this.lastChild) {
            this.removeChild(this.lastChild);
        }
        if (arguments.length) this.append.apply(this, arguments);
    }
    function replaceWith() {
        var parent = this.parentNode;
        for (
            var _len = arguments.length, nodes = new Array(_len), _key = 0;
            _key < _len;
            _key++
        ) {
            nodes[_key] = arguments[_key];
        }
        var i = nodes.length;
        if (!parent) return;
        if (!i) parent.removeChild(this);
        while (i--) {
            var node = nodes[i];
            if (typeof node !== 'object') {
                node = this.ownerDocument.createTextNode(node);
            } else if (node.parentNode) {
                node.parentNode.removeChild(node);
            }
            if (!i) {
                parent.replaceChild(node, this);
            } else {
                parent.insertBefore(this.previousSibling, node);
            }
        }
    }
    if (typeof Element !== 'undefined') {
        if (!Element.prototype.append) {
            Element.prototype.append = append;
            DocumentFragment.prototype.append = append;
        }
        if (!Element.prototype.replaceChildren) {
            Element.prototype.replaceChildren = replaceChildren;
            DocumentFragment.prototype.replaceChildren = replaceChildren;
        }
        if (!Element.prototype.replaceWith) {
            Element.prototype.replaceWith = replaceWith;
            DocumentFragment.prototype.replaceWith = replaceWith;
        }
    }
})();
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps) _defineProperties(Constructor.prototype, protoProps);
    if (staticProps) _defineProperties(Constructor, staticProps);
    return Constructor;
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value,
            enumerable: true,
            configurable: true,
            writable: true,
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly)
            symbols = symbols.filter(function (sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpread2(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i] != null ? arguments[i] : {};
        if (i % 2) {
            ownKeys(Object(source), true).forEach(function (key) {
                _defineProperty(target, key, source[key]);
            });
        } else if (Object.getOwnPropertyDescriptors) {
            Object.defineProperties(
                target,
                Object.getOwnPropertyDescriptors(source)
            );
        } else {
            ownKeys(Object(source)).forEach(function (key) {
                Object.defineProperty(
                    target,
                    key,
                    Object.getOwnPropertyDescriptor(source, key)
                );
            });
        }
    }
    return target;
}
function _slicedToArray(arr, i) {
    return (
        _arrayWithHoles(arr) ||
        _iterableToArrayLimit(arr, i) ||
        _unsupportedIterableToArray(arr, i) ||
        _nonIterableRest()
    );
}
function _toConsumableArray(arr) {
    return (
        _arrayWithoutHoles(arr) ||
        _iterableToArray(arr) ||
        _unsupportedIterableToArray(arr) ||
        _nonIterableSpread()
    );
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterableToArray(iter) {
    if (typeof Symbol !== 'undefined' && Symbol.iterator in Object(iter))
        return Array.from(iter);
}
function _iterableToArrayLimit(arr, i) {
    if (typeof Symbol === 'undefined' || !(Symbol.iterator in Object(arr)))
        return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = void 0;
    try {
        for (
            var _i = arr[Symbol.iterator](), _s;
            !(_n = (_s = _i.next()).done);
            _n = true
        ) {
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally {
        try {
            if (!_n && _i['return'] != null) _i['return']();
        } finally {
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === 'string') return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === 'Object' && o.constructor) n = o.constructor.name;
    if (n === 'Map' || n === 'Set') return Array.from(o);
    if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
        return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
    return arr2;
}
function _nonIterableSpread() {
    throw new TypeError(
        'Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.'
    );
}
function _nonIterableRest() {
    throw new TypeError(
        'Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.'
    );
}
function extend(target, object) {
    return Object.getOwnPropertyNames(Object(target)).reduce(function (
        extended,
        key
    ) {
        var currentValue = Object.getOwnPropertyDescriptor(Object(target), key);
        var newValue = Object.getOwnPropertyDescriptor(Object(object), key);
        return Object.defineProperty(extended, key, newValue || currentValue);
    },
    {});
}
function isString(value) {
    return typeof value === 'string';
}
function isArray(value) {
    return Array.isArray(value);
}
function parseSettings() {
    var settings =
        arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    var object = extend(settings);
    var types;
    if (object.types !== void 0) {
        types = object.types;
    } else if (object.split !== void 0) {
        types = object.split;
    }
    if (types !== void 0) {
        object.types = (isString(types) || isArray(types) ? String(types) : '')
            .split(',')
            .map(function (type) {
                return String(type).trim();
            })
            .filter(function (type) {
                return /((line)|(word)|(char))/i.test(type);
            });
    }
    if (object.absolute || object.position) {
        object.absolute = object.absolute || /absolute/.test(settings.position);
    }
    return object;
}
function parseTypes(value) {
    var types = isString(value) || isArray(value) ? String(value) : '';
    return {
        none: !types,
        lines: /line/i.test(types),
        words: /word/i.test(types),
        chars: /char/i.test(types),
    };
}
function isObject(value) {
    return value !== null && typeof value === 'object';
}
function isNode(input) {
    return isObject(input) && /^(1|3|11)$/.test(input.nodeType);
}
function isLength(value) {
    return typeof value === 'number' && value > -1 && value % 1 === 0;
}
function isArrayLike(value) {
    return isObject(value) && isLength(value.length);
}
function toArray3(value) {
    if (isArray(value)) return value;
    if (value == null) return [];
    return isArrayLike(value) ? Array.prototype.slice.call(value) : [value];
}
function getTargetElements(target) {
    var elements = target;
    if (isString(target)) {
        if (/^(#[a-z]\w+)$/.test(target.trim())) {
            elements = document.getElementById(target.trim().slice(1));
        } else {
            elements = document.querySelectorAll(target);
        }
    }
    return toArray3(elements).reduce(function (result, element) {
        return [].concat(
            _toConsumableArray(result),
            _toConsumableArray(toArray3(element).filter(isNode))
        );
    }, []);
}
var entries = Object.entries;
var expando = '_splittype';
var cache = {};
var uid = 0;
function set(owner, key, value) {
    if (!isObject(owner)) {
        console.warn('[data.set] owner is not an object');
        return null;
    }
    var id = owner[expando] || (owner[expando] = ++uid);
    var data = cache[id] || (cache[id] = {});
    if (value === void 0) {
        if (!!key && Object.getPrototypeOf(key) === Object.prototype) {
            cache[id] = _objectSpread2(_objectSpread2({}, data), key);
        }
    } else if (key !== void 0) {
        data[key] = value;
    }
    return value;
}
function get(owner, key) {
    var id = isObject(owner) ? owner[expando] : null;
    var data = (id && cache[id]) || {};
    if (key === void 0) {
        return data;
    }
    return data[key];
}
function remove(element) {
    var id = element && element[expando];
    if (id) {
        delete element[id];
        delete cache[id];
    }
}
function clear() {
    Object.keys(cache).forEach(function (key) {
        delete cache[key];
    });
}
function cleanup() {
    entries(cache).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            id = _ref2[0],
            _ref2$ = _ref2[1],
            isRoot = _ref2$.isRoot,
            isSplit = _ref2$.isSplit;
        if (!isRoot || !isSplit) {
            cache[id] = null;
            delete cache[id];
        }
    });
}
function toWords(value) {
    var separator =
        arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : ' ';
    var string = value ? String(value) : '';
    return string.trim().replace(/\s+/g, ' ').split(separator);
}
var rsAstralRange = '\\ud800-\\udfff';
var rsComboMarksRange = '\\u0300-\\u036f\\ufe20-\\ufe23';
var rsComboSymbolsRange = '\\u20d0-\\u20f0';
var rsVarRange = '\\ufe0e\\ufe0f';
var rsAstral = '['.concat(rsAstralRange, ']');
var rsCombo = '['.concat(rsComboMarksRange).concat(rsComboSymbolsRange, ']');
var rsFitz = '\\ud83c[\\udffb-\\udfff]';
var rsModifier = '(?:'.concat(rsCombo, '|').concat(rsFitz, ')');
var rsNonAstral = '[^'.concat(rsAstralRange, ']');
var rsRegional = '(?:\\ud83c[\\udde6-\\uddff]){2}';
var rsSurrPair = '[\\ud800-\\udbff][\\udc00-\\udfff]';
var rsZWJ = '\\u200d';
var reOptMod = ''.concat(rsModifier, '?');
var rsOptVar = '['.concat(rsVarRange, ']?');
var rsOptJoin =
    '(?:' +
    rsZWJ +
    '(?:' +
    [rsNonAstral, rsRegional, rsSurrPair].join('|') +
    ')' +
    rsOptVar +
    reOptMod +
    ')*';
var rsSeq = rsOptVar + reOptMod + rsOptJoin;
var rsSymbol = '(?:'.concat(
    [
        ''.concat(rsNonAstral).concat(rsCombo, '?'),
        rsCombo,
        rsRegional,
        rsSurrPair,
        rsAstral,
    ].join('|'),
    '\n)'
);
var reUnicode = RegExp(
    ''
        .concat(rsFitz, '(?=')
        .concat(rsFitz, ')|')
        .concat(rsSymbol)
        .concat(rsSeq),
    'g'
);
var unicodeRange = [
    rsZWJ,
    rsAstralRange,
    rsComboMarksRange,
    rsComboSymbolsRange,
    rsVarRange,
];
var reHasUnicode = RegExp('['.concat(unicodeRange.join(''), ']'));
function asciiToArray(string) {
    return string.split('');
}
function hasUnicode(string) {
    return reHasUnicode.test(string);
}
function unicodeToArray(string) {
    return string.match(reUnicode) || [];
}
function stringToArray(string) {
    return hasUnicode(string) ? unicodeToArray(string) : asciiToArray(string);
}
function toString(value) {
    return value == null ? '' : String(value);
}
function toChars(string) {
    var separator =
        arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '';
    string = toString(string);
    if (string && isString(string)) {
        if (!separator && hasUnicode(string)) {
            return stringToArray(string);
        }
    }
    return string.split(separator);
}
function createElement(name, attributes) {
    var element = document.createElement(name);
    if (!attributes) {
        return element;
    }
    Object.keys(attributes).forEach(function (attribute) {
        var rawValue = attributes[attribute];
        var value = isString(rawValue) ? rawValue.trim() : rawValue;
        if (value === null || value === '') return;
        if (attribute === 'children') {
            element.append.apply(element, _toConsumableArray(toArray3(value)));
        } else {
            element.setAttribute(attribute, value);
        }
    });
    return element;
}
var defaults2 = {
    splitClass: '',
    lineClass: 'line',
    wordClass: 'word',
    charClass: 'char',
    types: ['lines', 'words', 'chars'],
    absolute: false,
    tagName: 'div',
};
function splitWordsAndChars(textNode, settings) {
    settings = extend(defaults2, settings);
    var types = parseTypes(settings.types);
    var TAG_NAME = settings.tagName;
    var VALUE = textNode.nodeValue;
    var splitText = document.createDocumentFragment();
    var words = [];
    var chars = [];
    if (/^\s/.test(VALUE)) {
        splitText.append(' ');
    }
    words = toWords(VALUE).reduce(function (result, WORD, idx, arr) {
        var wordElement;
        var characterElementsForCurrentWord;
        if (types.chars) {
            characterElementsForCurrentWord = toChars(WORD).map(function (
                CHAR
            ) {
                var characterElement = createElement(TAG_NAME, {
                    class: ''
                        .concat(settings.splitClass, ' ')
                        .concat(settings.charClass),
                    style: 'display: inline-block;',
                    children: CHAR,
                });
                set(characterElement, 'isChar', true);
                chars = [].concat(_toConsumableArray(chars), [
                    characterElement,
                ]);
                return characterElement;
            });
        }
        if (types.words || types.lines) {
            wordElement = createElement(TAG_NAME, {
                class: ''
                    .concat(settings.wordClass, ' ')
                    .concat(settings.splitClass),
                style: 'display: inline-block; '.concat(
                    types.words && settings.absolute
                        ? 'position: relative;'
                        : ''
                ),
                children: types.chars ? characterElementsForCurrentWord : WORD,
            });
            set(wordElement, {
                isWord: true,
                isWordStart: true,
                isWordEnd: true,
            });
            splitText.appendChild(wordElement);
        } else {
            characterElementsForCurrentWord.forEach(function (
                characterElement
            ) {
                splitText.appendChild(characterElement);
            });
        }
        if (idx < arr.length - 1) {
            splitText.append(' ');
        }
        return types.words ? result.concat(wordElement) : result;
    }, []);
    if (/\s$/.test(VALUE)) {
        splitText.append(' ');
    }
    textNode.replaceWith(splitText);
    return {
        words,
        chars,
    };
}
function split(node, settings) {
    var type = node.nodeType;
    var wordsAndChars = {
        words: [],
        chars: [],
    };
    if (!/(1|3|11)/.test(type)) {
        return wordsAndChars;
    }
    if (type === 3 && /\S/.test(node.nodeValue)) {
        return splitWordsAndChars(node, settings);
    }
    var childNodes = toArray3(node.childNodes);
    if (childNodes.length) {
        set(node, 'isSplit', true);
        if (!get(node).isRoot) {
            node.style.display = 'inline-block';
            node.style.position = 'relative';
            var nextSibling = node.nextSibling;
            var prevSibling = node.previousSibling;
            var text = node.textContent || '';
            var textAfter = nextSibling ? nextSibling.textContent : ' ';
            var textBefore = prevSibling ? prevSibling.textContent : ' ';
            set(node, {
                isWordEnd: /\s$/.test(text) || /^\s/.test(textAfter),
                isWordStart: /^\s/.test(text) || /\s$/.test(textBefore),
            });
        }
    }
    return childNodes.reduce(function (result, child) {
        var _split = split(child, settings),
            words = _split.words,
            chars = _split.chars;
        return {
            words: [].concat(
                _toConsumableArray(result.words),
                _toConsumableArray(words)
            ),
            chars: [].concat(
                _toConsumableArray(result.chars),
                _toConsumableArray(chars)
            ),
        };
    }, wordsAndChars);
}
function getPosition(node, isWord, settings, scrollPos) {
    if (!settings.absolute) {
        return {
            top: isWord ? node.offsetTop : null,
        };
    }
    var parent = node.offsetParent;
    var _scrollPos = _slicedToArray(scrollPos, 2),
        scrollX = _scrollPos[0],
        scrollY = _scrollPos[1];
    var parentX = 0;
    var parentY = 0;
    if (parent && parent !== document.body) {
        var parentRect = parent.getBoundingClientRect();
        parentX = parentRect.x + scrollX;
        parentY = parentRect.y + scrollY;
    }
    var _node$getBoundingClie = node.getBoundingClientRect(),
        width = _node$getBoundingClie.width,
        height = _node$getBoundingClie.height,
        x = _node$getBoundingClie.x,
        y = _node$getBoundingClie.y;
    var top = y + scrollY - parentY;
    var left = x + scrollX - parentX;
    return {
        width,
        height,
        top,
        left,
    };
}
function unSplitWords(element) {
    if (!get(element).isWord) {
        toArray3(element.children).forEach(function (child) {
            return unSplitWords(child);
        });
    } else {
        remove(element);
        element.replaceWith.apply(
            element,
            _toConsumableArray(element.childNodes)
        );
    }
}
var createFragment = function createFragment2() {
    return document.createDocumentFragment();
};
function repositionAfterSplit(element, settings, scrollPos) {
    var types = parseTypes(settings.types);
    var TAG_NAME = settings.tagName;
    var nodes = element.getElementsByTagName('*');
    var wordsInEachLine = [];
    var wordsInCurrentLine = [];
    var lineOffsetY = null;
    var elementHeight;
    var elementWidth;
    var contentBox;
    var lines = [];
    var parent = element.parentElement;
    var nextSibling = element.nextElementSibling;
    var splitText = createFragment();
    var cs = window.getComputedStyle(element);
    var align = cs.textAlign;
    var fontSize = parseFloat(cs.fontSize);
    var lineThreshold = fontSize * 0.2;
    if (settings.absolute) {
        contentBox = {
            left: element.offsetLeft,
            top: element.offsetTop,
            width: element.offsetWidth,
        };
        elementWidth = element.offsetWidth;
        elementHeight = element.offsetHeight;
        set(element, {
            cssWidth: element.style.width,
            cssHeight: element.style.height,
        });
    }
    toArray3(nodes).forEach(function (node) {
        var isWordLike = node.parentElement === element;
        var _getPosition = getPosition(node, isWordLike, settings, scrollPos),
            width = _getPosition.width,
            height = _getPosition.height,
            top = _getPosition.top,
            left = _getPosition.left;
        if (/^br$/i.test(node.nodeName)) return;
        if (types.lines && isWordLike) {
            if (lineOffsetY === null || top - lineOffsetY >= lineThreshold) {
                lineOffsetY = top;
                wordsInEachLine.push((wordsInCurrentLine = []));
            }
            wordsInCurrentLine.push(node);
        }
        if (settings.absolute) {
            set(node, {
                top,
                left,
                width,
                height,
            });
        }
    });
    if (parent) {
        parent.removeChild(element);
    }
    if (types.lines) {
        lines = wordsInEachLine.map(function (wordsInThisLine) {
            var lineElement = createElement(TAG_NAME, {
                class: ''
                    .concat(settings.splitClass, ' ')
                    .concat(settings.lineClass),
                style: 'display: block; text-align: '.concat(
                    align,
                    '; width: 100%;'
                ),
            });
            set(lineElement, 'isLine', true);
            var lineDimensions = {
                height: 0,
                top: 1e4,
            };
            splitText.appendChild(lineElement);
            wordsInThisLine.forEach(function (wordOrElement, idx, arr) {
                var _data$get = get(wordOrElement),
                    isWordEnd = _data$get.isWordEnd,
                    top = _data$get.top,
                    height = _data$get.height;
                var next = arr[idx + 1];
                lineDimensions.height = Math.max(lineDimensions.height, height);
                lineDimensions.top = Math.min(lineDimensions.top, top);
                lineElement.appendChild(wordOrElement);
                if (isWordEnd && get(next).isWordStart) {
                    lineElement.append(' ');
                }
            });
            if (settings.absolute) {
                set(lineElement, {
                    height: lineDimensions.height,
                    top: lineDimensions.top,
                });
            }
            return lineElement;
        });
        if (!types.words) {
            unSplitWords(splitText);
        }
        element.replaceChildren(splitText);
    }
    if (settings.absolute) {
        element.style.width = ''.concat(
            element.style.width || elementWidth,
            'px'
        );
        element.style.height = ''.concat(elementHeight, 'px');
        toArray3(nodes).forEach(function (node) {
            var _data$get2 = get(node),
                isLine = _data$get2.isLine,
                top = _data$get2.top,
                left = _data$get2.left,
                width = _data$get2.width,
                height = _data$get2.height;
            var parentData = get(node.parentElement);
            var isChildOfLineNode = !isLine && parentData.isLine;
            node.style.top = ''.concat(
                isChildOfLineNode ? top - parentData.top : top,
                'px'
            );
            node.style.left = isLine
                ? ''.concat(contentBox.left, 'px')
                : ''.concat(
                      left - (isChildOfLineNode ? contentBox.left : 0),
                      'px'
                  );
            node.style.height = ''.concat(height, 'px');
            node.style.width = isLine
                ? ''.concat(contentBox.width, 'px')
                : ''.concat(width, 'px');
            node.style.position = 'absolute';
        });
    }
    if (parent) {
        if (nextSibling) parent.insertBefore(element, nextSibling);
        else parent.appendChild(element);
    }
    return lines;
}
var _defaults2 = extend(defaults2, {});
var SplitType = /* @__PURE__ */ (function () {
    _createClass(SplitType2, null, [
        {
            key: 'clearData',
            /**
             * CLears all data
             */
            value: function clearData() {
                clear();
            },
            /**
             * The default settings for all splitType instances
             * @static
             */
        },
        {
            key: 'setDefaults',
            /**
             * Sets the default settings for all SplitType instances.
             * The provided object will be merged with the existing defaults objects.
             *
             * @param {Object} settings an object containing the settings to override
             * @returns {Object} the new default settings
             * @public
             * @static
             * @example
             * SplitType.setDefaults({ "position": "absolute" })
             */
            value: function setDefaults(options) {
                _defaults2 = extend(_defaults2, parseSettings(options));
                return defaults2;
            },
            /**
             * Revert target elements to their original html content
             * Has no effect on that
             *
             * @param {any} elements The target elements to revert. One of:
             *  - {string} A css selector
             *  - {HTMLElement} A single element
             * -  {NodeList} A NodeList or collection
             *  - {HTMLElement[]} An array of Elements
             * -  {Array<HTMLElement|NodeList|HTMLElement[]>} A nested array of elements
             * @static
             */
        },
        {
            key: 'revert',
            value: function revert(elements) {
                getTargetElements(elements).forEach(function (element) {
                    var _data$get = get(element),
                        isSplit = _data$get.isSplit,
                        html = _data$get.html,
                        cssWidth = _data$get.cssWidth,
                        cssHeight = _data$get.cssHeight;
                    if (isSplit) {
                        element.innerHTML = html;
                        element.style.width = cssWidth || '';
                        element.style.height = cssHeight || '';
                        remove(element);
                    }
                });
            },
            /**
             * Creates a new SplitType instance
             * This static method provides a way to create a `SplitType` instance without
             * using the `new` keyword.
             *
             * @param {any} target The target elements to split. One of:
             *  - {string} A css selector
             *  - {HTMLElement} A single element
             * -  {NodeList} A NodeList or collection
             *  - {HTMLElement[]} An array of Elements
             * -  {Array<HTMLElement|NodeList|HTMLElement[]>} A nested array of elements
             * @param {Object} [options] Settings for the SplitType instance
             * @return {SplitType} the SplitType instance
             * @static
             */
        },
        {
            key: 'create',
            value: function create(target, options) {
                return new SplitType2(target, options);
            },
            /**
             * Creates a new `SplitType` instance
             *
             * @param {any} elements The target elements to split. One of:
             *  - {string} A css selector
             *  - {HTMLElement} A single element
             * -  {NodeList} A NodeList or collection
             *  - {HTMLElement[]} An array of Elements
             * -  {Array<HTMLElement|NodeList|HTMLElement[]>} A nested array of elements
             * @param {Object} [options] Settings for the SplitType instance
             */
        },
        {
            key: 'data',
            /**
             * The internal data store
             */
            get: function get2() {
                return cache;
            },
        },
        {
            key: 'defaults',
            get: function get2() {
                return _defaults2;
            },
            set: function set2(options) {
                _defaults2 = extend(_defaults2, parseSettings(options));
            },
        },
    ]);
    function SplitType2(elements, options) {
        _classCallCheck(this, SplitType2);
        this.isSplit = false;
        this.settings = extend(_defaults2, parseSettings(options));
        this.elements = getTargetElements(elements);
        this.split();
    }
    _createClass(SplitType2, [
        {
            key: 'split',
            value: function split$1(options) {
                var _this = this;
                this.revert();
                this.elements.forEach(function (element) {
                    set(element, 'html', element.innerHTML);
                });
                this.lines = [];
                this.words = [];
                this.chars = [];
                var scrollPos = [window.pageXOffset, window.pageYOffset];
                if (options !== void 0) {
                    this.settings = extend(
                        this.settings,
                        parseSettings(options)
                    );
                }
                var types = parseTypes(this.settings.types);
                if (types.none) {
                    return;
                }
                this.elements.forEach(function (element) {
                    set(element, 'isRoot', true);
                    var _split2 = split(element, _this.settings),
                        words = _split2.words,
                        chars = _split2.chars;
                    _this.words = [].concat(
                        _toConsumableArray(_this.words),
                        _toConsumableArray(words)
                    );
                    _this.chars = [].concat(
                        _toConsumableArray(_this.chars),
                        _toConsumableArray(chars)
                    );
                });
                this.elements.forEach(function (element) {
                    if (types.lines || _this.settings.absolute) {
                        var lines = repositionAfterSplit(
                            element,
                            _this.settings,
                            scrollPos
                        );
                        _this.lines = [].concat(
                            _toConsumableArray(_this.lines),
                            _toConsumableArray(lines)
                        );
                    }
                });
                this.isSplit = true;
                window.scrollTo(scrollPos[0], scrollPos[1]);
                cleanup();
            },
            /**
             * Reverts target element(s) back to their original html content
             * Deletes all stored data associated with the target elements
             * Resets the properties on the splitType instance
             *
             * @public
             */
        },
        {
            key: 'revert',
            value: function revert() {
                if (this.isSplit) {
                    this.lines = null;
                    this.words = null;
                    this.chars = null;
                    this.isSplit = false;
                }
                SplitType2.revert(this.elements);
            },
        },
    ]);
    return SplitType2;
})();

// node_modules/swiper/shared/ssr-window.esm.mjs
function isObject2(obj) {
    return (
        obj !== null &&
        typeof obj === 'object' &&
        'constructor' in obj &&
        obj.constructor === Object
    );
}
function extend2(target, src) {
    if (target === void 0) {
        target = {};
    }
    if (src === void 0) {
        src = {};
    }
    Object.keys(src).forEach((key) => {
        if (typeof target[key] === 'undefined') target[key] = src[key];
        else if (
            isObject2(src[key]) &&
            isObject2(target[key]) &&
            Object.keys(src[key]).length > 0
        ) {
            extend2(target[key], src[key]);
        }
    });
}
var ssrDocument = {
    body: {},
    addEventListener() {},
    removeEventListener() {},
    activeElement: {
        blur() {},
        nodeName: '',
    },
    querySelector() {
        return null;
    },
    querySelectorAll() {
        return [];
    },
    getElementById() {
        return null;
    },
    createEvent() {
        return {
            initEvent() {},
        };
    },
    createElement() {
        return {
            children: [],
            childNodes: [],
            style: {},
            setAttribute() {},
            getElementsByTagName() {
                return [];
            },
        };
    },
    createElementNS() {
        return {};
    },
    importNode() {
        return null;
    },
    location: {
        hash: '',
        host: '',
        hostname: '',
        href: '',
        origin: '',
        pathname: '',
        protocol: '',
        search: '',
    },
};
function getDocument() {
    const doc = typeof document !== 'undefined' ? document : {};
    extend2(doc, ssrDocument);
    return doc;
}
var ssrWindow = {
    document: ssrDocument,
    navigator: {
        userAgent: '',
    },
    location: {
        hash: '',
        host: '',
        hostname: '',
        href: '',
        origin: '',
        pathname: '',
        protocol: '',
        search: '',
    },
    history: {
        replaceState() {},
        pushState() {},
        go() {},
        back() {},
    },
    CustomEvent: function CustomEvent() {
        return this;
    },
    addEventListener() {},
    removeEventListener() {},
    getComputedStyle() {
        return {
            getPropertyValue() {
                return '';
            },
        };
    },
    Image() {},
    Date() {},
    screen: {},
    setTimeout() {},
    clearTimeout() {},
    matchMedia() {
        return {};
    },
    requestAnimationFrame(callback) {
        if (typeof setTimeout === 'undefined') {
            callback();
            return null;
        }
        return setTimeout(callback, 0);
    },
    cancelAnimationFrame(id) {
        if (typeof setTimeout === 'undefined') {
            return;
        }
        clearTimeout(id);
    },
};
function getWindow() {
    const win = typeof window !== 'undefined' ? window : {};
    extend2(win, ssrWindow);
    return win;
}

// node_modules/swiper/shared/utils.mjs
function classesToTokens(classes2) {
    if (classes2 === void 0) {
        classes2 = '';
    }
    return classes2
        .trim()
        .split(' ')
        .filter((c) => !!c.trim());
}
function deleteProps(obj) {
    const object = obj;
    Object.keys(object).forEach((key) => {
        try {
            object[key] = null;
        } catch (e2) {}
        try {
            delete object[key];
        } catch (e2) {}
    });
}
function nextTick(callback, delay) {
    if (delay === void 0) {
        delay = 0;
    }
    return setTimeout(callback, delay);
}
function now() {
    return Date.now();
}
function getComputedStyle2(el) {
    const window2 = getWindow();
    let style;
    if (window2.getComputedStyle) {
        style = window2.getComputedStyle(el, null);
    }
    if (!style && el.currentStyle) {
        style = el.currentStyle;
    }
    if (!style) {
        style = el.style;
    }
    return style;
}
function getTranslate(el, axis) {
    if (axis === void 0) {
        axis = 'x';
    }
    const window2 = getWindow();
    let matrix;
    let curTransform;
    let transformMatrix;
    const curStyle = getComputedStyle2(el);
    if (window2.WebKitCSSMatrix) {
        curTransform = curStyle.transform || curStyle.webkitTransform;
        if (curTransform.split(',').length > 6) {
            curTransform = curTransform
                .split(', ')
                .map((a) => a.replace(',', '.'))
                .join(', ');
        }
        transformMatrix = new window2.WebKitCSSMatrix(
            curTransform === 'none' ? '' : curTransform
        );
    } else {
        transformMatrix =
            curStyle.MozTransform ||
            curStyle.OTransform ||
            curStyle.MsTransform ||
            curStyle.msTransform ||
            curStyle.transform ||
            curStyle
                .getPropertyValue('transform')
                .replace('translate(', 'matrix(1, 0, 0, 1,');
        matrix = transformMatrix.toString().split(',');
    }
    if (axis === 'x') {
        if (window2.WebKitCSSMatrix) curTransform = transformMatrix.m41;
        else if (matrix.length === 16) curTransform = parseFloat(matrix[12]);
        else curTransform = parseFloat(matrix[4]);
    }
    if (axis === 'y') {
        if (window2.WebKitCSSMatrix) curTransform = transformMatrix.m42;
        else if (matrix.length === 16) curTransform = parseFloat(matrix[13]);
        else curTransform = parseFloat(matrix[5]);
    }
    return curTransform || 0;
}
function isObject3(o) {
    return (
        typeof o === 'object' &&
        o !== null &&
        o.constructor &&
        Object.prototype.toString.call(o).slice(8, -1) === 'Object'
    );
}
function isNode2(node) {
    if (
        typeof window !== 'undefined' &&
        typeof window.HTMLElement !== 'undefined'
    ) {
        return node instanceof HTMLElement;
    }
    return node && (node.nodeType === 1 || node.nodeType === 11);
}
function extend3() {
    const to = Object(arguments.length <= 0 ? void 0 : arguments[0]);
    const noExtend = ['__proto__', 'constructor', 'prototype'];
    for (let i = 1; i < arguments.length; i += 1) {
        const nextSource =
            i < 0 || arguments.length <= i ? void 0 : arguments[i];
        if (
            nextSource !== void 0 &&
            nextSource !== null &&
            !isNode2(nextSource)
        ) {
            const keysArray = Object.keys(Object(nextSource)).filter(
                (key) => noExtend.indexOf(key) < 0
            );
            for (
                let nextIndex = 0, len = keysArray.length;
                nextIndex < len;
                nextIndex += 1
            ) {
                const nextKey = keysArray[nextIndex];
                const desc = Object.getOwnPropertyDescriptor(
                    nextSource,
                    nextKey
                );
                if (desc !== void 0 && desc.enumerable) {
                    if (
                        isObject3(to[nextKey]) &&
                        isObject3(nextSource[nextKey])
                    ) {
                        if (nextSource[nextKey].__swiper__) {
                            to[nextKey] = nextSource[nextKey];
                        } else {
                            extend3(to[nextKey], nextSource[nextKey]);
                        }
                    } else if (
                        !isObject3(to[nextKey]) &&
                        isObject3(nextSource[nextKey])
                    ) {
                        to[nextKey] = {};
                        if (nextSource[nextKey].__swiper__) {
                            to[nextKey] = nextSource[nextKey];
                        } else {
                            extend3(to[nextKey], nextSource[nextKey]);
                        }
                    } else {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
        }
    }
    return to;
}
function setCSSProperty(el, varName, varValue) {
    el.style.setProperty(varName, varValue);
}
function animateCSSModeScroll(_ref) {
    let { swiper, targetPosition, side } = _ref;
    const window2 = getWindow();
    const startPosition = -swiper.translate;
    let startTime = null;
    let time;
    const duration = swiper.params.speed;
    swiper.wrapperEl.style.scrollSnapType = 'none';
    window2.cancelAnimationFrame(swiper.cssModeFrameID);
    const dir = targetPosition > startPosition ? 'next' : 'prev';
    const isOutOfBound = (current, target) => {
        return (
            (dir === 'next' && current >= target) ||
            (dir === 'prev' && current <= target)
        );
    };
    const animate = () => {
        time = /* @__PURE__ */ new Date().getTime();
        if (startTime === null) {
            startTime = time;
        }
        const progress = Math.max(
            Math.min((time - startTime) / duration, 1),
            0
        );
        const easeProgress = 0.5 - Math.cos(progress * Math.PI) / 2;
        let currentPosition =
            startPosition + easeProgress * (targetPosition - startPosition);
        if (isOutOfBound(currentPosition, targetPosition)) {
            currentPosition = targetPosition;
        }
        swiper.wrapperEl.scrollTo({
            [side]: currentPosition,
        });
        if (isOutOfBound(currentPosition, targetPosition)) {
            swiper.wrapperEl.style.overflow = 'hidden';
            swiper.wrapperEl.style.scrollSnapType = '';
            setTimeout(() => {
                swiper.wrapperEl.style.overflow = '';
                swiper.wrapperEl.scrollTo({
                    [side]: currentPosition,
                });
            });
            window2.cancelAnimationFrame(swiper.cssModeFrameID);
            return;
        }
        swiper.cssModeFrameID = window2.requestAnimationFrame(animate);
    };
    animate();
}
function elementChildren(element, selector3) {
    if (selector3 === void 0) {
        selector3 = '';
    }
    const children = [...element.children];
    if (element instanceof HTMLSlotElement) {
        children.push(...element.assignedElements());
    }
    if (!selector3) {
        return children;
    }
    return children.filter((el) => el.matches(selector3));
}
function elementIsChildOf(el, parent) {
    const isChild = parent.contains(el);
    if (!isChild && parent instanceof HTMLSlotElement) {
        const children = [...parent.assignedElements()];
        return children.includes(el);
    }
    return isChild;
}
function showWarning(text) {
    try {
        console.warn(text);
        return;
    } catch (err) {}
}
function createElement2(tag, classes2) {
    if (classes2 === void 0) {
        classes2 = [];
    }
    const el = document.createElement(tag);
    el.classList.add(
        ...(Array.isArray(classes2) ? classes2 : classesToTokens(classes2))
    );
    return el;
}
function elementPrevAll(el, selector3) {
    const prevEls = [];
    while (el.previousElementSibling) {
        const prev = el.previousElementSibling;
        if (selector3) {
            if (prev.matches(selector3)) prevEls.push(prev);
        } else prevEls.push(prev);
        el = prev;
    }
    return prevEls;
}
function elementNextAll(el, selector3) {
    const nextEls = [];
    while (el.nextElementSibling) {
        const next = el.nextElementSibling;
        if (selector3) {
            if (next.matches(selector3)) nextEls.push(next);
        } else nextEls.push(next);
        el = next;
    }
    return nextEls;
}
function elementStyle(el, prop) {
    const window2 = getWindow();
    return window2.getComputedStyle(el, null).getPropertyValue(prop);
}
function elementIndex(el) {
    let child = el;
    let i;
    if (child) {
        i = 0;
        while ((child = child.previousSibling) !== null) {
            if (child.nodeType === 1) i += 1;
        }
        return i;
    }
    return void 0;
}
function elementParents(el, selector3) {
    const parents = [];
    let parent = el.parentElement;
    while (parent) {
        if (selector3) {
            if (parent.matches(selector3)) parents.push(parent);
        } else {
            parents.push(parent);
        }
        parent = parent.parentElement;
    }
    return parents;
}
function elementOuterSize(el, size, includeMargins) {
    const window2 = getWindow();
    if (includeMargins) {
        return (
            el[size === 'width' ? 'offsetWidth' : 'offsetHeight'] +
            parseFloat(
                window2
                    .getComputedStyle(el, null)
                    .getPropertyValue(
                        size === 'width' ? 'margin-right' : 'margin-top'
                    )
            ) +
            parseFloat(
                window2
                    .getComputedStyle(el, null)
                    .getPropertyValue(
                        size === 'width' ? 'margin-left' : 'margin-bottom'
                    )
            )
        );
    }
    return el.offsetWidth;
}
function makeElementsArray(el) {
    return (Array.isArray(el) ? el : [el]).filter((e2) => !!e2);
}

// node_modules/swiper/shared/swiper-core.mjs
var support;
function calcSupport() {
    const window2 = getWindow();
    const document2 = getDocument();
    return {
        smoothScroll:
            document2.documentElement &&
            document2.documentElement.style &&
            'scrollBehavior' in document2.documentElement.style,
        touch: !!(
            'ontouchstart' in window2 ||
            (window2.DocumentTouch &&
                document2 instanceof window2.DocumentTouch)
        ),
    };
}
function getSupport() {
    if (!support) {
        support = calcSupport();
    }
    return support;
}
var deviceCached;
function calcDevice(_temp) {
    let { userAgent } = _temp === void 0 ? {} : _temp;
    const support2 = getSupport();
    const window2 = getWindow();
    const platform = window2.navigator.platform;
    const ua = userAgent || window2.navigator.userAgent;
    const device = {
        ios: false,
        android: false,
    };
    const screenWidth = window2.screen.width;
    const screenHeight = window2.screen.height;
    const android = ua.match(/(Android);?[\s\/]+([\d.]+)?/);
    let ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
    const ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/);
    const iphone = !ipad && ua.match(/(iPhone\sOS|iOS)\s([\d_]+)/);
    const windows = platform === 'Win32';
    let macos = platform === 'MacIntel';
    const iPadScreens = [
        '1024x1366',
        '1366x1024',
        '834x1194',
        '1194x834',
        '834x1112',
        '1112x834',
        '768x1024',
        '1024x768',
        '820x1180',
        '1180x820',
        '810x1080',
        '1080x810',
    ];
    if (
        !ipad &&
        macos &&
        support2.touch &&
        iPadScreens.indexOf(`${screenWidth}x${screenHeight}`) >= 0
    ) {
        ipad = ua.match(/(Version)\/([\d.]+)/);
        if (!ipad) ipad = [0, 1, '13_0_0'];
        macos = false;
    }
    if (android && !windows) {
        device.os = 'android';
        device.android = true;
    }
    if (ipad || iphone || ipod) {
        device.os = 'ios';
        device.ios = true;
    }
    return device;
}
function getDevice(overrides) {
    if (overrides === void 0) {
        overrides = {};
    }
    if (!deviceCached) {
        deviceCached = calcDevice(overrides);
    }
    return deviceCached;
}
var browser;
function calcBrowser() {
    const window2 = getWindow();
    const device = getDevice();
    let needPerspectiveFix = false;
    function isSafari() {
        const ua = window2.navigator.userAgent.toLowerCase();
        return (
            ua.indexOf('safari') >= 0 &&
            ua.indexOf('chrome') < 0 &&
            ua.indexOf('android') < 0
        );
    }
    if (isSafari()) {
        const ua = String(window2.navigator.userAgent);
        if (ua.includes('Version/')) {
            const [major, minor] = ua
                .split('Version/')[1]
                .split(' ')[0]
                .split('.')
                .map((num) => Number(num));
            needPerspectiveFix = major < 16 || (major === 16 && minor < 2);
        }
    }
    const isWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
        window2.navigator.userAgent
    );
    const isSafariBrowser = isSafari();
    const need3dFix = isSafariBrowser || (isWebView && device.ios);
    return {
        isSafari: needPerspectiveFix || isSafariBrowser,
        needPerspectiveFix,
        need3dFix,
        isWebView,
    };
}
function getBrowser() {
    if (!browser) {
        browser = calcBrowser();
    }
    return browser;
}
function Resize(_ref) {
    let { swiper, on, emit } = _ref;
    const window2 = getWindow();
    let observer = null;
    let animationFrame = null;
    const resizeHandler = () => {
        if (!swiper || swiper.destroyed || !swiper.initialized) return;
        emit('beforeResize');
        emit('resize');
    };
    const createObserver = () => {
        if (!swiper || swiper.destroyed || !swiper.initialized) return;
        observer = new ResizeObserver((entries2) => {
            animationFrame = window2.requestAnimationFrame(() => {
                const { width, height } = swiper;
                let newWidth = width;
                let newHeight = height;
                entries2.forEach((_ref2) => {
                    let { contentBoxSize, contentRect, target } = _ref2;
                    if (target && target !== swiper.el) return;
                    newWidth = contentRect
                        ? contentRect.width
                        : (contentBoxSize[0] || contentBoxSize).inlineSize;
                    newHeight = contentRect
                        ? contentRect.height
                        : (contentBoxSize[0] || contentBoxSize).blockSize;
                });
                if (newWidth !== width || newHeight !== height) {
                    resizeHandler();
                }
            });
        });
        observer.observe(swiper.el);
    };
    const removeObserver = () => {
        if (animationFrame) {
            window2.cancelAnimationFrame(animationFrame);
        }
        if (observer && observer.unobserve && swiper.el) {
            observer.unobserve(swiper.el);
            observer = null;
        }
    };
    const orientationChangeHandler = () => {
        if (!swiper || swiper.destroyed || !swiper.initialized) return;
        emit('orientationchange');
    };
    on('init', () => {
        if (
            swiper.params.resizeObserver &&
            typeof window2.ResizeObserver !== 'undefined'
        ) {
            createObserver();
            return;
        }
        window2.addEventListener('resize', resizeHandler);
        window2.addEventListener('orientationchange', orientationChangeHandler);
    });
    on('destroy', () => {
        removeObserver();
        window2.removeEventListener('resize', resizeHandler);
        window2.removeEventListener(
            'orientationchange',
            orientationChangeHandler
        );
    });
}
function Observer(_ref) {
    let { swiper, extendParams, on, emit } = _ref;
    const observers = [];
    const window2 = getWindow();
    const attach = function (target, options) {
        if (options === void 0) {
            options = {};
        }
        const ObserverFunc =
            window2.MutationObserver || window2.WebkitMutationObserver;
        const observer = new ObserverFunc((mutations) => {
            if (swiper.__preventObserver__) return;
            if (mutations.length === 1) {
                emit('observerUpdate', mutations[0]);
                return;
            }
            const observerUpdate = function observerUpdate2() {
                emit('observerUpdate', mutations[0]);
            };
            if (window2.requestAnimationFrame) {
                window2.requestAnimationFrame(observerUpdate);
            } else {
                window2.setTimeout(observerUpdate, 0);
            }
        });
        observer.observe(target, {
            attributes:
                typeof options.attributes === 'undefined'
                    ? true
                    : options.attributes,
            childList:
                swiper.isElement ||
                (typeof options.childList === 'undefined' ? true : options)
                    .childList,
            characterData:
                typeof options.characterData === 'undefined'
                    ? true
                    : options.characterData,
        });
        observers.push(observer);
    };
    const init4 = () => {
        if (!swiper.params.observer) return;
        if (swiper.params.observeParents) {
            const containerParents = elementParents(swiper.hostEl);
            for (let i = 0; i < containerParents.length; i += 1) {
                attach(containerParents[i]);
            }
        }
        attach(swiper.hostEl, {
            childList: swiper.params.observeSlideChildren,
        });
        attach(swiper.wrapperEl, {
            attributes: false,
        });
    };
    const destroy = () => {
        observers.forEach((observer) => {
            observer.disconnect();
        });
        observers.splice(0, observers.length);
    };
    extendParams({
        observer: false,
        observeParents: false,
        observeSlideChildren: false,
    });
    on('init', init4);
    on('destroy', destroy);
}
var eventsEmitter = {
    on(events2, handler, priority) {
        const self2 = this;
        if (!self2.eventsListeners || self2.destroyed) return self2;
        if (typeof handler !== 'function') return self2;
        const method = priority ? 'unshift' : 'push';
        events2.split(' ').forEach((event2) => {
            if (!self2.eventsListeners[event2])
                self2.eventsListeners[event2] = [];
            self2.eventsListeners[event2][method](handler);
        });
        return self2;
    },
    once(events2, handler, priority) {
        const self2 = this;
        if (!self2.eventsListeners || self2.destroyed) return self2;
        if (typeof handler !== 'function') return self2;
        function onceHandler() {
            self2.off(events2, onceHandler);
            if (onceHandler.__emitterProxy) {
                delete onceHandler.__emitterProxy;
            }
            for (
                var _len = arguments.length, args = new Array(_len), _key = 0;
                _key < _len;
                _key++
            ) {
                args[_key] = arguments[_key];
            }
            handler.apply(self2, args);
        }
        onceHandler.__emitterProxy = handler;
        return self2.on(events2, onceHandler, priority);
    },
    onAny(handler, priority) {
        const self2 = this;
        if (!self2.eventsListeners || self2.destroyed) return self2;
        if (typeof handler !== 'function') return self2;
        const method = priority ? 'unshift' : 'push';
        if (self2.eventsAnyListeners.indexOf(handler) < 0) {
            self2.eventsAnyListeners[method](handler);
        }
        return self2;
    },
    offAny(handler) {
        const self2 = this;
        if (!self2.eventsListeners || self2.destroyed) return self2;
        if (!self2.eventsAnyListeners) return self2;
        const index = self2.eventsAnyListeners.indexOf(handler);
        if (index >= 0) {
            self2.eventsAnyListeners.splice(index, 1);
        }
        return self2;
    },
    off(events2, handler) {
        const self2 = this;
        if (!self2.eventsListeners || self2.destroyed) return self2;
        if (!self2.eventsListeners) return self2;
        events2.split(' ').forEach((event2) => {
            if (typeof handler === 'undefined') {
                self2.eventsListeners[event2] = [];
            } else if (self2.eventsListeners[event2]) {
                self2.eventsListeners[event2].forEach((eventHandler, index) => {
                    if (
                        eventHandler === handler ||
                        (eventHandler.__emitterProxy &&
                            eventHandler.__emitterProxy === handler)
                    ) {
                        self2.eventsListeners[event2].splice(index, 1);
                    }
                });
            }
        });
        return self2;
    },
    emit() {
        const self2 = this;
        if (!self2.eventsListeners || self2.destroyed) return self2;
        if (!self2.eventsListeners) return self2;
        let events2;
        let data;
        let context3;
        for (
            var _len2 = arguments.length, args = new Array(_len2), _key2 = 0;
            _key2 < _len2;
            _key2++
        ) {
            args[_key2] = arguments[_key2];
        }
        if (typeof args[0] === 'string' || Array.isArray(args[0])) {
            events2 = args[0];
            data = args.slice(1, args.length);
            context3 = self2;
        } else {
            events2 = args[0].events;
            data = args[0].data;
            context3 = args[0].context || self2;
        }
        data.unshift(context3);
        const eventsArray = Array.isArray(events2)
            ? events2
            : events2.split(' ');
        eventsArray.forEach((event2) => {
            if (self2.eventsAnyListeners && self2.eventsAnyListeners.length) {
                self2.eventsAnyListeners.forEach((eventHandler) => {
                    eventHandler.apply(context3, [event2, ...data]);
                });
            }
            if (self2.eventsListeners && self2.eventsListeners[event2]) {
                self2.eventsListeners[event2].forEach((eventHandler) => {
                    eventHandler.apply(context3, data);
                });
            }
        });
        return self2;
    },
};
function updateSize() {
    const swiper = this;
    let width;
    let height;
    const el = swiper.el;
    if (
        typeof swiper.params.width !== 'undefined' &&
        swiper.params.width !== null
    ) {
        width = swiper.params.width;
    } else {
        width = el.clientWidth;
    }
    if (
        typeof swiper.params.height !== 'undefined' &&
        swiper.params.height !== null
    ) {
        height = swiper.params.height;
    } else {
        height = el.clientHeight;
    }
    if (
        (width === 0 && swiper.isHorizontal()) ||
        (height === 0 && swiper.isVertical())
    ) {
        return;
    }
    width =
        width -
        parseInt(elementStyle(el, 'padding-left') || 0, 10) -
        parseInt(elementStyle(el, 'padding-right') || 0, 10);
    height =
        height -
        parseInt(elementStyle(el, 'padding-top') || 0, 10) -
        parseInt(elementStyle(el, 'padding-bottom') || 0, 10);
    if (Number.isNaN(width)) width = 0;
    if (Number.isNaN(height)) height = 0;
    Object.assign(swiper, {
        width,
        height,
        size: swiper.isHorizontal() ? width : height,
    });
}
function updateSlides() {
    const swiper = this;
    function getDirectionPropertyValue(node, label) {
        return parseFloat(
            node.getPropertyValue(swiper.getDirectionLabel(label)) || 0
        );
    }
    const params = swiper.params;
    const {
        wrapperEl,
        slidesEl,
        size: swiperSize,
        rtlTranslate: rtl,
        wrongRTL,
    } = swiper;
    const isVirtual = swiper.virtual && params.virtual.enabled;
    const previousSlidesLength = isVirtual
        ? swiper.virtual.slides.length
        : swiper.slides.length;
    const slides = elementChildren(
        slidesEl,
        `.${swiper.params.slideClass}, swiper-slide`
    );
    const slidesLength = isVirtual
        ? swiper.virtual.slides.length
        : slides.length;
    let snapGrid = [];
    const slidesGrid = [];
    const slidesSizesGrid = [];
    let offsetBefore = params.slidesOffsetBefore;
    if (typeof offsetBefore === 'function') {
        offsetBefore = params.slidesOffsetBefore.call(swiper);
    }
    let offsetAfter = params.slidesOffsetAfter;
    if (typeof offsetAfter === 'function') {
        offsetAfter = params.slidesOffsetAfter.call(swiper);
    }
    const previousSnapGridLength = swiper.snapGrid.length;
    const previousSlidesGridLength = swiper.slidesGrid.length;
    let spaceBetween = params.spaceBetween;
    let slidePosition = -offsetBefore;
    let prevSlideSize = 0;
    let index = 0;
    if (typeof swiperSize === 'undefined') {
        return;
    }
    if (typeof spaceBetween === 'string' && spaceBetween.indexOf('%') >= 0) {
        spaceBetween =
            (parseFloat(spaceBetween.replace('%', '')) / 100) * swiperSize;
    } else if (typeof spaceBetween === 'string') {
        spaceBetween = parseFloat(spaceBetween);
    }
    swiper.virtualSize = -spaceBetween;
    slides.forEach((slideEl) => {
        if (rtl) {
            slideEl.style.marginLeft = '';
        } else {
            slideEl.style.marginRight = '';
        }
        slideEl.style.marginBottom = '';
        slideEl.style.marginTop = '';
    });
    if (params.centeredSlides && params.cssMode) {
        setCSSProperty(wrapperEl, '--swiper-centered-offset-before', '');
        setCSSProperty(wrapperEl, '--swiper-centered-offset-after', '');
    }
    const gridEnabled = params.grid && params.grid.rows > 1 && swiper.grid;
    if (gridEnabled) {
        swiper.grid.initSlides(slides);
    } else if (swiper.grid) {
        swiper.grid.unsetSlides();
    }
    let slideSize;
    const shouldResetSlideSize =
        params.slidesPerView === 'auto' &&
        params.breakpoints &&
        Object.keys(params.breakpoints).filter((key) => {
            return typeof params.breakpoints[key].slidesPerView !== 'undefined';
        }).length > 0;
    for (let i = 0; i < slidesLength; i += 1) {
        slideSize = 0;
        let slide2;
        if (slides[i]) slide2 = slides[i];
        if (gridEnabled) {
            swiper.grid.updateSlide(i, slide2, slides);
        }
        if (slides[i] && elementStyle(slide2, 'display') === 'none') continue;
        if (params.slidesPerView === 'auto') {
            if (shouldResetSlideSize) {
                slides[i].style[swiper.getDirectionLabel('width')] = ``;
            }
            const slideStyles = getComputedStyle(slide2);
            const currentTransform = slide2.style.transform;
            const currentWebKitTransform = slide2.style.webkitTransform;
            if (currentTransform) {
                slide2.style.transform = 'none';
            }
            if (currentWebKitTransform) {
                slide2.style.webkitTransform = 'none';
            }
            if (params.roundLengths) {
                slideSize = swiper.isHorizontal()
                    ? elementOuterSize(slide2, 'width', true)
                    : elementOuterSize(slide2, 'height', true);
            } else {
                const width = getDirectionPropertyValue(slideStyles, 'width');
                const paddingLeft = getDirectionPropertyValue(
                    slideStyles,
                    'padding-left'
                );
                const paddingRight = getDirectionPropertyValue(
                    slideStyles,
                    'padding-right'
                );
                const marginLeft = getDirectionPropertyValue(
                    slideStyles,
                    'margin-left'
                );
                const marginRight = getDirectionPropertyValue(
                    slideStyles,
                    'margin-right'
                );
                const boxSizing = slideStyles.getPropertyValue('box-sizing');
                if (boxSizing && boxSizing === 'border-box') {
                    slideSize = width + marginLeft + marginRight;
                } else {
                    const { clientWidth, offsetWidth } = slide2;
                    slideSize =
                        width +
                        paddingLeft +
                        paddingRight +
                        marginLeft +
                        marginRight +
                        (offsetWidth - clientWidth);
                }
            }
            if (currentTransform) {
                slide2.style.transform = currentTransform;
            }
            if (currentWebKitTransform) {
                slide2.style.webkitTransform = currentWebKitTransform;
            }
            if (params.roundLengths) slideSize = Math.floor(slideSize);
        } else {
            slideSize =
                (swiperSize - (params.slidesPerView - 1) * spaceBetween) /
                params.slidesPerView;
            if (params.roundLengths) slideSize = Math.floor(slideSize);
            if (slides[i]) {
                slides[i].style[
                    swiper.getDirectionLabel('width')
                ] = `${slideSize}px`;
            }
        }
        if (slides[i]) {
            slides[i].swiperSlideSize = slideSize;
        }
        slidesSizesGrid.push(slideSize);
        if (params.centeredSlides) {
            slidePosition =
                slidePosition +
                slideSize / 2 +
                prevSlideSize / 2 +
                spaceBetween;
            if (prevSlideSize === 0 && i !== 0)
                slidePosition = slidePosition - swiperSize / 2 - spaceBetween;
            if (i === 0)
                slidePosition = slidePosition - swiperSize / 2 - spaceBetween;
            if (Math.abs(slidePosition) < 1 / 1e3) slidePosition = 0;
            if (params.roundLengths) slidePosition = Math.floor(slidePosition);
            if (index % params.slidesPerGroup === 0)
                snapGrid.push(slidePosition);
            slidesGrid.push(slidePosition);
        } else {
            if (params.roundLengths) slidePosition = Math.floor(slidePosition);
            if (
                (index - Math.min(swiper.params.slidesPerGroupSkip, index)) %
                    swiper.params.slidesPerGroup ===
                0
            )
                snapGrid.push(slidePosition);
            slidesGrid.push(slidePosition);
            slidePosition = slidePosition + slideSize + spaceBetween;
        }
        swiper.virtualSize += slideSize + spaceBetween;
        prevSlideSize = slideSize;
        index += 1;
    }
    swiper.virtualSize = Math.max(swiper.virtualSize, swiperSize) + offsetAfter;
    if (
        rtl &&
        wrongRTL &&
        (params.effect === 'slide' || params.effect === 'coverflow')
    ) {
        wrapperEl.style.width = `${swiper.virtualSize + spaceBetween}px`;
    }
    if (params.setWrapperSize) {
        wrapperEl.style[swiper.getDirectionLabel('width')] = `${
            swiper.virtualSize + spaceBetween
        }px`;
    }
    if (gridEnabled) {
        swiper.grid.updateWrapperSize(slideSize, snapGrid);
    }
    if (!params.centeredSlides) {
        const newSlidesGrid = [];
        for (let i = 0; i < snapGrid.length; i += 1) {
            let slidesGridItem = snapGrid[i];
            if (params.roundLengths)
                slidesGridItem = Math.floor(slidesGridItem);
            if (snapGrid[i] <= swiper.virtualSize - swiperSize) {
                newSlidesGrid.push(slidesGridItem);
            }
        }
        snapGrid = newSlidesGrid;
        if (
            Math.floor(swiper.virtualSize - swiperSize) -
                Math.floor(snapGrid[snapGrid.length - 1]) >
            1
        ) {
            snapGrid.push(swiper.virtualSize - swiperSize);
        }
    }
    if (isVirtual && params.loop) {
        const size = slidesSizesGrid[0] + spaceBetween;
        if (params.slidesPerGroup > 1) {
            const groups = Math.ceil(
                (swiper.virtual.slidesBefore + swiper.virtual.slidesAfter) /
                    params.slidesPerGroup
            );
            const groupSize = size * params.slidesPerGroup;
            for (let i = 0; i < groups; i += 1) {
                snapGrid.push(snapGrid[snapGrid.length - 1] + groupSize);
            }
        }
        for (
            let i = 0;
            i < swiper.virtual.slidesBefore + swiper.virtual.slidesAfter;
            i += 1
        ) {
            if (params.slidesPerGroup === 1) {
                snapGrid.push(snapGrid[snapGrid.length - 1] + size);
            }
            slidesGrid.push(slidesGrid[slidesGrid.length - 1] + size);
            swiper.virtualSize += size;
        }
    }
    if (snapGrid.length === 0) snapGrid = [0];
    if (spaceBetween !== 0) {
        const key =
            swiper.isHorizontal() && rtl
                ? 'marginLeft'
                : swiper.getDirectionLabel('marginRight');
        slides
            .filter((_, slideIndex) => {
                if (!params.cssMode || params.loop) return true;
                if (slideIndex === slides.length - 1) {
                    return false;
                }
                return true;
            })
            .forEach((slideEl) => {
                slideEl.style[key] = `${spaceBetween}px`;
            });
    }
    if (params.centeredSlides && params.centeredSlidesBounds) {
        let allSlidesSize = 0;
        slidesSizesGrid.forEach((slideSizeValue) => {
            allSlidesSize += slideSizeValue + (spaceBetween || 0);
        });
        allSlidesSize -= spaceBetween;
        const maxSnap =
            allSlidesSize > swiperSize ? allSlidesSize - swiperSize : 0;
        snapGrid = snapGrid.map((snap3) => {
            if (snap3 <= 0) return -offsetBefore;
            if (snap3 > maxSnap) return maxSnap + offsetAfter;
            return snap3;
        });
    }
    if (params.centerInsufficientSlides) {
        let allSlidesSize = 0;
        slidesSizesGrid.forEach((slideSizeValue) => {
            allSlidesSize += slideSizeValue + (spaceBetween || 0);
        });
        allSlidesSize -= spaceBetween;
        const offsetSize =
            (params.slidesOffsetBefore || 0) + (params.slidesOffsetAfter || 0);
        if (allSlidesSize + offsetSize < swiperSize) {
            const allSlidesOffset =
                (swiperSize - allSlidesSize - offsetSize) / 2;
            snapGrid.forEach((snap3, snapIndex) => {
                snapGrid[snapIndex] = snap3 - allSlidesOffset;
            });
            slidesGrid.forEach((snap3, snapIndex) => {
                slidesGrid[snapIndex] = snap3 + allSlidesOffset;
            });
        }
    }
    Object.assign(swiper, {
        slides,
        snapGrid,
        slidesGrid,
        slidesSizesGrid,
    });
    if (
        params.centeredSlides &&
        params.cssMode &&
        !params.centeredSlidesBounds
    ) {
        setCSSProperty(
            wrapperEl,
            '--swiper-centered-offset-before',
            `${-snapGrid[0]}px`
        );
        setCSSProperty(
            wrapperEl,
            '--swiper-centered-offset-after',
            `${
                swiper.size / 2 -
                slidesSizesGrid[slidesSizesGrid.length - 1] / 2
            }px`
        );
        const addToSnapGrid = -swiper.snapGrid[0];
        const addToSlidesGrid = -swiper.slidesGrid[0];
        swiper.snapGrid = swiper.snapGrid.map((v) => v + addToSnapGrid);
        swiper.slidesGrid = swiper.slidesGrid.map((v) => v + addToSlidesGrid);
    }
    if (slidesLength !== previousSlidesLength) {
        swiper.emit('slidesLengthChange');
    }
    if (snapGrid.length !== previousSnapGridLength) {
        if (swiper.params.watchOverflow) swiper.checkOverflow();
        swiper.emit('snapGridLengthChange');
    }
    if (slidesGrid.length !== previousSlidesGridLength) {
        swiper.emit('slidesGridLengthChange');
    }
    if (params.watchSlidesProgress) {
        swiper.updateSlidesOffset();
    }
    swiper.emit('slidesUpdated');
    if (
        !isVirtual &&
        !params.cssMode &&
        (params.effect === 'slide' || params.effect === 'fade')
    ) {
        const backFaceHiddenClass = `${params.containerModifierClass}backface-hidden`;
        const hasClassBackfaceClassAdded =
            swiper.el.classList.contains(backFaceHiddenClass);
        if (slidesLength <= params.maxBackfaceHiddenSlides) {
            if (!hasClassBackfaceClassAdded)
                swiper.el.classList.add(backFaceHiddenClass);
        } else if (hasClassBackfaceClassAdded) {
            swiper.el.classList.remove(backFaceHiddenClass);
        }
    }
}
function updateAutoHeight(speed) {
    const swiper = this;
    const activeSlides = [];
    const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
    let newHeight = 0;
    let i;
    if (typeof speed === 'number') {
        swiper.setTransition(speed);
    } else if (speed === true) {
        swiper.setTransition(swiper.params.speed);
    }
    const getSlideByIndex = (index) => {
        if (isVirtual) {
            return swiper.slides[swiper.getSlideIndexByData(index)];
        }
        return swiper.slides[index];
    };
    if (
        swiper.params.slidesPerView !== 'auto' &&
        swiper.params.slidesPerView > 1
    ) {
        if (swiper.params.centeredSlides) {
            (swiper.visibleSlides || []).forEach((slide2) => {
                activeSlides.push(slide2);
            });
        } else {
            for (i = 0; i < Math.ceil(swiper.params.slidesPerView); i += 1) {
                const index = swiper.activeIndex + i;
                if (index > swiper.slides.length && !isVirtual) break;
                activeSlides.push(getSlideByIndex(index));
            }
        }
    } else {
        activeSlides.push(getSlideByIndex(swiper.activeIndex));
    }
    for (i = 0; i < activeSlides.length; i += 1) {
        if (typeof activeSlides[i] !== 'undefined') {
            const height = activeSlides[i].offsetHeight;
            newHeight = height > newHeight ? height : newHeight;
        }
    }
    if (newHeight || newHeight === 0)
        swiper.wrapperEl.style.height = `${newHeight}px`;
}
function updateSlidesOffset() {
    const swiper = this;
    const slides = swiper.slides;
    const minusOffset = swiper.isElement
        ? swiper.isHorizontal()
            ? swiper.wrapperEl.offsetLeft
            : swiper.wrapperEl.offsetTop
        : 0;
    for (let i = 0; i < slides.length; i += 1) {
        slides[i].swiperSlideOffset =
            (swiper.isHorizontal()
                ? slides[i].offsetLeft
                : slides[i].offsetTop) -
            minusOffset -
            swiper.cssOverflowAdjustment();
    }
}
var toggleSlideClasses$1 = (slideEl, condition, className) => {
    if (condition && !slideEl.classList.contains(className)) {
        slideEl.classList.add(className);
    } else if (!condition && slideEl.classList.contains(className)) {
        slideEl.classList.remove(className);
    }
};
function updateSlidesProgress(translate2) {
    if (translate2 === void 0) {
        translate2 = (this && this.translate) || 0;
    }
    const swiper = this;
    const params = swiper.params;
    const { slides, rtlTranslate: rtl, snapGrid } = swiper;
    if (slides.length === 0) return;
    if (typeof slides[0].swiperSlideOffset === 'undefined')
        swiper.updateSlidesOffset();
    let offsetCenter = -translate2;
    if (rtl) offsetCenter = translate2;
    swiper.visibleSlidesIndexes = [];
    swiper.visibleSlides = [];
    let spaceBetween = params.spaceBetween;
    if (typeof spaceBetween === 'string' && spaceBetween.indexOf('%') >= 0) {
        spaceBetween =
            (parseFloat(spaceBetween.replace('%', '')) / 100) * swiper.size;
    } else if (typeof spaceBetween === 'string') {
        spaceBetween = parseFloat(spaceBetween);
    }
    for (let i = 0; i < slides.length; i += 1) {
        const slide2 = slides[i];
        let slideOffset = slide2.swiperSlideOffset;
        if (params.cssMode && params.centeredSlides) {
            slideOffset -= slides[0].swiperSlideOffset;
        }
        const slideProgress =
            (offsetCenter +
                (params.centeredSlides ? swiper.minTranslate() : 0) -
                slideOffset) /
            (slide2.swiperSlideSize + spaceBetween);
        const originalSlideProgress =
            (offsetCenter -
                snapGrid[0] +
                (params.centeredSlides ? swiper.minTranslate() : 0) -
                slideOffset) /
            (slide2.swiperSlideSize + spaceBetween);
        const slideBefore = -(offsetCenter - slideOffset);
        const slideAfter = slideBefore + swiper.slidesSizesGrid[i];
        const isFullyVisible =
            slideBefore >= 0 &&
            slideBefore <= swiper.size - swiper.slidesSizesGrid[i];
        const isVisible =
            (slideBefore >= 0 && slideBefore < swiper.size - 1) ||
            (slideAfter > 1 && slideAfter <= swiper.size) ||
            (slideBefore <= 0 && slideAfter >= swiper.size);
        if (isVisible) {
            swiper.visibleSlides.push(slide2);
            swiper.visibleSlidesIndexes.push(i);
        }
        toggleSlideClasses$1(slide2, isVisible, params.slideVisibleClass);
        toggleSlideClasses$1(
            slide2,
            isFullyVisible,
            params.slideFullyVisibleClass
        );
        slide2.progress = rtl ? -slideProgress : slideProgress;
        slide2.originalProgress = rtl
            ? -originalSlideProgress
            : originalSlideProgress;
    }
}
function updateProgress(translate2) {
    const swiper = this;
    if (typeof translate2 === 'undefined') {
        const multiplier = swiper.rtlTranslate ? -1 : 1;
        translate2 =
            (swiper && swiper.translate && swiper.translate * multiplier) || 0;
    }
    const params = swiper.params;
    const translatesDiff = swiper.maxTranslate() - swiper.minTranslate();
    let { progress, isBeginning, isEnd, progressLoop } = swiper;
    const wasBeginning = isBeginning;
    const wasEnd = isEnd;
    if (translatesDiff === 0) {
        progress = 0;
        isBeginning = true;
        isEnd = true;
    } else {
        progress = (translate2 - swiper.minTranslate()) / translatesDiff;
        const isBeginningRounded =
            Math.abs(translate2 - swiper.minTranslate()) < 1;
        const isEndRounded = Math.abs(translate2 - swiper.maxTranslate()) < 1;
        isBeginning = isBeginningRounded || progress <= 0;
        isEnd = isEndRounded || progress >= 1;
        if (isBeginningRounded) progress = 0;
        if (isEndRounded) progress = 1;
    }
    if (params.loop) {
        const firstSlideIndex = swiper.getSlideIndexByData(0);
        const lastSlideIndex = swiper.getSlideIndexByData(
            swiper.slides.length - 1
        );
        const firstSlideTranslate = swiper.slidesGrid[firstSlideIndex];
        const lastSlideTranslate = swiper.slidesGrid[lastSlideIndex];
        const translateMax = swiper.slidesGrid[swiper.slidesGrid.length - 1];
        const translateAbs = Math.abs(translate2);
        if (translateAbs >= firstSlideTranslate) {
            progressLoop = (translateAbs - firstSlideTranslate) / translateMax;
        } else {
            progressLoop =
                (translateAbs + translateMax - lastSlideTranslate) /
                translateMax;
        }
        if (progressLoop > 1) progressLoop -= 1;
    }
    Object.assign(swiper, {
        progress,
        progressLoop,
        isBeginning,
        isEnd,
    });
    if (
        params.watchSlidesProgress ||
        (params.centeredSlides && params.autoHeight)
    )
        swiper.updateSlidesProgress(translate2);
    if (isBeginning && !wasBeginning) {
        swiper.emit('reachBeginning toEdge');
    }
    if (isEnd && !wasEnd) {
        swiper.emit('reachEnd toEdge');
    }
    if ((wasBeginning && !isBeginning) || (wasEnd && !isEnd)) {
        swiper.emit('fromEdge');
    }
    swiper.emit('progress', progress);
}
var toggleSlideClasses = (slideEl, condition, className) => {
    if (condition && !slideEl.classList.contains(className)) {
        slideEl.classList.add(className);
    } else if (!condition && slideEl.classList.contains(className)) {
        slideEl.classList.remove(className);
    }
};
function updateSlidesClasses() {
    const swiper = this;
    const { slides, params, slidesEl, activeIndex } = swiper;
    const isVirtual = swiper.virtual && params.virtual.enabled;
    const gridEnabled = swiper.grid && params.grid && params.grid.rows > 1;
    const getFilteredSlide = (selector3) => {
        return elementChildren(
            slidesEl,
            `.${params.slideClass}${selector3}, swiper-slide${selector3}`
        )[0];
    };
    let activeSlide;
    let prevSlide;
    let nextSlide;
    if (isVirtual) {
        if (params.loop) {
            let slideIndex = activeIndex - swiper.virtual.slidesBefore;
            if (slideIndex < 0)
                slideIndex = swiper.virtual.slides.length + slideIndex;
            if (slideIndex >= swiper.virtual.slides.length)
                slideIndex -= swiper.virtual.slides.length;
            activeSlide = getFilteredSlide(
                `[data-swiper-slide-index="${slideIndex}"]`
            );
        } else {
            activeSlide = getFilteredSlide(
                `[data-swiper-slide-index="${activeIndex}"]`
            );
        }
    } else {
        if (gridEnabled) {
            activeSlide = slides.filter(
                (slideEl) => slideEl.column === activeIndex
            )[0];
            nextSlide = slides.filter(
                (slideEl) => slideEl.column === activeIndex + 1
            )[0];
            prevSlide = slides.filter(
                (slideEl) => slideEl.column === activeIndex - 1
            )[0];
        } else {
            activeSlide = slides[activeIndex];
        }
    }
    if (activeSlide) {
        if (!gridEnabled) {
            nextSlide = elementNextAll(
                activeSlide,
                `.${params.slideClass}, swiper-slide`
            )[0];
            if (params.loop && !nextSlide) {
                nextSlide = slides[0];
            }
            prevSlide = elementPrevAll(
                activeSlide,
                `.${params.slideClass}, swiper-slide`
            )[0];
            if (params.loop && !prevSlide === 0) {
                prevSlide = slides[slides.length - 1];
            }
        }
    }
    slides.forEach((slideEl) => {
        toggleSlideClasses(
            slideEl,
            slideEl === activeSlide,
            params.slideActiveClass
        );
        toggleSlideClasses(
            slideEl,
            slideEl === nextSlide,
            params.slideNextClass
        );
        toggleSlideClasses(
            slideEl,
            slideEl === prevSlide,
            params.slidePrevClass
        );
    });
    swiper.emitSlidesClasses();
}
var processLazyPreloader = (swiper, imageEl) => {
    if (!swiper || swiper.destroyed || !swiper.params) return;
    const slideSelector = () =>
        swiper.isElement ? `swiper-slide` : `.${swiper.params.slideClass}`;
    const slideEl = imageEl.closest(slideSelector());
    if (slideEl) {
        let lazyEl = slideEl.querySelector(
            `.${swiper.params.lazyPreloaderClass}`
        );
        if (!lazyEl && swiper.isElement) {
            if (slideEl.shadowRoot) {
                lazyEl = slideEl.shadowRoot.querySelector(
                    `.${swiper.params.lazyPreloaderClass}`
                );
            } else {
                requestAnimationFrame(() => {
                    if (slideEl.shadowRoot) {
                        lazyEl = slideEl.shadowRoot.querySelector(
                            `.${swiper.params.lazyPreloaderClass}`
                        );
                        if (lazyEl) lazyEl.remove();
                    }
                });
            }
        }
        if (lazyEl) lazyEl.remove();
    }
};
var unlazy = (swiper, index) => {
    if (!swiper.slides[index]) return;
    const imageEl = swiper.slides[index].querySelector('[loading="lazy"]');
    if (imageEl) imageEl.removeAttribute('loading');
};
var preload = (swiper) => {
    if (!swiper || swiper.destroyed || !swiper.params) return;
    let amount = swiper.params.lazyPreloadPrevNext;
    const len = swiper.slides.length;
    if (!len || !amount || amount < 0) return;
    amount = Math.min(amount, len);
    const slidesPerView =
        swiper.params.slidesPerView === 'auto'
            ? swiper.slidesPerViewDynamic()
            : Math.ceil(swiper.params.slidesPerView);
    const activeIndex = swiper.activeIndex;
    if (swiper.params.grid && swiper.params.grid.rows > 1) {
        const activeColumn = activeIndex;
        const preloadColumns = [activeColumn - amount];
        preloadColumns.push(
            ...Array.from({
                length: amount,
            }).map((_, i) => {
                return activeColumn + slidesPerView + i;
            })
        );
        swiper.slides.forEach((slideEl, i) => {
            if (preloadColumns.includes(slideEl.column)) unlazy(swiper, i);
        });
        return;
    }
    const slideIndexLastInView = activeIndex + slidesPerView - 1;
    if (swiper.params.rewind || swiper.params.loop) {
        for (
            let i = activeIndex - amount;
            i <= slideIndexLastInView + amount;
            i += 1
        ) {
            const realIndex = ((i % len) + len) % len;
            if (realIndex < activeIndex || realIndex > slideIndexLastInView)
                unlazy(swiper, realIndex);
        }
    } else {
        for (
            let i = Math.max(activeIndex - amount, 0);
            i <= Math.min(slideIndexLastInView + amount, len - 1);
            i += 1
        ) {
            if (
                i !== activeIndex &&
                (i > slideIndexLastInView || i < activeIndex)
            ) {
                unlazy(swiper, i);
            }
        }
    }
};
function getActiveIndexByTranslate(swiper) {
    const { slidesGrid, params } = swiper;
    const translate2 = swiper.rtlTranslate
        ? swiper.translate
        : -swiper.translate;
    let activeIndex;
    for (let i = 0; i < slidesGrid.length; i += 1) {
        if (typeof slidesGrid[i + 1] !== 'undefined') {
            if (
                translate2 >= slidesGrid[i] &&
                translate2 <
                    slidesGrid[i + 1] - (slidesGrid[i + 1] - slidesGrid[i]) / 2
            ) {
                activeIndex = i;
            } else if (
                translate2 >= slidesGrid[i] &&
                translate2 < slidesGrid[i + 1]
            ) {
                activeIndex = i + 1;
            }
        } else if (translate2 >= slidesGrid[i]) {
            activeIndex = i;
        }
    }
    if (params.normalizeSlideIndex) {
        if (activeIndex < 0 || typeof activeIndex === 'undefined')
            activeIndex = 0;
    }
    return activeIndex;
}
function updateActiveIndex(newActiveIndex) {
    const swiper = this;
    const translate2 = swiper.rtlTranslate
        ? swiper.translate
        : -swiper.translate;
    const {
        snapGrid,
        params,
        activeIndex: previousIndex,
        realIndex: previousRealIndex,
        snapIndex: previousSnapIndex,
    } = swiper;
    let activeIndex = newActiveIndex;
    let snapIndex;
    const getVirtualRealIndex = (aIndex) => {
        let realIndex2 = aIndex - swiper.virtual.slidesBefore;
        if (realIndex2 < 0) {
            realIndex2 = swiper.virtual.slides.length + realIndex2;
        }
        if (realIndex2 >= swiper.virtual.slides.length) {
            realIndex2 -= swiper.virtual.slides.length;
        }
        return realIndex2;
    };
    if (typeof activeIndex === 'undefined') {
        activeIndex = getActiveIndexByTranslate(swiper);
    }
    if (snapGrid.indexOf(translate2) >= 0) {
        snapIndex = snapGrid.indexOf(translate2);
    } else {
        const skip = Math.min(params.slidesPerGroupSkip, activeIndex);
        snapIndex =
            skip + Math.floor((activeIndex - skip) / params.slidesPerGroup);
    }
    if (snapIndex >= snapGrid.length) snapIndex = snapGrid.length - 1;
    if (activeIndex === previousIndex && !swiper.params.loop) {
        if (snapIndex !== previousSnapIndex) {
            swiper.snapIndex = snapIndex;
            swiper.emit('snapIndexChange');
        }
        return;
    }
    if (
        activeIndex === previousIndex &&
        swiper.params.loop &&
        swiper.virtual &&
        swiper.params.virtual.enabled
    ) {
        swiper.realIndex = getVirtualRealIndex(activeIndex);
        return;
    }
    const gridEnabled = swiper.grid && params.grid && params.grid.rows > 1;
    let realIndex;
    if (swiper.virtual && params.virtual.enabled && params.loop) {
        realIndex = getVirtualRealIndex(activeIndex);
    } else if (gridEnabled) {
        const firstSlideInColumn = swiper.slides.filter(
            (slideEl) => slideEl.column === activeIndex
        )[0];
        let activeSlideIndex = parseInt(
            firstSlideInColumn.getAttribute('data-swiper-slide-index'),
            10
        );
        if (Number.isNaN(activeSlideIndex)) {
            activeSlideIndex = Math.max(
                swiper.slides.indexOf(firstSlideInColumn),
                0
            );
        }
        realIndex = Math.floor(activeSlideIndex / params.grid.rows);
    } else if (swiper.slides[activeIndex]) {
        const slideIndex = swiper.slides[activeIndex].getAttribute(
            'data-swiper-slide-index'
        );
        if (slideIndex) {
            realIndex = parseInt(slideIndex, 10);
        } else {
            realIndex = activeIndex;
        }
    } else {
        realIndex = activeIndex;
    }
    Object.assign(swiper, {
        previousSnapIndex,
        snapIndex,
        previousRealIndex,
        realIndex,
        previousIndex,
        activeIndex,
    });
    if (swiper.initialized) {
        preload(swiper);
    }
    swiper.emit('activeIndexChange');
    swiper.emit('snapIndexChange');
    if (swiper.initialized || swiper.params.runCallbacksOnInit) {
        if (previousRealIndex !== realIndex) {
            swiper.emit('realIndexChange');
        }
        swiper.emit('slideChange');
    }
}
function updateClickedSlide(el, path) {
    const swiper = this;
    const params = swiper.params;
    let slide2 = el.closest(`.${params.slideClass}, swiper-slide`);
    if (
        !slide2 &&
        swiper.isElement &&
        path &&
        path.length > 1 &&
        path.includes(el)
    ) {
        [...path.slice(path.indexOf(el) + 1, path.length)].forEach((pathEl) => {
            if (
                !slide2 &&
                pathEl.matches &&
                pathEl.matches(`.${params.slideClass}, swiper-slide`)
            ) {
                slide2 = pathEl;
            }
        });
    }
    let slideFound = false;
    let slideIndex;
    if (slide2) {
        for (let i = 0; i < swiper.slides.length; i += 1) {
            if (swiper.slides[i] === slide2) {
                slideFound = true;
                slideIndex = i;
                break;
            }
        }
    }
    if (slide2 && slideFound) {
        swiper.clickedSlide = slide2;
        if (swiper.virtual && swiper.params.virtual.enabled) {
            swiper.clickedIndex = parseInt(
                slide2.getAttribute('data-swiper-slide-index'),
                10
            );
        } else {
            swiper.clickedIndex = slideIndex;
        }
    } else {
        swiper.clickedSlide = void 0;
        swiper.clickedIndex = void 0;
        return;
    }
    if (
        params.slideToClickedSlide &&
        swiper.clickedIndex !== void 0 &&
        swiper.clickedIndex !== swiper.activeIndex
    ) {
        swiper.slideToClickedSlide();
    }
}
var update = {
    updateSize,
    updateSlides,
    updateAutoHeight,
    updateSlidesOffset,
    updateSlidesProgress,
    updateProgress,
    updateSlidesClasses,
    updateActiveIndex,
    updateClickedSlide,
};
function getSwiperTranslate(axis) {
    if (axis === void 0) {
        axis = this.isHorizontal() ? 'x' : 'y';
    }
    const swiper = this;
    const {
        params,
        rtlTranslate: rtl,
        translate: translate2,
        wrapperEl,
    } = swiper;
    if (params.virtualTranslate) {
        return rtl ? -translate2 : translate2;
    }
    if (params.cssMode) {
        return translate2;
    }
    let currentTranslate = getTranslate(wrapperEl, axis);
    currentTranslate += swiper.cssOverflowAdjustment();
    if (rtl) currentTranslate = -currentTranslate;
    return currentTranslate || 0;
}
function setTranslate(translate2, byController) {
    const swiper = this;
    const { rtlTranslate: rtl, params, wrapperEl, progress } = swiper;
    let x = 0;
    let y = 0;
    const z = 0;
    if (swiper.isHorizontal()) {
        x = rtl ? -translate2 : translate2;
    } else {
        y = translate2;
    }
    if (params.roundLengths) {
        x = Math.floor(x);
        y = Math.floor(y);
    }
    swiper.previousTranslate = swiper.translate;
    swiper.translate = swiper.isHorizontal() ? x : y;
    if (params.cssMode) {
        wrapperEl[swiper.isHorizontal() ? 'scrollLeft' : 'scrollTop'] =
            swiper.isHorizontal() ? -x : -y;
    } else if (!params.virtualTranslate) {
        if (swiper.isHorizontal()) {
            x -= swiper.cssOverflowAdjustment();
        } else {
            y -= swiper.cssOverflowAdjustment();
        }
        wrapperEl.style.transform = `translate3d(${x}px, ${y}px, ${z}px)`;
    }
    let newProgress;
    const translatesDiff = swiper.maxTranslate() - swiper.minTranslate();
    if (translatesDiff === 0) {
        newProgress = 0;
    } else {
        newProgress = (translate2 - swiper.minTranslate()) / translatesDiff;
    }
    if (newProgress !== progress) {
        swiper.updateProgress(translate2);
    }
    swiper.emit('setTranslate', swiper.translate, byController);
}
function minTranslate() {
    return -this.snapGrid[0];
}
function maxTranslate() {
    return -this.snapGrid[this.snapGrid.length - 1];
}
function translateTo(
    translate2,
    speed,
    runCallbacks,
    translateBounds,
    internal
) {
    if (translate2 === void 0) {
        translate2 = 0;
    }
    if (speed === void 0) {
        speed = this.params.speed;
    }
    if (runCallbacks === void 0) {
        runCallbacks = true;
    }
    if (translateBounds === void 0) {
        translateBounds = true;
    }
    const swiper = this;
    const { params, wrapperEl } = swiper;
    if (swiper.animating && params.preventInteractionOnTransition) {
        return false;
    }
    const minTranslate2 = swiper.minTranslate();
    const maxTranslate2 = swiper.maxTranslate();
    let newTranslate;
    if (translateBounds && translate2 > minTranslate2)
        newTranslate = minTranslate2;
    else if (translateBounds && translate2 < maxTranslate2)
        newTranslate = maxTranslate2;
    else newTranslate = translate2;
    swiper.updateProgress(newTranslate);
    if (params.cssMode) {
        const isH = swiper.isHorizontal();
        if (speed === 0) {
            wrapperEl[isH ? 'scrollLeft' : 'scrollTop'] = -newTranslate;
        } else {
            if (!swiper.support.smoothScroll) {
                animateCSSModeScroll({
                    swiper,
                    targetPosition: -newTranslate,
                    side: isH ? 'left' : 'top',
                });
                return true;
            }
            wrapperEl.scrollTo({
                [isH ? 'left' : 'top']: -newTranslate,
                behavior: 'smooth',
            });
        }
        return true;
    }
    if (speed === 0) {
        swiper.setTransition(0);
        swiper.setTranslate(newTranslate);
        if (runCallbacks) {
            swiper.emit('beforeTransitionStart', speed, internal);
            swiper.emit('transitionEnd');
        }
    } else {
        swiper.setTransition(speed);
        swiper.setTranslate(newTranslate);
        if (runCallbacks) {
            swiper.emit('beforeTransitionStart', speed, internal);
            swiper.emit('transitionStart');
        }
        if (!swiper.animating) {
            swiper.animating = true;
            if (!swiper.onTranslateToWrapperTransitionEnd) {
                swiper.onTranslateToWrapperTransitionEnd =
                    function transitionEnd2(e2) {
                        if (!swiper || swiper.destroyed) return;
                        if (e2.target !== this) return;
                        swiper.wrapperEl.removeEventListener(
                            'transitionend',
                            swiper.onTranslateToWrapperTransitionEnd
                        );
                        swiper.onTranslateToWrapperTransitionEnd = null;
                        delete swiper.onTranslateToWrapperTransitionEnd;
                        swiper.animating = false;
                        if (runCallbacks) {
                            swiper.emit('transitionEnd');
                        }
                    };
            }
            swiper.wrapperEl.addEventListener(
                'transitionend',
                swiper.onTranslateToWrapperTransitionEnd
            );
        }
    }
    return true;
}
var translate = {
    getTranslate: getSwiperTranslate,
    setTranslate,
    minTranslate,
    maxTranslate,
    translateTo,
};
function setTransition(duration, byController) {
    const swiper = this;
    if (!swiper.params.cssMode) {
        swiper.wrapperEl.style.transitionDuration = `${duration}ms`;
        swiper.wrapperEl.style.transitionDelay = duration === 0 ? `0ms` : '';
    }
    swiper.emit('setTransition', duration, byController);
}
function transitionEmit(_ref) {
    let { swiper, runCallbacks, direction, step } = _ref;
    const { activeIndex, previousIndex } = swiper;
    let dir = direction;
    if (!dir) {
        if (activeIndex > previousIndex) dir = 'next';
        else if (activeIndex < previousIndex) dir = 'prev';
        else dir = 'reset';
    }
    swiper.emit(`transition${step}`);
    if (runCallbacks && activeIndex !== previousIndex) {
        if (dir === 'reset') {
            swiper.emit(`slideResetTransition${step}`);
            return;
        }
        swiper.emit(`slideChangeTransition${step}`);
        if (dir === 'next') {
            swiper.emit(`slideNextTransition${step}`);
        } else {
            swiper.emit(`slidePrevTransition${step}`);
        }
    }
}
function transitionStart(runCallbacks, direction) {
    if (runCallbacks === void 0) {
        runCallbacks = true;
    }
    const swiper = this;
    const { params } = swiper;
    if (params.cssMode) return;
    if (params.autoHeight) {
        swiper.updateAutoHeight();
    }
    transitionEmit({
        swiper,
        runCallbacks,
        direction,
        step: 'Start',
    });
}
function transitionEnd(runCallbacks, direction) {
    if (runCallbacks === void 0) {
        runCallbacks = true;
    }
    const swiper = this;
    const { params } = swiper;
    swiper.animating = false;
    if (params.cssMode) return;
    swiper.setTransition(0);
    transitionEmit({
        swiper,
        runCallbacks,
        direction,
        step: 'End',
    });
}
var transition = {
    setTransition,
    transitionStart,
    transitionEnd,
};
function slideTo(index, speed, runCallbacks, internal, initial) {
    if (index === void 0) {
        index = 0;
    }
    if (runCallbacks === void 0) {
        runCallbacks = true;
    }
    if (typeof index === 'string') {
        index = parseInt(index, 10);
    }
    const swiper = this;
    let slideIndex = index;
    if (slideIndex < 0) slideIndex = 0;
    const {
        params,
        snapGrid,
        slidesGrid,
        previousIndex,
        activeIndex,
        rtlTranslate: rtl,
        wrapperEl,
        enabled,
    } = swiper;
    if (
        (!enabled && !internal && !initial) ||
        swiper.destroyed ||
        (swiper.animating && params.preventInteractionOnTransition)
    ) {
        return false;
    }
    if (typeof speed === 'undefined') {
        speed = swiper.params.speed;
    }
    const skip = Math.min(swiper.params.slidesPerGroupSkip, slideIndex);
    let snapIndex =
        skip + Math.floor((slideIndex - skip) / swiper.params.slidesPerGroup);
    if (snapIndex >= snapGrid.length) snapIndex = snapGrid.length - 1;
    const translate2 = -snapGrid[snapIndex];
    if (params.normalizeSlideIndex) {
        for (let i = 0; i < slidesGrid.length; i += 1) {
            const normalizedTranslate = -Math.floor(translate2 * 100);
            const normalizedGrid = Math.floor(slidesGrid[i] * 100);
            const normalizedGridNext = Math.floor(slidesGrid[i + 1] * 100);
            if (typeof slidesGrid[i + 1] !== 'undefined') {
                if (
                    normalizedTranslate >= normalizedGrid &&
                    normalizedTranslate <
                        normalizedGridNext -
                            (normalizedGridNext - normalizedGrid) / 2
                ) {
                    slideIndex = i;
                } else if (
                    normalizedTranslate >= normalizedGrid &&
                    normalizedTranslate < normalizedGridNext
                ) {
                    slideIndex = i + 1;
                }
            } else if (normalizedTranslate >= normalizedGrid) {
                slideIndex = i;
            }
        }
    }
    if (swiper.initialized && slideIndex !== activeIndex) {
        if (
            !swiper.allowSlideNext &&
            (rtl
                ? translate2 > swiper.translate &&
                  translate2 > swiper.minTranslate()
                : translate2 < swiper.translate &&
                  translate2 < swiper.minTranslate())
        ) {
            return false;
        }
        if (
            !swiper.allowSlidePrev &&
            translate2 > swiper.translate &&
            translate2 > swiper.maxTranslate()
        ) {
            if ((activeIndex || 0) !== slideIndex) {
                return false;
            }
        }
    }
    if (slideIndex !== (previousIndex || 0) && runCallbacks) {
        swiper.emit('beforeSlideChangeStart');
    }
    swiper.updateProgress(translate2);
    let direction;
    if (slideIndex > activeIndex) direction = 'next';
    else if (slideIndex < activeIndex) direction = 'prev';
    else direction = 'reset';
    const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
    const isInitialVirtual = isVirtual && initial;
    if (
        !isInitialVirtual &&
        ((rtl && -translate2 === swiper.translate) ||
            (!rtl && translate2 === swiper.translate))
    ) {
        swiper.updateActiveIndex(slideIndex);
        if (params.autoHeight) {
            swiper.updateAutoHeight();
        }
        swiper.updateSlidesClasses();
        if (params.effect !== 'slide') {
            swiper.setTranslate(translate2);
        }
        if (direction !== 'reset') {
            swiper.transitionStart(runCallbacks, direction);
            swiper.transitionEnd(runCallbacks, direction);
        }
        return false;
    }
    if (params.cssMode) {
        const isH = swiper.isHorizontal();
        const t2 = rtl ? translate2 : -translate2;
        if (speed === 0) {
            if (isVirtual) {
                swiper.wrapperEl.style.scrollSnapType = 'none';
                swiper._immediateVirtual = true;
            }
            if (
                isVirtual &&
                !swiper._cssModeVirtualInitialSet &&
                swiper.params.initialSlide > 0
            ) {
                swiper._cssModeVirtualInitialSet = true;
                requestAnimationFrame(() => {
                    wrapperEl[isH ? 'scrollLeft' : 'scrollTop'] = t2;
                });
            } else {
                wrapperEl[isH ? 'scrollLeft' : 'scrollTop'] = t2;
            }
            if (isVirtual) {
                requestAnimationFrame(() => {
                    swiper.wrapperEl.style.scrollSnapType = '';
                    swiper._immediateVirtual = false;
                });
            }
        } else {
            if (!swiper.support.smoothScroll) {
                animateCSSModeScroll({
                    swiper,
                    targetPosition: t2,
                    side: isH ? 'left' : 'top',
                });
                return true;
            }
            wrapperEl.scrollTo({
                [isH ? 'left' : 'top']: t2,
                behavior: 'smooth',
            });
        }
        return true;
    }
    swiper.setTransition(speed);
    swiper.setTranslate(translate2);
    swiper.updateActiveIndex(slideIndex);
    swiper.updateSlidesClasses();
    swiper.emit('beforeTransitionStart', speed, internal);
    swiper.transitionStart(runCallbacks, direction);
    if (speed === 0) {
        swiper.transitionEnd(runCallbacks, direction);
    } else if (!swiper.animating) {
        swiper.animating = true;
        if (!swiper.onSlideToWrapperTransitionEnd) {
            swiper.onSlideToWrapperTransitionEnd = function transitionEnd2(e2) {
                if (!swiper || swiper.destroyed) return;
                if (e2.target !== this) return;
                swiper.wrapperEl.removeEventListener(
                    'transitionend',
                    swiper.onSlideToWrapperTransitionEnd
                );
                swiper.onSlideToWrapperTransitionEnd = null;
                delete swiper.onSlideToWrapperTransitionEnd;
                swiper.transitionEnd(runCallbacks, direction);
            };
        }
        swiper.wrapperEl.addEventListener(
            'transitionend',
            swiper.onSlideToWrapperTransitionEnd
        );
    }
    return true;
}
function slideToLoop(index, speed, runCallbacks, internal) {
    if (index === void 0) {
        index = 0;
    }
    if (runCallbacks === void 0) {
        runCallbacks = true;
    }
    if (typeof index === 'string') {
        const indexAsNumber = parseInt(index, 10);
        index = indexAsNumber;
    }
    const swiper = this;
    if (swiper.destroyed) return;
    if (typeof speed === 'undefined') {
        speed = swiper.params.speed;
    }
    const gridEnabled =
        swiper.grid && swiper.params.grid && swiper.params.grid.rows > 1;
    let newIndex = index;
    if (swiper.params.loop) {
        if (swiper.virtual && swiper.params.virtual.enabled) {
            newIndex = newIndex + swiper.virtual.slidesBefore;
        } else {
            let targetSlideIndex;
            if (gridEnabled) {
                const slideIndex = newIndex * swiper.params.grid.rows;
                targetSlideIndex = swiper.slides.filter(
                    (slideEl) =>
                        slideEl.getAttribute('data-swiper-slide-index') * 1 ===
                        slideIndex
                )[0].column;
            } else {
                targetSlideIndex = swiper.getSlideIndexByData(newIndex);
            }
            const cols = gridEnabled
                ? Math.ceil(swiper.slides.length / swiper.params.grid.rows)
                : swiper.slides.length;
            const { centeredSlides } = swiper.params;
            let slidesPerView = swiper.params.slidesPerView;
            if (slidesPerView === 'auto') {
                slidesPerView = swiper.slidesPerViewDynamic();
            } else {
                slidesPerView = Math.ceil(
                    parseFloat(swiper.params.slidesPerView, 10)
                );
                if (centeredSlides && slidesPerView % 2 === 0) {
                    slidesPerView = slidesPerView + 1;
                }
            }
            let needLoopFix = cols - targetSlideIndex < slidesPerView;
            if (centeredSlides) {
                needLoopFix =
                    needLoopFix ||
                    targetSlideIndex < Math.ceil(slidesPerView / 2);
            }
            if (
                internal &&
                centeredSlides &&
                swiper.params.slidesPerView !== 'auto' &&
                !gridEnabled
            ) {
                needLoopFix = false;
            }
            if (needLoopFix) {
                const direction = centeredSlides
                    ? targetSlideIndex < swiper.activeIndex
                        ? 'prev'
                        : 'next'
                    : targetSlideIndex - swiper.activeIndex - 1 <
                      swiper.params.slidesPerView
                    ? 'next'
                    : 'prev';
                swiper.loopFix({
                    direction,
                    slideTo: true,
                    activeSlideIndex:
                        direction === 'next'
                            ? targetSlideIndex + 1
                            : targetSlideIndex - cols + 1,
                    slideRealIndex:
                        direction === 'next' ? swiper.realIndex : void 0,
                });
            }
            if (gridEnabled) {
                const slideIndex = newIndex * swiper.params.grid.rows;
                newIndex = swiper.slides.filter(
                    (slideEl) =>
                        slideEl.getAttribute('data-swiper-slide-index') * 1 ===
                        slideIndex
                )[0].column;
            } else {
                newIndex = swiper.getSlideIndexByData(newIndex);
            }
        }
    }
    requestAnimationFrame(() => {
        swiper.slideTo(newIndex, speed, runCallbacks, internal);
    });
    return swiper;
}
function slideNext(speed, runCallbacks, internal) {
    if (runCallbacks === void 0) {
        runCallbacks = true;
    }
    const swiper = this;
    const { enabled, params, animating } = swiper;
    if (!enabled || swiper.destroyed) return swiper;
    if (typeof speed === 'undefined') {
        speed = swiper.params.speed;
    }
    let perGroup = params.slidesPerGroup;
    if (
        params.slidesPerView === 'auto' &&
        params.slidesPerGroup === 1 &&
        params.slidesPerGroupAuto
    ) {
        perGroup = Math.max(swiper.slidesPerViewDynamic('current', true), 1);
    }
    const increment =
        swiper.activeIndex < params.slidesPerGroupSkip ? 1 : perGroup;
    const isVirtual = swiper.virtual && params.virtual.enabled;
    if (params.loop) {
        if (animating && !isVirtual && params.loopPreventsSliding) return false;
        swiper.loopFix({
            direction: 'next',
        });
        swiper._clientLeft = swiper.wrapperEl.clientLeft;
        if (swiper.activeIndex === swiper.slides.length - 1 && params.cssMode) {
            requestAnimationFrame(() => {
                swiper.slideTo(
                    swiper.activeIndex + increment,
                    speed,
                    runCallbacks,
                    internal
                );
            });
            return true;
        }
    }
    if (params.rewind && swiper.isEnd) {
        return swiper.slideTo(0, speed, runCallbacks, internal);
    }
    return swiper.slideTo(
        swiper.activeIndex + increment,
        speed,
        runCallbacks,
        internal
    );
}
function slidePrev(speed, runCallbacks, internal) {
    if (runCallbacks === void 0) {
        runCallbacks = true;
    }
    const swiper = this;
    const { params, snapGrid, slidesGrid, rtlTranslate, enabled, animating } =
        swiper;
    if (!enabled || swiper.destroyed) return swiper;
    if (typeof speed === 'undefined') {
        speed = swiper.params.speed;
    }
    const isVirtual = swiper.virtual && params.virtual.enabled;
    if (params.loop) {
        if (animating && !isVirtual && params.loopPreventsSliding) return false;
        swiper.loopFix({
            direction: 'prev',
        });
        swiper._clientLeft = swiper.wrapperEl.clientLeft;
    }
    const translate2 = rtlTranslate ? swiper.translate : -swiper.translate;
    function normalize3(val) {
        if (val < 0) return -Math.floor(Math.abs(val));
        return Math.floor(val);
    }
    const normalizedTranslate = normalize3(translate2);
    const normalizedSnapGrid = snapGrid.map((val) => normalize3(val));
    let prevSnap =
        snapGrid[normalizedSnapGrid.indexOf(normalizedTranslate) - 1];
    if (typeof prevSnap === 'undefined' && params.cssMode) {
        let prevSnapIndex;
        snapGrid.forEach((snap3, snapIndex) => {
            if (normalizedTranslate >= snap3) {
                prevSnapIndex = snapIndex;
            }
        });
        if (typeof prevSnapIndex !== 'undefined') {
            prevSnap =
                snapGrid[prevSnapIndex > 0 ? prevSnapIndex - 1 : prevSnapIndex];
        }
    }
    let prevIndex = 0;
    if (typeof prevSnap !== 'undefined') {
        prevIndex = slidesGrid.indexOf(prevSnap);
        if (prevIndex < 0) prevIndex = swiper.activeIndex - 1;
        if (
            params.slidesPerView === 'auto' &&
            params.slidesPerGroup === 1 &&
            params.slidesPerGroupAuto
        ) {
            prevIndex =
                prevIndex - swiper.slidesPerViewDynamic('previous', true) + 1;
            prevIndex = Math.max(prevIndex, 0);
        }
    }
    if (params.rewind && swiper.isBeginning) {
        const lastIndex =
            swiper.params.virtual &&
            swiper.params.virtual.enabled &&
            swiper.virtual
                ? swiper.virtual.slides.length - 1
                : swiper.slides.length - 1;
        return swiper.slideTo(lastIndex, speed, runCallbacks, internal);
    } else if (params.loop && swiper.activeIndex === 0 && params.cssMode) {
        requestAnimationFrame(() => {
            swiper.slideTo(prevIndex, speed, runCallbacks, internal);
        });
        return true;
    }
    return swiper.slideTo(prevIndex, speed, runCallbacks, internal);
}
function slideReset(speed, runCallbacks, internal) {
    if (runCallbacks === void 0) {
        runCallbacks = true;
    }
    const swiper = this;
    if (swiper.destroyed) return;
    if (typeof speed === 'undefined') {
        speed = swiper.params.speed;
    }
    return swiper.slideTo(swiper.activeIndex, speed, runCallbacks, internal);
}
function slideToClosest(speed, runCallbacks, internal, threshold) {
    if (runCallbacks === void 0) {
        runCallbacks = true;
    }
    if (threshold === void 0) {
        threshold = 0.5;
    }
    const swiper = this;
    if (swiper.destroyed) return;
    if (typeof speed === 'undefined') {
        speed = swiper.params.speed;
    }
    let index = swiper.activeIndex;
    const skip = Math.min(swiper.params.slidesPerGroupSkip, index);
    const snapIndex =
        skip + Math.floor((index - skip) / swiper.params.slidesPerGroup);
    const translate2 = swiper.rtlTranslate
        ? swiper.translate
        : -swiper.translate;
    if (translate2 >= swiper.snapGrid[snapIndex]) {
        const currentSnap = swiper.snapGrid[snapIndex];
        const nextSnap = swiper.snapGrid[snapIndex + 1];
        if (translate2 - currentSnap > (nextSnap - currentSnap) * threshold) {
            index += swiper.params.slidesPerGroup;
        }
    } else {
        const prevSnap = swiper.snapGrid[snapIndex - 1];
        const currentSnap = swiper.snapGrid[snapIndex];
        if (translate2 - prevSnap <= (currentSnap - prevSnap) * threshold) {
            index -= swiper.params.slidesPerGroup;
        }
    }
    index = Math.max(index, 0);
    index = Math.min(index, swiper.slidesGrid.length - 1);
    return swiper.slideTo(index, speed, runCallbacks, internal);
}
function slideToClickedSlide() {
    const swiper = this;
    if (swiper.destroyed) return;
    const { params, slidesEl } = swiper;
    const slidesPerView =
        params.slidesPerView === 'auto'
            ? swiper.slidesPerViewDynamic()
            : params.slidesPerView;
    let slideToIndex = swiper.clickedIndex;
    let realIndex;
    const slideSelector = swiper.isElement
        ? `swiper-slide`
        : `.${params.slideClass}`;
    if (params.loop) {
        if (swiper.animating) return;
        realIndex = parseInt(
            swiper.clickedSlide.getAttribute('data-swiper-slide-index'),
            10
        );
        if (params.centeredSlides) {
            if (
                slideToIndex < swiper.loopedSlides - slidesPerView / 2 ||
                slideToIndex >
                    swiper.slides.length -
                        swiper.loopedSlides +
                        slidesPerView / 2
            ) {
                swiper.loopFix();
                slideToIndex = swiper.getSlideIndex(
                    elementChildren(
                        slidesEl,
                        `${slideSelector}[data-swiper-slide-index="${realIndex}"]`
                    )[0]
                );
                nextTick(() => {
                    swiper.slideTo(slideToIndex);
                });
            } else {
                swiper.slideTo(slideToIndex);
            }
        } else if (slideToIndex > swiper.slides.length - slidesPerView) {
            swiper.loopFix();
            slideToIndex = swiper.getSlideIndex(
                elementChildren(
                    slidesEl,
                    `${slideSelector}[data-swiper-slide-index="${realIndex}"]`
                )[0]
            );
            nextTick(() => {
                swiper.slideTo(slideToIndex);
            });
        } else {
            swiper.slideTo(slideToIndex);
        }
    } else {
        swiper.slideTo(slideToIndex);
    }
}
var slide = {
    slideTo,
    slideToLoop,
    slideNext,
    slidePrev,
    slideReset,
    slideToClosest,
    slideToClickedSlide,
};
function loopCreate(slideRealIndex) {
    const swiper = this;
    const { params, slidesEl } = swiper;
    if (!params.loop || (swiper.virtual && swiper.params.virtual.enabled))
        return;
    const initSlides = () => {
        const slides = elementChildren(
            slidesEl,
            `.${params.slideClass}, swiper-slide`
        );
        slides.forEach((el, index) => {
            el.setAttribute('data-swiper-slide-index', index);
        });
    };
    const gridEnabled = swiper.grid && params.grid && params.grid.rows > 1;
    const slidesPerGroup =
        params.slidesPerGroup * (gridEnabled ? params.grid.rows : 1);
    const shouldFillGroup = swiper.slides.length % slidesPerGroup !== 0;
    const shouldFillGrid =
        gridEnabled && swiper.slides.length % params.grid.rows !== 0;
    const addBlankSlides = (amountOfSlides) => {
        for (let i = 0; i < amountOfSlides; i += 1) {
            const slideEl = swiper.isElement
                ? createElement2('swiper-slide', [params.slideBlankClass])
                : createElement2('div', [
                      params.slideClass,
                      params.slideBlankClass,
                  ]);
            swiper.slidesEl.append(slideEl);
        }
    };
    if (shouldFillGroup) {
        if (params.loopAddBlankSlides) {
            const slidesToAdd =
                slidesPerGroup - (swiper.slides.length % slidesPerGroup);
            addBlankSlides(slidesToAdd);
            swiper.recalcSlides();
            swiper.updateSlides();
        } else {
            showWarning(
                'Swiper Loop Warning: The number of slides is not even to slidesPerGroup, loop mode may not function properly. You need to add more slides (or make duplicates, or empty slides)'
            );
        }
        initSlides();
    } else if (shouldFillGrid) {
        if (params.loopAddBlankSlides) {
            const slidesToAdd =
                params.grid.rows - (swiper.slides.length % params.grid.rows);
            addBlankSlides(slidesToAdd);
            swiper.recalcSlides();
            swiper.updateSlides();
        } else {
            showWarning(
                'Swiper Loop Warning: The number of slides is not even to grid.rows, loop mode may not function properly. You need to add more slides (or make duplicates, or empty slides)'
            );
        }
        initSlides();
    } else {
        initSlides();
    }
    swiper.loopFix({
        slideRealIndex,
        direction: params.centeredSlides ? void 0 : 'next',
    });
}
function loopFix(_temp) {
    let {
        slideRealIndex,
        slideTo: slideTo2 = true,
        direction,
        setTranslate: setTranslate2,
        activeSlideIndex,
        byController,
        byMousewheel,
    } = _temp === void 0 ? {} : _temp;
    const swiper = this;
    if (!swiper.params.loop) return;
    swiper.emit('beforeLoopFix');
    const { slides, allowSlidePrev, allowSlideNext, slidesEl, params } = swiper;
    const { centeredSlides } = params;
    swiper.allowSlidePrev = true;
    swiper.allowSlideNext = true;
    if (swiper.virtual && params.virtual.enabled) {
        if (slideTo2) {
            if (!params.centeredSlides && swiper.snapIndex === 0) {
                swiper.slideTo(swiper.virtual.slides.length, 0, false, true);
            } else if (
                params.centeredSlides &&
                swiper.snapIndex < params.slidesPerView
            ) {
                swiper.slideTo(
                    swiper.virtual.slides.length + swiper.snapIndex,
                    0,
                    false,
                    true
                );
            } else if (swiper.snapIndex === swiper.snapGrid.length - 1) {
                swiper.slideTo(swiper.virtual.slidesBefore, 0, false, true);
            }
        }
        swiper.allowSlidePrev = allowSlidePrev;
        swiper.allowSlideNext = allowSlideNext;
        swiper.emit('loopFix');
        return;
    }
    let slidesPerView = params.slidesPerView;
    if (slidesPerView === 'auto') {
        slidesPerView = swiper.slidesPerViewDynamic();
    } else {
        slidesPerView = Math.ceil(parseFloat(params.slidesPerView, 10));
        if (centeredSlides && slidesPerView % 2 === 0) {
            slidesPerView = slidesPerView + 1;
        }
    }
    const slidesPerGroup = params.slidesPerGroupAuto
        ? slidesPerView
        : params.slidesPerGroup;
    let loopedSlides = slidesPerGroup;
    if (loopedSlides % slidesPerGroup !== 0) {
        loopedSlides += slidesPerGroup - (loopedSlides % slidesPerGroup);
    }
    loopedSlides += params.loopAdditionalSlides;
    swiper.loopedSlides = loopedSlides;
    const gridEnabled = swiper.grid && params.grid && params.grid.rows > 1;
    if (slides.length < slidesPerView + loopedSlides) {
        showWarning(
            'Swiper Loop Warning: The number of slides is not enough for loop mode, it will be disabled and not function properly. You need to add more slides (or make duplicates) or lower the values of slidesPerView and slidesPerGroup parameters'
        );
    } else if (gridEnabled && params.grid.fill === 'row') {
        showWarning(
            'Swiper Loop Warning: Loop mode is not compatible with grid.fill = `row`'
        );
    }
    const prependSlidesIndexes = [];
    const appendSlidesIndexes = [];
    let activeIndex = swiper.activeIndex;
    if (typeof activeSlideIndex === 'undefined') {
        activeSlideIndex = swiper.getSlideIndex(
            slides.filter((el) =>
                el.classList.contains(params.slideActiveClass)
            )[0]
        );
    } else {
        activeIndex = activeSlideIndex;
    }
    const isNext = direction === 'next' || !direction;
    const isPrev = direction === 'prev' || !direction;
    let slidesPrepended = 0;
    let slidesAppended = 0;
    const cols = gridEnabled
        ? Math.ceil(slides.length / params.grid.rows)
        : slides.length;
    const activeColIndex = gridEnabled
        ? slides[activeSlideIndex].column
        : activeSlideIndex;
    const activeColIndexWithShift =
        activeColIndex +
        (centeredSlides && typeof setTranslate2 === 'undefined'
            ? -slidesPerView / 2 + 0.5
            : 0);
    if (activeColIndexWithShift < loopedSlides) {
        slidesPrepended = Math.max(
            loopedSlides - activeColIndexWithShift,
            slidesPerGroup
        );
        for (let i = 0; i < loopedSlides - activeColIndexWithShift; i += 1) {
            const index = i - Math.floor(i / cols) * cols;
            if (gridEnabled) {
                const colIndexToPrepend = cols - index - 1;
                for (let i2 = slides.length - 1; i2 >= 0; i2 -= 1) {
                    if (slides[i2].column === colIndexToPrepend)
                        prependSlidesIndexes.push(i2);
                }
            } else {
                prependSlidesIndexes.push(cols - index - 1);
            }
        }
    } else if (activeColIndexWithShift + slidesPerView > cols - loopedSlides) {
        slidesAppended = Math.max(
            activeColIndexWithShift - (cols - loopedSlides * 2),
            slidesPerGroup
        );
        for (let i = 0; i < slidesAppended; i += 1) {
            const index = i - Math.floor(i / cols) * cols;
            if (gridEnabled) {
                slides.forEach((slide2, slideIndex) => {
                    if (slide2.column === index)
                        appendSlidesIndexes.push(slideIndex);
                });
            } else {
                appendSlidesIndexes.push(index);
            }
        }
    }
    swiper.__preventObserver__ = true;
    requestAnimationFrame(() => {
        swiper.__preventObserver__ = false;
    });
    if (isPrev) {
        prependSlidesIndexes.forEach((index) => {
            slides[index].swiperLoopMoveDOM = true;
            slidesEl.prepend(slides[index]);
            slides[index].swiperLoopMoveDOM = false;
        });
    }
    if (isNext) {
        appendSlidesIndexes.forEach((index) => {
            slides[index].swiperLoopMoveDOM = true;
            slidesEl.append(slides[index]);
            slides[index].swiperLoopMoveDOM = false;
        });
    }
    swiper.recalcSlides();
    if (params.slidesPerView === 'auto') {
        swiper.updateSlides();
    } else if (
        gridEnabled &&
        ((prependSlidesIndexes.length > 0 && isPrev) ||
            (appendSlidesIndexes.length > 0 && isNext))
    ) {
        swiper.slides.forEach((slide2, slideIndex) => {
            swiper.grid.updateSlide(slideIndex, slide2, swiper.slides);
        });
    }
    if (params.watchSlidesProgress) {
        swiper.updateSlidesOffset();
    }
    if (slideTo2) {
        if (prependSlidesIndexes.length > 0 && isPrev) {
            if (typeof slideRealIndex === 'undefined') {
                const currentSlideTranslate = swiper.slidesGrid[activeIndex];
                const newSlideTranslate =
                    swiper.slidesGrid[activeIndex + slidesPrepended];
                const diff = newSlideTranslate - currentSlideTranslate;
                if (byMousewheel) {
                    swiper.setTranslate(swiper.translate - diff);
                } else {
                    swiper.slideTo(
                        activeIndex + Math.ceil(slidesPrepended),
                        0,
                        false,
                        true
                    );
                    if (setTranslate2) {
                        swiper.touchEventsData.startTranslate =
                            swiper.touchEventsData.startTranslate - diff;
                        swiper.touchEventsData.currentTranslate =
                            swiper.touchEventsData.currentTranslate - diff;
                    }
                }
            } else {
                if (setTranslate2) {
                    const shift = gridEnabled
                        ? prependSlidesIndexes.length / params.grid.rows
                        : prependSlidesIndexes.length;
                    swiper.slideTo(swiper.activeIndex + shift, 0, false, true);
                    swiper.touchEventsData.currentTranslate = swiper.translate;
                }
            }
        } else if (appendSlidesIndexes.length > 0 && isNext) {
            if (typeof slideRealIndex === 'undefined') {
                const currentSlideTranslate = swiper.slidesGrid[activeIndex];
                const newSlideTranslate =
                    swiper.slidesGrid[activeIndex - slidesAppended];
                const diff = newSlideTranslate - currentSlideTranslate;
                if (byMousewheel) {
                    swiper.setTranslate(swiper.translate - diff);
                } else {
                    swiper.slideTo(
                        activeIndex - slidesAppended,
                        0,
                        false,
                        true
                    );
                    if (setTranslate2) {
                        swiper.touchEventsData.startTranslate =
                            swiper.touchEventsData.startTranslate - diff;
                        swiper.touchEventsData.currentTranslate =
                            swiper.touchEventsData.currentTranslate - diff;
                    }
                }
            } else {
                const shift = gridEnabled
                    ? appendSlidesIndexes.length / params.grid.rows
                    : appendSlidesIndexes.length;
                swiper.slideTo(swiper.activeIndex - shift, 0, false, true);
            }
        }
    }
    swiper.allowSlidePrev = allowSlidePrev;
    swiper.allowSlideNext = allowSlideNext;
    if (swiper.controller && swiper.controller.control && !byController) {
        const loopParams = {
            slideRealIndex,
            direction,
            setTranslate: setTranslate2,
            activeSlideIndex,
            byController: true,
        };
        if (Array.isArray(swiper.controller.control)) {
            swiper.controller.control.forEach((c) => {
                if (!c.destroyed && c.params.loop)
                    c.loopFix({
                        ...loopParams,
                        slideTo:
                            c.params.slidesPerView === params.slidesPerView
                                ? slideTo2
                                : false,
                    });
            });
        } else if (
            swiper.controller.control instanceof swiper.constructor &&
            swiper.controller.control.params.loop
        ) {
            swiper.controller.control.loopFix({
                ...loopParams,
                slideTo:
                    swiper.controller.control.params.slidesPerView ===
                    params.slidesPerView
                        ? slideTo2
                        : false,
            });
        }
    }
    swiper.emit('loopFix');
}
function loopDestroy() {
    const swiper = this;
    const { params, slidesEl } = swiper;
    if (!params.loop || (swiper.virtual && swiper.params.virtual.enabled))
        return;
    swiper.recalcSlides();
    const newSlidesOrder = [];
    swiper.slides.forEach((slideEl) => {
        const index =
            typeof slideEl.swiperSlideIndex === 'undefined'
                ? slideEl.getAttribute('data-swiper-slide-index') * 1
                : slideEl.swiperSlideIndex;
        newSlidesOrder[index] = slideEl;
    });
    swiper.slides.forEach((slideEl) => {
        slideEl.removeAttribute('data-swiper-slide-index');
    });
    newSlidesOrder.forEach((slideEl) => {
        slidesEl.append(slideEl);
    });
    swiper.recalcSlides();
    swiper.slideTo(swiper.realIndex, 0);
}
var loop = {
    loopCreate,
    loopFix,
    loopDestroy,
};
function setGrabCursor(moving) {
    const swiper = this;
    if (
        !swiper.params.simulateTouch ||
        (swiper.params.watchOverflow && swiper.isLocked) ||
        swiper.params.cssMode
    )
        return;
    const el =
        swiper.params.touchEventsTarget === 'container'
            ? swiper.el
            : swiper.wrapperEl;
    if (swiper.isElement) {
        swiper.__preventObserver__ = true;
    }
    el.style.cursor = 'move';
    el.style.cursor = moving ? 'grabbing' : 'grab';
    if (swiper.isElement) {
        requestAnimationFrame(() => {
            swiper.__preventObserver__ = false;
        });
    }
}
function unsetGrabCursor() {
    const swiper = this;
    if (
        (swiper.params.watchOverflow && swiper.isLocked) ||
        swiper.params.cssMode
    ) {
        return;
    }
    if (swiper.isElement) {
        swiper.__preventObserver__ = true;
    }
    swiper[
        swiper.params.touchEventsTarget === 'container' ? 'el' : 'wrapperEl'
    ].style.cursor = '';
    if (swiper.isElement) {
        requestAnimationFrame(() => {
            swiper.__preventObserver__ = false;
        });
    }
}
var grabCursor = {
    setGrabCursor,
    unsetGrabCursor,
};
function closestElement(selector3, base) {
    if (base === void 0) {
        base = this;
    }
    function __closestFrom(el) {
        if (!el || el === getDocument() || el === getWindow()) return null;
        if (el.assignedSlot) el = el.assignedSlot;
        const found = el.closest(selector3);
        if (!found && !el.getRootNode) {
            return null;
        }
        return found || __closestFrom(el.getRootNode().host);
    }
    return __closestFrom(base);
}
function preventEdgeSwipe(swiper, event2, startX) {
    const window2 = getWindow();
    const { params } = swiper;
    const edgeSwipeDetection = params.edgeSwipeDetection;
    const edgeSwipeThreshold = params.edgeSwipeThreshold;
    if (
        edgeSwipeDetection &&
        (startX <= edgeSwipeThreshold ||
            startX >= window2.innerWidth - edgeSwipeThreshold)
    ) {
        if (edgeSwipeDetection === 'prevent') {
            event2.preventDefault();
            return true;
        }
        return false;
    }
    return true;
}
function onTouchStart(event2) {
    const swiper = this;
    const document2 = getDocument();
    let e2 = event2;
    if (e2.originalEvent) e2 = e2.originalEvent;
    const data = swiper.touchEventsData;
    if (e2.type === 'pointerdown') {
        if (data.pointerId !== null && data.pointerId !== e2.pointerId) {
            return;
        }
        data.pointerId = e2.pointerId;
    } else if (e2.type === 'touchstart' && e2.targetTouches.length === 1) {
        data.touchId = e2.targetTouches[0].identifier;
    }
    if (e2.type === 'touchstart') {
        preventEdgeSwipe(swiper, e2, e2.targetTouches[0].pageX);
        return;
    }
    const { params, touches, enabled } = swiper;
    if (!enabled) return;
    if (!params.simulateTouch && e2.pointerType === 'mouse') return;
    if (swiper.animating && params.preventInteractionOnTransition) {
        return;
    }
    if (!swiper.animating && params.cssMode && params.loop) {
        swiper.loopFix();
    }
    let targetEl = e2.target;
    if (params.touchEventsTarget === 'wrapper') {
        if (!elementIsChildOf(targetEl, swiper.wrapperEl)) return;
    }
    if ('which' in e2 && e2.which === 3) return;
    if ('button' in e2 && e2.button > 0) return;
    if (data.isTouched && data.isMoved) return;
    const swipingClassHasValue =
        !!params.noSwipingClass && params.noSwipingClass !== '';
    const eventPath = e2.composedPath ? e2.composedPath() : e2.path;
    if (
        swipingClassHasValue &&
        e2.target &&
        e2.target.shadowRoot &&
        eventPath
    ) {
        targetEl = eventPath[0];
    }
    const noSwipingSelector = params.noSwipingSelector
        ? params.noSwipingSelector
        : `.${params.noSwipingClass}`;
    const isTargetShadow = !!(e2.target && e2.target.shadowRoot);
    if (
        params.noSwiping &&
        (isTargetShadow
            ? closestElement(noSwipingSelector, targetEl)
            : targetEl.closest(noSwipingSelector))
    ) {
        swiper.allowClick = true;
        return;
    }
    if (params.swipeHandler) {
        if (!targetEl.closest(params.swipeHandler)) return;
    }
    touches.currentX = e2.pageX;
    touches.currentY = e2.pageY;
    const startX = touches.currentX;
    const startY = touches.currentY;
    if (!preventEdgeSwipe(swiper, e2, startX)) {
        return;
    }
    Object.assign(data, {
        isTouched: true,
        isMoved: false,
        allowTouchCallbacks: true,
        isScrolling: void 0,
        startMoving: void 0,
    });
    touches.startX = startX;
    touches.startY = startY;
    data.touchStartTime = now();
    swiper.allowClick = true;
    swiper.updateSize();
    swiper.swipeDirection = void 0;
    if (params.threshold > 0) data.allowThresholdMove = false;
    let preventDefault = true;
    if (targetEl.matches(data.focusableElements)) {
        preventDefault = false;
        if (targetEl.nodeName === 'SELECT') {
            data.isTouched = false;
        }
    }
    if (
        document2.activeElement &&
        document2.activeElement.matches(data.focusableElements) &&
        document2.activeElement !== targetEl
    ) {
        document2.activeElement.blur();
    }
    const shouldPreventDefault =
        preventDefault &&
        swiper.allowTouchMove &&
        params.touchStartPreventDefault;
    if (
        (params.touchStartForcePreventDefault || shouldPreventDefault) &&
        !targetEl.isContentEditable
    ) {
        e2.preventDefault();
    }
    if (
        params.freeMode &&
        params.freeMode.enabled &&
        swiper.freeMode &&
        swiper.animating &&
        !params.cssMode
    ) {
        swiper.freeMode.onTouchStart();
    }
    swiper.emit('touchStart', e2);
}
function onTouchMove(event2) {
    const document2 = getDocument();
    const swiper = this;
    const data = swiper.touchEventsData;
    const { params, touches, rtlTranslate: rtl, enabled } = swiper;
    if (!enabled) return;
    if (!params.simulateTouch && event2.pointerType === 'mouse') return;
    let e2 = event2;
    if (e2.originalEvent) e2 = e2.originalEvent;
    if (e2.type === 'pointermove') {
        if (data.touchId !== null) return;
        const id = e2.pointerId;
        if (id !== data.pointerId) return;
    }
    let targetTouch;
    if (e2.type === 'touchmove') {
        targetTouch = [...e2.changedTouches].filter(
            (t2) => t2.identifier === data.touchId
        )[0];
        if (!targetTouch || targetTouch.identifier !== data.touchId) return;
    } else {
        targetTouch = e2;
    }
    if (!data.isTouched) {
        if (data.startMoving && data.isScrolling) {
            swiper.emit('touchMoveOpposite', e2);
        }
        return;
    }
    const pageX = targetTouch.pageX;
    const pageY = targetTouch.pageY;
    if (e2.preventedByNestedSwiper) {
        touches.startX = pageX;
        touches.startY = pageY;
        return;
    }
    if (!swiper.allowTouchMove) {
        if (!e2.target.matches(data.focusableElements)) {
            swiper.allowClick = false;
        }
        if (data.isTouched) {
            Object.assign(touches, {
                startX: pageX,
                startY: pageY,
                currentX: pageX,
                currentY: pageY,
            });
            data.touchStartTime = now();
        }
        return;
    }
    if (params.touchReleaseOnEdges && !params.loop) {
        if (swiper.isVertical()) {
            if (
                (pageY < touches.startY &&
                    swiper.translate <= swiper.maxTranslate()) ||
                (pageY > touches.startY &&
                    swiper.translate >= swiper.minTranslate())
            ) {
                data.isTouched = false;
                data.isMoved = false;
                return;
            }
        } else if (
            (pageX < touches.startX &&
                swiper.translate <= swiper.maxTranslate()) ||
            (pageX > touches.startX &&
                swiper.translate >= swiper.minTranslate())
        ) {
            return;
        }
    }
    if (document2.activeElement) {
        if (
            e2.target === document2.activeElement &&
            e2.target.matches(data.focusableElements)
        ) {
            data.isMoved = true;
            swiper.allowClick = false;
            return;
        }
    }
    if (data.allowTouchCallbacks) {
        swiper.emit('touchMove', e2);
    }
    touches.previousX = touches.currentX;
    touches.previousY = touches.currentY;
    touches.currentX = pageX;
    touches.currentY = pageY;
    const diffX = touches.currentX - touches.startX;
    const diffY = touches.currentY - touches.startY;
    if (
        swiper.params.threshold &&
        Math.sqrt(diffX ** 2 + diffY ** 2) < swiper.params.threshold
    )
        return;
    if (typeof data.isScrolling === 'undefined') {
        let touchAngle;
        if (
            (swiper.isHorizontal() && touches.currentY === touches.startY) ||
            (swiper.isVertical() && touches.currentX === touches.startX)
        ) {
            data.isScrolling = false;
        } else {
            if (diffX * diffX + diffY * diffY >= 25) {
                touchAngle =
                    (Math.atan2(Math.abs(diffY), Math.abs(diffX)) * 180) /
                    Math.PI;
                data.isScrolling = swiper.isHorizontal()
                    ? touchAngle > params.touchAngle
                    : 90 - touchAngle > params.touchAngle;
            }
        }
    }
    if (data.isScrolling) {
        swiper.emit('touchMoveOpposite', e2);
    }
    if (typeof data.startMoving === 'undefined') {
        if (
            touches.currentX !== touches.startX ||
            touches.currentY !== touches.startY
        ) {
            data.startMoving = true;
        }
    }
    if (
        data.isScrolling ||
        (e2.type === 'touchmove' && data.preventTouchMoveFromPointerMove)
    ) {
        data.isTouched = false;
        return;
    }
    if (!data.startMoving) {
        return;
    }
    swiper.allowClick = false;
    if (!params.cssMode && e2.cancelable) {
        e2.preventDefault();
    }
    if (params.touchMoveStopPropagation && !params.nested) {
        e2.stopPropagation();
    }
    let diff = swiper.isHorizontal() ? diffX : diffY;
    let touchesDiff = swiper.isHorizontal()
        ? touches.currentX - touches.previousX
        : touches.currentY - touches.previousY;
    if (params.oneWayMovement) {
        diff = Math.abs(diff) * (rtl ? 1 : -1);
        touchesDiff = Math.abs(touchesDiff) * (rtl ? 1 : -1);
    }
    touches.diff = diff;
    diff *= params.touchRatio;
    if (rtl) {
        diff = -diff;
        touchesDiff = -touchesDiff;
    }
    const prevTouchesDirection = swiper.touchesDirection;
    swiper.swipeDirection = diff > 0 ? 'prev' : 'next';
    swiper.touchesDirection = touchesDiff > 0 ? 'prev' : 'next';
    const isLoop = swiper.params.loop && !params.cssMode;
    const allowLoopFix =
        (swiper.touchesDirection === 'next' && swiper.allowSlideNext) ||
        (swiper.touchesDirection === 'prev' && swiper.allowSlidePrev);
    if (!data.isMoved) {
        if (isLoop && allowLoopFix) {
            swiper.loopFix({
                direction: swiper.swipeDirection,
            });
        }
        data.startTranslate = swiper.getTranslate();
        swiper.setTransition(0);
        if (swiper.animating) {
            const evt = new window.CustomEvent('transitionend', {
                bubbles: true,
                cancelable: true,
                detail: {
                    bySwiperTouchMove: true,
                },
            });
            swiper.wrapperEl.dispatchEvent(evt);
        }
        data.allowMomentumBounce = false;
        if (
            params.grabCursor &&
            (swiper.allowSlideNext === true || swiper.allowSlidePrev === true)
        ) {
            swiper.setGrabCursor(true);
        }
        swiper.emit('sliderFirstMove', e2);
    }
    let loopFixed;
    /* @__PURE__ */ new Date().getTime();
    if (
        data.isMoved &&
        data.allowThresholdMove &&
        prevTouchesDirection !== swiper.touchesDirection &&
        isLoop &&
        allowLoopFix &&
        Math.abs(diff) >= 1
    ) {
        Object.assign(touches, {
            startX: pageX,
            startY: pageY,
            currentX: pageX,
            currentY: pageY,
            startTranslate: data.currentTranslate,
        });
        data.loopSwapReset = true;
        data.startTranslate = data.currentTranslate;
        return;
    }
    swiper.emit('sliderMove', e2);
    data.isMoved = true;
    data.currentTranslate = diff + data.startTranslate;
    let disableParentSwiper = true;
    let resistanceRatio = params.resistanceRatio;
    if (params.touchReleaseOnEdges) {
        resistanceRatio = 0;
    }
    if (diff > 0) {
        if (
            isLoop &&
            allowLoopFix &&
            !loopFixed &&
            data.allowThresholdMove &&
            data.currentTranslate >
                (params.centeredSlides
                    ? swiper.minTranslate() -
                      swiper.slidesSizesGrid[swiper.activeIndex + 1] -
                      (params.slidesPerView !== 'auto' &&
                      swiper.slides.length - params.slidesPerView >= 2
                          ? swiper.slidesSizesGrid[swiper.activeIndex + 1] +
                            swiper.params.spaceBetween
                          : 0) -
                      swiper.params.spaceBetween
                    : swiper.minTranslate())
        ) {
            swiper.loopFix({
                direction: 'prev',
                setTranslate: true,
                activeSlideIndex: 0,
            });
        }
        if (data.currentTranslate > swiper.minTranslate()) {
            disableParentSwiper = false;
            if (params.resistance) {
                data.currentTranslate =
                    swiper.minTranslate() -
                    1 +
                    (-swiper.minTranslate() + data.startTranslate + diff) **
                        resistanceRatio;
            }
        }
    } else if (diff < 0) {
        if (
            isLoop &&
            allowLoopFix &&
            !loopFixed &&
            data.allowThresholdMove &&
            data.currentTranslate <
                (params.centeredSlides
                    ? swiper.maxTranslate() +
                      swiper.slidesSizesGrid[
                          swiper.slidesSizesGrid.length - 1
                      ] +
                      swiper.params.spaceBetween +
                      (params.slidesPerView !== 'auto' &&
                      swiper.slides.length - params.slidesPerView >= 2
                          ? swiper.slidesSizesGrid[
                                swiper.slidesSizesGrid.length - 1
                            ] + swiper.params.spaceBetween
                          : 0)
                    : swiper.maxTranslate())
        ) {
            swiper.loopFix({
                direction: 'next',
                setTranslate: true,
                activeSlideIndex:
                    swiper.slides.length -
                    (params.slidesPerView === 'auto'
                        ? swiper.slidesPerViewDynamic()
                        : Math.ceil(parseFloat(params.slidesPerView, 10))),
            });
        }
        if (data.currentTranslate < swiper.maxTranslate()) {
            disableParentSwiper = false;
            if (params.resistance) {
                data.currentTranslate =
                    swiper.maxTranslate() +
                    1 -
                    (swiper.maxTranslate() - data.startTranslate - diff) **
                        resistanceRatio;
            }
        }
    }
    if (disableParentSwiper) {
        e2.preventedByNestedSwiper = true;
    }
    if (
        !swiper.allowSlideNext &&
        swiper.swipeDirection === 'next' &&
        data.currentTranslate < data.startTranslate
    ) {
        data.currentTranslate = data.startTranslate;
    }
    if (
        !swiper.allowSlidePrev &&
        swiper.swipeDirection === 'prev' &&
        data.currentTranslate > data.startTranslate
    ) {
        data.currentTranslate = data.startTranslate;
    }
    if (!swiper.allowSlidePrev && !swiper.allowSlideNext) {
        data.currentTranslate = data.startTranslate;
    }
    if (params.threshold > 0) {
        if (Math.abs(diff) > params.threshold || data.allowThresholdMove) {
            if (!data.allowThresholdMove) {
                data.allowThresholdMove = true;
                touches.startX = touches.currentX;
                touches.startY = touches.currentY;
                data.currentTranslate = data.startTranslate;
                touches.diff = swiper.isHorizontal()
                    ? touches.currentX - touches.startX
                    : touches.currentY - touches.startY;
                return;
            }
        } else {
            data.currentTranslate = data.startTranslate;
            return;
        }
    }
    if (!params.followFinger || params.cssMode) return;
    if (
        (params.freeMode && params.freeMode.enabled && swiper.freeMode) ||
        params.watchSlidesProgress
    ) {
        swiper.updateActiveIndex();
        swiper.updateSlidesClasses();
    }
    if (params.freeMode && params.freeMode.enabled && swiper.freeMode) {
        swiper.freeMode.onTouchMove();
    }
    swiper.updateProgress(data.currentTranslate);
    swiper.setTranslate(data.currentTranslate);
}
function onTouchEnd(event2) {
    const swiper = this;
    const data = swiper.touchEventsData;
    let e2 = event2;
    if (e2.originalEvent) e2 = e2.originalEvent;
    let targetTouch;
    const isTouchEvent = e2.type === 'touchend' || e2.type === 'touchcancel';
    if (!isTouchEvent) {
        if (data.touchId !== null) return;
        if (e2.pointerId !== data.pointerId) return;
        targetTouch = e2;
    } else {
        targetTouch = [...e2.changedTouches].filter(
            (t2) => t2.identifier === data.touchId
        )[0];
        if (!targetTouch || targetTouch.identifier !== data.touchId) return;
    }
    if (
        ['pointercancel', 'pointerout', 'pointerleave', 'contextmenu'].includes(
            e2.type
        )
    ) {
        const proceed =
            ['pointercancel', 'contextmenu'].includes(e2.type) &&
            (swiper.browser.isSafari || swiper.browser.isWebView);
        if (!proceed) {
            return;
        }
    }
    data.pointerId = null;
    data.touchId = null;
    const { params, touches, rtlTranslate: rtl, slidesGrid, enabled } = swiper;
    if (!enabled) return;
    if (!params.simulateTouch && e2.pointerType === 'mouse') return;
    if (data.allowTouchCallbacks) {
        swiper.emit('touchEnd', e2);
    }
    data.allowTouchCallbacks = false;
    if (!data.isTouched) {
        if (data.isMoved && params.grabCursor) {
            swiper.setGrabCursor(false);
        }
        data.isMoved = false;
        data.startMoving = false;
        return;
    }
    if (
        params.grabCursor &&
        data.isMoved &&
        data.isTouched &&
        (swiper.allowSlideNext === true || swiper.allowSlidePrev === true)
    ) {
        swiper.setGrabCursor(false);
    }
    const touchEndTime = now();
    const timeDiff = touchEndTime - data.touchStartTime;
    if (swiper.allowClick) {
        const pathTree = e2.path || (e2.composedPath && e2.composedPath());
        swiper.updateClickedSlide(
            (pathTree && pathTree[0]) || e2.target,
            pathTree
        );
        swiper.emit('tap click', e2);
        if (timeDiff < 300 && touchEndTime - data.lastClickTime < 300) {
            swiper.emit('doubleTap doubleClick', e2);
        }
    }
    data.lastClickTime = now();
    nextTick(() => {
        if (!swiper.destroyed) swiper.allowClick = true;
    });
    if (
        !data.isTouched ||
        !data.isMoved ||
        !swiper.swipeDirection ||
        (touches.diff === 0 && !data.loopSwapReset) ||
        (data.currentTranslate === data.startTranslate && !data.loopSwapReset)
    ) {
        data.isTouched = false;
        data.isMoved = false;
        data.startMoving = false;
        return;
    }
    data.isTouched = false;
    data.isMoved = false;
    data.startMoving = false;
    let currentPos;
    if (params.followFinger) {
        currentPos = rtl ? swiper.translate : -swiper.translate;
    } else {
        currentPos = -data.currentTranslate;
    }
    if (params.cssMode) {
        return;
    }
    if (params.freeMode && params.freeMode.enabled) {
        swiper.freeMode.onTouchEnd({
            currentPos,
        });
        return;
    }
    const swipeToLast =
        currentPos >= -swiper.maxTranslate() && !swiper.params.loop;
    let stopIndex = 0;
    let groupSize = swiper.slidesSizesGrid[0];
    for (
        let i = 0;
        i < slidesGrid.length;
        i += i < params.slidesPerGroupSkip ? 1 : params.slidesPerGroup
    ) {
        const increment2 =
            i < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;
        if (typeof slidesGrid[i + increment2] !== 'undefined') {
            if (
                swipeToLast ||
                (currentPos >= slidesGrid[i] &&
                    currentPos < slidesGrid[i + increment2])
            ) {
                stopIndex = i;
                groupSize = slidesGrid[i + increment2] - slidesGrid[i];
            }
        } else if (swipeToLast || currentPos >= slidesGrid[i]) {
            stopIndex = i;
            groupSize =
                slidesGrid[slidesGrid.length - 1] -
                slidesGrid[slidesGrid.length - 2];
        }
    }
    let rewindFirstIndex = null;
    let rewindLastIndex = null;
    if (params.rewind) {
        if (swiper.isBeginning) {
            rewindLastIndex =
                params.virtual && params.virtual.enabled && swiper.virtual
                    ? swiper.virtual.slides.length - 1
                    : swiper.slides.length - 1;
        } else if (swiper.isEnd) {
            rewindFirstIndex = 0;
        }
    }
    const ratio = (currentPos - slidesGrid[stopIndex]) / groupSize;
    const increment =
        stopIndex < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;
    if (timeDiff > params.longSwipesMs) {
        if (!params.longSwipes) {
            swiper.slideTo(swiper.activeIndex);
            return;
        }
        if (swiper.swipeDirection === 'next') {
            if (ratio >= params.longSwipesRatio)
                swiper.slideTo(
                    params.rewind && swiper.isEnd
                        ? rewindFirstIndex
                        : stopIndex + increment
                );
            else swiper.slideTo(stopIndex);
        }
        if (swiper.swipeDirection === 'prev') {
            if (ratio > 1 - params.longSwipesRatio) {
                swiper.slideTo(stopIndex + increment);
            } else if (
                rewindLastIndex !== null &&
                ratio < 0 &&
                Math.abs(ratio) > params.longSwipesRatio
            ) {
                swiper.slideTo(rewindLastIndex);
            } else {
                swiper.slideTo(stopIndex);
            }
        }
    } else {
        if (!params.shortSwipes) {
            swiper.slideTo(swiper.activeIndex);
            return;
        }
        const isNavButtonTarget =
            swiper.navigation &&
            (e2.target === swiper.navigation.nextEl ||
                e2.target === swiper.navigation.prevEl);
        if (!isNavButtonTarget) {
            if (swiper.swipeDirection === 'next') {
                swiper.slideTo(
                    rewindFirstIndex !== null
                        ? rewindFirstIndex
                        : stopIndex + increment
                );
            }
            if (swiper.swipeDirection === 'prev') {
                swiper.slideTo(
                    rewindLastIndex !== null ? rewindLastIndex : stopIndex
                );
            }
        } else if (e2.target === swiper.navigation.nextEl) {
            swiper.slideTo(stopIndex + increment);
        } else {
            swiper.slideTo(stopIndex);
        }
    }
}
function onResize() {
    const swiper = this;
    const { params, el } = swiper;
    if (el && el.offsetWidth === 0) return;
    if (params.breakpoints) {
        swiper.setBreakpoint();
    }
    const { allowSlideNext, allowSlidePrev, snapGrid } = swiper;
    const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
    swiper.allowSlideNext = true;
    swiper.allowSlidePrev = true;
    swiper.updateSize();
    swiper.updateSlides();
    swiper.updateSlidesClasses();
    const isVirtualLoop = isVirtual && params.loop;
    if (
        (params.slidesPerView === 'auto' || params.slidesPerView > 1) &&
        swiper.isEnd &&
        !swiper.isBeginning &&
        !swiper.params.centeredSlides &&
        !isVirtualLoop
    ) {
        swiper.slideTo(swiper.slides.length - 1, 0, false, true);
    } else {
        if (swiper.params.loop && !isVirtual) {
            swiper.slideToLoop(swiper.realIndex, 0, false, true);
        } else {
            swiper.slideTo(swiper.activeIndex, 0, false, true);
        }
    }
    if (swiper.autoplay && swiper.autoplay.running && swiper.autoplay.paused) {
        clearTimeout(swiper.autoplay.resizeTimeout);
        swiper.autoplay.resizeTimeout = setTimeout(() => {
            if (
                swiper.autoplay &&
                swiper.autoplay.running &&
                swiper.autoplay.paused
            ) {
                swiper.autoplay.resume();
            }
        }, 500);
    }
    swiper.allowSlidePrev = allowSlidePrev;
    swiper.allowSlideNext = allowSlideNext;
    if (swiper.params.watchOverflow && snapGrid !== swiper.snapGrid) {
        swiper.checkOverflow();
    }
}
function onClick(e2) {
    const swiper = this;
    if (!swiper.enabled) return;
    if (!swiper.allowClick) {
        if (swiper.params.preventClicks) e2.preventDefault();
        if (swiper.params.preventClicksPropagation && swiper.animating) {
            e2.stopPropagation();
            e2.stopImmediatePropagation();
        }
    }
}
function onScroll() {
    const swiper = this;
    const { wrapperEl, rtlTranslate, enabled } = swiper;
    if (!enabled) return;
    swiper.previousTranslate = swiper.translate;
    if (swiper.isHorizontal()) {
        swiper.translate = -wrapperEl.scrollLeft;
    } else {
        swiper.translate = -wrapperEl.scrollTop;
    }
    if (swiper.translate === 0) swiper.translate = 0;
    swiper.updateActiveIndex();
    swiper.updateSlidesClasses();
    let newProgress;
    const translatesDiff = swiper.maxTranslate() - swiper.minTranslate();
    if (translatesDiff === 0) {
        newProgress = 0;
    } else {
        newProgress =
            (swiper.translate - swiper.minTranslate()) / translatesDiff;
    }
    if (newProgress !== swiper.progress) {
        swiper.updateProgress(
            rtlTranslate ? -swiper.translate : swiper.translate
        );
    }
    swiper.emit('setTranslate', swiper.translate, false);
}
function onLoad(e2) {
    const swiper = this;
    processLazyPreloader(swiper, e2.target);
    if (
        swiper.params.cssMode ||
        (swiper.params.slidesPerView !== 'auto' && !swiper.params.autoHeight)
    ) {
        return;
    }
    swiper.update();
}
function onDocumentTouchStart() {
    const swiper = this;
    if (swiper.documentTouchHandlerProceeded) return;
    swiper.documentTouchHandlerProceeded = true;
    if (swiper.params.touchReleaseOnEdges) {
        swiper.el.style.touchAction = 'auto';
    }
}
var events = (swiper, method) => {
    const document2 = getDocument();
    const { params, el, wrapperEl, device } = swiper;
    const capture = !!params.nested;
    const domMethod =
        method === 'on' ? 'addEventListener' : 'removeEventListener';
    const swiperMethod = method;
    if (!el || typeof el === 'string') return;
    document2[domMethod]('touchstart', swiper.onDocumentTouchStart, {
        passive: false,
        capture,
    });
    el[domMethod]('touchstart', swiper.onTouchStart, {
        passive: false,
    });
    el[domMethod]('pointerdown', swiper.onTouchStart, {
        passive: false,
    });
    document2[domMethod]('touchmove', swiper.onTouchMove, {
        passive: false,
        capture,
    });
    document2[domMethod]('pointermove', swiper.onTouchMove, {
        passive: false,
        capture,
    });
    document2[domMethod]('touchend', swiper.onTouchEnd, {
        passive: true,
    });
    document2[domMethod]('pointerup', swiper.onTouchEnd, {
        passive: true,
    });
    document2[domMethod]('pointercancel', swiper.onTouchEnd, {
        passive: true,
    });
    document2[domMethod]('touchcancel', swiper.onTouchEnd, {
        passive: true,
    });
    document2[domMethod]('pointerout', swiper.onTouchEnd, {
        passive: true,
    });
    document2[domMethod]('pointerleave', swiper.onTouchEnd, {
        passive: true,
    });
    document2[domMethod]('contextmenu', swiper.onTouchEnd, {
        passive: true,
    });
    if (params.preventClicks || params.preventClicksPropagation) {
        el[domMethod]('click', swiper.onClick, true);
    }
    if (params.cssMode) {
        wrapperEl[domMethod]('scroll', swiper.onScroll);
    }
    if (params.updateOnWindowResize) {
        swiper[swiperMethod](
            device.ios || device.android
                ? 'resize orientationchange observerUpdate'
                : 'resize observerUpdate',
            onResize,
            true
        );
    } else {
        swiper[swiperMethod]('observerUpdate', onResize, true);
    }
    el[domMethod]('load', swiper.onLoad, {
        capture: true,
    });
};
function attachEvents() {
    const swiper = this;
    const { params } = swiper;
    swiper.onTouchStart = onTouchStart.bind(swiper);
    swiper.onTouchMove = onTouchMove.bind(swiper);
    swiper.onTouchEnd = onTouchEnd.bind(swiper);
    swiper.onDocumentTouchStart = onDocumentTouchStart.bind(swiper);
    if (params.cssMode) {
        swiper.onScroll = onScroll.bind(swiper);
    }
    swiper.onClick = onClick.bind(swiper);
    swiper.onLoad = onLoad.bind(swiper);
    events(swiper, 'on');
}
function detachEvents() {
    const swiper = this;
    events(swiper, 'off');
}
var events$1 = {
    attachEvents,
    detachEvents,
};
var isGridEnabled = (swiper, params) => {
    return swiper.grid && params.grid && params.grid.rows > 1;
};
function setBreakpoint() {
    const swiper = this;
    const { realIndex, initialized, params, el } = swiper;
    const breakpoints2 = params.breakpoints;
    if (
        !breakpoints2 ||
        (breakpoints2 && Object.keys(breakpoints2).length === 0)
    )
        return;
    const breakpoint = swiper.getBreakpoint(
        breakpoints2,
        swiper.params.breakpointsBase,
        swiper.el
    );
    if (!breakpoint || swiper.currentBreakpoint === breakpoint) return;
    const breakpointOnlyParams =
        breakpoint in breakpoints2 ? breakpoints2[breakpoint] : void 0;
    const breakpointParams = breakpointOnlyParams || swiper.originalParams;
    const wasMultiRow = isGridEnabled(swiper, params);
    const isMultiRow = isGridEnabled(swiper, breakpointParams);
    const wasGrabCursor = swiper.params.grabCursor;
    const isGrabCursor = breakpointParams.grabCursor;
    const wasEnabled = params.enabled;
    if (wasMultiRow && !isMultiRow) {
        el.classList.remove(
            `${params.containerModifierClass}grid`,
            `${params.containerModifierClass}grid-column`
        );
        swiper.emitContainerClasses();
    } else if (!wasMultiRow && isMultiRow) {
        el.classList.add(`${params.containerModifierClass}grid`);
        if (
            (breakpointParams.grid.fill &&
                breakpointParams.grid.fill === 'column') ||
            (!breakpointParams.grid.fill && params.grid.fill === 'column')
        ) {
            el.classList.add(`${params.containerModifierClass}grid-column`);
        }
        swiper.emitContainerClasses();
    }
    if (wasGrabCursor && !isGrabCursor) {
        swiper.unsetGrabCursor();
    } else if (!wasGrabCursor && isGrabCursor) {
        swiper.setGrabCursor();
    }
    ['navigation', 'pagination', 'scrollbar'].forEach((prop) => {
        if (typeof breakpointParams[prop] === 'undefined') return;
        const wasModuleEnabled = params[prop] && params[prop].enabled;
        const isModuleEnabled =
            breakpointParams[prop] && breakpointParams[prop].enabled;
        if (wasModuleEnabled && !isModuleEnabled) {
            swiper[prop].disable();
        }
        if (!wasModuleEnabled && isModuleEnabled) {
            swiper[prop].enable();
        }
    });
    const directionChanged =
        breakpointParams.direction &&
        breakpointParams.direction !== params.direction;
    const needsReLoop =
        params.loop &&
        (breakpointParams.slidesPerView !== params.slidesPerView ||
            directionChanged);
    const wasLoop = params.loop;
    if (directionChanged && initialized) {
        swiper.changeDirection();
    }
    extend3(swiper.params, breakpointParams);
    const isEnabled = swiper.params.enabled;
    const hasLoop = swiper.params.loop;
    Object.assign(swiper, {
        allowTouchMove: swiper.params.allowTouchMove,
        allowSlideNext: swiper.params.allowSlideNext,
        allowSlidePrev: swiper.params.allowSlidePrev,
    });
    if (wasEnabled && !isEnabled) {
        swiper.disable();
    } else if (!wasEnabled && isEnabled) {
        swiper.enable();
    }
    swiper.currentBreakpoint = breakpoint;
    swiper.emit('_beforeBreakpoint', breakpointParams);
    if (initialized) {
        if (needsReLoop) {
            swiper.loopDestroy();
            swiper.loopCreate(realIndex);
            swiper.updateSlides();
        } else if (!wasLoop && hasLoop) {
            swiper.loopCreate(realIndex);
            swiper.updateSlides();
        } else if (wasLoop && !hasLoop) {
            swiper.loopDestroy();
        }
    }
    swiper.emit('breakpoint', breakpointParams);
}
function getBreakpoint(breakpoints2, base, containerEl) {
    if (base === void 0) {
        base = 'window';
    }
    if (!breakpoints2 || (base === 'container' && !containerEl)) return void 0;
    let breakpoint = false;
    const window2 = getWindow();
    const currentHeight =
        base === 'window' ? window2.innerHeight : containerEl.clientHeight;
    const points = Object.keys(breakpoints2).map((point) => {
        if (typeof point === 'string' && point.indexOf('@') === 0) {
            const minRatio = parseFloat(point.substr(1));
            const value = currentHeight * minRatio;
            return {
                value,
                point,
            };
        }
        return {
            value: point,
            point,
        };
    });
    points.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
    for (let i = 0; i < points.length; i += 1) {
        const { point, value } = points[i];
        if (base === 'window') {
            if (window2.matchMedia(`(min-width: ${value}px)`).matches) {
                breakpoint = point;
            }
        } else if (value <= containerEl.clientWidth) {
            breakpoint = point;
        }
    }
    return breakpoint || 'max';
}
var breakpoints = {
    setBreakpoint,
    getBreakpoint,
};
function prepareClasses(entries2, prefix) {
    const resultClasses = [];
    entries2.forEach((item) => {
        if (typeof item === 'object') {
            Object.keys(item).forEach((classNames) => {
                if (item[classNames]) {
                    resultClasses.push(prefix + classNames);
                }
            });
        } else if (typeof item === 'string') {
            resultClasses.push(prefix + item);
        }
    });
    return resultClasses;
}
function addClasses() {
    const swiper = this;
    const { classNames, params, rtl, el, device } = swiper;
    const suffixes = prepareClasses(
        [
            'initialized',
            params.direction,
            {
                'free-mode': swiper.params.freeMode && params.freeMode.enabled,
            },
            {
                autoheight: params.autoHeight,
            },
            {
                rtl: rtl,
            },
            {
                grid: params.grid && params.grid.rows > 1,
            },
            {
                'grid-column':
                    params.grid &&
                    params.grid.rows > 1 &&
                    params.grid.fill === 'column',
            },
            {
                android: device.android,
            },
            {
                ios: device.ios,
            },
            {
                'css-mode': params.cssMode,
            },
            {
                centered: params.cssMode && params.centeredSlides,
            },
            {
                'watch-progress': params.watchSlidesProgress,
            },
        ],
        params.containerModifierClass
    );
    classNames.push(...suffixes);
    el.classList.add(...classNames);
    swiper.emitContainerClasses();
}
function removeClasses() {
    const swiper = this;
    const { el, classNames } = swiper;
    if (!el || typeof el === 'string') return;
    el.classList.remove(...classNames);
    swiper.emitContainerClasses();
}
var classes = {
    addClasses,
    removeClasses,
};
function checkOverflow() {
    const swiper = this;
    const { isLocked: wasLocked, params } = swiper;
    const { slidesOffsetBefore } = params;
    if (slidesOffsetBefore) {
        const lastSlideIndex = swiper.slides.length - 1;
        const lastSlideRightEdge =
            swiper.slidesGrid[lastSlideIndex] +
            swiper.slidesSizesGrid[lastSlideIndex] +
            slidesOffsetBefore * 2;
        swiper.isLocked = swiper.size > lastSlideRightEdge;
    } else {
        swiper.isLocked = swiper.snapGrid.length === 1;
    }
    if (params.allowSlideNext === true) {
        swiper.allowSlideNext = !swiper.isLocked;
    }
    if (params.allowSlidePrev === true) {
        swiper.allowSlidePrev = !swiper.isLocked;
    }
    if (wasLocked && wasLocked !== swiper.isLocked) {
        swiper.isEnd = false;
    }
    if (wasLocked !== swiper.isLocked) {
        swiper.emit(swiper.isLocked ? 'lock' : 'unlock');
    }
}
var checkOverflow$1 = {
    checkOverflow,
};
var defaults3 = {
    init: true,
    direction: 'horizontal',
    oneWayMovement: false,
    swiperElementNodeName: 'SWIPER-CONTAINER',
    touchEventsTarget: 'wrapper',
    initialSlide: 0,
    speed: 300,
    cssMode: false,
    updateOnWindowResize: true,
    resizeObserver: true,
    nested: false,
    createElements: false,
    eventsPrefix: 'swiper',
    enabled: true,
    focusableElements: 'input, select, option, textarea, button, video, label',
    // Overrides
    width: null,
    height: null,
    //
    preventInteractionOnTransition: false,
    // ssr
    userAgent: null,
    url: null,
    // To support iOS's swipe-to-go-back gesture (when being used in-app).
    edgeSwipeDetection: false,
    edgeSwipeThreshold: 20,
    // Autoheight
    autoHeight: false,
    // Set wrapper width
    setWrapperSize: false,
    // Virtual Translate
    virtualTranslate: false,
    // Effects
    effect: 'slide',
    // 'slide' or 'fade' or 'cube' or 'coverflow' or 'flip'
    // Breakpoints
    breakpoints: void 0,
    breakpointsBase: 'window',
    // Slides grid
    spaceBetween: 0,
    slidesPerView: 1,
    slidesPerGroup: 1,
    slidesPerGroupSkip: 0,
    slidesPerGroupAuto: false,
    centeredSlides: false,
    centeredSlidesBounds: false,
    slidesOffsetBefore: 0,
    // in px
    slidesOffsetAfter: 0,
    // in px
    normalizeSlideIndex: true,
    centerInsufficientSlides: false,
    // Disable swiper and hide navigation when container not overflow
    watchOverflow: true,
    // Round length
    roundLengths: false,
    // Touches
    touchRatio: 1,
    touchAngle: 45,
    simulateTouch: true,
    shortSwipes: true,
    longSwipes: true,
    longSwipesRatio: 0.5,
    longSwipesMs: 300,
    followFinger: true,
    allowTouchMove: true,
    threshold: 5,
    touchMoveStopPropagation: false,
    touchStartPreventDefault: true,
    touchStartForcePreventDefault: false,
    touchReleaseOnEdges: false,
    // Unique Navigation Elements
    uniqueNavElements: true,
    // Resistance
    resistance: true,
    resistanceRatio: 0.85,
    // Progress
    watchSlidesProgress: false,
    // Cursor
    grabCursor: false,
    // Clicks
    preventClicks: true,
    preventClicksPropagation: true,
    slideToClickedSlide: false,
    // loop
    loop: false,
    loopAddBlankSlides: true,
    loopAdditionalSlides: 0,
    loopPreventsSliding: true,
    // rewind
    rewind: false,
    // Swiping/no swiping
    allowSlidePrev: true,
    allowSlideNext: true,
    swipeHandler: null,
    // '.swipe-handler',
    noSwiping: true,
    noSwipingClass: 'swiper-no-swiping',
    noSwipingSelector: null,
    // Passive Listeners
    passiveListeners: true,
    maxBackfaceHiddenSlides: 10,
    // NS
    containerModifierClass: 'swiper-',
    // NEW
    slideClass: 'swiper-slide',
    slideBlankClass: 'swiper-slide-blank',
    slideActiveClass: 'swiper-slide-active',
    slideVisibleClass: 'swiper-slide-visible',
    slideFullyVisibleClass: 'swiper-slide-fully-visible',
    slideNextClass: 'swiper-slide-next',
    slidePrevClass: 'swiper-slide-prev',
    wrapperClass: 'swiper-wrapper',
    lazyPreloaderClass: 'swiper-lazy-preloader',
    lazyPreloadPrevNext: 0,
    // Callbacks
    runCallbacksOnInit: true,
    // Internals
    _emitClasses: false,
};
function moduleExtendParams(params, allModulesParams) {
    return function extendParams(obj) {
        if (obj === void 0) {
            obj = {};
        }
        const moduleParamName = Object.keys(obj)[0];
        const moduleParams = obj[moduleParamName];
        if (typeof moduleParams !== 'object' || moduleParams === null) {
            extend3(allModulesParams, obj);
            return;
        }
        if (params[moduleParamName] === true) {
            params[moduleParamName] = {
                enabled: true,
            };
        }
        if (
            moduleParamName === 'navigation' &&
            params[moduleParamName] &&
            params[moduleParamName].enabled &&
            !params[moduleParamName].prevEl &&
            !params[moduleParamName].nextEl
        ) {
            params[moduleParamName].auto = true;
        }
        if (
            ['pagination', 'scrollbar'].indexOf(moduleParamName) >= 0 &&
            params[moduleParamName] &&
            params[moduleParamName].enabled &&
            !params[moduleParamName].el
        ) {
            params[moduleParamName].auto = true;
        }
        if (!(moduleParamName in params && 'enabled' in moduleParams)) {
            extend3(allModulesParams, obj);
            return;
        }
        if (
            typeof params[moduleParamName] === 'object' &&
            !('enabled' in params[moduleParamName])
        ) {
            params[moduleParamName].enabled = true;
        }
        if (!params[moduleParamName])
            params[moduleParamName] = {
                enabled: false,
            };
        extend3(allModulesParams, obj);
    };
}
var prototypes = {
    eventsEmitter,
    update,
    translate,
    transition,
    slide,
    loop,
    grabCursor,
    events: events$1,
    breakpoints,
    checkOverflow: checkOverflow$1,
    classes,
};
var extendedDefaults = {};
var Swiper = class _Swiper {
    constructor() {
        let el;
        let params;
        for (
            var _len = arguments.length, args = new Array(_len), _key = 0;
            _key < _len;
            _key++
        ) {
            args[_key] = arguments[_key];
        }
        if (
            args.length === 1 &&
            args[0].constructor &&
            Object.prototype.toString.call(args[0]).slice(8, -1) === 'Object'
        ) {
            params = args[0];
        } else {
            [el, params] = args;
        }
        if (!params) params = {};
        params = extend3({}, params);
        if (el && !params.el) params.el = el;
        const document2 = getDocument();
        if (
            params.el &&
            typeof params.el === 'string' &&
            document2.querySelectorAll(params.el).length > 1
        ) {
            const swipers = [];
            document2.querySelectorAll(params.el).forEach((containerEl) => {
                const newParams = extend3({}, params, {
                    el: containerEl,
                });
                swipers.push(new _Swiper(newParams));
            });
            return swipers;
        }
        const swiper = this;
        swiper.__swiper__ = true;
        swiper.support = getSupport();
        swiper.device = getDevice({
            userAgent: params.userAgent,
        });
        swiper.browser = getBrowser();
        swiper.eventsListeners = {};
        swiper.eventsAnyListeners = [];
        swiper.modules = [...swiper.__modules__];
        if (params.modules && Array.isArray(params.modules)) {
            swiper.modules.push(...params.modules);
        }
        const allModulesParams = {};
        swiper.modules.forEach((mod) => {
            mod({
                params,
                swiper,
                extendParams: moduleExtendParams(params, allModulesParams),
                on: swiper.on.bind(swiper),
                once: swiper.once.bind(swiper),
                off: swiper.off.bind(swiper),
                emit: swiper.emit.bind(swiper),
            });
        });
        const swiperParams = extend3({}, defaults3, allModulesParams);
        swiper.params = extend3({}, swiperParams, extendedDefaults, params);
        swiper.originalParams = extend3({}, swiper.params);
        swiper.passedParams = extend3({}, params);
        if (swiper.params && swiper.params.on) {
            Object.keys(swiper.params.on).forEach((eventName) => {
                swiper.on(eventName, swiper.params.on[eventName]);
            });
        }
        if (swiper.params && swiper.params.onAny) {
            swiper.onAny(swiper.params.onAny);
        }
        Object.assign(swiper, {
            enabled: swiper.params.enabled,
            el,
            // Classes
            classNames: [],
            // Slides
            slides: [],
            slidesGrid: [],
            snapGrid: [],
            slidesSizesGrid: [],
            // isDirection
            isHorizontal() {
                return swiper.params.direction === 'horizontal';
            },
            isVertical() {
                return swiper.params.direction === 'vertical';
            },
            // Indexes
            activeIndex: 0,
            realIndex: 0,
            //
            isBeginning: true,
            isEnd: false,
            // Props
            translate: 0,
            previousTranslate: 0,
            progress: 0,
            velocity: 0,
            animating: false,
            cssOverflowAdjustment() {
                return Math.trunc(this.translate / 2 ** 23) * 2 ** 23;
            },
            // Locks
            allowSlideNext: swiper.params.allowSlideNext,
            allowSlidePrev: swiper.params.allowSlidePrev,
            // Touch Events
            touchEventsData: {
                isTouched: void 0,
                isMoved: void 0,
                allowTouchCallbacks: void 0,
                touchStartTime: void 0,
                isScrolling: void 0,
                currentTranslate: void 0,
                startTranslate: void 0,
                allowThresholdMove: void 0,
                // Form elements to match
                focusableElements: swiper.params.focusableElements,
                // Last click time
                lastClickTime: 0,
                clickTimeout: void 0,
                // Velocities
                velocities: [],
                allowMomentumBounce: void 0,
                startMoving: void 0,
                pointerId: null,
                touchId: null,
            },
            // Clicks
            allowClick: true,
            // Touches
            allowTouchMove: swiper.params.allowTouchMove,
            touches: {
                startX: 0,
                startY: 0,
                currentX: 0,
                currentY: 0,
                diff: 0,
            },
            // Images
            imagesToLoad: [],
            imagesLoaded: 0,
        });
        swiper.emit('_swiper');
        if (swiper.params.init) {
            swiper.init();
        }
        return swiper;
    }
    getDirectionLabel(property) {
        if (this.isHorizontal()) {
            return property;
        }
        return {
            width: 'height',
            'margin-top': 'margin-left',
            'margin-bottom ': 'margin-right',
            'margin-left': 'margin-top',
            'margin-right': 'margin-bottom',
            'padding-left': 'padding-top',
            'padding-right': 'padding-bottom',
            marginRight: 'marginBottom',
        }[property];
    }
    getSlideIndex(slideEl) {
        const { slidesEl, params } = this;
        const slides = elementChildren(
            slidesEl,
            `.${params.slideClass}, swiper-slide`
        );
        const firstSlideIndex = elementIndex(slides[0]);
        return elementIndex(slideEl) - firstSlideIndex;
    }
    getSlideIndexByData(index) {
        return this.getSlideIndex(
            this.slides.filter(
                (slideEl) =>
                    slideEl.getAttribute('data-swiper-slide-index') * 1 ===
                    index
            )[0]
        );
    }
    recalcSlides() {
        const swiper = this;
        const { slidesEl, params } = swiper;
        swiper.slides = elementChildren(
            slidesEl,
            `.${params.slideClass}, swiper-slide`
        );
    }
    enable() {
        const swiper = this;
        if (swiper.enabled) return;
        swiper.enabled = true;
        if (swiper.params.grabCursor) {
            swiper.setGrabCursor();
        }
        swiper.emit('enable');
    }
    disable() {
        const swiper = this;
        if (!swiper.enabled) return;
        swiper.enabled = false;
        if (swiper.params.grabCursor) {
            swiper.unsetGrabCursor();
        }
        swiper.emit('disable');
    }
    setProgress(progress, speed) {
        const swiper = this;
        progress = Math.min(Math.max(progress, 0), 1);
        const min = swiper.minTranslate();
        const max = swiper.maxTranslate();
        const current = (max - min) * progress + min;
        swiper.translateTo(current, typeof speed === 'undefined' ? 0 : speed);
        swiper.updateActiveIndex();
        swiper.updateSlidesClasses();
    }
    emitContainerClasses() {
        const swiper = this;
        if (!swiper.params._emitClasses || !swiper.el) return;
        const cls = swiper.el.className.split(' ').filter((className) => {
            return (
                className.indexOf('swiper') === 0 ||
                className.indexOf(swiper.params.containerModifierClass) === 0
            );
        });
        swiper.emit('_containerClasses', cls.join(' '));
    }
    getSlideClasses(slideEl) {
        const swiper = this;
        if (swiper.destroyed) return '';
        return slideEl.className
            .split(' ')
            .filter((className) => {
                return (
                    className.indexOf('swiper-slide') === 0 ||
                    className.indexOf(swiper.params.slideClass) === 0
                );
            })
            .join(' ');
    }
    emitSlidesClasses() {
        const swiper = this;
        if (!swiper.params._emitClasses || !swiper.el) return;
        const updates = [];
        swiper.slides.forEach((slideEl) => {
            const classNames = swiper.getSlideClasses(slideEl);
            updates.push({
                slideEl,
                classNames,
            });
            swiper.emit('_slideClass', slideEl, classNames);
        });
        swiper.emit('_slideClasses', updates);
    }
    slidesPerViewDynamic(view, exact) {
        if (view === void 0) {
            view = 'current';
        }
        if (exact === void 0) {
            exact = false;
        }
        const swiper = this;
        const {
            params,
            slides,
            slidesGrid,
            slidesSizesGrid,
            size: swiperSize,
            activeIndex,
        } = swiper;
        let spv = 1;
        if (typeof params.slidesPerView === 'number')
            return params.slidesPerView;
        if (params.centeredSlides) {
            let slideSize = slides[activeIndex]
                ? Math.ceil(slides[activeIndex].swiperSlideSize)
                : 0;
            let breakLoop;
            for (let i = activeIndex + 1; i < slides.length; i += 1) {
                if (slides[i] && !breakLoop) {
                    slideSize += Math.ceil(slides[i].swiperSlideSize);
                    spv += 1;
                    if (slideSize > swiperSize) breakLoop = true;
                }
            }
            for (let i = activeIndex - 1; i >= 0; i -= 1) {
                if (slides[i] && !breakLoop) {
                    slideSize += slides[i].swiperSlideSize;
                    spv += 1;
                    if (slideSize > swiperSize) breakLoop = true;
                }
            }
        } else {
            if (view === 'current') {
                for (let i = activeIndex + 1; i < slides.length; i += 1) {
                    const slideInView = exact
                        ? slidesGrid[i] +
                              slidesSizesGrid[i] -
                              slidesGrid[activeIndex] <
                          swiperSize
                        : slidesGrid[i] - slidesGrid[activeIndex] < swiperSize;
                    if (slideInView) {
                        spv += 1;
                    }
                }
            } else {
                for (let i = activeIndex - 1; i >= 0; i -= 1) {
                    const slideInView =
                        slidesGrid[activeIndex] - slidesGrid[i] < swiperSize;
                    if (slideInView) {
                        spv += 1;
                    }
                }
            }
        }
        return spv;
    }
    update() {
        const swiper = this;
        if (!swiper || swiper.destroyed) return;
        const { snapGrid, params } = swiper;
        if (params.breakpoints) {
            swiper.setBreakpoint();
        }
        [...swiper.el.querySelectorAll('[loading="lazy"]')].forEach(
            (imageEl) => {
                if (imageEl.complete) {
                    processLazyPreloader(swiper, imageEl);
                }
            }
        );
        swiper.updateSize();
        swiper.updateSlides();
        swiper.updateProgress();
        swiper.updateSlidesClasses();
        function setTranslate2() {
            const translateValue = swiper.rtlTranslate
                ? swiper.translate * -1
                : swiper.translate;
            const newTranslate = Math.min(
                Math.max(translateValue, swiper.maxTranslate()),
                swiper.minTranslate()
            );
            swiper.setTranslate(newTranslate);
            swiper.updateActiveIndex();
            swiper.updateSlidesClasses();
        }
        let translated;
        if (params.freeMode && params.freeMode.enabled && !params.cssMode) {
            setTranslate2();
            if (params.autoHeight) {
                swiper.updateAutoHeight();
            }
        } else {
            if (
                (params.slidesPerView === 'auto' || params.slidesPerView > 1) &&
                swiper.isEnd &&
                !params.centeredSlides
            ) {
                const slides =
                    swiper.virtual && params.virtual.enabled
                        ? swiper.virtual.slides
                        : swiper.slides;
                translated = swiper.slideTo(slides.length - 1, 0, false, true);
            } else {
                translated = swiper.slideTo(swiper.activeIndex, 0, false, true);
            }
            if (!translated) {
                setTranslate2();
            }
        }
        if (params.watchOverflow && snapGrid !== swiper.snapGrid) {
            swiper.checkOverflow();
        }
        swiper.emit('update');
    }
    changeDirection(newDirection, needUpdate) {
        if (needUpdate === void 0) {
            needUpdate = true;
        }
        const swiper = this;
        const currentDirection = swiper.params.direction;
        if (!newDirection) {
            newDirection =
                currentDirection === 'horizontal' ? 'vertical' : 'horizontal';
        }
        if (
            newDirection === currentDirection ||
            (newDirection !== 'horizontal' && newDirection !== 'vertical')
        ) {
            return swiper;
        }
        swiper.el.classList.remove(
            `${swiper.params.containerModifierClass}${currentDirection}`
        );
        swiper.el.classList.add(
            `${swiper.params.containerModifierClass}${newDirection}`
        );
        swiper.emitContainerClasses();
        swiper.params.direction = newDirection;
        swiper.slides.forEach((slideEl) => {
            if (newDirection === 'vertical') {
                slideEl.style.width = '';
            } else {
                slideEl.style.height = '';
            }
        });
        swiper.emit('changeDirection');
        if (needUpdate) swiper.update();
        return swiper;
    }
    changeLanguageDirection(direction) {
        const swiper = this;
        if (
            (swiper.rtl && direction === 'rtl') ||
            (!swiper.rtl && direction === 'ltr')
        )
            return;
        swiper.rtl = direction === 'rtl';
        swiper.rtlTranslate =
            swiper.params.direction === 'horizontal' && swiper.rtl;
        if (swiper.rtl) {
            swiper.el.classList.add(
                `${swiper.params.containerModifierClass}rtl`
            );
            swiper.el.dir = 'rtl';
        } else {
            swiper.el.classList.remove(
                `${swiper.params.containerModifierClass}rtl`
            );
            swiper.el.dir = 'ltr';
        }
        swiper.update();
    }
    mount(element) {
        const swiper = this;
        if (swiper.mounted) return true;
        let el = element || swiper.params.el;
        if (typeof el === 'string') {
            el = document.querySelector(el);
        }
        if (!el) {
            return false;
        }
        el.swiper = swiper;
        if (
            el.parentNode &&
            el.parentNode.host &&
            el.parentNode.host.nodeName ===
                swiper.params.swiperElementNodeName.toUpperCase()
        ) {
            swiper.isElement = true;
        }
        const getWrapperSelector = () => {
            return `.${(swiper.params.wrapperClass || '')
                .trim()
                .split(' ')
                .join('.')}`;
        };
        const getWrapper = () => {
            if (el && el.shadowRoot && el.shadowRoot.querySelector) {
                const res = el.shadowRoot.querySelector(getWrapperSelector());
                return res;
            }
            return elementChildren(el, getWrapperSelector())[0];
        };
        let wrapperEl = getWrapper();
        if (!wrapperEl && swiper.params.createElements) {
            wrapperEl = createElement2('div', swiper.params.wrapperClass);
            el.append(wrapperEl);
            elementChildren(el, `.${swiper.params.slideClass}`).forEach(
                (slideEl) => {
                    wrapperEl.append(slideEl);
                }
            );
        }
        Object.assign(swiper, {
            el,
            wrapperEl,
            slidesEl:
                swiper.isElement && !el.parentNode.host.slideSlots
                    ? el.parentNode.host
                    : wrapperEl,
            hostEl: swiper.isElement ? el.parentNode.host : el,
            mounted: true,
            // RTL
            rtl:
                el.dir.toLowerCase() === 'rtl' ||
                elementStyle(el, 'direction') === 'rtl',
            rtlTranslate:
                swiper.params.direction === 'horizontal' &&
                (el.dir.toLowerCase() === 'rtl' ||
                    elementStyle(el, 'direction') === 'rtl'),
            wrongRTL: elementStyle(wrapperEl, 'display') === '-webkit-box',
        });
        return true;
    }
    init(el) {
        const swiper = this;
        if (swiper.initialized) return swiper;
        const mounted = swiper.mount(el);
        if (mounted === false) return swiper;
        swiper.emit('beforeInit');
        if (swiper.params.breakpoints) {
            swiper.setBreakpoint();
        }
        swiper.addClasses();
        swiper.updateSize();
        swiper.updateSlides();
        if (swiper.params.watchOverflow) {
            swiper.checkOverflow();
        }
        if (swiper.params.grabCursor && swiper.enabled) {
            swiper.setGrabCursor();
        }
        if (
            swiper.params.loop &&
            swiper.virtual &&
            swiper.params.virtual.enabled
        ) {
            swiper.slideTo(
                swiper.params.initialSlide + swiper.virtual.slidesBefore,
                0,
                swiper.params.runCallbacksOnInit,
                false,
                true
            );
        } else {
            swiper.slideTo(
                swiper.params.initialSlide,
                0,
                swiper.params.runCallbacksOnInit,
                false,
                true
            );
        }
        if (swiper.params.loop) {
            swiper.loopCreate();
        }
        swiper.attachEvents();
        const lazyElements = [
            ...swiper.el.querySelectorAll('[loading="lazy"]'),
        ];
        if (swiper.isElement) {
            lazyElements.push(
                ...swiper.hostEl.querySelectorAll('[loading="lazy"]')
            );
        }
        lazyElements.forEach((imageEl) => {
            if (imageEl.complete) {
                processLazyPreloader(swiper, imageEl);
            } else {
                imageEl.addEventListener('load', (e2) => {
                    processLazyPreloader(swiper, e2.target);
                });
            }
        });
        preload(swiper);
        swiper.initialized = true;
        preload(swiper);
        swiper.emit('init');
        swiper.emit('afterInit');
        return swiper;
    }
    destroy(deleteInstance, cleanStyles) {
        if (deleteInstance === void 0) {
            deleteInstance = true;
        }
        if (cleanStyles === void 0) {
            cleanStyles = true;
        }
        const swiper = this;
        const { params, el, wrapperEl, slides } = swiper;
        if (typeof swiper.params === 'undefined' || swiper.destroyed) {
            return null;
        }
        swiper.emit('beforeDestroy');
        swiper.initialized = false;
        swiper.detachEvents();
        if (params.loop) {
            swiper.loopDestroy();
        }
        if (cleanStyles) {
            swiper.removeClasses();
            if (el && typeof el !== 'string') {
                el.removeAttribute('style');
            }
            if (wrapperEl) {
                wrapperEl.removeAttribute('style');
            }
            if (slides && slides.length) {
                slides.forEach((slideEl) => {
                    slideEl.classList.remove(
                        params.slideVisibleClass,
                        params.slideFullyVisibleClass,
                        params.slideActiveClass,
                        params.slideNextClass,
                        params.slidePrevClass
                    );
                    slideEl.removeAttribute('style');
                    slideEl.removeAttribute('data-swiper-slide-index');
                });
            }
        }
        swiper.emit('destroy');
        Object.keys(swiper.eventsListeners).forEach((eventName) => {
            swiper.off(eventName);
        });
        if (deleteInstance !== false) {
            if (swiper.el && typeof swiper.el !== 'string') {
                swiper.el.swiper = null;
            }
            deleteProps(swiper);
        }
        swiper.destroyed = true;
        return null;
    }
    static extendDefaults(newDefaults) {
        extend3(extendedDefaults, newDefaults);
    }
    static get extendedDefaults() {
        return extendedDefaults;
    }
    static get defaults() {
        return defaults3;
    }
    static installModule(mod) {
        if (!_Swiper.prototype.__modules__) _Swiper.prototype.__modules__ = [];
        const modules = _Swiper.prototype.__modules__;
        if (typeof mod === 'function' && modules.indexOf(mod) < 0) {
            modules.push(mod);
        }
    }
    static use(module) {
        if (Array.isArray(module)) {
            module.forEach((m) => _Swiper.installModule(m));
            return _Swiper;
        }
        _Swiper.installModule(module);
        return _Swiper;
    }
};
Object.keys(prototypes).forEach((prototypeGroup) => {
    Object.keys(prototypes[prototypeGroup]).forEach((protoMethod) => {
        Swiper.prototype[protoMethod] = prototypes[prototypeGroup][protoMethod];
    });
});
Swiper.use([Resize, Observer]);

// node_modules/swiper/shared/create-element-if-not-defined.mjs
function createElementIfNotDefined(swiper, originalParams, params, checkProps) {
    if (swiper.params.createElements) {
        Object.keys(checkProps).forEach((key) => {
            if (!params[key] && params.auto === true) {
                let element = elementChildren(
                    swiper.el,
                    `.${checkProps[key]}`
                )[0];
                if (!element) {
                    element = createElement2('div', checkProps[key]);
                    element.className = checkProps[key];
                    swiper.el.append(element);
                }
                params[key] = element;
                originalParams[key] = element;
            }
        });
    }
    return params;
}

// node_modules/swiper/modules/navigation.mjs
function Navigation(_ref) {
    let { swiper, extendParams, on, emit } = _ref;
    extendParams({
        navigation: {
            nextEl: null,
            prevEl: null,
            hideOnClick: false,
            disabledClass: 'swiper-button-disabled',
            hiddenClass: 'swiper-button-hidden',
            lockClass: 'swiper-button-lock',
            navigationDisabledClass: 'swiper-navigation-disabled',
        },
    });
    swiper.navigation = {
        nextEl: null,
        prevEl: null,
    };
    function getEl(el) {
        let res;
        if (el && typeof el === 'string' && swiper.isElement) {
            res = swiper.el.querySelector(el);
            if (res) return res;
        }
        if (el) {
            if (typeof el === 'string')
                res = [...document.querySelectorAll(el)];
            if (
                swiper.params.uniqueNavElements &&
                typeof el === 'string' &&
                res &&
                res.length > 1 &&
                swiper.el.querySelectorAll(el).length === 1
            ) {
                res = swiper.el.querySelector(el);
            } else if (res && res.length === 1) {
                res = res[0];
            }
        }
        if (el && !res) return el;
        return res;
    }
    function toggleEl(el, disabled) {
        const params = swiper.params.navigation;
        el = makeElementsArray(el);
        el.forEach((subEl) => {
            if (subEl) {
                subEl.classList[disabled ? 'add' : 'remove'](
                    ...params.disabledClass.split(' ')
                );
                if (subEl.tagName === 'BUTTON') subEl.disabled = disabled;
                if (swiper.params.watchOverflow && swiper.enabled) {
                    subEl.classList[swiper.isLocked ? 'add' : 'remove'](
                        params.lockClass
                    );
                }
            }
        });
    }
    function update2() {
        const { nextEl, prevEl } = swiper.navigation;
        if (swiper.params.loop) {
            toggleEl(prevEl, false);
            toggleEl(nextEl, false);
            return;
        }
        toggleEl(prevEl, swiper.isBeginning && !swiper.params.rewind);
        toggleEl(nextEl, swiper.isEnd && !swiper.params.rewind);
    }
    function onPrevClick(e2) {
        e2.preventDefault();
        if (swiper.isBeginning && !swiper.params.loop && !swiper.params.rewind)
            return;
        swiper.slidePrev();
        emit('navigationPrev');
    }
    function onNextClick(e2) {
        e2.preventDefault();
        if (swiper.isEnd && !swiper.params.loop && !swiper.params.rewind)
            return;
        swiper.slideNext();
        emit('navigationNext');
    }
    function init4() {
        const params = swiper.params.navigation;
        swiper.params.navigation = createElementIfNotDefined(
            swiper,
            swiper.originalParams.navigation,
            swiper.params.navigation,
            {
                nextEl: 'swiper-button-next',
                prevEl: 'swiper-button-prev',
            }
        );
        if (!(params.nextEl || params.prevEl)) return;
        let nextEl = getEl(params.nextEl);
        let prevEl = getEl(params.prevEl);
        Object.assign(swiper.navigation, {
            nextEl,
            prevEl,
        });
        nextEl = makeElementsArray(nextEl);
        prevEl = makeElementsArray(prevEl);
        const initButton = (el, dir) => {
            if (el) {
                el.addEventListener(
                    'click',
                    dir === 'next' ? onNextClick : onPrevClick
                );
            }
            if (!swiper.enabled && el) {
                el.classList.add(...params.lockClass.split(' '));
            }
        };
        nextEl.forEach((el) => initButton(el, 'next'));
        prevEl.forEach((el) => initButton(el, 'prev'));
    }
    function destroy() {
        let { nextEl, prevEl } = swiper.navigation;
        nextEl = makeElementsArray(nextEl);
        prevEl = makeElementsArray(prevEl);
        const destroyButton = (el, dir) => {
            el.removeEventListener(
                'click',
                dir === 'next' ? onNextClick : onPrevClick
            );
            el.classList.remove(
                ...swiper.params.navigation.disabledClass.split(' ')
            );
        };
        nextEl.forEach((el) => destroyButton(el, 'next'));
        prevEl.forEach((el) => destroyButton(el, 'prev'));
    }
    on('init', () => {
        if (swiper.params.navigation.enabled === false) {
            disable();
        } else {
            init4();
            update2();
        }
    });
    on('toEdge fromEdge lock unlock', () => {
        update2();
    });
    on('destroy', () => {
        destroy();
    });
    on('enable disable', () => {
        let { nextEl, prevEl } = swiper.navigation;
        nextEl = makeElementsArray(nextEl);
        prevEl = makeElementsArray(prevEl);
        if (swiper.enabled) {
            update2();
            return;
        }
        [...nextEl, ...prevEl]
            .filter((el) => !!el)
            .forEach((el) =>
                el.classList.add(swiper.params.navigation.lockClass)
            );
    });
    on('click', (_s, e2) => {
        let { nextEl, prevEl } = swiper.navigation;
        nextEl = makeElementsArray(nextEl);
        prevEl = makeElementsArray(prevEl);
        const targetEl = e2.target;
        let targetIsButton =
            prevEl.includes(targetEl) || nextEl.includes(targetEl);
        if (swiper.isElement && !targetIsButton) {
            const path = e2.path || (e2.composedPath && e2.composedPath());
            if (path) {
                targetIsButton = path.find(
                    (pathEl) =>
                        nextEl.includes(pathEl) || prevEl.includes(pathEl)
                );
            }
        }
        if (swiper.params.navigation.hideOnClick && !targetIsButton) {
            if (
                swiper.pagination &&
                swiper.params.pagination &&
                swiper.params.pagination.clickable &&
                (swiper.pagination.el === targetEl ||
                    swiper.pagination.el.contains(targetEl))
            )
                return;
            let isHidden;
            if (nextEl.length) {
                isHidden = nextEl[0].classList.contains(
                    swiper.params.navigation.hiddenClass
                );
            } else if (prevEl.length) {
                isHidden = prevEl[0].classList.contains(
                    swiper.params.navigation.hiddenClass
                );
            }
            if (isHidden === true) {
                emit('navigationShow');
            } else {
                emit('navigationHide');
            }
            [...nextEl, ...prevEl]
                .filter((el) => !!el)
                .forEach((el) =>
                    el.classList.toggle(swiper.params.navigation.hiddenClass)
                );
        }
    });
    const enable = () => {
        swiper.el.classList.remove(
            ...swiper.params.navigation.navigationDisabledClass.split(' ')
        );
        init4();
        update2();
    };
    const disable = () => {
        swiper.el.classList.add(
            ...swiper.params.navigation.navigationDisabledClass.split(' ')
        );
        destroy();
    };
    Object.assign(swiper.navigation, {
        enable,
        disable,
        update: update2,
        init: init4,
        destroy,
    });
}

// node_modules/swiper/shared/classes-to-selector.mjs
function classesToSelector(classes2) {
    if (classes2 === void 0) {
        classes2 = '';
    }
    return `.${classes2
        .trim()
        .replace(/([\.:!+\/])/g, '\\$1')
        .replace(/ /g, '.')}`;
}

// node_modules/swiper/modules/pagination.mjs
function Pagination(_ref) {
    let { swiper, extendParams, on, emit } = _ref;
    const pfx = 'swiper-pagination';
    extendParams({
        pagination: {
            el: null,
            bulletElement: 'span',
            clickable: false,
            hideOnClick: false,
            renderBullet: null,
            renderProgressbar: null,
            renderFraction: null,
            renderCustom: null,
            progressbarOpposite: false,
            type: 'bullets',
            // 'bullets' or 'progressbar' or 'fraction' or 'custom'
            dynamicBullets: false,
            dynamicMainBullets: 1,
            formatFractionCurrent: (number) => number,
            formatFractionTotal: (number) => number,
            bulletClass: `${pfx}-bullet`,
            bulletActiveClass: `${pfx}-bullet-active`,
            modifierClass: `${pfx}-`,
            currentClass: `${pfx}-current`,
            totalClass: `${pfx}-total`,
            hiddenClass: `${pfx}-hidden`,
            progressbarFillClass: `${pfx}-progressbar-fill`,
            progressbarOppositeClass: `${pfx}-progressbar-opposite`,
            clickableClass: `${pfx}-clickable`,
            lockClass: `${pfx}-lock`,
            horizontalClass: `${pfx}-horizontal`,
            verticalClass: `${pfx}-vertical`,
            paginationDisabledClass: `${pfx}-disabled`,
        },
    });
    swiper.pagination = {
        el: null,
        bullets: [],
    };
    let bulletSize;
    let dynamicBulletIndex = 0;
    function isPaginationDisabled() {
        return (
            !swiper.params.pagination.el ||
            !swiper.pagination.el ||
            (Array.isArray(swiper.pagination.el) &&
                swiper.pagination.el.length === 0)
        );
    }
    function setSideBullets(bulletEl, position) {
        const { bulletActiveClass } = swiper.params.pagination;
        if (!bulletEl) return;
        bulletEl =
            bulletEl[
                `${position === 'prev' ? 'previous' : 'next'}ElementSibling`
            ];
        if (bulletEl) {
            bulletEl.classList.add(`${bulletActiveClass}-${position}`);
            bulletEl =
                bulletEl[
                    `${position === 'prev' ? 'previous' : 'next'}ElementSibling`
                ];
            if (bulletEl) {
                bulletEl.classList.add(
                    `${bulletActiveClass}-${position}-${position}`
                );
            }
        }
    }
    function getMoveDirection(prevIndex, nextIndex, length) {
        prevIndex = prevIndex % length;
        nextIndex = nextIndex % length;
        if (nextIndex === prevIndex + 1) {
            return 'next';
        } else if (nextIndex === prevIndex - 1) {
            return 'previous';
        }
        return;
    }
    function onBulletClick(e2) {
        const bulletEl = e2.target.closest(
            classesToSelector(swiper.params.pagination.bulletClass)
        );
        if (!bulletEl) {
            return;
        }
        e2.preventDefault();
        const index = elementIndex(bulletEl) * swiper.params.slidesPerGroup;
        if (swiper.params.loop) {
            if (swiper.realIndex === index) return;
            const moveDirection = getMoveDirection(
                swiper.realIndex,
                index,
                swiper.slides.length
            );
            if (moveDirection === 'next') {
                swiper.slideNext();
            } else if (moveDirection === 'previous') {
                swiper.slidePrev();
            } else {
                swiper.slideToLoop(index);
            }
        } else {
            swiper.slideTo(index);
        }
    }
    function update2() {
        const rtl = swiper.rtl;
        const params = swiper.params.pagination;
        if (isPaginationDisabled()) return;
        let el = swiper.pagination.el;
        el = makeElementsArray(el);
        let current;
        let previousIndex;
        const slidesLength =
            swiper.virtual && swiper.params.virtual.enabled
                ? swiper.virtual.slides.length
                : swiper.slides.length;
        const total = swiper.params.loop
            ? Math.ceil(slidesLength / swiper.params.slidesPerGroup)
            : swiper.snapGrid.length;
        if (swiper.params.loop) {
            previousIndex = swiper.previousRealIndex || 0;
            current =
                swiper.params.slidesPerGroup > 1
                    ? Math.floor(
                          swiper.realIndex / swiper.params.slidesPerGroup
                      )
                    : swiper.realIndex;
        } else if (typeof swiper.snapIndex !== 'undefined') {
            current = swiper.snapIndex;
            previousIndex = swiper.previousSnapIndex;
        } else {
            previousIndex = swiper.previousIndex || 0;
            current = swiper.activeIndex || 0;
        }
        if (
            params.type === 'bullets' &&
            swiper.pagination.bullets &&
            swiper.pagination.bullets.length > 0
        ) {
            const bullets = swiper.pagination.bullets;
            let firstIndex;
            let lastIndex;
            let midIndex;
            if (params.dynamicBullets) {
                bulletSize = elementOuterSize(
                    bullets[0],
                    swiper.isHorizontal() ? 'width' : 'height',
                    true
                );
                el.forEach((subEl) => {
                    subEl.style[
                        swiper.isHorizontal() ? 'width' : 'height'
                    ] = `${bulletSize * (params.dynamicMainBullets + 4)}px`;
                });
                if (params.dynamicMainBullets > 1 && previousIndex !== void 0) {
                    dynamicBulletIndex += current - (previousIndex || 0);
                    if (dynamicBulletIndex > params.dynamicMainBullets - 1) {
                        dynamicBulletIndex = params.dynamicMainBullets - 1;
                    } else if (dynamicBulletIndex < 0) {
                        dynamicBulletIndex = 0;
                    }
                }
                firstIndex = Math.max(current - dynamicBulletIndex, 0);
                lastIndex =
                    firstIndex +
                    (Math.min(bullets.length, params.dynamicMainBullets) - 1);
                midIndex = (lastIndex + firstIndex) / 2;
            }
            bullets.forEach((bulletEl) => {
                const classesToRemove = [
                    ...[
                        '',
                        '-next',
                        '-next-next',
                        '-prev',
                        '-prev-prev',
                        '-main',
                    ].map((suffix) => `${params.bulletActiveClass}${suffix}`),
                ]
                    .map((s) =>
                        typeof s === 'string' && s.includes(' ')
                            ? s.split(' ')
                            : s
                    )
                    .flat();
                bulletEl.classList.remove(...classesToRemove);
            });
            if (el.length > 1) {
                bullets.forEach((bullet) => {
                    const bulletIndex = elementIndex(bullet);
                    if (bulletIndex === current) {
                        bullet.classList.add(
                            ...params.bulletActiveClass.split(' ')
                        );
                    } else if (swiper.isElement) {
                        bullet.setAttribute('part', 'bullet');
                    }
                    if (params.dynamicBullets) {
                        if (
                            bulletIndex >= firstIndex &&
                            bulletIndex <= lastIndex
                        ) {
                            bullet.classList.add(
                                ...`${params.bulletActiveClass}-main`.split(' ')
                            );
                        }
                        if (bulletIndex === firstIndex) {
                            setSideBullets(bullet, 'prev');
                        }
                        if (bulletIndex === lastIndex) {
                            setSideBullets(bullet, 'next');
                        }
                    }
                });
            } else {
                const bullet = bullets[current];
                if (bullet) {
                    bullet.classList.add(
                        ...params.bulletActiveClass.split(' ')
                    );
                }
                if (swiper.isElement) {
                    bullets.forEach((bulletEl, bulletIndex) => {
                        bulletEl.setAttribute(
                            'part',
                            bulletIndex === current ? 'bullet-active' : 'bullet'
                        );
                    });
                }
                if (params.dynamicBullets) {
                    const firstDisplayedBullet = bullets[firstIndex];
                    const lastDisplayedBullet = bullets[lastIndex];
                    for (let i = firstIndex; i <= lastIndex; i += 1) {
                        if (bullets[i]) {
                            bullets[i].classList.add(
                                ...`${params.bulletActiveClass}-main`.split(' ')
                            );
                        }
                    }
                    setSideBullets(firstDisplayedBullet, 'prev');
                    setSideBullets(lastDisplayedBullet, 'next');
                }
            }
            if (params.dynamicBullets) {
                const dynamicBulletsLength = Math.min(
                    bullets.length,
                    params.dynamicMainBullets + 4
                );
                const bulletsOffset =
                    (bulletSize * dynamicBulletsLength - bulletSize) / 2 -
                    midIndex * bulletSize;
                const offsetProp = rtl ? 'right' : 'left';
                bullets.forEach((bullet) => {
                    bullet.style[
                        swiper.isHorizontal() ? offsetProp : 'top'
                    ] = `${bulletsOffset}px`;
                });
            }
        }
        el.forEach((subEl, subElIndex) => {
            if (params.type === 'fraction') {
                subEl
                    .querySelectorAll(classesToSelector(params.currentClass))
                    .forEach((fractionEl) => {
                        fractionEl.textContent = params.formatFractionCurrent(
                            current + 1
                        );
                    });
                subEl
                    .querySelectorAll(classesToSelector(params.totalClass))
                    .forEach((totalEl) => {
                        totalEl.textContent = params.formatFractionTotal(total);
                    });
            }
            if (params.type === 'progressbar') {
                let progressbarDirection;
                if (params.progressbarOpposite) {
                    progressbarDirection = swiper.isHorizontal()
                        ? 'vertical'
                        : 'horizontal';
                } else {
                    progressbarDirection = swiper.isHorizontal()
                        ? 'horizontal'
                        : 'vertical';
                }
                const scale = (current + 1) / total;
                let scaleX = 1;
                let scaleY = 1;
                if (progressbarDirection === 'horizontal') {
                    scaleX = scale;
                } else {
                    scaleY = scale;
                }
                subEl
                    .querySelectorAll(
                        classesToSelector(params.progressbarFillClass)
                    )
                    .forEach((progressEl) => {
                        progressEl.style.transform = `translate3d(0,0,0) scaleX(${scaleX}) scaleY(${scaleY})`;
                        progressEl.style.transitionDuration = `${swiper.params.speed}ms`;
                    });
            }
            if (params.type === 'custom' && params.renderCustom) {
                subEl.innerHTML = params.renderCustom(
                    swiper,
                    current + 1,
                    total
                );
                if (subElIndex === 0) emit('paginationRender', subEl);
            } else {
                if (subElIndex === 0) emit('paginationRender', subEl);
                emit('paginationUpdate', subEl);
            }
            if (swiper.params.watchOverflow && swiper.enabled) {
                subEl.classList[swiper.isLocked ? 'add' : 'remove'](
                    params.lockClass
                );
            }
        });
    }
    function render3() {
        const params = swiper.params.pagination;
        if (isPaginationDisabled()) return;
        const slidesLength =
            swiper.virtual && swiper.params.virtual.enabled
                ? swiper.virtual.slides.length
                : swiper.grid && swiper.params.grid.rows > 1
                ? swiper.slides.length / Math.ceil(swiper.params.grid.rows)
                : swiper.slides.length;
        let el = swiper.pagination.el;
        el = makeElementsArray(el);
        let paginationHTML = '';
        if (params.type === 'bullets') {
            let numberOfBullets = swiper.params.loop
                ? Math.ceil(slidesLength / swiper.params.slidesPerGroup)
                : swiper.snapGrid.length;
            if (
                swiper.params.freeMode &&
                swiper.params.freeMode.enabled &&
                numberOfBullets > slidesLength
            ) {
                numberOfBullets = slidesLength;
            }
            for (let i = 0; i < numberOfBullets; i += 1) {
                if (params.renderBullet) {
                    paginationHTML += params.renderBullet.call(
                        swiper,
                        i,
                        params.bulletClass
                    );
                } else {
                    paginationHTML += `<${params.bulletElement} ${
                        swiper.isElement ? 'part="bullet"' : ''
                    } class="${params.bulletClass}"></${params.bulletElement}>`;
                }
            }
        }
        if (params.type === 'fraction') {
            if (params.renderFraction) {
                paginationHTML = params.renderFraction.call(
                    swiper,
                    params.currentClass,
                    params.totalClass
                );
            } else {
                paginationHTML = `<span class="${params.currentClass}"></span> / <span class="${params.totalClass}"></span>`;
            }
        }
        if (params.type === 'progressbar') {
            if (params.renderProgressbar) {
                paginationHTML = params.renderProgressbar.call(
                    swiper,
                    params.progressbarFillClass
                );
            } else {
                paginationHTML = `<span class="${params.progressbarFillClass}"></span>`;
            }
        }
        swiper.pagination.bullets = [];
        el.forEach((subEl) => {
            if (params.type !== 'custom') {
                subEl.innerHTML = paginationHTML || '';
            }
            if (params.type === 'bullets') {
                swiper.pagination.bullets.push(
                    ...subEl.querySelectorAll(
                        classesToSelector(params.bulletClass)
                    )
                );
            }
        });
        if (params.type !== 'custom') {
            emit('paginationRender', el[0]);
        }
    }
    function init4() {
        swiper.params.pagination = createElementIfNotDefined(
            swiper,
            swiper.originalParams.pagination,
            swiper.params.pagination,
            {
                el: 'swiper-pagination',
            }
        );
        const params = swiper.params.pagination;
        if (!params.el) return;
        let el;
        if (typeof params.el === 'string' && swiper.isElement) {
            el = swiper.el.querySelector(params.el);
        }
        if (!el && typeof params.el === 'string') {
            el = [...document.querySelectorAll(params.el)];
        }
        if (!el) {
            el = params.el;
        }
        if (!el || el.length === 0) return;
        if (
            swiper.params.uniqueNavElements &&
            typeof params.el === 'string' &&
            Array.isArray(el) &&
            el.length > 1
        ) {
            el = [...swiper.el.querySelectorAll(params.el)];
            if (el.length > 1) {
                el = el.filter((subEl) => {
                    if (elementParents(subEl, '.swiper')[0] !== swiper.el)
                        return false;
                    return true;
                })[0];
            }
        }
        if (Array.isArray(el) && el.length === 1) el = el[0];
        Object.assign(swiper.pagination, {
            el,
        });
        el = makeElementsArray(el);
        el.forEach((subEl) => {
            if (params.type === 'bullets' && params.clickable) {
                subEl.classList.add(
                    ...(params.clickableClass || '').split(' ')
                );
            }
            subEl.classList.add(params.modifierClass + params.type);
            subEl.classList.add(
                swiper.isHorizontal()
                    ? params.horizontalClass
                    : params.verticalClass
            );
            if (params.type === 'bullets' && params.dynamicBullets) {
                subEl.classList.add(
                    `${params.modifierClass}${params.type}-dynamic`
                );
                dynamicBulletIndex = 0;
                if (params.dynamicMainBullets < 1) {
                    params.dynamicMainBullets = 1;
                }
            }
            if (params.type === 'progressbar' && params.progressbarOpposite) {
                subEl.classList.add(params.progressbarOppositeClass);
            }
            if (params.clickable) {
                subEl.addEventListener('click', onBulletClick);
            }
            if (!swiper.enabled) {
                subEl.classList.add(params.lockClass);
            }
        });
    }
    function destroy() {
        const params = swiper.params.pagination;
        if (isPaginationDisabled()) return;
        let el = swiper.pagination.el;
        if (el) {
            el = makeElementsArray(el);
            el.forEach((subEl) => {
                subEl.classList.remove(params.hiddenClass);
                subEl.classList.remove(params.modifierClass + params.type);
                subEl.classList.remove(
                    swiper.isHorizontal()
                        ? params.horizontalClass
                        : params.verticalClass
                );
                if (params.clickable) {
                    subEl.classList.remove(
                        ...(params.clickableClass || '').split(' ')
                    );
                    subEl.removeEventListener('click', onBulletClick);
                }
            });
        }
        if (swiper.pagination.bullets)
            swiper.pagination.bullets.forEach((subEl) =>
                subEl.classList.remove(...params.bulletActiveClass.split(' '))
            );
    }
    on('changeDirection', () => {
        if (!swiper.pagination || !swiper.pagination.el) return;
        const params = swiper.params.pagination;
        let { el } = swiper.pagination;
        el = makeElementsArray(el);
        el.forEach((subEl) => {
            subEl.classList.remove(
                params.horizontalClass,
                params.verticalClass
            );
            subEl.classList.add(
                swiper.isHorizontal()
                    ? params.horizontalClass
                    : params.verticalClass
            );
        });
    });
    on('init', () => {
        if (swiper.params.pagination.enabled === false) {
            disable();
        } else {
            init4();
            render3();
            update2();
        }
    });
    on('activeIndexChange', () => {
        if (typeof swiper.snapIndex === 'undefined') {
            update2();
        }
    });
    on('snapIndexChange', () => {
        update2();
    });
    on('snapGridLengthChange', () => {
        render3();
        update2();
    });
    on('destroy', () => {
        destroy();
    });
    on('enable disable', () => {
        let { el } = swiper.pagination;
        if (el) {
            el = makeElementsArray(el);
            el.forEach((subEl) =>
                subEl.classList[swiper.enabled ? 'remove' : 'add'](
                    swiper.params.pagination.lockClass
                )
            );
        }
    });
    on('lock unlock', () => {
        update2();
    });
    on('click', (_s, e2) => {
        const targetEl = e2.target;
        const el = makeElementsArray(swiper.pagination.el);
        if (
            swiper.params.pagination.el &&
            swiper.params.pagination.hideOnClick &&
            el &&
            el.length > 0 &&
            !targetEl.classList.contains(swiper.params.pagination.bulletClass)
        ) {
            if (
                swiper.navigation &&
                ((swiper.navigation.nextEl &&
                    targetEl === swiper.navigation.nextEl) ||
                    (swiper.navigation.prevEl &&
                        targetEl === swiper.navigation.prevEl))
            )
                return;
            const isHidden = el[0].classList.contains(
                swiper.params.pagination.hiddenClass
            );
            if (isHidden === true) {
                emit('paginationShow');
            } else {
                emit('paginationHide');
            }
            el.forEach((subEl) =>
                subEl.classList.toggle(swiper.params.pagination.hiddenClass)
            );
        }
    });
    const enable = () => {
        swiper.el.classList.remove(
            swiper.params.pagination.paginationDisabledClass
        );
        let { el } = swiper.pagination;
        if (el) {
            el = makeElementsArray(el);
            el.forEach((subEl) =>
                subEl.classList.remove(
                    swiper.params.pagination.paginationDisabledClass
                )
            );
        }
        init4();
        render3();
        update2();
    };
    const disable = () => {
        swiper.el.classList.add(
            swiper.params.pagination.paginationDisabledClass
        );
        let { el } = swiper.pagination;
        if (el) {
            el = makeElementsArray(el);
            el.forEach((subEl) =>
                subEl.classList.add(
                    swiper.params.pagination.paginationDisabledClass
                )
            );
        }
        destroy();
    };
    Object.assign(swiper.pagination, {
        enable,
        disable,
        render: render3,
        update: update2,
        init: init4,
        destroy,
    });
}

// global.js
gsapWithCSS.registerPlugin(import_ScrollTrigger.ScrollTrigger);
var typeSplit = new SplitType('[text-split]', {
    types: 'words, chars',
    tagName: 'span',
});
$('[txt-slide-up]').each(function (index) {
    let tl = new gsapWithCSS.timeline({ paused: true });
    tl.from($(this).find('.char'), {
        yPercent: 110,
        duration: 0.6,
        ease: 'back.out(2)',
        stagger: {
            amount: 0.4,
            ease: 'power1.in',
        },
    });
    tl.from(
        $(this).find('.hindi'),
        {
            opacity: 0,
            duration: 1,
            ease: 'power2.inout',
        },
        '-=0.3'
    );
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top bottom',
        onLeaveBack: () => {
            tl.pause(0);
        },
    });
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top 80%',
        onEnter: () => tl.play(),
    });
});
gsapWithCSS.set('[txt-slide-up]', { opacity: 1 });
$('[slide-from-left]').each(function (index) {
    let tl = new gsapWithCSS.timeline({ paused: true });
    tl.from($(this), {
        xPercent: -50,
        duration: 1,
        opacity: 0,
        ease: 'power2.out',
    });
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top bottom',
        onLeaveBack: () => {
            tl.pause(0);
        },
    });
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top 60%',
        onEnter: () => tl.play(),
    });
});
$('[slide-from-right]').each(function (index) {
    let tl = new gsapWithCSS.timeline({
        paused: true,
    });
    tl.from($(this), {
        xPercent: 50,
        duration: 1,
        opacity: 0,
        ease: 'power2.out',
    });
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top bottom',
        onLeaveBack: () => {
            tl.pause(0);
        },
    });
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top 60%',
        onEnter: () => tl.play(),
    });
});
$('[gsap="fade"]').each(function (index) {
    let tl = new gsapWithCSS.timeline({ paused: true });
    tl.from($(this), {
        duration: 1,
        opacity: 0,
        ease: 'power2.inout',
    });
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top bottom',
        onLeaveBack: () => {
            tl.pause(0);
        },
    });
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top 75%',
        onEnter: () => tl.play(),
    });
});
$('[gsap="grow"]').each(function (index) {
    let tl = new gsapWithCSS.timeline({ paused: true });
    tl.from($(this), {
        duration: 1,
        scale: 0.8,
        opacity: 0,
        ease: 'power2.out',
    });
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top bottom',
        onLeaveBack: () => {
            tl.pause(0);
        },
    });
    import_ScrollTrigger.ScrollTrigger.create({
        trigger: $(this),
        start: 'top 75%',
        onEnter: () => tl.play(),
    });
});
var menuTl = new gsapWithCSS.timeline({ paused: true });
menuTl.from($('.nav').find('.nav__link, .nav__star'), {
    yPercent: 110,
    opacity: 0,
    duration: 0.6,
    ease: 'back.out(2)',
    stagger: { amount: 0.4, ease: 'power1.in' },
});
$('.menu-btn').on('click', function () {
    if ($(this).attr('aria-expanded') === 'false') {
        setTimeout(function () {
            menuTl.play();
        }, 400);
        $(this).attr('aria-expanded', 'true');
        $('.nav').attr('aria-hidden', 'false');
    } else {
        menuTl.reverse();
        $(this).attr('aria-expanded', 'false');
        $('.nav').attr('aria-hidden', 'true');
    }
});
$('.swiper-component--booking').each(function (index) {
    const swiperBook = new Swiper($(this).find('.swiper')[0], {
        modules: [Navigation, Pagination],
        slidesPerView: 1.25,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        speed: 400,
        mousewheel: {
            forceToAxis: true,
        },
        pagination: {
            el: $(this).find('.swiper-bullet-wrapper')[0],
            type: 'bullets',
            bulletActiveClass: 'is-active',
            bulletClass: 'swiper-bullet--yellow',
            bulletElement: 'button',
            clickable: true,
        },
        navigation: {
            nextEl: $(this).find('.swiper-next')[0],
            prevEl: $(this).find('.swiper-prev')[0],
        },
    });
});
$('.faq__trigger').on('click', function () {
    if (!$(this).hasClass('is--open')) {
        $('.faq__trigger.is--open').click();
    }
    $(this).toggleClass('is--open');
});
var lenis;
if (Webflow.env('editor') === void 0) {
    let raf = function (time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    };
    lenis = new Lenis({
        lerp: 0.12,
        wheelMultiplier: 1,
        gestureOrientation: 'vertical',
        normalizeWheel: false,
        smoothTouch: false,
        smoothWheel: true,
    });
    requestAnimationFrame(raf);
    $('[data-lenis-start]').on('click', function () {
        lenis.start();
    });
    $('[data-lenis-stop]').on('click', function () {
        lenis.stop();
    });
    $('[data-lenis-toggle]').on('click', function () {
        $(this).toggleClass('stop-scroll');
        if ($(this).hasClass('stop-scroll')) {
            lenis.stop();
        } else {
            lenis.start();
        }
    });
    $('[mobile-reservations]').on('click', function () {
        $("[aria-label='menu']").trigger('click');
        setTimeout(() => {
            lenis.stop();
        }, 50);
    });
}

/*! Bundled license information:

gsap/dist/ScrollTrigger.js:
  (*!
   * Observer 3.12.5
   * https://gsap.com
   *
   * @license Copyright 2008-2024, GreenSock. All rights reserved.
   * Subject to the terms at https://gsap.com/standard-license or for
   * Club GSAP members, the agreement issued with that membership.
   * @author: Jack Doyle, jack@greensock.com
  *)
  (*!
   * ScrollTrigger 3.12.5
   * https://gsap.com
   *
   * @license Copyright 2008-2024, GreenSock. All rights reserved.
   * Subject to the terms at https://gsap.com/standard-license or for
   * Club GSAP members, the agreement issued with that membership.
   * @author: Jack Doyle, jack@greensock.com
  *)

gsap/gsap-core.js:
  (*!
   * GSAP 3.12.5
   * https://gsap.com
   *
   * @license Copyright 2008-2024, GreenSock. All rights reserved.
   * Subject to the terms at https://gsap.com/standard-license or for
   * Club GSAP members, the agreement issued with that membership.
   * @author: Jack Doyle, jack@greensock.com
  *)

gsap/CSSPlugin.js:
  (*!
   * CSSPlugin 3.12.5
   * https://gsap.com
   *
   * Copyright 2008-2024, GreenSock. All rights reserved.
   * Subject to the terms at https://gsap.com/standard-license or for
   * Club GSAP members, the agreement issued with that membership.
   * @author: Jack Doyle, jack@greensock.com
  *)
*/
